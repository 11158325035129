
import React, { useEffect } from 'react'

function AADSContainer() {
    useEffect(() => {



    }, [])
    return (
        <div className='page-container'>
            <iframe data-aa='2188243' src='//ad.a-ads.com/2188243?size=728x90' style={{
                width: '728px',
                height: '90px', minHeight: '90px', maxHeight: '90px', border: '0px', margin: '0 auto', padding: '0', overflow: 'hidden', backgroundColor: 'transparent'
            }}></iframe>
            <iframe data-aa='2188245' src='//ad.a-ads.com/2188245?size=728x90' style={{
                width: '728px',
                height: '90px', minHeight: '90px', maxHeight: '90px', border: '0px', margin: '0 auto', padding: '0', overflow: 'hidden', backgroundColor: 'transparent'
            }}></iframe>
        </div>
    )
}

export default AADSContainer