
import { useState, useEffect } from 'react'
import { useParams, useLocation, Link, Navigate } from "react-router-dom";
import $ from 'jquery';
import { socket } from '../../components/Containers/MainContainer';
import { Loader } from '../../components/Loader';

function PTCAdsView() {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const [ptcData, setPtcData] = useState(undefined)
    const { referral_id, page, second_page, third_page } = useParams();
    const [timeFinished, setTimeFinished] = useState(false)
    const [timerFaucet, setTimerFaucet] = useState(undefined);
    const [timerFaucetMessage, setTimerFaucetMessage] = useState(`Wait ${ptcData == undefined ? 0 : ptcData.waitingTime}s`);
    const [updateFaucetTimer, setUpdateFaucetTimer] = useState(false);
    const [focus, setFocus] = useState(true)
    useEffect(() => {
        socket.emit('get_ptc_data', {
            code: third_page
        })
        socket.on('ptc_data_view', (data) => {
            if (data.link == undefined) {
                window.location.href = "/ptc"
            } else {
                setPtcData(data)
                setTimerFaucet(data.waitingTime)
                setTimerFaucetMessage(`Wait ${data.waitingTime}s`)
            }
        })
        $(document).ready(function () {
            var interval_id;
            $(window).focus(function () {
                if (!interval_id) {
                    interval_id = setInterval(() => { }, 1000);
                }
            });

            $(window).blur(function () {
                clearInterval(interval_id);
                interval_id = 0;
            });

        });
        document.addEventListener('mouseleave', function (event) {
            setFocus(false)
        });
        document.addEventListener('mouseenter', function (event) {
            setFocus(true)
        });
    }, [])
    const { code } = useParams();

    useEffect(function () {
        if (document.title == 'Wait 1s') {
            document.title = 'PaidCash.co - Pay To Click Ads'
        }
        if (timerFaucet == undefined) {

        } else {
            setTimeout(function () {
                if (focus == false) {
                    if (timerFaucet > 0) {
                        setTimerFaucetMessage(timerFaucetMessage == "Come Back" ? 'Hey!' : 'Come Back');
                        document.title = timerFaucetMessage == "Come Back" ? 'Hey!' : 'Come Back';
                    }
                } else {
                    setTimerFaucet(timerFaucet - 1)
                    setTimerFaucetMessage(`Wait ${timerFaucet}s`)
                    if (timerFaucet > 0) {
                        document.title = `Wait ${timerFaucet}s`
                    }

                    if (timerFaucet > 0) {
                        setUpdateFaucetTimer(updateFaucetTimer == true ? false : true);
                    } else {
                        setTimeFinished(true)
                        setTimerFaucetMessage("Continue")
                    }
                }
            }, 1000 * 1)
        }

    }, [timerFaucet, timerFaucetMessage])


    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    return (ptcData == undefined ? <Loader /> : <>
        <section className="main-page" style={{ overflow: 'hidden' }}>
            <title>Pay To Click - PaidCash</title>




            {/* <PrizeClaim/> */}
            <div className="ptc-view-top">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <img src='/assets/img/faucetify.png' className="ptc-view-logo-desktop" alt="f" style={{ height: '39px', fontWeight: '450' }} />
                    <img src='/assets/img/faucetify.png' className="ptc-view-logo-mobile" alt="f" style={{ height: '39px', fontWeight: '450' }} />
                    <div className="ptc-view-mobile-info-top">
                        <p style={{ color: '#f3f3f3', fontSize: '10px', marginBottom: '0px', textAlign: 'left', marginLeft: '13px' }}>Please consider looking at the website below sincerely, not just for the reward!</p>
                        <p style={{ color: '#e34949', fontSize: '10px', marginBottom: '0px', textAlign: 'left', marginLeft: '13px' }}>PaidCash is not related and has no partnership with the website below!</p>
                    </div>
                </div>
                <div className="ptc-view-desktop-info">
                    <p style={{ color: '#f3f3f3', fontSize: '11px', marginBottom: '0px', textAlign: 'center' }}>Please consider looking at the website below sincerely, not just for the reward!</p>
                    <p style={{ color: '#e34949', fontSize: '11px', marginBottom: '0px', textAlign: 'center' }}>PaidCash is not related and has no partnership with the website below!</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start' }}>

                    </div>
                    <div onClick={() => {
                        if (timeFinished == true) {
                            window.dispatchEvent(new Event('continueClick'));
                            window.location.href = ptcData.link
                            socket.emit('userAction', {
                                action: 'watched_ptc_ad',
                                code: third_page
                            })

                        }
                    }} style={{
                        color: '#f3f3f3', fontSize: '14px', cursor: timeFinished == true ? 'pointer' : 'default', fontWeight: 'bold',
                        padding: '7px 20px', background: timeFinished == true ? '#0fa2af85' : '#3a3a3a', borderRadius: '4px', marginRight: '5px'
                    }}>
                        {timerFaucetMessage}
                    </div>
                </div>
            </div>
            <div className="ptc-view-mobile-info-bottom">
                <p style={{ color: '#ddc1c1', fontSize: '10px', marginBottom: '0px' }}>PaidCash is not related and has no partnership with the website below!</p>
            </div>
            {ptcData == undefined ? '' : <iframe id="ifrmame-site" seamless="seamless" className="ptc-view-iframe" src={ptcData.link} ></iframe>}

        </section>



    </>);
}
const getTime = (time) => {
    let date = new Date(time);
    let minutes = date.getMinutes() > 30 ? date.getMinutes() - 30 : date.getMinutes()
    let newTime = minutes + ":" + date.getSeconds();
    return newTime
}
export default PTCAdsView;