'use client'
import React from 'react'
import PlutoMenu from './PlutoMenu'
import { socket } from '../Containers/MainContainer';
import { useState } from 'react';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import ChartComp from './ChartComp';
import ChartComp2 from './ChartComp2';

import { numberWithCommas } from '../utils';
import PlutoUserModal from './PlutoUserModal';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';

function PlutoMain() {
    const [activeUsers, setActiveUsers] = useState(undefined);
    const [activeUsersMonthly, setActiveUsersMonthly] = useState(undefined);
    const [revenue, setRevenue] = useState(undefined);
    const [spendings, setSpendings] = useState(undefined);
    const [profits, setProfits] = useState(undefined);
    const [monthlyRev, setMonthlyRev] = useState(undefined);
    const [monthlyTarget, setMonthlyTaregt] = useState(undefined);
    const [monthlyPosibility, setMonthlyPosibility] = useState(undefined);

    const [top10Offers, setTop10Offers] = useState(undefined)
    const [last10Offers, setLast10Offers] = useState(undefined)
    const [last10Users, setLast10Users] = useState(undefined)
    const [rank, setRank] = useState(undefined)
    const [revt, setRevt] = useState(undefined)
    const [withT, setWithT] = useState(undefined)
    const [uid, setUID] = useState(-1)
    const [monthlyRevThis, setMonthlyRevTHis] = useState(undefined)
    const [dataWalls, setDataWalls] = useState(undefined)
    const [dataWallsX, setDataWallsX] = useState(undefined)
    useEffect(() => {

        socket.emit('getHomeAdmin')
        socket.on('response_get_home_admin_data', (data) => {
            setTop10Offers(data.top10OffersMadeToday)
            setMonthlyRevTHis(data.thisMonthRev)
            setLast10Offers(data.last10offers)
            setLast10Users(data.last10Users)
            setMonthlyRev(data.monthlyRev)
            setMonthlyTaregt(data.monthlyTarget)
            setMonthlyPosibility(data.monthlyPosibility)
            setRank(data.rank)
            setRevt(data.dailyR)
            setDataWallsX(data.dataWallsX)
            setDataWalls(data.dataWalls)
            setWithT(data.dailyW)
            setUID(data.uid);

        })
        socket.on('response_get_home_admin_data_activeusers', (data) => {
            setActiveUsers(data.activeUsers)
        })
        socket.on('response_get_home_admin_data_activeusersmonthly', (data) => {
            setActiveUsersMonthly(data.activeUsersMonthly)

        })
        socket.on('response_get_home_admin_data_dailyrev', (data) => {
            setRevenue(data.dataRev);
            setProfits(data.profits)
            setSpendings(data.dataWith);

        })

    }, [])

    return (
        <div style={{padding:'20px', maxWidth:'1200px', margin:'0 auto'}}>
            <div className='chart-grid-plto'>
                <div className='chart-plto'>
                    <h2>Last 7 Days active users</h2>
                    {activeUsers == undefined ? <Skeleton  style={{ height: '200px', width: '100%' }} /> : <ChartComp title="" data={activeUsers} chartID={"7usersac"} obj={'users'} />}
                </div>
                <div className='chart-plto'>
                    <h2>Daily Wall Earnings & Target</h2>
                    {dataWallsX == undefined ? <Skeleton style={{ height: '200px', width: '100%' }} /> : <ChartComp2 title="" title2="" title3='' title4='' walls={dataWallsX.map(x => x.wall_name).reverse()} dataType='walls' data={dataWallsX.map(x => x.usd).reverse()} data2={dataWallsX.map(x =>167).reverse()} chartID={"eariusersacmwallsdataWallsX"} obj={'$'} />}

                </div>
             
            </div>
            <div className='chart-grid-plto'>
             
                <div className='chart-plto'>
                    <h2>Daily Withdrawals, Revenue & Profit</h2>
                    {spendings == undefined ? <Skeleton style={{ height: '200px', width: '100%'}}  /> : <ChartComp2 title="" title2="" title3="" data={spendings} data2={revenue} data3={profits} chartID={"eariusersacm"} obj={'$'} />}
                </div>
                <div className='chart-plto'>
                    <h2>Monthly Revenue & Target </h2>
                    {monthlyRev == undefined || monthlyTarget == undefined ? <Skeleton style={{ height: '200px', width: '100%' }} /> : <ChartComp2 title="" title2="" title3="" data={monthlyRev} data2={monthlyTarget} chartID={"montlyrev"} obj={'$'} />}

                </div>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', flexWrap: 'wrap', marginBottom: '60px' }}> */}

                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
                    <div style={{ height: '200px', width: '90vw', maxWidth: '500px' }}>
                        {activeUsers == undefined ? <Skeleton style={{ height: '200px', width: '90vw', maxWidth: '500px' }} /> : <ChartComp title="Last 7 Days active users" data={activeUsers} chartID={"7usersac"} obj={'users'} />}
                    </div>

                </div> */}
                  {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
                    <div style={{ height: '200px', width: '90vw', maxWidth: '500px' }}>
                        {dataWallsX == undefined ? <Skeleton style={{ height: '200px', width: '90vw', maxWidth: '500px' }} /> : <ChartComp2 title="Chargebacks ($) Walls" title2="Daily Target" title3='' title4='' walls={dataWallsX.map(x => x.wall_name).reverse()} dataType='walls' data={dataWallsX.map(x => x.usd).reverse()} data2={dataWallsX.map(x =>167).reverse()} chartID={"eariusersacmwallsdataWallsX"} obj={'$'} />}
                    </div>

                </div>  */}

            {/* </div> */}
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', flexWrap: 'wrap', marginBottom: '100px' }}>

                {rank == undefined ? '' : rank == 10 ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', flexWrap: 'wrap', marginTop: '70px' }}>
                    <div style={{ height: '200px', width: '90vw', maxWidth: '500px' }}>
                        {spendings == undefined ? <Skeleton style={{ height: '200px', width: '90vw', maxWidth: '500px' }} /> : <ChartComp2 title="Spendings" title2="Revenue" title3="Profit" data={spendings} data2={revenue} data3={profits} chartID={"eariusersacm"} obj={'$'} />}
                    </div>

                </div> : ''}
                {rank == undefined ? '' : rank == 10 ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', flexWrap: 'wrap', marginTop: '70px' }}>
                    <div style={{ height: '200px', width: '90vw', maxWidth: '500px' }}>
                        {monthlyRev == undefined || monthlyTarget == undefined ? <Skeleton style={{ height: '200px', width: '90vw', maxWidth: '500px' }} /> : <ChartComp2 title="Earning [Red]" title2="Target [Blue]" title3="" data={monthlyRev} data2={monthlyTarget} chartID={"montlyrev"} obj={'$'} />}
                    </div>
                </div> : ''}
            </div> */}
            <div className='tables-plto'>
            <div className='table-x-plto'>

            <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>

                       <h3>Top 10 Offers made </h3>
                       <p style={{color:'#f3f3f3', fontSize:'15px'}}>R: $ {numberWithCommas(revt+'')} - W: $ {numberWithCommas(withT+'')} - M: $ {numberWithCommas(parseFloat(monthlyRevThis).toFixed(2)+'')}</p>
            <table className='table-plto'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Wall</th>
                    <th>Total Made</th>
                </tr>
            </thead>
            <tbody>
             
                {top10Offers == undefined ? "" : top10Offers.map((earning, i) => {
                    return (
                        <tr key={earning.id}>
                        <td>{earning.name || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>{earning.wall_name || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>{earning.c || <Skeleton style={{ width: '30px' }} />}</td>
                    </tr>
                      
                    )
                })}
            </tbody>
        </table>
        </div>
        </div>
            {/* <div style={{ overflow: 'auto', maxWidth: '700px', margin: '0 auto', padding: '20px', marginTop: '70px', background: 'var(--accent-1)' }}>

                <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Top 10 offers today ({revt}) || ({withT}) || ({monthlyRevThis})</p>

                <div style={{ fontWeight: 'bold' }}>
                    <div className="user-details-table-elements" >

                        <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                        <div style={{ minWidth: '100px' }}>Wall</div>
                        <div style={{ minWidth: '150px', textAlign: 'right' }}>Total Madde</div>

                    </div>
                </div>

                {top10Offers == undefined ? "" : top10Offers.map((earning, i) => {

                    return (<div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                        <div style={{ minWidth: '250px' }} > {earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                        <div style={{ minWidth: '100px' }} >{earning.wall_name || <Skeleton style={{ width: '30px' }} />}</div>
                        <div style={{ minWidth: '150px', textAlign: 'right' }} >{earning.c || <Skeleton style={{ width: '30px' }} />}</div>

                    </div>)
                })}
            </div> */}
            <div className='table-x-plto'>

              <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
            <h3>Last 10 Offers made</h3>
            <table className='table-plto'>
            <thead>
                <tr>
                    <th>#</th>
                    <th>User ID</th>
                    <th>Name</th>
                    <th>Wall</th>
                    <th>Country</th>
                </tr>
            </thead>
            <tbody>
             
                {last10Offers == undefined ? "" : last10Offers.map((earning, i) => {
                    return (
                        <tr key={earning.id}>
                        <td>{earning.id || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>  <Link to={`/pluto/user/${earning.user_id}`} style={{ minWidth: '50px', fontSize: '12px' }} > {earning.user_id || <Skeleton style={{ width: '30px' }} />}</Link></td>
                        <td>{earning.name || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>{earning.wall || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>{earning.country || <Skeleton style={{ width: '30px' }} />}</td>
                    </tr>
                      
                    )
                })}
            </tbody>
        </table>
        </div>
        </div>
            {/* <div style={{ overflow: 'auto', maxWidth: '700px', margin: '0 auto', padding: '20px', marginTop: '20px', background: 'var(--accent-1)' }}>

                <p style={{ color: 'var(--text-primary)', fontWeight: 'bold', }}>Last 10 Offers made</p>
                <div style={{ fontWeight: 'bold' }}>
                    <div className="user-details-table-elements" >

                        <div style={{ minWidth: '50px', width: '50px', textAlign: 'left', color: 'var(--text-primary)' }} >#</div>
                        <div style={{ minWidth: '50px', width: '50px', }}>User ID</div>
                        <div style={{ minWidth: '250px', maxWidth: '250px' }}>Name</div>
                        <div style={{ minWidth: '100px' }}>Wall</div>
                        <div style={{ minWidth: '150px', textAlign: 'right' }}>Country</div>

                    </div>
                </div>

                {last10Offers == undefined ? "" : last10Offers.map((earning, i) => {
                    return (
                        <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>
                            <div style={{ minWidth: '50px' }} > {earning.id || <Skeleton style={{ width: '30px' }} />}</div>
                            <Link to={`/pluto/user/${earning.user_id}`} style={{ minWidth: '50px', fontSize: '12px' }} > {earning.user_id || <Skeleton style={{ width: '30px' }} />}</Link>

                            <div style={{ minWidth: '250px' }} > {earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                            <div style={{ minWidth: '100px' }} >{earning.wall || <Skeleton style={{ width: '30px' }} />}</div>
                            <div style={{ minWidth: '150px', textAlign: 'right' }} >{earning.country || <Skeleton style={{ width: '30px' }} />}</div>

                        </div>
                    )
                })}
            </div> */}
            <div className='table-x-plto'>

              <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>

                        <h3>Last 10 Accounts Made</h3>
            <table className='table-plto'>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Balance</th>
                    <th>Name</th>
                    <th>Eamil Provider</th>
                    <th>Country</th>
                </tr>
            </thead>
            <tbody>
             
                {last10Users == undefined ? "" : last10Users.map((earning, i) => {
                    return (
                        <tr key={earning.id}>
                        <td><Link to={`/pluto/user/${earning.id}`} style={{ minWidth: '50px', fontSize: '12px' }} > {earning.id || <Skeleton style={{ width: '30px' }} />}</Link></td>
                        <td> {numberWithCommas(earning.balance) || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>{earning.name || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>{earning.email_provider || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>{earning.country || <Skeleton style={{ width: '30px' }} />}</td>
                    </tr>
                      
                    )
                })}
            </tbody>
        </table>
        </div>
        </div>
            {/* <div style={{ overflow: 'auto', maxWidth: '700px', margin: '0 auto', padding: '20px', marginTop: '20px', background: 'var(--accent-1)' }}>

                <p style={{ color: 'var(--text-primary)', fontWeight: 'bold', }}>Last 10 Users</p>
                <div style={{ fontWeight: 'bold' }}>
                    <div className="user-details-table-elements" >

                        <div style={{ minWidth: '50px', width: '50px', textAlign: 'left', color: 'var(--text-primary)' }} >#</div>
                        <div style={{ minWidth: '50px', width: '50px', }}>Balance</div>
                        <div style={{ minWidth: '250px', maxWidth: '250px' }}>Name</div>
                        <div style={{ minWidth: '100px' }}>Eamil Provider</div>
                        <div style={{ minWidth: '150px', textAlign: 'right' }}>Country</div>

                    </div>
                </div>

                {last10Users == undefined ? "" : last10Users.map((earning, i) => {
                    return (
                        <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>
                            <Link to={`/pluto/user/${earning.id}`} style={{ minWidth: '50px', fontSize: '12px' }} > {earning.id || <Skeleton style={{ width: '30px' }} />}</Link>
                            <div style={{ minWidth: '50px' }} > {numberWithCommas(earning.balance) || <Skeleton style={{ width: '30px' }} />}</div>

                            <div style={{ minWidth: '250px' }} > {earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                            <div style={{ minWidth: '100px' }} >{earning.email_provider || <Skeleton style={{ width: '30px' }} />}</div>
                            <div style={{ minWidth: '150px', textAlign: 'right' }} >{earning.country || <Skeleton style={{ width: '30px' }} />}</div>

                        </div>
                    )
                })}
            </div> */}
            <div className='table-x-plto'>

        <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>

                                  <h3>Monthly Target Per Offer Wall</h3>
            <table className='table-plto'>
            <thead>
                <tr>
                    <th>Wall</th>
                    <th>Target</th>
                    <th>Made</th>
                    <th>Remaining</th>
                </tr>
            </thead>
            <tbody>
             
                {dataWalls == undefined ? "" : dataWalls.map((earning, i) => {
                    return (
                        <tr key={earning.id}>
                        <td>{earning.wall_name || <Skeleton style={{ width: '30px' }} />}</td>
                        <td>{'$5,000' || <Skeleton style={{ width: '30px' }} />}</td>
                        <td style={{ background: earning.usd > 5000 ? '#124212' : earning.usd > 3000 ? '#474727' : '#4b1010', textAlign: 'right' }}>{'$' + numberWithCommas(earning.usd.toFixed(2)) || <Skeleton style={{ width: '30px' }} />}</td>
                        <td style={{ background: 5000 - parseFloat(earning.usd) <= 0 ? '#124212' :5000 - parseFloat(earning.usd) <= 2000?'#474727': '#4b1010',  textAlign: 'right' }}>{5000 - parseFloat(earning.usd) <= 0 ? 0 : '$' + numberWithCommas((5000 - parseFloat(earning.usd)).toFixed(2)) || <Skeleton style={{ width: '30px' }} />}</td>
                    </tr>
                      
                    )
                })}
            </tbody>
        </table>
        </div>
        </div>
        </div>
            {/* <div style={{ overflow: 'auto', maxWidth: '700px', margin: '0 auto', padding: '20px', marginTop: '20px', background: 'var(--accent-1)' }}>

                <p style={{ color: 'var(--text-primary)', fontWeight: 'bold', }}>OfferWall Data</p>
                <div style={{ fontWeight: 'bold' }}>
                    <div className="user-details-table-elements" >

                        <div style={{ minWidth: '250px', width: '50px', }}>Wall</div>
                        <div style={{ minWidth: '100px', maxWidth: '250px' }}>Target</div>
                        <div style={{ minWidth: '150px' }}>Made</div>
                        <div style={{ minWidth: '150px', textAlign: 'right' }}>Remaining</div>

                    </div>
                </div>

                {dataWalls == undefined ? "" : dataWalls.map((earning, i) => {
                    return (
                        <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                            <div style={{ minWidth: '250px' }} > {earning.wall_name || <Skeleton style={{ width: '30px' }} />}</div>
                            <div style={{ minWidth: '100px' }} >{'$5,000' || <Skeleton style={{ width: '30px' }} />}</div>
                            <div style={{ background: earning.usd > 5000 ? '#124212' : earning.usd > 3000 ? '#474727' : '#4b1010', minWidth: '150px', textAlign: 'right' }} >{'$' + numberWithCommas(earning.usd.toFixed(2)) || <Skeleton style={{ width: '30px' }} />}</div>
                            <div style={{ background: 5000 - parseFloat(earning.usd) <= 0 ? '#124212' :5000 - parseFloat(earning.usd) <= 2000?'#474727': '#4b1010', minWidth: '150px', textAlign: 'right' }} >{5000 - parseFloat(earning.usd) <= 0 ? 0 : '$' + numberWithCommas((5000 - parseFloat(earning.usd)).toFixed(2)) || <Skeleton style={{ width: '30px' }} />}</div>

                        </div>
                    )
                })}
            </div> */}
        </div>
    )
}

export default PlutoMain