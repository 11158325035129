'use client'
import { socket } from '../Containers/MainContainer';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

function VerifyAdmin({ rank }) {
    const [pcSent, sPCS] = useState(false);
    const [code, setCode] = useState(0)
    // useEffect(() => {
    //     socket.emit('adminAction', {
    //         action: 'send_admin_verification_email'
    //     })
    // }, [])
    return (rank <= 1 ? <span className="loader"></span> : <>

        <div className="Auth-form-container">

            <form className="Auth-form">
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Security Check</h3>
                    <p>This ip is not in your trusted list. Please request a passcode and then verify it.</p>
                    {pcSent == false ? <p onClick={() => {
                        sPCS(true)
                        if (window.socket != undefined) {
                            socket.emit('adminAction', {
                                action: 'send_admin_verification_email'
                            })
                        }
                    }} className=" mt-2" style={{ color: 'blue', cursor: 'pointer' }}>
                        Send passcode to email
                    </p>
                        : <><div className="form-group mt-3">
                            <p>Passcode sent to your email. Please check your spam folder too</p>
                            <p onClick={() => {
                                socket.emit('adminAction', {
                                    action: 'send_admin_verification_email'
                                })
                            }} className=" mt-2" style={{ color: 'blue', cursor: 'pointer' }}>
                                Resent passcode
                            </p> <div className="input_container" style={{ marginTop: '10px' }}>
                                <label >Passcode</label>
                                <input
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setCode(e.target.value)
                                    }}
                                    type="password"
                                    className="input_field"
                                    placeholder="Enter passcode"
                                />
                            </div>
                        </div>
                            <div className="d-grid gap-2 mt-3">
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    socket.emit('adminAction', {
                                        action: 'check_admin_verification_code',
                                        code,
                                    })
                                }} type="submit" className="btn btn-brand">
                                    Submit
                                </button>
                            </div> </>}

                </div>
            </form>
        </div>
    </>)
}

export default VerifyAdmin