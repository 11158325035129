'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';

import { useEffect } from 'react';
import { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PlutoMenu from './PlutoMenu';
import PaginationPluto from './Pagination';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';

function PlutoOffers() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [rank, setRank] = useState(0)

    useEffect(() => {
        socket.on('response_get_admin_offers', (data) => {
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 5))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)

        })
        socket.emit('getOffersAdmin', {
            offers_like: username,
            page,
        })
    }, [])
    useEffect(() => {
        socket.on('reTakePendingData', () => {
            socket.emit('getOffersAdmin', {
                offers_like: username,

                page,
            })
        })
    }, [])
    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">

            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {userProfile.withdrawalsLength != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.withdrawalsLength)}
                            </p>
                            <p>Count offers</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}


                </div>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Suspect Offers</p>
                    <div className="input-container">
                        <input style={{ borderRadius: '5px', background: 'transparent', marginBottom: '5px', color: '#fff', fontSize: '12px', padding: '5px' }} onChange={(e) => {
                            setUsername(e.currentTarget.value)
                            setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

                            socket.emit('getOffersAdmin', {
                                offers_like: e.currentTarget.value.toLowerCase(),
                                page: 1,
                            })

                        }} type="text" name="amount" placeholder="Search by offer name" autoComplete="off" />
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                            <div style={{ minWidth: '200px' }}>Amount</div>
                            <div style={{ minWidth: '90px' }}>Payout</div>
                            <div style={{ minWidth: '50px' }}>Wall Name</div>
                            <div style={{ minWidth: '50px' }}>Date</div>
                            <div style={{ minWidth: '50px' }}>Country</div>
                            <div style={{ minWidth: '50px' }}>CB Rate</div>

                            <div style={{ minWidth: '100px', maxWidth: '100px' }}>Edit</div>

                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '200px' }} >{earning.amount || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '90px', }} >{earning.payout || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', }} >{earning.wall || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', }} >{earning.date || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', }} >{earning.country || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{
                                    minWidth: '50px',
                                    background: parseInt(earning.cb_rate) <= 10 && parseInt(earning.cb_rate) >= 1 ? '#434317' :
                                        parseInt(earning.cb_rate) <= 20 && parseInt(earning.cb_rate) > 10 ? '#5c5c1f' :
                                            parseInt(earning.cb_rate) <= 40 && parseInt(earning.cb_rate) > 20 ? '#4c1b1a' :
                                                parseInt(earning.cb_rate) >= 50 ? '#702322' : ''
                                }} >{earning.cb_rate || <Skeleton style={{ width: '30px' }} />}</div>

                                <Link to={`/pluto/user/${earning.user_id}`} className="button button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px', fontSize: '10px' }} >{earning.id == undefined ? <Skeleton style={{ width: '30px' }} /> : 'Check User'}</Link>

                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getOffersAdmin', {
                        offers_like: username,

                        page,
                    })
                }} />}

            </div>
            <div >
                <input onKeyDown={
                    (e) => {
                        if (e.key == 'Enter') {
                            canUsePagination(false)
                            setEarnings([0, 0, 0, 0, 0])
                            socket.emit('getOffersAdmin', {
                                offers_like: username,
                                page: document.getElementById('jumptotoday2').value,
                            })
                        }
                    }

                } type="number" id="jumptotoday2" style={{ background: 'gray', color: '#000' }} />
                <button onClick={() => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getOffersAdmin', {
                        offers_like: username,
                        page: document.getElementById('jumptotoday2').value,
                    })
                }
                } style={{ color: '#fff', background: '#000' }}>Go to page</button>
            </div>
        </SkeletonTheme>
    )
}

export default PlutoOffers