'use client '
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'

import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import '@splidejs/react-splide/css';
import './BigSliderOffers.css'
import { IoLogoAndroid } from 'react-icons/io'
import { FaAndroid, FaApple, FaDollarSign, FaLaptop } from 'react-icons/fa'
import { USDWithCommas, getChatStatus, getDevicesSelected, getOfferWallName, getShownCurency, isLoggedIn, numberWithCommas, showLoginModal } from '../utils'
import { socket } from '../Containers/MainContainer'
import ProgressiveImage from 'react-progressive-image-loading';

let i = Math.floor(Math.random() * 10) + 1;
function BigSliderOffers() {
    const [hapa, setHandleAmount] = useState(getShownCurency() == 'usd' ? 1 : 0)
    function handleAmount(am) {
        if (hapa == 1) {
            return USDWithCommas(am)
        } else {
            return numberWithCommas(am)
        }
    }
    useEffect(() => {
        window.addEventListener('shownCurrency', () => {
            if (getShownCurency() == 'usd') {
                setHandleAmount(1)
            } else {
                setHandleAmount(0)
            }
        })
    }, [getShownCurency()])
    let [loggedIN, setLogged] = useState(isLoggedIn());
    useEffect(() => { setLogged(isLoggedIn()) }, [isLoggedIn()])


    function getBodyValue() { return (getChatStatus() == true) && document.querySelector('body').getBoundingClientRect().width > 1200 ? document.querySelector('body').clientWidth - 480 : document.querySelector('body').clientWidth - 230 }
    const [perpage, setPerPage] = useState(Math.floor((getBodyValue()) / 220))
    useEffect(() => {
        setPerPage(Math.floor((getBodyValue()) / 220))

        window.addEventListener('resize', () => {
            if (document.querySelector('body').getBoundingClientRect().width > 1200) {
                setPerPage(Math.floor((getBodyValue()) / 220))
            }
        })
    }, [])
    useEffect(() => {
        window.addEventListener('showChat', () => {
            setPerPage(Math.floor((getBodyValue()) / 220))
        })
    }, [])
    const options = {
        rewind: false,
        perPage: perpage,

        perMove: 1,
        gap: 20,
        padding: "0",
        pagination: false,
        breakpoints: {
            1200: {
                perPage: 5,
            },
            1050: {
                perPage: 4,
            },
            800: {
                perPage: 3,
            },
            560: {
                perPage: 2,
            },
            340: {
                perPage: 1,
            },
        }

    }
    const [promotedOffers, setPromotedOffers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    useEffect(() => {
        setTimeout(() => {
            socket.emit('getPromotedBigOffers', { devices: getDevicesSelected() });
        }, 1000)
        socket.on('emitPromotedBigOffers', (data) => {
            let s = Math.floor(Math.random() * 10) + 1;


            setPromotedOffers([...data.offers])
        })
    }, [])
    useEffect(() => {
    }, [promotedOffers])
    return (
        <div className="splider-container">
            <Splide
                options={options}
                hasTrack={false}>

                <div className=" splide__arrows custom_arrows_banner splide__arrows--ltr">
                    <div className="custom_arrow_banner_container custom_arrow_banner_container_left">
                        <button className="splide__arrow splide__arrow--prev custom_arrow_banner" aria-label="Previous slide" aria-controls="splide20-track">
                            <svg width="18" height="18" viewBox="0 0 24 24">
                                <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M10.35 16.63a1.29 1.29 0 0 1 0-1.76L13.1 12l-2.75-2.87a1.29 1.29 0 0 1 0-1.76 1.17 1.17 0 0 1 1.7 0l3.6 3.75c.47.48.47 1.28 0 1.76l-3.6 3.75c-.47.5-1.23.5-1.7 0Z">
                                </path>
                            </svg>
                        </button>
                    </div>
                    <div className="custom_arrow_banner_container custom_arrow_banner_container_right">
                        <button className="splide__arrow splide__arrow--next custom_arrow_banner" disabled="" aria-label="Next slide" aria-controls="splide20-track">
                            <svg width="18" height="18" viewBox="0 0 24 24">
                                <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M10.35 16.63a1.29 1.29 0 0 1 0-1.76L13.1 12l-2.75-2.87a1.29 1.29 0 0 1 0-1.76 1.17 1.17 0 0 1 1.7 0l3.6 3.75c.47.48.47 1.28 0 1.76l-3.6 3.75c-.47.5-1.23.5-1.7 0Z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </div>
                <SplideTrack>
                    {promotedOffers.map((item, i) => {

                        return (
                            <SplideSlide key={i} className='offer-featured-box' onClick={() => {
                                if (loggedIN == false) {
                                    showLoginModal()
                                } else {
                                    window.dispatchEvent(new CustomEvent('showOfferID', { detail: { "offerID": item.offerId } }))

                                }
                            }}>
                                {item.picture == undefined ? <Skeleton height={149.5} style={{ borderRadius: '10px' }} /> : <div className='promoted-offer-big-box'>
                                    <div className="blur-over">
                                        <div className="blur-over-icon">
                                            <svg width="16px" height="16px" viewBox="0 0 16 16"><path xmlns="http://www.w3.org/2000/svg" d="M4.5.88a1 1 0 00-1.5.86V14.3a1 1 0 001.49.87l10.98-6.13a1 1 0 00.02-1.74L4.5.88z"></path></svg>
                                        </div>
                                    </div>
                                    <div className='promoted-offer-image-container'>
                                        <div className='promoted-offer-image-container-os'>
                                            {item.platforms == undefined ? '' : item.platforms.includes("android") || item.platforms.includes("all") || item.platforms.includes("*") ? <IoLogoAndroid /> : ''}
                                            {item.platforms == undefined ? '' : item.platforms.includes("desktop") || item.platforms.includes("all") || item.platforms.includes("*")
                                                || item.platforms.includes("windows") || item.platforms.includes("window") || item.platforms.includes("linux")
                                                || item.platforms.includes("mac") || item.platforms.includes("") ?
                                                <FaLaptop /> : ''}
                                            {item.platforms == undefined ? '' : item.platforms.includes("ios") || item.platforms.includes("all") || item.platforms.includes("apple")
                                                || item.platforms.includes("iphone") || item.platforms.includes("ipad") || item.platforms.includes("*") ? <FaApple /> : ''}
                                        </div>
                                        <img loading="lazy" onError={(e) => {
                                            e.currentTarget.src = "/assets/img/placeholder-square.png"
                                            e.currentTarget.srcset = "/assets/img/placeholder-square.png"
                                        }} alt="offer image" width={10} height={10} src={item.picture} />

                                    </div>
                                    <div className='promoted-offers-details'>
                                        <p className='promoted-offers-name'>
                                            {item.name}
                                        </p>
                                        <div className='promoted-offers-bottom--details'>
                                            <p className='promoted-offers-reward'>
                                                {hapa == 1 ? <FaDollarSign /> : <ProgressiveImage
                                                    preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                    src="/assets/img/faucetify_coin_small.png"
                                                    render={(src, style) => <img alt="offer image" width={10}
                                                        height={10} src={src} />}
                                                />}
                                                {handleAmount(item.reward)}
                                            </p>
                                            <p className='promoted-offers-wall'>
                                                {getOfferWallName(item.wall)}
                                            </p>
                                        </div>
                                    </div>

                                </div>}
                            </SplideSlide>
                        );
                    })}
                </SplideTrack>

            </Splide>
        </div>
    )
}

export default BigSliderOffers
