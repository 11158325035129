'use client'
import { socket } from '../Containers/MainContainer'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import PlutoMenu from './PlutoMenu'
import PlutoUser from './PlutoUser'
import VerifyAdmin from './PlutoVerify'
import { useParams } from 'react-router-dom'

function PlutoSingleWithdrawal(props) {
    const [data, setData] = useState({})
    const { referral_id, page, second_page, third_page } = useParams();
    const [showUser, setShowUser] = useState(false)
    const [alert, setAlert] = useState({ show: false, type: '', flagReason: '', code: '', pin: '' })
    const [alert2, setAlert2] = useState({ show: false })
    const [rank, setRank] = useState(0)

    useEffect(() => {
        socket.emit('getWithdrawAdmin', {
            withdraw_id: third_page
        });
        socket.on('response_get_admin_withdraw', (data) => {
            setData(data)
            setRank(data.rank)
        })
        socket.on('reTakeWithdrawDet', () => {
            socket.emit('getWithdrawAdmin', {
                withdraw_id: third_page
            });
        })

    }, [])
    return (<>
        <style>
            {
                `
            .card{
                background-color: var(--bg-color-3);
                color:var(--text-primary);
                width: 380px;
                border-radius: 33px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                padding: 2rem !important;
            }
            .top-container{
                display: flex;
                align-items: center;
            }
            .profile-image{
                border-radius: 10px;
                border: 2px solid #5957f9;
            }
            .name{
                font-size: 15px;
                font-weight: bold;
                position: relative;
                top: 8px;
            }
            .mail{
                font-size: 14px;
                position: relative;
                top: 2px;
            }
            .middle-container{
                background-color: var(--accent-3);
                border-radius: 12px;
            
            }
            .middle-container:hover {
                border: 1px solid #5957f9;
            }
            .dollar-div{
                background-color: #5957f9;
                padding: 12px;
                border-radius: 10px;
            }
            .round-div{
                border-radius: 50%;
                width: 35px;
                height: 35px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            .dollar{
                font-size: 16px !important;
                font-weight: bold !important;
            }
            
            
            .current-balance{
                font-size: 12px;
                font-weight: bold;
            }
            .amount{
                font-size: 16px;
                font-weight: bold;
            }
            .dollar-sign{
                font-size: 16px;
                font-weight: bold;
            }
            
            .recent-border{
                border-left: 2px solid #5957f9;
                display: flex;
                align-items: center;
            
            }
            .recent-border:hover {
                border-bottom: 1px solid #dee2e6!important;
            }
            
            .recent-orders{
                font-size: 16px;
                font-weight: 700;
                margin-left: 2px;
            }
            
            .wishlist{
                font-size: 16px;
                font-weight: 700;
            
            }
            .wishlist-border:hover{
                border-bottom: 1px solid #dee2e6!important;
            }
            .fashion-studio{
                font-size: 16px;
                font-weight: 700;
            }
            .fashion-studio-border:hover {
                border-bottom: 1px solid #dee2e6!important;
            }`
            }
        </style>
        <SweetAlert
            show={alert.show}
            title={alert.title}
            onConfirm={function () {
                if (data.withdraw.option_request.toLowerCase().includes("paypal")) {
                    socket.emit('adminAction', {
                        action: 'pay_gift_card',
                        withdraw: data.withdraw.id,
                    })
                    setAlert({ ...alert, show: false })
                } else
                    if (alert.code.split("").length > 4) {
                        socket.emit('adminAction', {
                            action: 'pay_gift_card',
                            withdraw: data.withdraw.id,
                            code: alert.code,
                            pin: alert.pin
                        })
                        setAlert({ ...alert, show: false })
                    }
            }}
            style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
            cancelBtnText="Not now"
            confirmBtnText="Proceed"
            showCancel={true}
            cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
            confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
            onCancel={function () { setAlert({ show: false, type: '', flagReason: '', code: '', pin: '' }) }}
        >
            <>
                <h3>Gift Card details ({data.withdraw == undefined ? "" : data.withdraw.id}):</h3>
                {data.withdraw == undefined ? "" : data.withdraw.option_request.toLowerCase().includes("paypal") ? '' : <>
                    <input class="form-control" style={{ marginBottom: '10px', color: '#fff', background: 'transparent' }} placeholder="Gift Card Code" type="text" onKeyUp={(e) => {
                        setAlert({ ...alert, code: e.target.value })
                    }}></input>
                    <p>Code: \{alert.code}\</p>
                    <input class="form-control" style={{ marginBottom: '10px', color: '#fff', background: 'transparent' }} placeholder="Gift Card Pin" type="text" onKeyUp={(e) => {
                        setAlert({ ...alert, pin: e.target.value })
                    }}></input>
                    <p>Pin: \{alert.pin}\</p>
                </>
                }
            </>

        </SweetAlert>
        <SweetAlert
            show={alert2.show}
            onConfirm={function () {
                socket.emit('adminAction', {
                    action: 'refund_withdraw',
                    withdraw: data.withdraw.id,
                })
                setAlert2({ show: false })
            }}
            style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
            cancelBtnText="Not now"
            confirmBtnText="Proceed"
            showCancel={true}
            cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
            confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
            onCancel={function () { setAlert2({ show: false }) }}
        >
            <>
                <h3>Are you sure that you want to refund?</h3>


            </>

        </SweetAlert>

        {data.user == undefined ? 'loading' : 
        <div style={{ marginTop: '10px', flexDirection: 'column', alignItems: 'center', width: '100%', margin: ' 0 auto' }}>

            <div class="card mt-5" style={{ margin: ' 0 auto', marginTop: '20px' }}>
                {data.withdraw.paid == 1 || data.user.banned == true ? "" : <> {data.withdraw.review == 1 ? "" :
                    data.withdraw.type == "Crypto" ? <button type="button" class="button button-brand" onClick={() => {
                        socket.emit('adminAction', {
                            action: 'flag',
                            withdraw: data.withdraw.id,
                        })
                    }}>{data.withdraw.flagged == 1 ? "Unflag" : "Flag"}</button> :
                        <button type="button" class="button button-brand" onClick={() => {
                            setAlert({ ...alert, show: true })
                        }}>{data.withdraw.flagged == 1 ? "Pay" : ""}</button>}
                    <button type="button" class="button button-brand mt-2" onClick={() => {
                        socket.emit('adminAction', {
                            action: 'review',
                            withdraw: data.withdraw.id,
                        })
                    }}>{data.withdraw.review == 1 ? "Release review" : "Send to review"}</button>
                    <button type="button" class="button button-brand mt-2" onClick={() => {
                        setAlert2({ show: true })
                    }}>{"Refund"}</button></>}

                <div class="top-container">



                    <div class="ml-3">
                        <h5 class="name">{data.user.username}</h5>
                        <p class="mail">{data.user.emailProvider}</p>
                        <p class="mail">(Referee: {data.user.referee} | PhoneC: {data.user.phoneCountry} | CountryJoin: {data.user.country})</p>
                        <p class="mail">(Used Countries Offers: {data.user.countries})</p>
                        {data.withdraw.option_request}<br />
                        {data.withdraw.tyep == "Crypto" ? <>    <span class="current-balance">Crypto Price: </span>
                            <span class="current-balance"><span class="current-balance">$</span>{parseFloat(data.withdraw.details.cryptoPrice).toFixed(3)}</span>
                            <br />
                            <span class="current-balance">Valued now: </span>
                            <span class="current-balance"><span class="current-balance"></span>{parseFloat(data.withdraw.details.valued).toFixed(8)}</span>
                            <br />
                            <span class="current-balance">Paid amount: </span>
                            <span class="current-balance"><span class="current-balance"></span>{isNaN(parseFloat(data.withdraw.details.paid)) == true ? parseFloat(data.withdraw.details.value).toFixed(8) : parseFloat(data.withdraw.details.paid).toFixed(8)}</span>
                        </> : ""}
                    </div>
                </div>


                <div class="middle-container d-flex justify-content-between align-items-center mt-1 p-1">

                    <div class="d-flex flex-column  mr-2" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <span class="current-balance">Requested</span>
                        <span class="amount"><span class="dollar-sign">$</span>{parseFloat(parseInt(data.withdraw.amount) / 1000).toFixed(3)}</span>
                        <span class="current-balance">Fee</span>
                        <span class="amount"><span class="dollar-sign">$</span>{parseFloat(parseInt(data.withdraw.details.fee) / 1000).toFixed(3)}</span>
                    </div>

                </div>
                <div className="mb-1">
                    <span class="recent-orders">Paid</span><br />
                    <span class="current-balance" style={{ color: 'var(--text-secondary)' }}>{data.withdraw.paid == 1 ? "true" : 'false'}</span>
                </div>
                {data.withdraw.type == "Crypto" ? "" :
                    <div className="mb-1">
                        <span class="recent-orders">Claimed</span><br />
                        <span class="current-balance" style={{ color: 'var(--text-secondary)' }}>{data.withdraw.details.claimed}</span>
                    </div>
                }
             
                <div className="mb-1">
                    <span class="recent-orders">{data.withdraw.type == "Crypto" ? "Withdraw to" : "Code"}</span><br />
                    <span class="current-balance" style={{ color: 'var(--text-secondary)' }}>{data.withdraw.type == "Crypto" ? data.withdraw.withdraw_to : data.withdraw.details.code}</span>
                </div>
                <div className="mb-1">
                    <span class="recent-orders">{data.withdraw.type == "Crypto" ? "Withdraw to" : "Code"}</span><br />
                    <span class="current-balance" style={{ color: 'var(--text-secondary)' }}>{data.withdraw.type == "Crypto" ? data.withdraw.withdraw_to : data.withdraw.details.code}</span>
                </div>
                <div className="mb-1">
                    <span class="recent-orders">{data.withdraw.type == "Crypto" ? "Option Name" : "Pin"}</span><br />
                    <span class="current-balance" style={{ color: 'var(--text-secondary)' }}>{data.withdraw.type == "Crypto" ? data.withdraw.option_name : data.withdraw.details.pin}</span>
                </div>
                {data.withdraw.flagged == 0 ? "" : <div className="mb-1">
                    <span class="recent-orders">Flag Reason</span><br />
                    <span class="current-balance" style={{ color: 'var(--text-secondary)' }} dangerouslySetInnerHTML={{ __html: data.withdraw.flag_reason.split(",").join("<br/>") }}></span>
                </div>}
            </div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}><button style={{margin:'0 auto'}} type="button" class="button button-brand" onClick={() => {
                setShowUser(!showUser)
            }}>{showUser == true ? "Hide user" : "Show User"}</button></div>
            {showUser == true ? <PlutoUser second_page={data.user.id} x={1} /> : ''}

        </div>}
    </>

    )
}

export default PlutoSingleWithdrawal