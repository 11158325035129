import { Link } from "react-router-dom"


export const MenuButtonBottomMenu = ({title, icon, selected, func, isLink, to}) => {
    
    return (
        isLink==true?
       
            <Link to={to} className={`menu-button-bottom-menu ${selected?'menu-button-bottom-menu-selected':''}`}>
                <div className="menu-button-bottom-menu-icon">
                    {icon}
                </div>
                <div className="menu-button-bottom-menu-title">
                    {title}
                </div>
        </Link>:
        <div className={`menu-button-bottom-menu ${selected?'menu-button-bottom-menu-selected':''}`} onClick={func}>
            <div className="menu-button-bottom-menu-icon">
                {icon}
            </div>
            <div className="menu-button-bottom-menu-title">
                {title}
            </div>
        </div>
    )
}