'use client'
import { socket } from '../Containers/MainContainer'
import React from 'react'
import PlutoMain from './PlutoMain'
import { useState } from 'react'
import { useEffect } from 'react'
import VerifyAdmin from './PlutoVerify'
import { set } from 'nprogress'

function PlutoHome() {


    return (
        <PlutoMain />
    )
}

export default PlutoHome