
import { useEffect } from 'react'
import '../assets/style/Terms.css'
import TopTOSCOntainer from './TopTOSCOntainer'

export const CCPA = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const lastEdited = '2023-09-09 [YYYY-MM-DD]'
    let TermsOfServicePage = [

        {
            title: 'Introduction',
            id: 'introduction',
            content: `
      <p>This Privacy Policy for California Residents provides additional information in conjunction with PaidCash's Privacy Policy and is applicable exclusively to all individuals residing in the State of California, referred to as "consumers" or "you." We have adopted this notice to adhere to the provisions of the California Consumer Privacy Act of 2018 (CCPA). Any terms defined in the CCPA maintain the same meanings when utilized in this <a href="/policies/privacy">Policy</a>.</p>
      `
        },

        {
            title: 'Information We Collect',
            id: 'information-we-collect',
            content: `
      <p>Our Website gathers information that can be linked, directly or indirectly, to a specific consumer, household, or device, which is referred to as "personal information." Personal information does not encompass the following:</p>
    <ul style="list-style: circle; margin-left:20px">
        <li>Publicly available information from government records.</li>
        <li>Deidentified or aggregated consumer information.</li>
    </ul>
    <br/>
    <p>In particular, within the last twelve (12) months, our Website has collected personal information falling into the following categories from consumers:</p>
    <table class="border-policy">
    <tr>
        <th>CATEGORY</th>
        <th>EXAMPLES</th>
        <th>COLLECTED</th>
    </tr>
    <tr>
        <td>A. Identifiers</td>
        <td>Real name, alias, postal address, unique personal identifier, online identifier, IP address, email address, account name, driver's license number, passport number, or similar identifiers.</td>
        <td>YES</td>
    </tr>
    <tr>
        <td>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</td>
        <td>Name, signature, address, telephone number, driver's license or state identification card number (may overlap with other categories).</td>
        <td>YES</td>
    </tr>
    <tr>
        <td>C. Protected classification characteristics under California or federal law</td>
        <td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
        <td>YES</td>
    </tr>
    <tr>
        <td>D. Commercial information</td>
        <td>Records of products or services used, obtained, or considered in exchange for certain rewards for participating on the Websites or history of rewards you received.</td>
        <td>YES</td>
    </tr>
    <tr>
        <td>E. Biometric information</td>
        <td>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</td>
        <td>YES</td>
    </tr>
    <tr>
        <td>F. Internet or other similar network activity</td>
        <td>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</td>
        <td>YES</td>
    </tr>
    <tr>
        <td>G. Geolocation data</td>
        <td>Physical location or movements.</td>
        <td>NO</td>
    </tr>
    <tr>
        <td>H. Sensory data</td>
        <td>Audio, electronic, visual, thermal, olfactory, or similar information.</td>
        <td>NO</td>
    </tr>
    <tr>
        <td>I. Professional or employment-related information</td>
        <td>Current or past job history or performance evaluations.</td>
        <td>YES</td>
    </tr>
    <tr>
        <td>J. Non-public education information</td>
        <td>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</td>
        <td>NO</td>
    </tr>
    <tr>
        <td>K. Inferences drawn from other personal information</td>
        <td>Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
        <td>YES</td>
    </tr>
</table>
    <br/>
    <p>Our Website acquires the categories of personal information mentioned above from the following sources:</p>
    <ul style="list-style: circle; margin-left:20px">
        <li>Directly from you, such as from forms you complete (e.g., to register for our services or participate in surveys).</li>
        <li>Indirectly from you, by observing your actions on our Website.</li>
        <li>Third parties, which may involve collecting additional profiler information.</li>
    </ul>
`
        },
        {
            title: 'Purposes for Using Personal Information',
            id: 'purposes-for-using-personal-information',
            content: `
  <ul style="list-style: circle; margin-left:20px">
  <li>To fulfill or meet the reason you provided the information. For instance, if you share your name and contact details to register for an account or inquire about our products or services, we will use that information to respond to your inquiry. Similarly, if you provide personal information to accept an offer and redeem your rewards.</li>
  <li>To provide, support, customize, and enhance our Website, products, and services.</li>
  <li>To establish, manage, customize, and secure your account with us.</li>
  <li>To process your requests, offers, transactions, and reward-based payments on our Website while preventing transactional fraud.</li>
  <li>To offer support and address your inquiries, including investigating and resolving your concerns, and enhancing our responses.</li>
  <li>To personalize your Website experience and deliver content, product, and service offers that match your interests, including targeted offers and advertisements through our Website, third-party sites, email, or text messages (with your consent, where required by law).</li>
  <li>To maintain the safety, security, and integrity of our Website, products, services, databases, technology assets, and business.</li>
  <li>For testing, research, analysis, and product development, which includes improvements to our Website, products, and services.</li>
  <li>To comply with law enforcement requests and as mandated by applicable law, court orders, or governmental regulations.</li>
  <li>As explained to you when collecting your personal information or as otherwise outlined in the CCPA.</li>
  <li>For conducting surveys.</li>
  <li>To evaluate or carry out a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or a similar proceeding, where personal information held by us about our Website users is among the transferred assets.</li>
</ul>
<br/>
<p>We will not collect additional categories of personal information or use the personal information we collected for significantly different, unrelated, or incompatible purposes without providing you with notice.</p>
`
        },

        {
            title: 'Sharing Personal Information',
            id: 'sharing-personal-information',
            content: `
         <p>We may share your personal information by disclosing it to third parties for business purposes. These business purpose disclosures are exclusively made under written contracts that explicitly outline the purposes, mandate the recipient to maintain confidentiality, and prohibit the use of disclosed information for any purpose other than contract performance. In the preceding twelve (12) months, PaidCash has disclosed personal information for business purposes to the categories of third parties indicated in the chart below.</p>
<br/>
    <p>Additionally, we may share your personal information by selling it to third parties, subject to your right to opt-out of such sales. Our personal information sales do not include information about individuals under the age of 16. In the preceding twelve (12) months, PaidCash has sold the following categories of personal information to the categories of third parties indicated in the chart below. For more details on your personal information sale rights, please refer to the <a href="#opt-out-section">Personal Information Sales Opt-Out and Opt-In Rights</a> section.</p>
    <table class="border-policy">
    <thead>
        <tr>
            <th>CATEGORY OF THIRD-PARTY RECIPIENTS</th>
            <th>BUSINESS PURPOSE DISCLOSURES</th>
            <th>SALES</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>A: Identifiers.</td>
            <td>
                <ul>
                    <li>Conducting Surveys</li>
                    <li>Data Analytics providers</li>
                    <li>Data Storage</li>
                    <li>Improving our Products & Services</li>
                    <li>Internal Business Operations</li>
                    <li>Operating Our Website or Mobile Apps</li>
                    <li>Processing Withdrawals</li>
                    <li>Providing Customer Support</li>
                    <li>Sending Promotional Communications</li>
                    <li>Tracking Conversions & Customer Data</li>
                    <li>Fraud Prevention</li>
                </ul>
            </td>
            <td>
                <ul>
                    <li>Market Research companies</li>
                    <li>Advertiser partners</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>B: California Customer Records personal information categories.</td>
            <td>
                <ul>
                    <li>Conducting Surveys</li>
                    <li>Providing Customer Support</li>
                    <li>Processing Withdrawals</li>
                    <li>Fraud Prevention</li>
                </ul>
            </td>
            <td>
                <ul>
                    <li>Market Research companies</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>C: Protected classification characteristics under California or federal law.</td>
            <td>
                <ul>
                    <li>Conducting Surveys</li>
                </ul>
            </td>
            <td>
                <ul>
                    <li>Market Research companies</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>D: Commercial information.</td>
            <td>
                <ul>
                    <li>Operating Our Website or Mobile Apps</li>
                </ul>
            </td>
            <td>None</td>
        </tr>
        <tr>
            <td>E: Biometric information.</td>
            <td>
                <ul>
                    <li>Fraud Prevention</li>
                </ul>
            </td>
            <td>None</td>
        </tr>
        <tr>
            <td>F: Internet or other similar network activity.</td>
            <td>
                <ul>
                    <li>Data Analytics providers</li>
                    <li>Data Storage providers</li>
                </ul>
            </td>
            <td>None</td>
        </tr>
        <tr>
            <td>G: Geolocation data.</td>
            <td>None</td>
            <td>None</td>
        </tr>
        <tr>
            <td>H: Sensory data.</td>
            <td>None</td>
            <td>None</td>
        </tr>
        <tr>
            <td>I: Professional or employment-related information.</td>
            <td>
                <ul>
                    <li>Conducting Surveys</li>
                </ul>
            </td>
            <td>
                <ul>
                    <li>Market Research companies</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>J: Non-public education information.</td>
            <td>None</td>
            <td>None</td>
        </tr>
        <tr>
            <td>K: Inferences drawn from other personal information.</td>
            <td>
                <ul>
                    <li>Improving our Products & Services</li>
                </ul>
            </td>
            <td>None</td>
        </tr>
    </tbody>
</table>
  <br/>
    <p>The CCPA restricts third parties from reselling personal information unless you have received explicit notice and an opportunity to opt-out of further sales. You consent to certain businesses purchasing your personal information from us, and these businesses may resell your personal information. However, you retain the right to opt-out. To exercise your right to opt-out of these sales, please visit our opt-out section <a id="opt-out-section" href="#">here</a>.</p>
`
        },
        {
            title: 'Your Rights and Choices',
            id: 'your-rights-and-choices',
            content: `
      
    <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
      <br/>
    <strong>Right to Know and Data Portability</strong>
<br/>
    <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months (the "right to know"). Once we receive your request and confirm your identity (see Exercising Your Rights to Know or Delete), we will disclose to you:</p>

    <ul style="list-style: circle; margin-left:20px">
        <li>The categories of personal information we collected about you.</li>
        <li>The categories of sources for the personal information we collected about you.</li>
        <li>Our business or commercial purpose for collecting or selling that personal information.</li>
        <li>The categories of third parties with whom we share that personal information.</li>
        <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>
        <ul style="list-style: circle; margin-left:20px">
            <li>Sales, identifying the personal information categories that each category of recipient purchased.</li>
            <li>Disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
        </ul>
        <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
    </ul>
<br/>
    <strong>Right to Delete</strong>

    <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions (the "right to delete"). Once we receive your request and confirm your identity (see Exercising Your Rights to Know or Delete), we will review your request to see if an exception allowing us to retain the information applies. We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:</p>

    <ul style="list-style: circle; margin-left:20px">
        <li>Complete the transaction for which we collected the personal information (e.g., storing information with earned credits).</li>
        <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
        <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
        <li>Exercise free speech or comply with the law.</li>
        <li>Engage in public or peer-reviewed scientific, historical, or statistical research.</li>
        <li>Enable internal uses that align with consumer expectations.</li>
        <li>Comply with a legal obligation.</li>
    </ul>
<br/>
    <p>We do not provide these deletion rights for B2B personal information.</p>
<br/>
    <strong>Exercising Your Rights to Know or Delete</strong>
<br/>
    <p>To exercise your rights to know or delete described above, please submit a request by either:</p>

    <ul style="list-style: circle; margin-left:20px">
        <li>Emailing us at <a href="mailto:hello@paidcash.co">hello@paidcash.co</a></li>
        <li>Visiting <a href="https://paidcash.co">https://paidcash.co</a></li>
    </ul>
<br/>
    <p>Only you or someone legally authorized to act on your behalf may make a request to know or delete your personal information. You may only submit a request to know twice within a 12-month period.</p>
    <p>You may only submit a request to know twice within a 12-month period. Your request to know or delete must:</p>

    <ul style="list-style: circle; margin-left:20px">
        <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
        <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
    </ul>
    <br/>
    <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.</p>
    <br/>
    <p>We will only use personal information provided in the request to verify the requestor's identity or authority to make it.</p>
    <br/>
    <p>For instructions on exercising your sale opt-out or opt-in rights, see <a href="#purposes-for-using-personal-information">Personal Information Sales Opt-Out and Opt-In Rights</a>.</p>
    <br/>
    <strong>Response Timing and Format</strong>
<br/>
    <p>We will confirm receipt of your request within ten (10) business days. If you do not receive confirmation within the 10-day timeframe, please contact <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>.</p>
<br/>
    <p>We endeavor to substantively respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to another 45 days), we will inform you of the reason and extension period in writing.</p>
<br/>
    <p>If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by email.</p>
<br/>
    <p>Any disclosures we provide will only cover the 12-month period preceding our receipt of your request. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity.</p>
<br/>
    <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
<br/>
    <strong>Personal Information Sales Opt-Out and Opt-In Rights</strong>
<br/>
    <p>If you are age 16 or older, you have the right to direct us to not sell your personal information at any time (the "right to opt-out"). We do not sell the personal information of consumers we know are less than 16 years old. Consumers who opt-in to personal information sales may opt-out of future sales at any time.</p>
<br/>
    <p>To exercise the right to opt-out, you (or your authorized representative) may submit a request to us by emailing <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>. We will wait at least twelve (12) months before asking you to reauthorize personal information sales.</p>
<br/>
    <p>You do need to create an account with us to exercise your opt-out rights. We will only use personal information provided in an opt-out request to review and comply with the request.</p>
`
        },
        {
            title: 'Non-Discrimination',
            id: 'non-discrimination',
            content: `
      <p>We want to make it clear that we will not engage in any form of discrimination or retaliation against you, which is prohibited by applicable U.S. State Privacy Laws, for exercising your Consumer privacy rights. In certain cases, we may adjust the price, rate, or offer a different level or quality of goods or services, to the extent that such adjustments are reasonably related to the value of the data in question.</p>
`
        },

        {
            title: 'Changes to Our Privacy Policy',
            id: 'changes-to-our-privacy-policy',
            content: `
      <p>Our policy is to publish any revisions we make to our privacy policy on this page. In the event of substantial changes in how we handle your personal information, we will notify you through either the primary email address provided in your account or by posting a notice on the Website's home page. The date of the last revision to the privacy policy can be found at the top of this page.</p>
    <br/>
      <p>It is your responsibility to ensure that we have an accurate, current, and functional email address on file for you. Additionally, we recommend that you periodically visit our Website and review this privacy policy to stay informed about any updates.</p>

      <p>Your continued use of our Website following the posting of changes constitutes your acceptance of such changes.</p>
  `
        },
        {
            title: 'Contact Information',
            id: 'contact-information',
            content: `
      <p>If you have any questions or comments concerning this notice, how PaidCash collects and utilizes your information as detailed in this notice and our   <a href="/policies/privacy">Privacy Policy</a>, your options and entitlements concerning such utilization, or if you wish to exercise your rights under California law, please don't hesitate to reach out to us at:</p>
       <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>`
        }

    ]
    return (
        <div className='terms-page'>
            <TopTOSCOntainer edited={lastEdited} title={'Privacy Policy for California Residents'} second_page={props.second_page}/>
            {TermsOfServicePage.map((item, index) => {
                return (
                        <div className="terms-item"  id={item.id} key={index}>
                            <div className="terms-title" >{index + 1}{' -'} {item.title}</div>
                            <div className='terms-content' dangerouslySetInnerHTML={{ __html: item.content }} />
                            {index == 0 ?
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', fontSize: '12px' }}>
                                    {TermsOfServicePage.map((item, index) => {
                                        return <div className='tos-al' style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div >{index + 1}</div> <a href={`#${item.id}`} key={index}> {`) ${item.title}`}</a>
                                            </div>
                                    })}
                                </div> : ''}
                        </div>

                )
            })}
        </div>
    )
}