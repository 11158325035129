

export const SideMenuButtonHeader = ({ onClick }) => {
    function handleClick() {
        document.dispatchEvent(new CustomEvent('handleSideMenu', { detail: { open: true } }))
        const AppContainerDOM = document.querySelector('.app-container');
        console.log(AppContainerDOM)
        if(AppContainerDOM!=undefined){
            if(!AppContainerDOM.classList.contains('app-container-full')){
                AppContainerDOM.classList.add('app-container-full')
                document.dispatchEvent(new CustomEvent('handleUpdateSize', { detail: { open: true } }))
            }else{
                AppContainerDOM.classList.remove('app-container-full')
                document.dispatchEvent(new CustomEvent('handleUpdateSize', { detail: { open: false } }))

            }
          }
    }
    return (
        <div class="header-burger-menu" onClick={handleClick}>
            <div class="header-burger-menu-line"></div>
            <div class="header-burger-menu-line"></div>
            <div class="header-burger-menu-line"></div>
        </div>
    );
}