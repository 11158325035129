import ProgressiveImage from "react-progressive-image-loading"
import { ModalContent } from "./ModalContent"
import { FaAndroid, FaStar } from "react-icons/fa"
import { getOfferWallName, getWallName, numberWithCommas, submitFingersWall } from "../../components/utils"
import { GiSandsOfTime } from "react-icons/gi"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { useEffect, useState } from "react"
import { socket } from "../../components/Containers/MainContainer"
import fingerprintjs from '@fingerprintjs/fingerprintjs';

export const WallModalContent = (props) => {
    const [wall, setWall] = useState(undefined);
    const [loading, setLoading] = useState(true);
    useEffect(() => {

        socket.emit('offerwall_details_w', {
            name:props.title.split(' ').join('-')
        });
        if (typeof fingerprintjs == 'null') {
            setTimeout(() => {
                submitFingersWall(fingerprintjs);
            }, 1000)
        } else {
            submitFingersWall(fingerprintjs);
        }
        socket.on('offerwall_details_wr', async (data) => {
            setWall(data);
            socket.off('offerwall_details_wr')

        });
    }, []);
    return (
        <ModalContent 
        click={props.click}
        title={`${getOfferWallName(props.title.split(' ').join('-'))}`} 
        className={`modal-content-wall modal-content-wall-${props.title.split(' ').join('-')}`}
        content={
            <>
            {wall == undefined ?

                <div className="loading-center">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div> 
            :
            wall.reqNumber == true ?
            <div className="phone-numb-v-ask-cont">
                In order to earn on {getOfferWallName(props.title.split(' ').join('-'))} you have to veify your Phone Number.
                <div className="phoneNumberBTN"  onClick={() => {
                    // window.dispatchEvent(new Event('showPhoneVerifyModals'))
                    // document.querySelector(`.${name}-modal-wall`).style.display = 'none'
                }}>Verify Phone Number</div>
            </div> 
            : 
            <iframe className="walls-iframe" onLoad={(e) => {
                setTimeout(() => {
                    setLoading(false)
                },1000)
            }} src={wall.wallDetails.link} name={wall.wallDetails.name} allow="camera https://asmwall.com;" ></iframe>
            } </>
        }/>
    )
}