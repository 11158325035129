import { useEffect, useRef, useState } from "react"
import { canUsePagination, CreateToast, getLevelColor, isLoggedIn, numberWithCommas, validateEmail, validatePassword, validateUsername } from "../components/utils";
import { socket } from "../components/Containers/MainContainer";
import { Link, Navigate } from "react-router-dom";
import { byIso } from "country-code-lookup";
import ProgressiveImage from "react-progressive-image-loading";
import { FaAddressBook, FaCheckCircle, FaDollarSign, FaPencilAlt, FaTicketAlt } from "react-icons/fa";
import UserIDProfileModalContainerDTImg from "../components/earn/UserIDProfileModalContainerDTImg";
import Skeleton from "react-loading-skeleton";
import { IoMdSave } from "react-icons/io";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import '../assets/style/Profile.css'
import { MdAlternateEmail, MdDriveFileRenameOutline } from "react-icons/md";
import { PiPasswordFill } from "react-icons/pi";
import { set } from "nprogress";

export const UserProfileSettingsContainer = () => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
   
    let [loggedIN, setLogged] = useState(isLoggedIn());
    useEffect(() => { setLogged(isLoggedIn()) }, [isLoggedIn()])
    const [isOpen, setIsOpen] = useState(true)
    const [userDetails, setUserDetails] = useState({})
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [userEarnings, setUserEarnings] = useState([])
    const [selectAll, setSelectAll] = useState(false);
    const [country, setCountry] = useState('EU')
    const [countryFlag, setCountryFlag] = useState('')
    const [usedTab, setUsedTab] = useState(0);
    const [editUsername, setEditUsername] = useState(false)
    const [username, setUsername] = useState('');
    const [editEmail, setEditEmail] = useState(false)
    const [email, setEmail] = useState('');
    const [usedSubTab, setUsedSubTab] = useState(0);
    const [category, setCategory] = useState(0)
    const creaRef = useRef(null);
    function getEarnings(thisCategory) {
        if (category != thisCategory || usedTab == 1) {
            setUsedSubTab(thisCategory)
            setUserEarnings(undefined)
            setCategory(thisCategory)
            canUsePagination(false)
            if (loggedIN == true) {
                setlazyState({ ...lazyState, page: 0, first: 0 })

            }
        }
    }
    function getWithdrawals(thisCategory) {
        if (category != thisCategory || usedTab == 0) {
            setUsedSubTab(thisCategory)
            setUserEarnings(undefined)
            setCategory(thisCategory)
            canUsePagination(false)
            userEarningsSetter()
            if (loggedIN == true) {
                setlazyState({ ...lazyState, page: 0, first: 0 })

                //     socket.emit('getUserWithdrawals', {
                //         page: 1,
                //         category: thisCategory,
                //     })
            }
        }
    }
    useEffect(() => {
        if (loggedIN == false) {
            <Navigate to='/earn' />
        }
       

            ;
    }, [])
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    let networkTimeout = null;

    useEffect(() => {
        loadLazyData();
    }, [lazyState]);

    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
        if (loggedIN == true) {
            userEarningsSetter()
            if (usedTab == 1) {
                socket.emit('getUserWithdrawals', {
                    user: 'me',
                    page: lazyState.page + 1,
                    category: category,
                })
            } else {
                socket.emit('getUserEarnings', {
                    user: 'me',
                    page: lazyState.page + 1,
                    category: category,
                })
            }

        }

    };



    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setlazyState(event);
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setUserEarnings(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            userEarnings.getCustomers().then((data) => {
                setSelectAll(true);
                setUserEarnings(data.customers);
            });
        } else {
            setSelectAll(false);
            setUserEarnings([]);
        }
    };
    const onPage = (event) => {
        setlazyState(event);
    };
    useEffect(() => {
        setIsOpen(true)
    }, [])
    function userEarningsSetter() {
        socket.on('userEarningsProfile', (data) => {
            setUserEarnings(data.earnings)
            setTotalRecords(data.pages * 5)
            setLoading(false);
        })
    }
    useEffect(() => {

        socket.on('userProfile', (data) => {
            try {
                if (data.userDetails.country != undefined) {
                    setCountry(byIso(data.userDetails.country).country)
                }
            } catch (e) {
                setCountry('EU')
            }
            setCountryFlag(data.userDetails.country)
            setUserDetails(data.userDetails)
            setEmail(data.userDetails.email)

            // setUserProfile({ ...data.userDetails })
        })
        userEarningsSetter()
        if (loggedIN == true) {
            socket.emit('getUserDetails', {
                user: 'me',
            })
            socket.emit('getUserEarnings', {
                user: 'me',
                page: 1,
                category: category,
            })
        }
        window.claimGiftCard = async (id) => {


            socket.emit('userAction', {
                action: 'gift_card_data',
                id: id
            })

        }
    }, [])
    const notificationTypes = [
        // 'Withdrawals','Earnings',
        'Promo',
        // 'Events'
    ]
    const [myPassword, setMyPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    return (loggedIN == false ? window.location.href = '/earn' :
            <div className='profile-container'>
                 <div className="cat-earn-mob" style={{margin:'0px'}}>
                        <Link className={`cat-earn-mob-item `} to={'/profile'}>
                            Profile
                        </Link>
                        <Link className={`cat-earn-mob-item sel`} to={'/profile/settings'}>
                            Settings
                        </Link>
                    </div>
                <div className='profile-top-user'>
                <div className="profile-setting-section">
                    <div className='profile-setting-section-title'>
                        Account
                    </div>

                          <div className='profile-setting-section-item'>
                        <div className='profile-setting-section-item-data'>
                            <div className='profile-setting-section-item-data-title'>
                                Hide Profile
                            </div>
                            <div className='profile-setting-section-item-data-description'>
                                Protect your profile from being viewed by others
                            </div>
                        </div>
                        <div className='profile-setting-section-item-content'>
                        <div className="toggle-switch">
                                {userDetails.receivePromo == undefined ? '' : <input className="toggle-input" id="toggle" onClick={(e) => {
                                     socket.emit('userAction', {
                                        action: 'profile_visibility_check'
                                    })
                                }} defaultChecked={userDetails.receivePromo} type="checkbox" />}
                                <label className="toggle-label" for="toggle"></label>
                        </div>
                        </div>  
                        </div>
                </div>
                <div className="profile-setting-section">
                    <div className='profile-setting-section-title'>
                        Email
                    </div>

                    <div className='profile-setting-section-item-2'>
                            
                        <div className={`${userDetails.emailVerified==undefined?'':userDetails.emailVerified==true?'profile-setting-section-item-badge-green':'profile-setting-section-item-badge-red'}`}>
                            Email {userDetails.email==undefined?'':userDetails.email} is{userDetails.emailVerified==undefined?'':userDetails.emailVerified==false?' not':''} verified 
                            {userDetails.emailVerified == false ?
                                <div className='profile-top-left-info'>
                                    <p></p>
                                    <button onClick={(e) => {
                                        socket.emit('userAction', {
                                            action: 'new_ver_email'
                                        })
                                    }} className="button button-brand" style={{display:'inline-block'}}>Resend Email Verification</button>
                                </div> : ''}
                        </div>
                        <div className='input-container'>
                        <div className="input-icon"><MdAlternateEmail/></div>
                        <input type={'email'} className="walletCrypto" name="coin_amount" placeholder="Enter email address" defaultValue={`${userDetails.email==undefined?'':userDetails.email}`} autoComplete="off" onChange={(e)=>{
                            setEmail(e.target.value)
                        }} />
                        
                    </div>
                    <button onClick={() => {
                                            if (validateEmail(email) == false) {
                                                CreateToast("Email not valid", creaRef, 1);
                                            } else {
                                                setEditEmail(false)
                                                if (userDetails.email == email) {
                                                    CreateToast('Nice, you kept your email!', creaRef, 0);
                                                } else {
                                                    userDetails.emailVerified = false;
                                                    userDetails.email = email;
                                                    socket.emit('userAction', {
                                                        action: 'edit_profile_email',
                                                        email: email
                                                    })

                                                }
                                            }
                                        }}  className="button button-brand" style={{padding:'7px 10px', fontSize:'12px', minWidth:'100px', }}>Update</button>
                    </div>  
                </div>
                <div className="profile-setting-section">
                    <div className='profile-setting-section-title'>
                        Username
                    </div>

                    <div className='profile-setting-section-item-2'>
                        <div className='input-container'>
                        <div className="input-icon"><MdDriveFileRenameOutline/></div>
                        <input type={'text'} className="walletCrypto" name="coin_amount" placeholder="Username" defaultValue={`${userDetails.username==undefined?'':userDetails.username}`} autoComplete="off" onChange={(e)=>{
                            setUsername(e.target.value)
                        }} />
                        
                    </div>
                    <button onClick={() => {
                                            if (validateUsername(username)[0] == false) {
                                                CreateToast(validateUsername(username)[1], creaRef, 1);
                                            } else if (/\p{Extended_Pictographic}/u.test(username)) {
                                                CreateToast('This username is not available.', creaRef, 1)
                                            } else {
                                                setEditUsername(false)
                                                if (userDetails.username == username) {
                                                    CreateToast('Nice, you kept your username!', creaRef, 0);
                                                } else {
                                                    userDetails.username = username;
                                                    socket.emit('userAction', {
                                                        action: 'edit_profile_username',
                                                        username: username
                                                    })
    
                                                }
                                            }
                                        }}  className="button button-brand" style={{padding:'7px 10px', fontSize:'12px', minWidth:'100px', }}>Update</button>
                    </div>  
                </div>
                <div className="profile-setting-section">
                    <div className='profile-setting-section-title'>
                        Password
                    </div>

                    <div className='profile-setting-section-item-2'>
                            
                        
                        <div className='input-container'>
                            <div className="input-icon"><PiPasswordFill/></div>
                            <input type={'password'} className="walletCrypto" name="coin_amount" placeholder="Enter new password"  autoComplete="off" onChange={(e)=>{
                                setMyPassword(e.target.value)
                            }} />
                        </div>
                        <div className='input-container'>
                            <div className="input-icon"><PiPasswordFill/></div>
                            <input type={'password'} className="walletCrypto" name="coin_amount" placeholder="Repeat new password"  autoComplete="off" onChange={(e)=>{
                               setNewPassword(e.target.value)
                            }} />
                        </div>
                        <div className='input-container'>
                            <div className="input-icon"><PiPasswordFill/></div>
                            <input type={'password'} className="walletCrypto" name="coin_amount" placeholder="Enter current password"  autoComplete="off" onChange={(e)=>{
                                setRepeatPassword(e.target.value)
                            }} />
                        </div>
                        <button onClick={() => {
                                                 if (validatePassword(myPassword)[0] == false) {
                                                    CreateToast("Current password not valid. "+validatePassword(newPassword)[1], creaRef, 1);
                                                }else if (validatePassword(newPassword)[0] == false) {
                                                    CreateToast("New password not valid. "+validatePassword(newPassword)[1], creaRef, 1);
                                                }else  if (newPassword != repeatPassword) {
                                                    CreateToast("Repeated Password is not the same as the new Passowrd.", creaRef, 1);
                                                } else {
                                                        userDetails.email = email;
                                                        socket.emit('userAction', {
                                                            action: 'change_password_inside',
                                                            password: myPassword,
                                                            newPassword: newPassword,
                                                            repeatPassword: repeatPassword
                                                        })
                                                }
                        }}  className="button button-brand" style={{padding:'7px 10px', fontSize:'12px', minWidth:'100px', }}>Update</button>
                    </div>  
                </div>
                <div className="profile-setting-section">
                    <div className='profile-setting-section-title'>
                        Notifications
                    </div>
                   {notificationTypes.map((item)=>{
                    return  (
                        <>
                          <div className='profile-setting-section-item'>
                        <div className='profile-setting-section-item-data'>
                            <div className='profile-setting-section-item-data-title'>
                                {item}
                            </div>
                            <div className='profile-setting-section-item-data-description'>
                                Receive notifications for {item.toLowerCase()}
                            </div>
                        </div>
                        <div className='profile-setting-section-item-content'>
                        <div className="toggle-switch">
                                {userDetails.receivePromo == undefined ? '' : <input className="toggle-input" id={`toggle_${item.toLowerCase()}`} onClick={(e) => {
                                    socket.emit('userAction', {
                                        action: `${item.toLowerCase()}_mail_check`
                                    })
                                }} defaultChecked={userDetails.receivePromo} type="checkbox" />}
                                <label className="toggle-label" for={`toggle_${item.toLowerCase()}`}></label>
                        </div>
                        </div>  
                        </div>
                        </>
                        )
                       
                    
                   })}
                    </div>
                    <div className="profile-setting-section">
                    <div className='profile-setting-section-title'>
                        Delete account
                    </div>

                    <div className='profile-setting-section-item-2'>
                    <div className='profile-setting-section-item-data-description'>
                                By clicking the 'Delete Account' button, we will send you an email and once that clicked you will permanently delete your account. This action is irreversible. We may keep some of your data to prevent fraudulent account creation such as email addresses, withdraw information & IP sessions.
                            </div>
                        
                    <button onClick={() => {
                                          socket.emit('userAction', {
                                            action: 'account_deletion_request'
                                        })
                                        }}  className="button button-red" style={{padding:'7px 10px', fontSize:'12px', minWidth:'100px', }}>Delete Account</button>
                    </div>  
                </div>
                  
                </div>
                </div>
               

    )
}