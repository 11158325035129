import ProgressiveImage from "react-progressive-image-loading"
import { ModalContent } from "./ModalContent"
import { FaAndroid, FaStar } from "react-icons/fa"
import { ChangeStateUrl, CreateToast, getOfferWallName, getWallName, hideLoginModal, hideResetPWModal, numberWithCommas, showLoginModal, showRegisterModal, submitFingersWall, validatePassword } from "../../components/utils"
import { GiSandsOfTime } from "react-icons/gi"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { useEffect, useRef, useState } from "react"
import { socket } from "../../components/Containers/MainContainer"
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import { MdOutlineEmail, MdOutlineToken } from "react-icons/md"
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri"
import { IoMdLock } from "react-icons/io"
import { Link } from "react-router-dom"
import { FcGoogle } from "react-icons/fc"
import queryString from "query-string"

export const ResetPasswordModalContent = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    let params = queryString.parse(window.location.search)

    const [resetKey, setResetKey] = useState('');
    useEffect(() => {
        if (params.resetKey != undefined) {
            
            setResetKey(params.resetKey)
            setTimeout(() => {
                ChangeStateUrl("")
            }, 0)
        }
    }, [])

    const [password, setPassword] = useState('');
    const [passwordRe, setPasswordRe] = useState('');
    const creaRef = useRef(null);
    const [showPW, setShowPW] = useState(false);
    return (
        <ModalContent 
        click={props.click}
        hideTitle={true} 
        hide={true}

        className={`modal-content-wall modal-content-reset-password`}
        content={
            <>
            <div className="modal-content-login-data">
                        {/* signup with email and password */}
                       
                            <div className="sc-right-box-2">
                                <p className="sc-right-box-2-el">Reset Your Password</p>
                                <p className="sc-right-box-2-el" style={{fontSize:'10px', color:'var(--text-gray-5)'}}>Make sure to set a strong password.</p>
                                <div class="line" ></div>

                            </div>
                            
                            <div className='input-container'>
                                <div className="input-icon"><MdOutlineToken/></div>
                                <input onChange={(e) => {  }} placeholder='Email address' id="key" disabled={true} type="text" value={params.resetKey} name="key"  />
                            </div>
                            <div className='input-container'>
                                <div className="input-icon"><IoMdLock/></div>
                                <input  onChange={(e) => {
                                    setPassword(e.currentTarget.value)
                                }}  placeholder='Password' id="email" type={`${showPassword==true?"text":"password"}`} name="password"  />
                                <div className="input-icon">{showPassword==true?<RiEyeFill onClick={()=>setShowPassword(false)}/>:<RiEyeCloseFill onClick={()=>setShowPassword(true)}/>}</div>
                            </div>
                            <div className='input-container'>
                                <div className="input-icon"><IoMdLock/></div>
                                <input onChange={(e) => {
                                    setPasswordRe(e.currentTarget.value)
                                }} placeholder='Repeat Password' id="email" type={`${showPassword2==true?"text":"password"}`} name="password"  />
                                <div className="input-icon">{showPassword2==true?<RiEyeFill onClick={()=>setShowPassword2(false)}/>:<RiEyeCloseFill onClick={()=>setShowPassword2(true)}/>}</div>
                            </div>
                           
                           
                        <div className="signup-main-button"  onClick={() => {
                            if (password.split("").length < 6) {
                                CreateToast("Password must be at least 6 characters long.", creaRef, 1)
                            } else if (validatePassword(password)[0] == false) {
                                CreateToast(validatePassword(password)[1], creaRef, 1)
                            } else if (password != passwordRe) {
                                CreateToast('Passwords must match', creaRef, 1)
                            } else {
                                socket.emit('userAction', {
                                    action: 'reset_password',
                                    password: password,
                                    code: resetKey
                                })
                            }
                        }}>Reset Password</div>
                      
                        
        </div>
            </>
        }/>
    )
}