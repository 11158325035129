
import React, { use, useEffect, useRef, useState } from 'react'
import './connectionmodals.css'
import { FaEye, FaEyeSlash, FaGoogle, FaSteam } from 'react-icons/fa'
import { CiMail } from 'react-icons/ci'
import { PiPasswordLight, PiPasswordThin } from 'react-icons/pi'
import { FcGoogle } from 'react-icons/fc'
import { CreateToast, hideLoginModal, hidePhoneNumberVerifications, hideRegisterModal, setLocalStorage, showLoginModal, showPhoneNumberSendSms, showRegisterModal, showResetPWModal, validateEmail } from '../utils'
import { IoCloseSharp } from 'react-icons/io5'
import { useGoogleLogin } from '@react-oauth/google'
import ReCAPTCHA from "react-google-recaptcha";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from "@firebase/auth";
import { socket } from '../Containers/MainContainer'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
function PhoneNumberModal() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const creaRef = useRef(null)
    const [countryCode, setCountryCode] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        socket.on('checkVerificationPhoneNumberRes', (data) => {
            if (data.type == 1) {
                hidePhoneNumberVerifications()
                showPhoneNumberSendSms()
                setLoading(false)
            } else {
                CreateToast(data.error, creaRef)
                setLoading(false)

            }
        })
    }, [])
    return (
        <div className='modal' style={{ display: 'none' }} onClick={(e) => {
            if (e.target.classList.contains('modal') && !e.target.classList.contains('modal-uip')) {
                e.target.style.display = 'none'
                window.dispatchEvent(new Event('hidePhoneVerifyModals'))
            }
        }} >
            <div className='phone-number--verifications-modal modal-uip x5'>

                <div className="form-container" style={{ minHeight: 'auto' }}>
                    <IoCloseSharp className='modal-close' onClick={() => {
                        window.dispatchEvent(new Event('hidePhoneVerifyModals'))
                        hidePhoneNumberVerifications()
                    }} />
                    <div className="title_container">
                        <p className="title">Verify  Number</p>
                        <span className="subtitle">Input your phone number below</span>
                    </div>
                    <div className='line' style={{ marginBottom: '10px' }}></div>
                    {loading == true ? <p style={{ width: '100%', textAlign: 'center' }}>Loading...</p> : <> <PhoneInput
                        country={'us'}

                        isValid={(value, country) => {
                            setCountryCode(country.dialCode)
                            setPhoneNumber(value)

                        }}
                    />
                        <div className="sign" style={{ marginTop: '20px', cursor: 'pointer' }} onClick={async (e) => {
                            e.preventDefault();

                            socket.emit('checkVerificationPhoneNumber', {
                                number: phoneNumber,
                            })
                            setLocalStorage('phoneNumber', '+' + phoneNumber)
                            window.dispatchEvent(new CustomEvent('phoneNumbChanged', { detail: '+' + phoneNumber }),);
                            setLoading(true)


                        }}>Check Phone Number</div>
                    </>}
                </div>
            </div>
        </div >
    )
}

export default PhoneNumberModal