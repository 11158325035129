import ProgressiveImage from "react-progressive-image-loading"
import { Link } from "react-router-dom"

export const ItemCashOut = (props) => {
    return (
        props.value==undefined?
        <Link className="cashout-item" to={`/cash-out/${props.name.toLowerCase().split(" ").join("-")}`} style={{background:props.background}}>
            <ProgressiveImage
                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                src={props.img}
                render={(src, style) => <img className="cashout-item-img" src={src} />}
            />
            <div className="cashout-item-title">{props.name.toLowerCase().split("-").join(" ")}</div>
        </Link>
        :
        <div className="cashout-item" onClick={(e)=>{props.click(e)}} style={{background:props.background}}>
            <ProgressiveImage
                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                src={props.img}
                render={(src, style) => <img className="cashout-item-img" src={src} />}
            />
            <div className="cashout-item-title">{props.value} {props.name.toLowerCase().split("-").join(" ")} </div>
        </div>
    )
}