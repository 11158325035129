'use client'
import React, { useEffect, useState } from 'react'
import PaginationPluto from './Pagination'
import { canUsePagination, numberWithCommas } from '../utils'
import { socket } from '../Containers/MainContainer'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import SweetAlert from 'react-bootstrap-sweetalert'
import { set } from 'nprogress'
import PlutoMenu from './PlutoMenu'
import VerifyAdmin from './PlutoVerify'
import { Link } from 'react-router-dom'


function PlutoPendingOffers(props) {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [today, setToday] = useState(undefined)
    const [rank, setRank] = useState(0)
    const [alert, setAlert] = useState({ show: false, user_id: -1, offer_id: -1, name: '' })
    useEffect(() => {
        socket.on('response_get_admin_pending', (data) => {
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 5))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)
        })
        socket.on('reTakeUsersPendingDet', (data) => {
            socket.emit('getPendingOffersAdmin', {
                page,
                for_user: props.second_page
            })
        })
        socket.emit('getPendingOffersAdmin', {
            page,
            for_user: props.second_page
        })
    }, [])

    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
            <SweetAlert
                show={alert.show}
                title={alert.title}
                onConfirm={function () {
                    socket.emit('adminAction', {
                        action: 'releaseOfferAdmin',
                        user_id: alert.user_id,
                        offer_id: alert.offer_id,
                    })
                    setAlert({ ...alert, show: false })
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Proceed"
                showCancel={true}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert({ show: false, user_id: '', offer_id: '', name: '' }) }}
            >
                <>
                    <h3>Do you want to release this offer?</h3>
                    <p>Offer_name:{alert.name}</p>
                    <p>Offer_id:{alert.offer_id}</p>
                    <p>User_id:{alert.user_id}</p>
                </>

            </SweetAlert>
            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: '20px' }}>
                    <div className='btn button-disabled waves-effect' style={{ width: '100px' }} onClick={() => {
                        setToday(true)
                        socket.emit('getPendingOffersAdmin', {
                            page,
                            for_user: props.second_page,
                            today: true,
                        })
                    }}>Today</div><div className='btn button-disabled waves-effect' style={{ width: '100px' }} onClick={() => {
                        setToday(undefined)
                        socket.emit('getPendingOffersAdmin', {
                            page,
                            for_user: props.second_page,
                        })
                    }}>All</div>
                </div>
                <div className="user-details-stats">

                    {userProfile.pendingAmount != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.pendingAmount)}
                            </p>
                            <p>Total pending {props.x == 1 ? `for uid_${props.second_page}` : ''}</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}
                    {userProfile.withdrawalsLength != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.withdrawalsLength)}
                            </p>
                            <p>Count pending</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}


                </div>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Pending Offers</p>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '50px' }} >id</div>
                            <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                            <div style={{ minWidth: '100px' }}>Wall</div>
                            <div style={{ minWidth: '150px' }}>Amount</div>
                            <div style={{ minWidth: '150px' }}>Release Time</div>

                            <div style={{ minWidth: '100px', maxWidth: '100px' }}>Check</div>
                            <div style={{ minWidth: '100px', maxWidth: '100px' }}>Check</div>

                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ minWidth: '50px' }} > {earning.id || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.wall || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '150px', }} >{earning.amount || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '150px', }} >{earning.pending_time || <Skeleton style={{ width: '30px' }} />}</div>
                                <div
                                    onClick={() => {
                                        setAlert({ show: true, user_id: earning.user_id, offer_id: earning.id, name: earning.name })
                                    }}
                                    className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{earning.id == undefined ? <Skeleton style={{ width: '30px' }} /> : 'Release'}</div>
                                <Link to={`/pluto/user/${earning.user_id}`} className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', fontSize: '10px', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{earning.id == undefined ? <Skeleton style={{ width: '30px' }} /> : 'Check User'}</Link>

                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getPendingOffersAdmin', {
                        username_like: username,
                        for_user: props.second_page,
                        page,
                        today,
                    })
                }} />}

            </div>

        </SkeletonTheme>
    )
}
function getPathNames() {

}
export default PlutoPendingOffers