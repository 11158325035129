import React from 'react'
import PlutoUser from './PlutoUser'
import { IoCloseSharp } from 'react-icons/io5'

function PlutoUserModal({ userID }) {
    return (
        <div className='modal' style={{ display: 'flex' }} onClick={(e) => {
            if (e.target.classList.contains('modal') && !e.target.classList.contains('modal-body')) {
                e.target.style.display = 'none'
            }
        }} >
            <div className='withdraw-msg-modal modal-body' style={{ width: '90vw' }}>
                <IoCloseSharp className='modal-close' onClick={(e) => {
                    if (e.target.parentNode.parentNode.classList.contains('modal') && !e.target.parentNode.parentNode.classList.contains('modal-body')) {
                        e.target.parentNode.parentNode.style.display = 'none'
                    }
                }} />
                <PlutoUser second_page={userID} x={1} />
            </div></div>
    )
}

export default PlutoUserModal