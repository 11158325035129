'use client'
import { socket } from '../Containers/MainContainer';

import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PlutoMenu from './PlutoMenu';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';

function PlutoIPDet(props) {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [users, setUsers] = useState([]);
    const [ip, setIP] = useState([]);
    const [rank, setRank] = useState(0)

    useEffect(() => {
        socket.on('response_get_admin_ipdetails', (data) => {
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 5))
            setUserProfile(data)
            setRank(data.rank)
        })
        socket.on('response_get_admin_ipdetails_users', (data) => {
            setUsers(data.users)
            setRank(data.rank)

        })
        socket.on('response_get_admin_ipdetails_ip', (data) => {
            setIP(data.ip)

        })
        socket.emit('getIPDetailsAdmin', {
            ip: props.second_page,
        })
        socket.on('reTakeIpDet', () => {
            socket.emit('getIPDetailsAdmin', {
                ip: props.second_page,
            })
        })
    }, [])
    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>

                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>IP Details for {props.second_page}</p>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '50px' }} >id</div>
                            <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Static Score</div>
                            <div style={{ minWidth: '100px' }}>User Type</div>
                            <div style={{ minWidth: '150px' }}>Provider</div>
                            <div style={{ minWidth: '150px' }}>Bad</div>

                            <div style={{ minWidth: '100px', maxWidth: '100px' }}>Check</div>

                        </div>
                    </div>
                    <div className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                        <div style={{ minWidth: '50px' }} > {ip.id || <Skeleton style={{ width: '30px' }} />}</div>
                        <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{ip.static_ip_score || <Skeleton style={{ width: '30px' }} />}</div>
                        <div style={{ minWidth: '100px' }} >{ip.user_type || <Skeleton style={{ width: '30px' }} />}</div>
                        <div style={{ minWidth: '150px', }} >{ip.reason || <Skeleton style={{ width: '30px' }} />}</div>
                        <div style={{ minWidth: '150px', }} >{ip.is_bad == undefined ? <Skeleton style={{ width: '30px' }} /> : ip.is_bad == true ? 'true' : 'false'}</div>

                        <div onClick={() => {
                            socket.emit('adminAction', {
                                action: 'ban_ip',
                                ip: props.second_page,
                            })
                        }} className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', fontSize: '10px', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{ip.is_bad == undefined ? <Skeleton style={{ width: '30px' }} /> : ip.is_bad == true ? 'Unban' : 'Ban'}</div>

                    </div>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>IP Users for {props.second_page}</p>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '50px' }} >id</div>
                            <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Username</div>
                            <div style={{ minWidth: '100px' }}>Country</div>
                            <div style={{ minWidth: '150px' }}>Join Date</div>

                            <div style={{ minWidth: '100px', maxWidth: '100px' }}>Check</div>

                        </div>
                    </div>
                    {users.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ minWidth: '50px' }} > {earning.id || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.username || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.country || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '150px', }} >{earning.joinDate || <Skeleton style={{ width: '30px' }} />}</div>
                                <Link to={`/pluto/user/${earning.id}`} className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', fontSize: '10px', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{earning.id == undefined ? <Skeleton style={{ width: '30px' }} /> : 'Check User'}</Link>

                            </div>
                        )
                    })}
                </div>

            </div>

        </SkeletonTheme>
    )
}

export default PlutoIPDet