import ProgressiveImage from "react-progressive-image-loading"
import { ModalContent } from "./ModalContent"
import { FaAndroid, FaStar } from "react-icons/fa"
import { getOfferWallName, getWallName, numberWithCommas, submitFingersWall } from "../../components/utils"
import { GiSandsOfTime } from "react-icons/gi"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { useEffect, useState } from "react"
import { socket } from "../../components/Containers/MainContainer"
import fingerprintjs from '@fingerprintjs/fingerprintjs';

export const DownloadMobileModalContent = (props) => {
    const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.ml.paidcash&referrer=soruce_website';
    const [msg, SetMsg] = useState('')

    return (
        <ModalContent 
        click={props.click}
        hide={true}
        hideTitle={true}
        title={``} 
        className={`modal-content-wall modal-content-download-mobile`}
        content={
            <>


<p className=" font-bold" style={{fontSize:'25px'}}>Download Our Android App</p>
<img
style={{background:'#fff'}}
src="/assets/img/app_gq.png"
alt="App QR Code"
width={150}
height={150}
/>
<p className=" text-gray-600">
Scan the QR code above or click the button below to download our Android app from Google Play Store for extra features!
</p>
<a
href={playStoreUrl}
style={{fontSize:'20px', padding:'10px 20px', borderRadius:'5px', background:'#4CAF50', color:'#fff', textDecoration:'none'}}
target="_blank"
rel="noopener noreferrer"
className=" button button-brand"
>
Download App
</a>
           </>
        }/>
    )
}