import React from 'react';
import { render } from 'react-dom';
import { Link, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import App from './App';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route path="/:page" element={<App />} />
      <Route path="/:page/:second_page" element={<App />} />
      <Route path="/:page/:second_page/:third_page" element={<App />} />
    </Routes>
  </Router>,
); 