
import { CreateToast, USDWithCommas, getChatStatus, getShownCurency, numberWithCommas, onSocket, validateEmail, withdrawCryptoCards, withdrawGiftcardCards, withdrawSkinsCards } from '../components/utils'

import React, { useEffect, useRef, useState } from 'react'
import Skeleton from "react-loading-skeleton";
import ProgressiveImage from "react-progressive-image-loading";
import { Menu, Popover, Switch } from "@headlessui/react"

import { Fa500Px, FaDollarSign, FaSortNumericDown, FaWallet } from 'react-icons/fa'
import { Navigate, useParams } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import { socket } from '../components/Containers/MainContainer';
import '../assets/style/CashOutOption.css'
import { ItemCashOut } from '../Component/CashOut/Item.CashOut';
import { MdNumbers } from 'react-icons/md';


export function CashOutOptionContainer(props) {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const [hapa, setHandleAmount] = useState(getShownCurency() == 'usd' ? 1 : 0)
    function handleAmount(am) {
            return numberWithCommas(am)
    }
    useEffect(() => {
        window.addEventListener('shownCurrency', () => {
            if (getShownCurency() == 'usd') {
                setHandleAmount(1)
            } else {
                setHandleAmount(0)
            }
        })
    }, [getShownCurency()])
    const { referral_id, page, second_page, third_page } = useParams();

    const [cryptoCard, setCryptoCard] = useState('');
    const [wait, setWait] = useState(false)
    const [address, setAddress] = useState('');
    const [fee, setFee] = useState(0);
    const [paypalData, setPaypalData] = useState({ paypal_email: '', first_name: '', last_name: '', address: '' })
    const [amount, setAmount] = useState(0);
    const [dbData, setDBData] = useState([])
    const [option, setOptione] = useState({})
    const [cryptoPrice, setCryptoPrice] = useState(-1);
    const [optionID, setOptionID] = useState(-1)
    const [selectedOption, setOption] = useState({})
    const creaRef = useRef(null);
    const [countries, setCountries] = useState(null)
    const [balance, setBalance] = useState(0);
    const [isBanned, setBanned] = useState(false);
    const [type, setType] = useState(-1);
    const [giftCard, setGiftCard] = useState({});
    function calculateAmountReceived() {

        let finalAmount = parseFloat(((amount - selectedOption.fee) / 1000) / cryptoPrice).toFixed(8);
        if (finalAmount < 0) {
            return 0;
        }
        return finalAmount
    }

    function canWithdraw() {
        if (wait == false && parseInt(balance) >= parseInt(amount) && isBanned == false && (address.split("").length > 5 || type == 1) && amount != 0) {
            return true;
        } else {
            return false;
        }
    }
    const [selectedCountry, setSelectedCountry] = useState('')
    useEffect(() => {

        if ((withdrawCryptoCards.filter(x => x.name.toLowerCase().split(" ").join("-") == second_page.toLowerCase().split(" ").join("-"))).length > 0) {
            setType(0)
            setCryptoCard(withdrawCryptoCards.filter(x => x.name.toLowerCase().split(" ").join("-") == second_page.toLowerCase().split(" ").join("-")))
            socket.emit('getCryptoData', {
                for: second_page.toLowerCase().split(" ").join("-")
            })
            socket.on('allowWithdraw', () => {
                setTimeout(() => {
                    setWait(false)
                }, 1000)
            })
            socket.on('cryptoData', (data) => {
                if (data.price == undefined) { <Navigate to={'/withdraw'} /> }
                setDBData(data.data)
                setCryptoPrice(data.price)
                setOption(data.data.options[0])
                setBanned(data.banned)
                setBalance(data.balance)

            })
        } else if ((withdrawGiftcardCards.filter(x => x.name.toLowerCase().split(" ").join("-") == second_page.toLowerCase().split(" ").join("-"))).length > 0) {
            setType(1)
            socket.emit('getGiftCardData', {
                for: second_page.toLowerCase().split(" ").join("-")
            })
            setGiftCard(withdrawGiftcardCards.filter(x => x.name.toLowerCase().split(" ").join("-") == second_page.toLowerCase().split(" ").join("-")))
            socket.on('giftCardData', (data) => {
                if (data.data.name != "Error") {
                    setSelectedCountry(data.data.showFor)
                    setCountries(data.data.options)
                    setOptione(data.data.options[data.data.showFor])
                    setDBData(data.data)
                    setBanned(data.banned)
                    setBalance(data.balance)
                } else {
                    <Navigate to={'/withdraw'} />
                }

            })
            socket.on('allowWithdraw', () => {
                setTimeout(() => {
                    setWait(false)
                }, 1000)
            })
        } else if ((withdrawSkinsCards.filter(x => x.name.toLowerCase().split(" ").join("-") == second_page.toLowerCase().split(" ").join("-"))).length > 0) {
            setType(1)
            socket.emit('getGiftCardData', {
                for: second_page.toLowerCase().split(" ").join("-")
            })
            setGiftCard(withdrawSkinsCards.filter(x => x.name.toLowerCase().split(" ").join("-") == second_page.toLowerCase().split(" ").join("-")))
            socket.on('giftCardData', (data) => {
                if (data.data.name != "Error") {
                    setSelectedCountry(data.data.showFor)
                    setCountries(data.data.options)
                    setOptione(data.data.options[data.data.showFor])
                    setDBData(data.data)

                    setBanned(data.banned)
                    setBalance(data.balance)
                }

            })
            socket.on('allowWithdraw', () => {
                setTimeout(() => {
                    setWait(false)
                }, 1000)
            })
        } else {
            <Navigate to={'/withdraw'} />
        }
    }, [])
    const [chatStatus, setChatStatus] = useState(getChatStatus())
    useEffect(() => {
        window.addEventListener('showChat', () => {
            setChatStatus(getChatStatus())
        })

    }, [])
     socket.on('emitWithdrawAnswer', (data) => {
        window.dispatchEvent(new CustomEvent('showInfoModal', {detail: {title: `Here's News!`, description: `${data.message}`,  type:'medium', click:()=>{}}}))
        })
    return (
        <DocumentMeta title={`Cashout ${dbData.name} | Paidcash.co`}  >
            <>
                {type == -1 ? "" : type == 0 ? <div className="withdraw-container">
                    <div className="cashout-option-header">
                        <div className="cashout-option-header-left">
                <div className='cashout-option-title' >{dbData.name}</div>
                
                <Popover>
                        {({ open }) => (
                            <>
                                <Popover.Button className="withdraw-drop-btn">{selectedOption.name || 'loading..'}</Popover.Button>
                                <Popover.Panel className="header-menu-box-withdraw ">
                                    {({ close }) => (

                                        dbData.options == undefined ? '' : dbData.options.map((options, i) => {
                                            let country = dbData.options[0];
                                            return (
                                                <div className="header-menu-box-item"
                                                    onClick={() => {
                                                        setOption(options)
                                                        close()
                                                    }}>

                                                    {options.name}
                                                </div>
                                            )
                                        })

                                    )}
                                </Popover.Panel>
                            </>
                        )}
                    </Popover>
                    </div>
                    <div className='cashout-header-balance'>
                        <div className='cashout-header-balance-amount'>
                        <div className="header-balance-text-balance">Balance: </div>
                            <ProgressiveImage
                                    preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                    src="/assets/img/faucetify_coin_small.png"
                                    render={(src, style) => <img alt="offer image" className="header-balance-icon" style={style}
                                        loading="lazy" src={src} />}
                            />
                            <div className="header-balance-text">{numberWithCommas(balance)}</div>
                        </div>
                    </div>
                    </div>
                    


                    <p className="withdraw-crypto-para-label">{selectedOption.message_1 || <Skeleton style={{ width: '80px' }} />}</p>
                    <div className='input-container'>
                        <div className="input-icon"><FaWallet/></div>
                        <input onChange={(e) => {
                        setAddress(e.currentTarget.value)
                        }} type={selectedOption.require == "email" ? "email" : 'text'} className="walletCrypto" name="coin_amount" placeholder="Enter address" autoComplete="off" />

                    </div>
                    <p className="withdraw-crypto-para-label">Amount</p>
                    <div className='input-container'>
                        <div className="input-icon"><MdNumbers/></div>
                        <input defaultValue={10} onChange={(e) => {
                        setAmount(e.currentTarget.value)
                    }} type="number" name="amount" className="amountCrypto" placeholder="Enter amount" autoComplete="off" />
                    </div>
                   
                    <div className="modal-cont-item-with-info">

                        <div className="modal-cont-item-with-min">
                            Minimum {selectedOption.minimum == undefined ? '-' : handleAmount(selectedOption.minimum)} coins ({selectedOption.minimum == undefined ? '-' : '$' + (selectedOption.minimum / 1000).toFixed(2)})
                        </div>
                        <div className="modal-cont-item-with-price">
                            <p>1 {cryptoCard.unit != undefined ? cryptoCard.unit.toUpperCase() : ''}</p>
                            <p>{cryptoPrice == -1 ? <Skeleton style={{ width: '30px' }} /> : '$' + numberWithCommas(cryptoPrice.toFixed(3))}</p>
                        </div>
                    </div>
                    <div className="modal-cont-item-with-fee">
                        <div>
                            Fee
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                            <ProgressiveImage
                                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                src="/assets/img/faucetify_coin_small.png"
                                render={(src, style) => <img alt="offer image" width={10}
                                    loading="lazy"
                                    height={10} src={src} />}
                            />{selectedOption.fee == undefined ? <Skeleton style={{ width: '30px' }} /> : selectedOption.fee}
                        </div>
                    </div>
                    <div className="modal-cont-item-with">
                        <div>
                            You receive
                        </div>
                        <div>
                            {handleAmount(calculateAmountReceived())} {cryptoCard[0].unit != undefined ? cryptoCard[0].unit.toUpperCase() : ''}
                        </div>
                    </div>
                    <button className="btn withdraw-btn waves-effect"
                        style={{ background: canWithdraw() == true ? 'var(--text-brand-2)' : 'var(--bg-color-1)' }} onClick={() => {
                            if (canWithdraw() == true) {
                                if (selectedOption.require == "email") {
                                    if (validateEmail(address) == false) {
                                        CreateToast("Email address not valid!", creaRef, 1)
                                    } else {
                                        handleWithdrawal()
                                    }
                                } else {
                                    handleWithdrawal()
                                }
                                function handleWithdrawal() {
                                    setWait(true)
                                    CreateToast("Requesting Withdrawal!", creaRef, 0)
                                    socket.emit('withdrawCrypto', {
                                        platform: selectedOption.name,
                                        coin: dbData.name,
                                        address: address,
                                        amount: amount,
                                    })
                                }
                            }
                        }}>Withdraw</button>
                </div> : type == 1 ? <div className="withdraw-container">
                    <div className="cashout-option-header">
                        <div className="cashout-option-header-left">
                <div className='cashout-option-title' >{dbData.name}</div>
                {option.name == undefined ? <Skeleton style={{ width: '300px', height: '40px' }} /> :
                        option.name.toLowerCase() == "worldwide" || option.name.toLowerCase() == "international" ? "" :
                            <Popover>
                                {({ open }) => (
                                    <>
                                        <Popover.Button className="withdraw-drop-btn">
                                           <img width={30} height={30}  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry}.svg`} />
                                            {option.name}
                                        </Popover.Button>
                                        <Popover.Panel className="header-menu-box-withdraw-gc ">
                                            {({ close }) => (

                                                dbData.options == undefined ? '' :
                                                    Object.keys(countries).map((countryCode, i) => {
                                                        let country = countries[countryCode];
                                                        return (
                                                            <div className="header-menu-box-item-gc" style={{ background: selectedCountry == countryCode ? 'var(--accent-2)' : 'var(--accent-4)' }}
                                                                onClick={() => {
                                                                    setOptione(country)
                                                                    setSelectedCountry(countryCode)
                                                                    close()
                                                                }}>
                                                               <img width={30} height={30}  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`} />
                                                                {country.name}
                                                            </div>
                                                        )
                                                    })

                                            )}
                                        </Popover.Panel>
                                    </>
                                )}
                            </Popover>
                    }
                    </div>
                    <div className='cashout-header-balance'>
                        <div className='cashout-header-balance-amount'>
                        <div className="header-balance-text-balance">Balance: </div>
                            <ProgressiveImage
                                    preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                    src="/assets/img/faucetify_coin_small.png"
                                    render={(src, style) => <img alt="offer image" className="header-balance-icon" style={style}
                                        loading="lazy" src={src} />}
                            />
                            <div className="header-balance-text">{numberWithCommas(balance)}</div>
                        </div>
                    </div>
                    </div>
                   

                    <div className="gc-selection" >
                    <div className="cashout-items">
                        {option.packs == undefined || giftCard==undefined ? <Skeleton style={{ width: '300px', height: '200px' }} /> : option.packs.map((x, i) => {
                            let calcAmount = Math.ceil((x[0] * parseFloat(option.rate)) * 1000)
                            return (
                                <ItemCashOut click={()=>{
                                    setAmount(calcAmount)
                                    if (dbData.name.toLowerCase() == "paypal") {
                                        let feeCalcAmount = calcAmount * 0.025

                                        if (parseInt(x[0])<=20) {
                                            setFee(550)
                                        } else {
                                            setFee(feeCalcAmount)

                                        }

                                    } else {
                                        setFee(x[1])
                                    }
                                    setOptionID(i)
                                }} img={`${giftCard[0].img}`} name={`${giftCard[0].name}`} background={`${giftCard[0].background}`} value={`${option.currencySign == undefined ? "" : option.currencySign}${x[0]}`} />

                              
                            )
                        })}
</div>
                    </div>
                    <div className="modal-cont-item-with-fee">
                        <div>
                            Fee
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <ProgressiveImage
                                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                src="/assets/img/faucetify_coin_small.png"
                                render={(src, style) => <img alt="offer image" width={10}
                                    loading="lazy"
                                    height={10} src={src} />}
                            /> {handleAmount(fee)}
                        </div>
                    </div>
                    <div className="modal-cont-item-with">
                        <div>
                            Coin Price
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <ProgressiveImage
                                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                src="/assets/img/faucetify_coin_small.png"
                                render={(src, style) => <img alt="offer image" width={10}
                                    loading="lazy"
                                    height={10} src={src} />}
                            /> {handleAmount(amount + fee)}
                        </div>
                    </div>
                    <button className="btn withdraw-btn waves-effect"
                        style={{ background: canWithdraw() == true ? 'var(--text-brand-2)' : 'var(--bg-color-1)' }} onClick={() => {
                            if (canWithdraw() == true) {
                                setWait(true)
                                    socket.emit('withdrawGiftCard', {
                                        country: selectedCountry,
                                        giftcard: dbData.name,
                                        option: optionID,
                                    })

                            }
                        }}>Withdraw</button>
                </div> : ""}
                </>
        </DocumentMeta>
    )
}

