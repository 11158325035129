import { FaBackward } from "react-icons/fa"
import '../../assets/style/ModalContent.css'
import { RiCloseFill } from "react-icons/ri"

export const ModalContent = (props) => {
    return (
        <div style={{display:props.hide==true?'none':'inherit'}} className="modal-main" onClick={(e)=>{
            // try{
            //     if(!e.target.className.includes('modal-content')){
            //         if(props.className.includes('modal-content-wall')){
            //             if(e.target.className.includes('modal-main')){
            //                 e.currentTarget.style.display = 'none'
            //             }
            //         }else{
            //             props.click()
            //         }
            //     }
            // }catch(e){}
        }}>
            <div className={`modal-content ${props.className}`}>
                <div className="modal-content-header" style={{padding:props.hideTitle==undefined?'auto':props.hideTitle==false?'auto':'0px'}}>
                    {props.hideTitle==undefined?<div className="modal-content-header-title">{props.title}</div>:
                    props.hideTitle==false?<div className="modal-content-header-title">{props.title}</div>:''}
                    <div className={`${props.hideTitle==undefined?'modal-content-header-close':
                    props.hideTitle==false?'modal-content-header-close':'modal-content-header-close modal-content-header-close-fixed'}`} 
                    onClick={(e)=>{ props.click(e) }}><RiCloseFill/></div>
                </div>
                <div className="modal-content-body" >
                    {props.content}
                </div>
            </div>
        </div>
    )
}