'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';
import React from 'react'
import PaginationPluto from './Pagination';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useEffect } from 'react';
import { useState } from 'react';
import PlutoMenu from './PlutoMenu';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';

function PlutoUsersToday() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [rank, setRank] = useState(0)
    const [uid, setUID] = useState(-1)
    useEffect(() => {
        socket.on('response_get_admin_userstoday', (data) => {
            setEarnings(data.users)
            setPageNumber(Math.ceil(data.usersLength2 / 10))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)
            setUID(data.uid);

        })
        socket.emit('getUsersAdminToday', {
            username_like: username,
            page,
        })
    }, [])

    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {userProfile.newUsersYesterday != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.newUsersYesterday)}
                            </p>
                            <p>Joined Today</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}
                </div>
                <div className="input-container">
                    <input style={{ borderRadius: '5px', background: 'transparent', marginBottom: '5px', color: '#fff', fontSize: '12px', padding: '5px' }} onChange={(e) => {
                        setUsername(e.currentTarget.value)
                        setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

                        socket.emit('getUsersAdminToday', {
                            username_like: e.currentTarget.value.toLowerCase(),
                            page: 1,
                        })

                    }} type="text" name="amount" placeholder="Search by username" autoComplete="off" />
                </div>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Users</p>

                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '200px' }} >(id) username</div>
                            <div style={{ minWidth: '50px', textAlign: 'left', color: 'var(--text-primary)' }} >Country</div>
                            <div style={{ minWidth: '100px' }}>Balance</div>
                            <div style={{ minWidth: '100px' }}>Total Earned</div>
                            <div style={{ minWidth: '100px' }}>Join Date</div>
                            <div style={{ minWidth: '100px' }}>Banned</div>
                            <div style={{ minWidth: '100px' }}>Deleted</div>
                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <Link to={`/pluto/user/${earning.id}`} key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)', textDecoration: 'none' }}>

                                <div style={{ minWidth: '200px' }} >{earning.username == undefined ? '' : `(${earning.id})`} {earning.username || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.country || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.balance || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.total_earned || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.join_date || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px', color: earning.banned == "Yes" ? '#b06a6a' : '' }} >{earning.banned || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.deleted || <Skeleton style={{ width: '30px' }} />}</div>

                            </Link>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                    socket.emit('getUsersAdminToday', {
                        username_like: username,
                        page,
                    })
                }} />}
                <div >
                    <input onKeyDown={
                        (e) => {
                            if (e.key == 'Enter') {
                                canUsePagination(false)
                                setEarnings([0, 0, 0, 0, 0])
                                socket.emit('getUsersAdminToday', {
                                    username_like: username,
                                    page: document.getElementById('jumpto3d').value,
                                })
                            }
                        }

                    } type="number" id="jumpto3d" style={{ background: 'gray', color: '#000' }} />
                    <button onClick={() => {
                        canUsePagination(false)
                        setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                        socket.emit('getUsersAdminToday', {
                            username_like: username,
                            page: document.getElementById('jumpto3d').value,
                        })
                    }
                    } style={{ color: '#fff', background: '#000' }}>Go to page</button>
                </div>
            </div>

        </SkeletonTheme>
    )
}

export default PlutoUsersToday