import { useEffect, useState } from "react";
import { Link } from "react-router-dom"


export const MenuButtonSideMenu = (props) => {
    const size = useWindowSize();

    function handleMenuClick() {
        if (size.width <= 1050) {
        document.dispatchEvent(new CustomEvent('handleSideMenuMobile', { detail: { open: true } }))
        }
    }
    return(
        props.link==true?
        <Link onClick={(e)=>{
            handleMenuClick()
            }} to={props.to} className={`side-menu-button ${props.selected==true?'side-menu-button-selected':''}`}>
            <div className="side-menu-button-icon">
                {props.icon}
            </div>
            <div className="side-menu-button-title">
                {props.title}
            </div>
        </Link>
        :
        <div onClick={(e)=>{
            handleMenuClick()
            props.click(e)
            }} className={`side-menu-button ${props.selected==true?'side-menu-button-selected':''}`}>
            <div className="side-menu-button-icon">
                {props.icon}
            </div>
            <div className="side-menu-button-title">
                {props.title}
            </div>
        </div>
        
    )

}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // only execute all the code below in client side
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}