
import { useState, useEffect } from 'react'
import { useParams, useLocation, Link } from "react-router-dom";
import { FaArrowLeft, FaAward, FaCashRegister, FaChartBar, FaChartLine, FaCoins, FaDollarSign, FaEdit, FaEye, FaFacebook, FaIdBadge, FaInstagram, FaLink, FaParagraph, FaPause, FaPlay, FaQuestion, FaQuestionCircle, FaRemoveFormat, FaStar, FaStopwatch, FaTelegram, FaTrash, FaTwitter } from "react-icons/fa";
import { MdVerified } from 'react-icons/md';
import { getChatStatus, isLoggedIn, numberWithCommas } from '../../components/utils';
import { socket } from '../../components/Containers/MainContainer';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import SweetAlert from 'react-bootstrap-sweetalert';
import '../../assets/style/PTC.css'

const durationPrice = [[7, 0.0006], [10, 0.0008], [15, 0.001], [20, 0.0019], [40, 0.0023], [60, 0.0027]]
const rerunAdsOptions = [[1, 0], [2, 0.03], [3, 0.15], [7, 0.25], [15, 0.25], [30, 0.35], [-1, 0.5]]
function AdDashboard() {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const STYLE = {
        input: { width: '100%', backgroundColor: '#0e151e', color: '#7a7a7a', border: 'none', padding: '11px 9px 12px 9px', fontSize: '13px', outline: 'none', borderRadius: '4px' }
    }
    const [clicksNumber, setClicksNumber] = useState(2000);

    const [chatStatus, setChatStatus] = useState(getChatStatus())
    let [loggedIN, setLogged] = useState(isLoggedIn());
    useEffect(() => { setLogged(isLoggedIn()) }, [isLoggedIn()])
    const { referral_id, page, second_page, third_page } = useParams();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const { state } = useLocation();
    const statusChat = state == null ? undefined : state.chatStatus;
    const [showChat, setShowChat] = useState(statusChat == undefined ? false : statusChat)
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [promotedOffers, setPromotedOffers] = useState(new Array(0).fill('cappa'))

    useEffect(() => {
        window.addEventListener('showChat', () => {
            setChatStatus(getChatStatus())
        })
        socket.on('clodeAddBalancePTC', () => {
            setAlert({ show: false })
        })
    }, [])
    let networkTimeout = null;

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });
    useEffect(() => {
        loadLazyData();
    }, [lazyState]);
    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
        listenEmittersEarnings()
        socket.emit('getPTCCampaigns', {
            page: lazyState.page+1,
        });


    };


    const onSort = (event) => {
        setlazyState(event);
    };
    function listenEmittersEarnings() {

        socket.on('ptcCampaigns', async (data) => {
            let ppOff = [...data.offers]

            setLoading(false);
            setTotalRecords(data.pageNumber)
            setPromotedOffers([...ppOff]);

        })
    }
    const onFilter = (event) => {
        event['first'] = 0;
        setlazyState(event);
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setSelectedCustomers(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            promotedOffers.getCustomers().then((data) => {
                setSelectAll(true);
                setSelectedCustomers(data.customers);
            });
        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onPage = (event) => {
        setlazyState(event);
    };
    const [alert, setAlert] = useState({ type: 1, show: false, user_id: -1, offer_id: -1, name: '' })

    return (<>
       <div className={`main-container`}>
            <SweetAlert
                show={alert.show}
                onConfirm={function () {
                    if (alert.type == 1) {
                        socket.emit('userAction', {
                            action: 'addPTCBalance',
                            id: alert.id,
                            balance: clicksNumber
                        })
                    } else if (alert.type == 2) {
                        socket.emit('userAction', {
                            action: 'changePTCDailyLimit',
                            id: alert.id,
                            daily_limit: clicksNumber
                        })

                    }
                }
                }
                style={{ backgroundColor: 'rgb(20, 30, 41)', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Proceed"
                showCancel={true}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#1b5251' }}
                onCancel={function () { setAlert({ show: false, user_id: '', offer_id: '', name: '' }) }}
            >
                <>
                    <h3>{alert.type == 1 ? 'Add Balance' : 'Change Daily Limit'}</h3>
                    {alert.type == 2 ? '0 means infinite' : ''}
                    <input onChange={(e) => {
                        setClicksNumber(parseInt(e.target.value))
                    }} min="1" defaultValue={clicksNumber} id="clicks" type="number" name="clicks" style={STYLE.input} />

                    {alert.type == 1 ? <div className="payout-price" >
                        Total:
                        <div>
                            {isNaN((alert.is_promoted == true ? (parseInt(0.1 * (clicksNumber))) : 0)
                                +
                                (alert.is_social == true ? (parseInt(0.1 * (clicksNumber))) : 0)
                                + clicksNumber) ? 0 : (alert.is_promoted == true ? (parseInt(0.1 * (clicksNumber))) : 0)
                                +
                                (alert.is_social == true ? (parseInt(0.1 * (clicksNumber))) : 0)
                            + clicksNumber}
                        </div>

                    </div> : ''}
                </>

            </SweetAlert>
            <div className='cat-earn-mob' style={{ display: 'flex', margin:'0px' }}>
                <Link className={`${second_page.toLowerCase() == "create" ? 'sel' : ''}`} to={`/advertise`} key={-1}>
                    <div className={`cat-earn-mob-item `}>
                        <div className='cat-earn-mob-item-name'>Create Ads</div>
                    </div>
                </Link>
                <Link className={`${second_page.toLowerCase() == "campaigns" ? 'sel' : ''}`} to={`/advertise/campaigns`} key={-1}>
                    <div className={`cat-earn-mob-item `} style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                        <div className='cat-earn-mob-item-name'>Campaigns</div>
                    </div>
                </Link>
                <Link className={`${second_page.toLowerCase() == "deposits" ? 'sel' : ''}`} to={`/advertise/deposits`} key={-1}>
                    <div className={`cat-earn-mob-item `} style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                        <div className='cat-earn-mob-item-name'>Deposits</div>
                    </div>
                </Link>
            </div>



            <p style={{ fontSize: '12px', color: '#93acd3',  marginBottom:'-15px' }}>Manage your created advertisements, charge them and check their statistics. <FaCashRegister style={{ fill: '#d65d31' }} /></p>

            <div >
                <DataTable
                    value={promotedOffers} lazy dataKey="id" paginator
                    first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading}
                    selection={promotedOffers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
                    <Column field="name" header="Title" body={(product) => {
                        return <p style={{  marginLeft:'8px', display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer', marginBottom:'0px' }}>
                            <p className='promoted-offers-name-small' style={{ maxWidth: '250px', padding: '0 5px',
                                color:'var(--text-white-3)', fontSize:'12px', marginBottom:'0px'
                             }}>{(product.title)} </p></p>
                    }}></Column>
                    <Column field="created_at" header="Created At" body={(product) => {
                        return <p style={{   color:'var(--text-gray-4)', fontSize:'12px', marginBottom:'0px'}}>  {product.created_at}</p>
                    }}></Column>
                    <Column field="balance" header="Main Balance" body={(product) => {
                        return <>
                            <p style={{color:'var(--text-gray-4)', marginBottom:'0px', fontSize:'12px'}}>{numberWithCommas(product.balance) + ` ${product.balance > 1 ? 'tokens' : 'token'}`}</p>
                            <div onClick={() => {
                                setAlert({ show: true, type: 1, id: product.id, balance: product.balance, title: product.title, is_promoted: product.is_promoted, is_social: product.is_social, })
                            }} className={`cat-earn-mob-item `} style={{ background: '#2b672b', width: 'auto', color: '#f3f3f3', textAlign: 'center', borderRadius: 'var(--default-border-radius)', cursor: 'pointer', fontSize: '12px' }}>
                                <div className='cat-earn-mob-item-name'>Add</div>
                            </div></>
                    }}></Column>
                    <Column field="is_promoted" header="Promoted" body={(product) => {
                        return product.is_promoted == true ?
                            <MdVerified style={{ fill: '#6fbf6f' }} /> :
                            <MdVerified style={{ fill: '#b95d5d' }} />
                    }}></Column>
                    <Column field="is_social" header="Social Links" body={(product) => {
                        return product.is_social == true ?
                            <MdVerified style={{ fill: '#6fbf6f' }} /> :
                            <MdVerified style={{ fill: '#b95d5d' }} />
                    }}></Column>
                    <Column field="pending" header="Pending" body={(product) => {
                        return product.pending == false ?
                            <p style={{ color: '#6fbf6f', marginBottom: '0px', marginBottom:'0px', fontSize:'12px' }}>Approved</p> :
                            <p style={{ color: '#b7b661', marginBottom: '0px', marginBottom:'0px', fontSize:'12px' }}>Pending</p>
                    }}></Column>
                    <Column field="active" header="Status" body={(product) => {
                        return product.active == true ? <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', gap:'5px'}}>
                            <p style={{ color: '#6fbf6f', marginBottom: '0px', display: 'inline-block', fontSize: '12px' }}>(Running)</p> <FaPause onClick={() => {
                                socket.emit('userAction', { action: 'pausePTC', id: product.id })
                            }} style={{ fontSize: '12px', margin: '0 8px 0 0', fill: '#b7b661', cursor: 'pointer' }} /> </div> :
                            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', gap:'5px'}}><p style={{ color: '#b7b661', marginBottom: '0px', display: 'inline-block', fontSize: '12px' }}>(Paused)</p> <FaPlay onClick={() => {
                                socket.emit('userAction', { action: 'runPTC', id: product.id })

                            }} style={{ fontSize: '12px', margin: '0 8px 0 0', fill: '#6fbf6f', cursor: 'pointer' }} /></div>
                    }}></Column>
                    <Column field="daily_limit" header="Daily Limit" body={(product) => {
                        return <>
                        <p style={{ color: 'var(--text-gray-4)', marginBottom: '0px', marginBottom:'0px', fontSize:'12px' }}>{numberWithCommas(product.daily_limit)}</p>
                            <div onClick={() => {

                                setAlert({ show: true, type: 2, id: product.id, daily_limit: product.daily_limit, title: product.title, is_promoted: product.is_promoted, is_social: product.is_social })
                            }} className={`cat-earn-mob-item `} style={{ background: '#2b672b', width: 'auto', borderRadius: 'var(--default-border-radius)', color: '#f3f3f3', textAlign: 'center',  cursor: 'pointer', fontSize: '12px' }}>
                                <div className='cat-earn-mob-item-name'>Change</div>
                            </div>
                        </>
                    }}></Column>
                    <Column field="clicks_today" header="Today Watched" body={(product) => {
                        return  <p style={{ color: 'var(--text-gray-4)', marginBottom: '0px', marginBottom:'0px', fontSize:'12px', textAlign:'center' }}>{numberWithCommas(product.clicks_today)}</p>
                    }}></Column>
                    <Column field="clicks_total" header="Total Watched" body={(product) => {
                        return <p style={{ color: 'var(--text-gray-4)', marginBottom: '0px', marginBottom:'0px', fontSize:'12px', textAlign:'center' }}>{numberWithCommas(product.clicks_total)}</p>
                    }}></Column>
                    <Column field="type" header="type" body={(product) => {
                        return <p style={{ color: 'var(--text-gray-4)', marginBottom: '0px', marginBottom:'0px', fontSize:'12px', textAlign:'right' }}>{product.type == 1 ? 'Frameless' : 'iFrame'}</p>
                    }}></Column>


                </DataTable>

            </div>


        </div>


    </>);
}

export default AdDashboard;