
import React, { useEffect, useState } from 'react'
import { getChatStatus, numberWithCommas, setDesiredCrypto, setDesiredGiftCard, withdrawCryptoCards, withdrawGiftcardCards, withdrawSkinsCards } from "../components/utils.js"
import ProgressiveImage from 'react-progressive-image-loading'
import { Link, useParams } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import '../assets/style/CashOut.css'
import { ItemCashOut } from '../Component/CashOut/Item.CashOut.js'
import { FaQuestionCircle, FaRegQuestionCircle } from 'react-icons/fa'
import { socket } from '../components/Containers/MainContainer.js'

export function CashOutContainer() {
    const { referral_id, page, second_page, third_page } = useParams();
    const [currentBalance, setCurrentBalance] = useState(0)
    const [pendingBalance, setPendingBalance] = useState(0)
    const [lockedBalance, setLockedBalance] = useState(0)
    const [required, requiredAmount] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        socket.emit('get_withdraw_balances')
        socket.on('emit_withdraw_balances', (data) => {
            setCurrentBalance(data.currentBalance)
            setPendingBalance(data.pendingBalance)
            setLockedBalance(data.lockedBalance)
            requiredAmount(data.required)
        })
    },[])

    return (
        <DocumentMeta title={`Cash Out ${second_page == undefined ? '' : second_page.split("-")} | PaidCash.co`} description="Withdraw your earnings from PaidCash.co">
            
            <div className='cashout-header'>
                <div className='cashout-header-left'>
                <div className="cashout-title">Withdraw Cash</div>
                <div className="cashout-description">Turn your coins into real rewards on PaidCash.co! Cash out via PayPal, Bitcoin, VISA, Amazon, and more. Crypto withdrawals start from just $0.10</div>
                </div>
                <div className='cashout-header-right'>
                    <div className='cashout-header-balance'>
                        <div className='cashout-header-balance-amount'>
                            <ProgressiveImage
                                    preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                    src="/assets/img/faucetify_coin_small.png"
                                    render={(src, style) => <img alt="offer image" className="header-balance-icon" style={style}
                                        loading="lazy" src={src} />}
                            />
                            <div className="header-balance-text">{numberWithCommas(currentBalance)}</div>
                        </div>
                        <div className='cashout-header-balance-title'>Current Balance</div>
                    </div>
                    <div className='cashout-header-balance-pend'>
                       <div className='cashout-header-balance'>
                            <div className='cashout-header-balance-amount'>   <ProgressiveImage
                                    preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                    src="/assets/img/faucetify_coin_small.png"
                                    render={(src, style) => <img alt="offer image" className="header-balance-icon" style={style}
                                        loading="lazy" src={src} />}
                            />
                            <div className="header-balance-text">{numberWithCommas(lockedBalance)}
                            <div className='cashout-header-balance-why'><FaRegQuestionCircle onClick={()=>{
                                window.dispatchEvent(new CustomEvent('showInfoModal', {detail: {title: 'Pending Balance', description: `You currently have ${numberWithCommas(lockedBalance)} coins held and in order to unlock these you have to earn ${numberWithCommas(required)} coins.
                                 These coins are rewards from PaidCash, and you’ll need to accumulate ${numberWithCommas(required)} coins before they’re eligible for withdrawal. `, type:'small', image:''}}))
                            }}/></div>
                            </div>
                            </div>
                            <div className='cashout-header-balance-title'>Withdrawable Soon  </div>
                           

                       </div>
                       <div className='cashout-header-balance'>
                            <div className='cashout-header-balance-amount'>   <ProgressiveImage
                                    preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                    src="/assets/img/faucetify_coin_small.png"
                                    render={(src, style) => <img alt="offer image" className="header-balance-icon" style={style}
                                        loading="lazy" src={src} />}
                            />
                            <div className="header-balance-text">{numberWithCommas(pendingBalance)}
                            <div className='cashout-header-balance-why' onClick={()=>{
                                 window.dispatchEvent(new CustomEvent('showInfoModal', {detail: {title: 'Pending Balance', description: `You currently have ${numberWithCommas(pendingBalance)} coins in pending and these are to be released once the specified time expires. You can see these offers on your profile section in pendings.`, type:'small', image:''}}))
                            }}><FaRegQuestionCircle/></div>
                             </div></div>
                            <div className='cashout-header-balance-title'>Pending Offers  </div>
                           
                           

                       </div>
                    </div>
                </div>
            </div>
            <div className='cashout-body'>
             <div className="cashout-title">Withdraw Cash</div>
            <div className="cashout-items">
               
                {withdrawCryptoCards.map((card, i) => {
                    if (card.type == "Crypto") {
                        return (
                            <ItemCashOut img={card.img} name={card.name} background={card.background} />
                        )
                    }
                })}
            </div>
            <div className="cashout-title">Withdraw GiftCards</div>
            <div className="cashout-items">
                
                {withdrawGiftcardCards.map((card, i) => {
                    if (card.type == "GiftCard") {
                        return (
                            <ItemCashOut img={card.img} name={card.name} background={card.background}  />
                        )
                    }
                })}
            </div>
            </div>
        </DocumentMeta>
    )
}

