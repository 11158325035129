import ProgressiveImage from "react-progressive-image-loading"
import { ModalContent } from "./ModalContent"
import { FaAndroid, FaStar } from "react-icons/fa"
import { getOfferWallName, getWallName, numberWithCommas, submitFingersWall } from "../../components/utils"
import { GiSandsOfTime } from "react-icons/gi"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { useEffect, useState } from "react"
import { socket } from "../../components/Containers/MainContainer"
import fingerprintjs from '@fingerprintjs/fingerprintjs';

export const BannedModalContent = (props) => {
    const [giftCardID, setGiftCardID] = useState(-1)
    const [giftCard, setGiftCard] = useState({})
    const [giftCardDB, setGiftCardDB] = useState({})

    const [banReason, setBanReason] = useState('')
    useEffect(() => {
        socket.emit('getBanReasonx1')
        socket.on('banReasonx1', (data) => {
            setBanReason(data.reason)
        })
    }, [])
    return (
        <ModalContent 
        click={props.click}
        hideTitle={true}
        hide={true}
        title={``} 
        className={`modal-content-login modal-content-banned`}
        content={
            <>
              <div style={{ fontSize: '16px', maxWidth: '400px', textAlign: 'center', padding: '10px 20px', color: '#fff', background: '#ed1d49', borderRadius: '10px' }}>
                    Your account has been banned due to violating the Terms & Conditions. You consider this to be wrong? You can always appeal this decision in the support tab.
                    <br />
                    {banReason != '' ? <><br />Reason: {banReason}</> : ''}
                </div>
          </>
        }/>
    )
}