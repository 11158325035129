
import { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { getChatStatus, isLoggedIn, playAlert, showLoginModal } from '../../components/utils';
import { FaCashRegister, FaCoins, FaFacebook, FaInstagram, FaStopwatch, FaTelegram, FaTwitter } from 'react-icons/fa';
import { set } from 'nprogress';
import { GiPartyPopper } from 'react-icons/gi';
import Skeleton from 'react-loading-skeleton';
import { socket } from '../../components/Containers/MainContainer';
function PTCFramelessAdsShow() {
  useEffect(function () {
    document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
    window.scrollTo(0, 0)
}, [])
  const [chatStatus, setChatStatus] = useState(getChatStatus())
  const [ads, setAds] = useState([])
  useEffect(() => {
    window.addEventListener('showChat', () => {
      setChatStatus(getChatStatus())
    })

      ;
  }, [])
  const { referral_id, page, second_page, third_page } = useParams();

  const [facebookLink, setFacebookLink] = useState("")
  const [instagramlink, setInstagramLink] = useState("")
  const [twiteerlink, setTwiteerLink] = useState("")
  const [telegramLink, setTelegramLink] = useState("")
  const [canClickAds, setCanClickAds] = useState(true);
  const [adWatching, setAdWatching] = useState(-1)
  const [adWatchingTimer, setAdWatchingTimer] = useState(`Starting...`)
  const [focus, setFocus] = useState(true)
  const [timerFaucet, setTimerFaucet] = useState(undefined);
  const [link, setLink] = useState(undefined)
  useEffect(() => {
    socket.emit('get_ptc_ads', { frameless: true })
    socket.on('ptc_ads', (data) => {
      setAds(data)
    })

  }, [])
  return (
    <div className={`main-container`}>

        <div className="top">

          <div className="cat-earn-mob" style={{margin:'0px'}} >
            <Link onClick={() => {
              if (isLoggedIn() == false) {
                showLoginModal()
              }
            }} state={{

            }} to='/ptc/create' className="cat-earn-mob-item"
              >
              Advertise with us
            </Link>
            <Link onClick={() => {
              if (isLoggedIn() == false) {
                showLoginModal()
              }
            }} state={{

            }} to='/ptc/frameless' className="cat-earn-mob-item sel"
              >
              Frameless Ads
            </Link>
            <Link onClick={() => {
              if (isLoggedIn() == false) {
                showLoginModal()
              }
            }} state={{

            }} to='/ptc' className="cat-earn-mob-item"
              >
              iFrame Ads
            </Link>
          </div>
        </div>
        <div className="ptc-ad-principal" >
          {ads[0] == undefined ? [0, 0, 0, 0].map(s => {
            return <div className="advertise-ad-fa ptc-ad"> <Skeleton height={200} width={300} /></div>
          }) : ads.map(ad => {
            return <div className={`advertise-ad-fa ptc-ad ${ad.is_promoted==true?'ptc-ad-promoted':''}`} >
              <div className="featured-ad">
              </div><div className="featured-ad2">
              </div>
              {ad.is_promoted == true ? <>
                <div className="featured-ad">
                  Promoted
                </div></> : ""}
              <div>
                <p className="ptc-ad-title" style={{ marginTop: ad.is_social == true == true ? '10px' : '0px' }}>{ad.title} </p>
                <p className="ptc-ad-description">{ad.description.length > 100 ? ad.description.substring(0, 100) + '...' : ad.description}</p>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div className="ptc-ad-details">
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <p className='ptc-ad-reward'><FaCoins /> {ad.CPC} </p>
                      <p className='ptc-ad-timer'><FaStopwatch style={{ marginTop: '-5px' }} /> {ad.timer}  s</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                {ad.id == adWatching ? <p style={{ display: 'block', color: '#d7ce63', fontSize: '10px', textAlign: 'center' }}>{adWatchingTimer}</p> : ''}

                {ad.viewed == true ? <p className='watch-ptc-tmrw'>Watch again tomorrow</p> : ''}
                <div onClick={() => {
                  if (canClickAds == true) {
                    setAdWatching(ad.id)
                    setCanClickAds(false)
                    socket.emit('get_ptc_data', { code: ad.code })
                    socket.on('ptc_data_view', (data) => {
                      socket.off('ptc_data_view')
                      setCanClickAds(false)
                      var myWindow;
                      myWindow = window.open(data.link, '_blank');
                      myWindow.focus();

                      let time = data.waitingTime;
                      if (time == 0) {
                        setCanClickAds(true)
                        setAdWatching(-1)
                      }
                      let title = document.title;
                      function j(time) {
                        if (time >= 0) {
                          setTimeout(function () {
                            document.title = `${time} seconds left to view the ad`
                            setAdWatchingTimer(`Wait ${time}s`)
                            time--;
                            j(time);
                          }, 1000)
                        } else {
                          document.title = `Task completed!`;
                          playAlert();
                          setTimeout(function () {
                            setCanClickAds(true)
                            setAdWatching(-1)
                            setAdWatchingTimer(`Starting...`)
                            socket.emit('get_ptc_ads', { frameless: true })
                            socket.emit('userAction', {
                              action: 'watched_ptc_ad',
                              code: data.code
                            })
                            document.title = title;
                          }, 1000)
                        }
                      }
                      setCanClickAds(false)

                      j(time)

                    })

                  }
                }} className="ptc-ad-btn" style={{ background: ad.viewed == true || ad.link == undefined || canClickAds == false ? '#0d131a' : '#178a94', textDecoration: 'none', color: '#f3f3f3', fontSize: '13px', width: '100%', textAlign: 'center' }}>{ad.viewed == true ? 'Visit Again' : 'Watch AD'}</div>
              </div>

              <div className="mobile-social-links-ad-fa" style={{ flexDirection: 'column', alignItems: 'start', minWidth: '0px', display: ad.is_social == true ? "flex" : 'inherit' }}>
                {ad.is_social == true ? <div>
                  {ad.facebook_link == "" ? "" : <a href={ad.facebook_link} target='_blank'><FaFacebook style={{ color: '#3b7ee7', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} /></a>}
                  {ad.instagram_link == "" ? "" : <a href={ad.instagram_link} target='_blank'><FaInstagram style={{ color: '#f36c6c', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} /></a>}
                  {ad.twitter_link == "" ? "" : <a href={ad.twitter_link} target='_blank'><FaTwitter style={{ color: '#55b1e7', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} /></a>}
                  {ad.telegram_link == "" ? "" : <a href={ad.telegram_link} target='_blank'><FaTelegram style={{ color: '#5f99f3', fontSize: '16px', margin: ' 0 2px ', cursor: 'pointer' }} /></a>}
                </div> : ""}

              </div>
            </div>
          })}
        </div>
      </div>);

}

export default PTCFramelessAdsShow;