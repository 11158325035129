import React from 'react';
import ProgressiveImage from 'react-progressive-image-loading';

const NotAvailableInYourCountry = () => {
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Use full viewport height
        textAlign: 'center',
        padding: '20px', // Add some padding
        boxSizing: 'border-box', // Include padding in the element's total width and height
        backgroundColor: '#f0f0f0', // Light grey background
    };

    const messageStyle = {
        fontSize: '20px', // Base font size
        color: '#fff', // Dark grey color for the text
        maxWidth: '600px', // Max width for better readability on larger screens
        lineHeight: '1.5', // Line height for better readability
        marginLeft: '-30px',
        marginTop: '20px',
    };

    return (
        <div style={{ position: 'relative', zIndex: '20', width: '100vw' }} bis_skin_checked="1">
            <div id="loader" className="valign-wrapper" bis_skin_checked="1">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100vw', marginLeft: '30px' }} bis_skin_checked="1">
                    <ProgressiveImage
                        preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        src="/assets/img/faucetify.png"
                        render={(src, style) => <img id="loader-logo" className="loaderLogo animate__animated animate__bounceIn" height={50} src={src} />}
                    />
                    <p style={{ ...messageStyle }}>
                        We're truly sorry, but PaidCash isn't available in your region just yet. Our team is working around the clock to bring our services to more areas across the globe. Please stay tuned and check back with us later. Your patience and support mean the world to us!
                    </p></div>
            </div>
        </div>
    );
};

export default NotAvailableInYourCountry;