
import { useState, useEffect } from 'react'
import { useParams, useLocation, Link } from "react-router-dom";
import { FaArrowLeft, FaAward, FaCashRegister, FaChartBar, FaChartLine, FaCoins, FaDollarSign, FaEdit, FaEye, FaFacebook, FaIdBadge, FaInstagram, FaLink, FaParagraph, FaPause, FaPlay, FaQuestion, FaQuestionCircle, FaRemoveFormat, FaStar, FaStopwatch, FaTelegram, FaTrash, FaTwitter } from "react-icons/fa";
import { MdVerified } from 'react-icons/md';
import { getChatStatus, isLoggedIn, numberWithCommas }  from '../../components/utils';
import { socket } from '../../components/Containers/MainContainer';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import '../../assets/style/PTC.css'

const durationPrice = [[7, 0.0006], [10, 0.0008], [15, 0.001], [20, 0.0019], [40, 0.0023], [60, 0.0027]]
const rerunAdsOptions = [[1, 0], [2, 0.03], [3, 0.15], [7, 0.25], [15, 0.25], [30, 0.35], [-1, 0.5]]
function AdDashDeposits() {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const [chatStatus, setChatStatus] = useState(getChatStatus())
    let [loggedIN, setLogged] = useState(isLoggedIn());
    useEffect(() => { setLogged(isLoggedIn()) }, [isLoggedIn()])
    const { referral_id, page, second_page, third_page } = useParams();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const { state } = useLocation();
    const statusChat = state == null ? undefined : state.chatStatus;
    const [showChat, setShowChat] = useState(statusChat == undefined ? false : statusChat)
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [promotedOffers, setPromotedOffers] = useState(new Array(0).fill('cappa'))

    useEffect(() => {
        window.addEventListener('showChat', () => {
            setChatStatus(getChatStatus())
        })
    }, [])
    let networkTimeout = null;

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });
    useEffect(() => {
        loadLazyData();
    }, [lazyState]);
    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
        listenEmittersEarnings()
        socket.emit('getPTCDeposits', {
            page: lazyState.page,
        });


    };


    const onSort = (event) => {
        setlazyState(event);
    };
    function listenEmittersEarnings() {

        socket.on('ptcDeposits', async (data) => {
            let ppOff = [...data.offers]

            setLoading(false);
            setTotalRecords(data.pageNumber)
            setPromotedOffers([...ppOff]);

        })
    }
    const onFilter = (event) => {
        event['first'] = 0;
        setlazyState(event);
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setSelectedCustomers(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            promotedOffers.getCustomers().then((data) => {
                setSelectAll(true);
                setSelectedCustomers(data.customers);
            });
        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onPage = (event) => {
        setlazyState(event);
    };
    return (<>
     <div className={`main-container`}>
            <div className='cat-earn-mob' style={{ display: 'flex', margin:'0px' }}>
                <Link className={`${second_page.toLowerCase() == "create" ? 'sel' : ''}`} to={`/advertise`} key={-1}>
                    <div className={`cat-earn-mob-item `}>
                        <div className='cat-earn-mob-item-name'>Create Ads</div>
                    </div>
                </Link>
                <Link className={`${second_page.toLowerCase() == "campaigns" ? 'sel' : ''}`} to={`/advertise/campaigns`} key={-1}>
                    <div className={`cat-earn-mob-item `} style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                        <div className='cat-earn-mob-item-name'>Campaigns</div>
                    </div>
                </Link>
                <Link className={`${second_page.toLowerCase() == "deposits" ? 'sel' : ''}`} to={`/advertise/deposits`} key={-1}>
                    <div className={`cat-earn-mob-item `} style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                        <div className='cat-earn-mob-item-name'>Deposits</div>
                    </div>
                </Link>
            </div>

            

            <p style={{ fontSize: '12px', color: '#93acd3',  marginBottom:'-15px' }}>Manage your created advertisements, charge them and check their statistics. <FaCashRegister style={{ fill: '#d65d31' }} /></p>

            <div >
                <DataTable
                    value={promotedOffers} lazy dataKey="id" paginator
                    first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading}
                    
                    selection={promotedOffers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
                    <Column field="provider" header="Provider" body={(product) => {
                        return <p style={{ marginLeft:'12px',color: 'var(--text-white-3)', marginBottom: '0px', marginBottom:'0px', fontSize:'12px', textAlign:'left' }}>{product.provider}</p>
                    }}></Column>

                    <Column field="usd" header="USD" body={(product) => {
                        return <p style={{ color: 'var(--text-gray-4)', marginBottom: '0px', marginBottom:'0px', fontSize:'12px', textAlign:'left' }}>{'$' + numberWithCommas(product.usd)}</p>
                    }}></Column>
                    <Column field="token" header="Tokens" body={(product) => {
                        return <p style={{ color: 'var(--text-gray-4)', marginBottom: '0px', marginBottom:'0px', fontSize:'12px', textAlign:'left' }}>{numberWithCommas(product.token) + ` ${product.token > 1 ? 'tokens' : 'token'}`} </p>
                    }}></Column>

                    <Column field="date" header="Date" body={(product) => {
                        return <p style={{ color: 'var(--text-gray-4)', marginBottom: '0px', marginBottom:'0px', fontSize:'12px', textAlign:'right' }}>{product.date}</p>
                    }}></Column>

                </DataTable>

            </div>


        </div>


    </>);
}

export default AdDashDeposits;