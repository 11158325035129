
import { useEffect } from 'react'
import '../assets/style/Terms.css'
import TopTOSCOntainer from './TopTOSCOntainer'

export const PrivacyEU = (props) => {
  useEffect(function () {
    document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
    window.scrollTo(0, 0)
}, [])
    const lastEdited = '2023-09-09 [YYYY-MM-DD]'
    let TermsOfServicePage = [
  
      {
        title: 'Introduction',
        id: 'introduction',
        content: `Welcome to our platform at paidcash.co and our mobile application, collectively referred to as the “Website.” PaidCash ("PaidCash," "we," "us," or "our") highly values your privacy and is dedicated to safeguarding your personal data. This privacy policy aims to provide you with detailed insights into how we handle your personal data when you access our Website, regardless of your location. It also elucidates your privacy rights and the legal protections in place for your benefit.<br/>
              <br/>
              This privacy policy is structured in a layered format, allowing you to navigate to specific sections for more detailed information. Additionally, you can refer to our Glossary to better comprehend the meanings of certain terms used throughout this privacy policy.`
      },
      {
        title: 'VITAL DETAILS AND OUR IDENTITY',
        id: 'vital-details-and-our-identity',
        content: `
              <strong>Purpose of this Privacy Policy</strong><br/>
              <br/>
              This privacy policy is designed to provide you with comprehensive information regarding how PaidCash collects and processes your personal data during your use of this Website. This encompasses any data you may furnish to us through this Website when you create an account, subscribe to our newsletter, access our content, utilize our services and support services, or receive marketing information.<br/>
              <br/>
              We do not direct our Website towards minors, and we do not knowingly collect personal data from individuals under the age of 16, or as defined by local legal requirements. If we discover that we have unintentionally collected or received personal data from a minor without the appropriate consent, we will promptly delete it. If you believe that we have mistakenly collected any information from or about a minor, please contact us at hello@paidcash.co<br/>
              <br/>
              It is crucial that you read this privacy policy in conjunction with any other privacy policy or fair processing policy we may provide on specific occasions when we collect or process your personal data. This privacy policy serves to complement other notices and privacy policies and is not intended to supersede them.<br/>
              <br/>   
              <strong>Controller</strong><br/>
              <br/>
              PaidCash is the entity responsible for and in control of your personal data as outlined in this privacy policy.<br/>
              <br/>
              We have designated a data protection officer (DPO) who is responsible for overseeing inquiries related to this privacy policy. If you have any questions concerning this privacy policy, including any requests to exercise your legal rights, please contact the DPO using the details provided below.<br/>
              <br/>
              <strong>Contact Details</strong><br/>
              <br/>
              If you have any inquiries regarding this privacy policy or our privacy practices, please reach out to our DPO through the following means:</br>
              <br/>
              Data Protection Officer<br/>
              hello+dpo@paidcash.co<br/>
              <br/>
              You retain the right to lodge a complaint at any time with a data protection supervisory authority if you believe that we have infringed upon your privacy rights. However, we would appreciate the opportunity to address your concerns before you proceed with filing a complaint.<br/>
              <br/>
              Changes to the Privacy Policy and Your Duty to Inform Us of Changes<br/>
              <br/>
              We reserve the right to modify and amend the content of this privacy policy. The updated privacy policy will become effective when made available to you. We routinely review and update our privacy policy. This version was last updated on Sep 9, 2023.<br/>
              <br/>
              Ensuring the accuracy and currency of the personal data we hold about you is crucial. Kindly inform us promptly if your personal data undergoes any changes during your association with us.
              
              <p>Your continued use of our Website following the posting of changes constitutes your acceptance of such changes.</p>`
      }, {
        title: 'HOW WE COLLECT YOUR PERSONAL DATA',
        id: 'how-we-collect-your-personal-data',
        content: `
              We employ various methods to collect data from and about you, including the following:<br/>
  <br/>
  1. Direct Interactions: You may provide us with Identity Data and Contact Data when you engage in direct interactions. This includes, but is not limited to, situations when you:<br/>
  <br/>
  <ul style="list-style:circle; margin-left:20px">
  <li>Apply to receive, participate in, or interact with our products or services on the Website.</li>
  <li>Create an account on our Website.</li>
  <li>Utilize our products and services.</li>
  <li>Subscribe to our newsletters and publications.</li>
  <li>Request marketing materials.</li>
  <li>Seek support services.</li>
  <li>Complete surveys.</li>
  <li>Participate in special offers.</li>
  <li>Provide feedback or get in touch with us.</li>
  </ul>
  <br/>
  2. Automated Technologies or Interactions: While you interact with our Website, we automatically collect Technical Data related to your devices, computers, equipment, browsing actions, and usage patterns. This data is gathered using technologies such as cookies, server logs, and similar mechanisms. To learn more about how we and our partners use cookies and similar technologies for advertising purposes, please refer to our Cookie Policy. Please note that you have the option to delete your account at any time. For further details, consult our cookie policy.<br/>
  <br/>
  3. Third Parties or Publicly Available Sources: We may also receive personal data about you from various third-party sources, including but not limited to platforms such as Google Analytics and Facebook.<br/>
  <br/>
  This comprehensive data collection process allows us to offer you a better user experience and tailor our services to your needs. Rest assured that we handle your data with care and in accordance with applicable privacy regulations. If you have any questions or concerns about your data privacy, please don't hesitate to contact us.`
      },
      {
        title: 'How We Utilize Your Personal Data',
        id: 'how-we-utilize-your-personal-data',
        content: `
              We will only use your personal data when legally permissible. The most common circumstances under which we use your personal data include:<br/>
  <br/>
  Performance of a Contract: We use your data when it is necessary to fulfill the contract we are about to enter into or have entered into with you.<br/>
  <br/>
  Legitimate Interests: We may use your data when it is necessary for our legitimate interests (or those of a third party) and when your interests and fundamental rights do not override those interests.<br/>
  <br/>
  
  Legal Obligations: We use your data when it is required to comply with legal obligations.<br/>
  <br/>
  
  Below, we outline the specific purposes for which we use your personal data, the type of data involved, and the lawful basis for processing, including any legitimate interests:<br/>
  <table class="border-policy">
    <tr>
      <th>Purpose/Activity</th>
      <th>Type of Personal Data</th>
      <th>Lawful Basis for Processing</th>
    </tr>
    <tr>
      <td><strong>To register you as a new customer or user</strong><br>
      To enable you to use our Website so you can participate in our rewards program.</td>
      <td>
        (a) <strong>Identity Data</strong><br>
        (b) <strong>Contact Data</strong><br>
        (c) Profile Data<br>
        (d) Technical Data<br>
        (e) Special Categories Data
      </td>
      <td>
        (a) Performance of a contract with you<br>
        (b) Necessary for our legitimate interests<br>
        (c) Comply with a legal obligation
      </td>
    </tr>
    <tr>
      <td><strong>To manage our relationship with you</strong><br>
      (including notifying you about changes to our terms or privacy policy and asking you to leave a review or take a survey.)</td>
      <td>
        (a) <strong>Identity Data</strong><br>
        (b) <strong>Contact Data</strong><br>
        (c) <strong>Profile Data</strong><br>
        (d) Marketing and Communications Data<br>
        (e) Special Categories Data
      </td>
      <td>
        (a) Performance of a contract with you<br>
        (b) Necessary to comply with a legal obligation<br>
        (c) Necessary for our legitimate interests
      </td>
    </tr>
    <tr>
      <td><strong>To improve and enhance our Website and services.</strong></td>
      <td>
        (a) <strong>Identity Data</strong><br>
        (b) <strong>Contact Data</strong><br>
        (c) <strong>Profile Data</strong><br>
        (d) <strong>Usage Data</strong><br>
        (e) <strong>Marketing and Communications Data</strong><br>
        (f) Technical Data
      </td>
      <td>
        (a) Performance of a contract with you<br>
        (b) Necessary for our legitimate interests
      </td>
    </tr>
    <tr>
      <td><strong>To administer and protect our business and this Website</strong><br>
      (including troubleshooting, data analysis, testing, system maintenance, support, reporting, and hosting of data)</td>
      <td>
        (a) <strong>Identity Data</strong><br>
        (b) <strong>Contact Data</strong><br>
        (c) Technical Data
      </td>
      <td>
        (a) Necessary for our legitimate interests<br>
        (b) Necessary to comply with a legal obligation
      </td>
    </tr>
    <tr>
      <td><strong>To deliver relevant Website content and advertisements to you</strong><br>
      (and measure or understand the effectiveness of the advertising we serve to you)</td>
      <td>
        (a) <strong>Identity Data</strong><br>
        (b) <strong>Contact Data</strong><br>
        (c) <strong>Profile Data</strong><br>
        (d) <strong>Usage Data</strong><br>
        (e) <strong>Marketing and Communications Data</strong><br>
        (f) Technical Data<br>
        (g) Special Categories Data
      </td>
      <td>Necessary for our legitimate interests</td>
    </tr>
    <tr>
      <td><strong>To use data analytics to improve our Website, products/services, marketing, customer relationships, and experiences</strong></td>
      <td>
        (a) <strong>Technical Data</strong><br>
        (b) <strong>Usage Data</strong><br>
        (c) Special Categories Data
      </td>
      <td>Necessary for our legitimate interests</td>
    </tr>
    <tr>
      <td><strong>To make suggestions and recommendations to you about goods or services that may be of interest to you</strong></td>
      <td>
        (a) <strong>Identity Data</strong><br>
        (b) <strong>Contact Data</strong><br>
        (c) <strong>Technical Data</strong><br>
        (d) <strong>Usage Data</strong><br>
        (e) <strong>Profile Data</strong><br>
        (f) <strong>Marketing and Communications Data</strong><br>
        (g) Special Categories Data
      </td>
      <td>Necessary for our legitimate interests</td>
    </tr>
  </table>
  <br/>
  We respect your choices regarding your personal data, especially with respect to marketing and advertising:<br/>
  <br/>
  <ul style="list-style:circle;margin-left:20px">
  <li>You will receive marketing communications from us if you have requested information from us or used our services and have not opted out of receiving that marketing.</li>
  <li>We will obtain your express opt-in consent before sharing your personal data with any third party for marketing purposes.</li>
  <li>You can opt out of receiving marketing messages from us or third parties at any time via your account on the Website or through Microsoft, Facebook, or Google's opt-out functions.</li>
  </ul>
  <br/>
  Please be aware that we may process your personal data without your knowledge or consent when required or permitted by law, in accordance with the above rules. If you require an explanation of how the processing for a new purpose is compatible with the original purpose, please contact us.<br/>
  `
      }, {
        title: 'DISCLOSURES OF YOUR PERSONAL DATAS',
        id: 'disclosures-of-your-personal-datas',
        content: `
              <strong>Third-Party Involvement</strong>
  
  <p>We collaborate with third-party service providers to enhance our service delivery. Below is a list of parties with whom we may share your personal data, acting as processors, for the purposes outlined in the section "How We Utilize Your Personal Data":</p><br/>
  <p><strong>Service providers acting as processors:</strong></p><br/>
  <ul style="margin-left:20px;list-style:circle">
    <li>Marketing and promotions to promote our Website and services</li>
    <li>Telecommunications technology to send you messages, including SMS messages</li>
    <li>Data hosting service providers</li>
    <li>Security service providers for investigating fraud and security incidents</li>
    <li>Analytic providers to understand how you use our Website and services</li>
    <li>Document repository service providers</li>
    <li>Customer support vendors</li>
    <li>Survey providers to better understand users’ needs for Website and service improvements</li>
    <li>Marketing analysis and statistics providers</li>
    <li>Professional advisers acting as processors, including lawyers, bankers, auditors, and insurers who provide consultancy, banking, legal, insurance, and accounting services</li>
  </ul>
  <br/>
  <p><strong>Linked Third-Party Websites:</strong></p><br/>
  <p>When you use third-party services or websites linked through our services, the providers of those services or products may receive information about you, which may be shared by us, you, or others. Please note that these third-party services are not governed by this privacy policy, and their own terms and privacy policies will apply to your use of those services and products.</p>
  <br/>
  <p><strong>Sale of Business:</strong></p><br/>
  <p>In certain circumstances, we may choose to sell, transfer, or merge parts of our business or assets. Alternatively, we may seek to acquire other businesses or merge with them. If such changes occur within our business, the new owners may use your personal data in the same manner as described in this privacy policy.</p><br/>
  <p>We hold all third parties to strict standards of respecting the security and confidentiality of your personal data, ensuring its proper treatment in accordance with applicable laws. We do not permit our third-party service providers to use your personal data for their own purposes, and we only allow them to process your personal data for specific purposes and in accordance with our instructions. Your data privacy and security remain a top priority for us.</p>
  `
      },
      {
        title: 'INTERNATIONAL TRANSFERS',
        id: 'international-transfers',
        content: `
  We operate globally, serving users across various countries. In the course of our operations, your data may be transferred and processed outside of your home country. To provide our services effectively, we utilize third-party service providers for data processing and storage, including locations within the United States, the European Union (EU), and other countries.<br/>
  <br/>
  When we transfer your personal data to our affiliates located outside the EU, we take measures to ensure your data's security and privacy. We employ standard contractual clauses approved by the European Commission. These clauses are designed to uphold a high standard of data protection and safeguard your privacy rights.<br/>
  <br/>
  If you would like more information about the specific contractual arrangements we have in place to protect your data during international transfers, please feel free to contact us at hello@paidcash.co. You can also refer to the standard contractual clauses approved by the European Commission at the following link: [Standard Contractual Clauses] <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en</a>.<br/>
  <br/>
  For inquiries regarding the mechanisms we use when transferring your personal data out of the UK, please don't hesitate to reach out to us.
  `
      },
      {
        title: 'DATA SECURITY',
        id: 'data-security',
        content: `
  Your data's security and privacy are of utmost importance to us. We've implemented robust security measures to mitigate the risk of accidental loss, unauthorized access, alteration, or disclosure of your personal data. <br/>
  <br/>
  To ensure your data's confidentiality and integrity, we restrict access to your personal information. Only employees, agents, contractors, and select third parties who have a legitimate business need will have access to your data. These individuals are bound by strict confidentiality obligations and will process your personal data solely as per our instructions.<br/>
  <br/>
  In the event of any suspected breach of personal data, we have established comprehensive procedures to address the situation. If required by applicable laws and regulations, we will promptly notify you and the relevant regulatory authorities about any data breaches.
  `
      },
      {
        title: 'DATA RETENTION',
        id: 'data-retention',
        content: `  
              <strong>Data Retention Duration</strong><br/>
  <br/>
  We will retain your personal data only for as long as reasonably necessary to fulfill the purposes for which we collected it. This includes meeting any legal, regulatory, tax, accounting, or reporting requirements. In specific cases, we may retain your personal data for an extended period if there is a complaint or a reasonable expectation of litigation related to our relationship with you.<br/>
  <br/>
  
  To determine the appropriate retention period, we consider several factors, such as the volume, nature, and sensitivity of the personal data, potential risks associated with unauthorized access or disclosure, the purposes for which we process your data, and whether alternative methods can achieve these purposes. We also take into account applicable legal, regulatory, tax, accounting, and other requirements.<br/>
  <br/>
  Under certain circumstances, you have the right to request the deletion of your data; please refer to the "Your Legal Rights" section below for more information.<br/>
  <br/>
  Additionally, we may anonymize your personal data (rendering it no longer associated with you) for research or statistical purposes. In such cases, this information may be used indefinitely without further notice.
  `
      },
      {
        title: 'YOUR LEGAL RIGHTS',
        id: 'your-legal-rights',
        content: `
              <p>Under data protection laws, you have certain rights regarding your personal data:</p>
  <br/>
  <ul style="list-style:circle;margin-left:20px">
    <li>Request access to your personal data.</li>
    <li>Request correction of your personal data.</li>
    <li>Request erasure of your personal data.</li>
    <li>Object to processing of your personal data.</li>
    <li>Request restriction of processing your personal data.</li>
    <li>Request transfer of your personal data.</li>
    <li>Right to withdraw consent.</li>
  </ul>
  <br/>
  <p>To exercise any of these data protection rights with us, please send your request, including your first and last name, either via email to <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>. When you assert your rights as the data controller, we will process your personal data in this context to respond to your request.</p>
  <br/>
  <p><strong>No Fee Required</strong></p>
  <br/>
  <p>You will not be charged a fee to access your personal data or to exercise any of your rights. However, if your request is evidently unfounded, repetitive, or excessive, we may charge a reasonable fee or decline your request in such cases.</p>
  <br/>
  <p><strong>Information Verification</strong></p>
  <br/>
  <p>To ensure that personal data is not disclosed to unauthorized individuals, we may need to request specific information from you to verify your identity and confirm your right to access your personal data or exercise your rights. We may also contact you for additional information to expedite our response.</p>
  <br/>
  <p><strong>Response Time</strong></p>
  <br/>
  <p>We aim to respond to all legitimate requests within one month. However, if your request is particularly complex or if multiple requests have been made, it may take longer to address. In such instances, we will notify you and keep you informed of our progress.</p>`
      },
      {
        title: 'GLOSSARY',
        id: 'glossary',
        content: `<p>We process your personal data based on the following lawful grounds:</p>
              <br/>
              <ol>
                <li>
                  <strong>Legitimate Interest:</strong> 
                  <br/>We have a legitimate interest in conducting and managing our business to provide you with the best service, product, and secure experience. Before processing your personal data for our legitimate interests, we carefully consider and balance the potential impact on you, both positive and negative, as well as your rights. We do not use your personal data for activities where our interests are outweighed by their impact on you, unless we have your consent or are required or permitted to do so by law. For further information about how we assess our legitimate interests concerning specific activities, please <a href="mailto:hello@paidcash.co">contact us</a>.
                </li><br/>
                <li>
                  <strong>Performance of a Contract:</strong><br/> We process your data when it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.
                </li><br/>
                <li>
                  <strong>Compliance with a Legal Obligation:</strong><br/> We process your personal data when it is necessary to comply with a legal obligation.
                </li>
              </ol>`
      }
  
    ]
    return (
        <div className='terms-page'>
            <TopTOSCOntainer edited={lastEdited} title={'Privacy Policy for European Residents'} second_page={props.second_page}/>
            {TermsOfServicePage.map((item, index) => {
                return (
                        <div className="terms-item"  id={item.id} key={index}>
                            <div className="terms-title" >{index + 1}{' -'} {item.title}</div>
                            <div className='terms-content' dangerouslySetInnerHTML={{ __html: item.content }} />
                            {index == 0 ?
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', fontSize: '12px' }}>
                                    {TermsOfServicePage.map((item, index) => {
                                        return <div className='tos-al' style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div >{index + 1}</div> <a href={`#${item.id}`} key={index}> {`) ${item.title}`}</a>
                                            </div>
                                    })}
                                </div> : ''}
                        </div>

                )
            })}
        </div>
    )
}