'use client'
import { socket } from '../Containers/MainContainer'
import { canUsePagination, getLevelColor, numberWithCommas } from '../utils'

import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import PlutoMenu from './PlutoMenu'
import PaginationPluto from './Pagination'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Link } from 'react-router-dom'
import VerifyAdmin from './PlutoVerify'

function PlutoUser(props) {

    const [userData, setUserData] = useState({})
    const [fingerprints, setUserFingerPrints] = useState([])
    const [userProfile, setUserProfile] = useState({})
    const [offersMade, setOffersMade] = useState([0, 0, 0, 0])
    const [pageNumber, setPageNumber] = useState(-1)
    const [pageN, setPageN] = useState(1)
    const [category, setCategory] = useState(0);
    const [rank, setRank] = useState(0)
    const [banRate, setBanRate] = useState(undefined)


    useEffect(() => {
        setUserProfile({})
        setUserFingerPrints([])
        setOffersMade([0, 0, 0, 0])
        setUserData({})
        socket.on('response_get_admin_user_fingerprints', (data) => {
            setUserFingerPrints(data.fingerprints)
        })
        socket.on('response_get_admin_user', (data) => {
            setUserData(data)
            setUserProfile(data.user)

            // socket.off('response_get_admin_user')
        })

        socket.on('response_get_admin_user_data', (data) => {
            setBanRate(data.banRate)
            setOffersMade(data.data)
            canUsePagination(true)
            setPageNumber(Math.ceil(parseInt(data.length) / 5))
            setRank(data.rank)
        })

        socket.emit('getUserAdmin', {
            user_id: props.second_page
        });

        socket.emit('getUserAdminData', {
            user: props.second_page,
            page: 1,
            cat: category
        });



    }, [])
    const [alert, setAlert] = useState({ show: false, user_id: -1, offer_id: -1, name: '' })

    const [banReason, setBanReason] = useState('Fraudulent Behaviour')
    function banUserWithGoodReason() {
        socket.emit('adminAction', {
            action: 'ban_user',
            user: userProfile.id,
            reason: banReason,
        })
    }
    function ChangeCategory(cat) {
        setCategory(cat)
        setOffersMade([0, 0, 0, 0, 0])
        setPageNumber(-1)
        socket.emit('getUserAdminData', {
            user: props.second_page,
            page: 1,
            cat: cat

        })
    }
    return (


        <div style={{ width: '100%', height: '100%' }}>
            <style>{`
.profile-head {
  transform: translateY(5rem)
}

.cover {
  background-image: url(https://images.unsplash.com/photo-1530305408560-82d13781b33a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auhref=format&fit=crop&w=1352&q=80);
  background-size: cover;
  background-repeat: no-repeat
}

body {
  background: #654ea3;
  background: linear-gradient(to right, var(--bg-color-0), var(--bg-color-0));
  min-height: 100vh;
  overflow-x:hidden;
}
`}
            </style>
            <div class=" mx-auto">
                <SweetAlert
                    show={alert.show}
                    title={alert.title}
                    onConfirm={function () {
                        socket.emit('adminAction', {
                            action: 'releaseOfferAdmin',
                            user_id: alert.user_id,
                            offer_id: alert.offer_id,
                        })
                        setAlert({ ...alert, show: false })
                        socket.emit('getUserAdminData', {
                            user: props.second_page,
                            page: pageN,
                            cat: category

                        })
                    }}
                    style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                    cancelbuttonText="Not now"
                    confirmbuttonText="Proceed"
                    showCancel={true}
                    cancelbuttonStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                    confirmbuttonStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                    onCancel={function () { setAlert({ show: false, user_id: '', offer_id: '', name: '' }) }}
                >
                    <>
                        <h3>Do you want to release this offer?</h3>
                        <p>Offer_name:{alert.name}</p>
                        <p>Offer_id:{alert.offer_id}</p>
                        <p>User_id:{alert.user_id}</p>
                    </>

                </SweetAlert>
                <div class="shadow rounded overflow-hidden">

                    <div class="px-2 pt-0 pb-4 cover">

                        <div class="media align-items-end profile-head">

                            {rank < 8 ? "" : <div class="profile d-flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img src={userProfile.profilePic} alt="..." width="100" class="rounded mb-2 img-thumbnail" />
                            </div>}

                            <div class="media-body mb-5 text-white" style={{ display: 'flex', color: '#fff', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'column', marginBottom: '90px', fontSize: '13px' }}> <h4 class="mt-0 mb-0" style={{ display: 'flex', alignItems: 'center' }}>{userProfile.username || ""}{userProfile.level == undefined ? "" : <p style={{ background: getLevelColor(userProfile.level), display: 'inline', padding: '2px 10px', borderRadius: '5px', fontSize: '12px', marginBottom: '0px', marginLeft: '10px' }}>{userProfile.level}</p>}</h4>

                              <a style={{textDecoration:'none'}} href={`/pluto/emails/${(userProfile!=undefined?userProfile.same_email!=undefined?userProfile.same_email.split('%').join("1"):'':'')}`} target="_blank"> <p class="small" > <i class=""></i>{userProfile.email || ""} {userProfile.emailVerified == undefined ? "" : userProfile.emailVerified == true ? "(verified)" : "(not verified)"}
                                    {userProfile.muted == undefined ? "" : userProfile.muted == true ? "(muted)" : ""}</p></a>
                                <p class="small" style={{ marginBottom: '0px' }}> <i class=""></i>Join Date: {userProfile.joinDate || ""}</p>
                                <p class="small" style={{ marginBottom: '0px' }}> <i class=""></i>Last online: {userProfile.lastOnline || ""} ago</p>
                                <p class="small" style={{ marginBottom: '0px' }}> <i class=""></i>Last checked: {userProfile.lastChecked || ""} ago</p>
                                <p class="small" style={{ marginBottom: '0px' }}> <i class=""></i>Phone Verify: {userProfile.phone_verify || ""}</p>


                                <p class="small mb-2"> <i class="fal fa-map-marker-alt  mb-4"></i> {userProfile.country || ''}</p>
                            </div>
                        </div>
                    </div>
                    <div class=" pt-4  text-center" style={{ background: 'var(--bg-color-3)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                        <p style={{ color: 'var(--text-primary)', marginBottom: '0px' }}>{userProfile.banned != undefined ? userProfile.banned == true ? <>Banned for: {userProfile.banReason}</> : userProfile.deleted == true ? 'Account deleted' : '' : ''}</p>
                        <p style={{ color: 'var(--text-primary)', marginBottom: '0px' }}>{userProfile.countries != undefined ? <>Countries used for offers: {userProfile.countries}</> : ''}</p>
                        <p style={{ color: 'var(--text-primary)', display: 'block', marginBottom: '0px' }}>{userProfile.profileVisibility != undefined ? userProfile.profileVisibility == true ? <>Profile: hidden</> : 'Profile: public' : ''}</p>

                    </div>
                    {rank < 8 ? '' : <div class=" pt-4  text-center" style={{ background: 'var(--bg-color-3)', display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'row' }}>
                        {userProfile.banned == undefined ? '' : userProfile.banned == true ? '' : <select onChange={(e) => {
                            var value = e.target.value;
                            setBanReason(value)
                        }} id='banreasonselectoption'>
                            <option value="Fraudulent Behaviour">Fraudulent Behaviour</option>
                            <option value="Temp Email Address">Temp Email Address</option>
                            <option value="Multiple Accounts (Same Username or Similar Email)">Multiple Accounts (Same Username or Similar Email)</option>
                            <option value="Fraudulent Offers">Fraudulent Offers</option>
                            <option value="Fraudulent Suvryes">Fraudulent Surveys</option>
                            <option value="High Fraud Rate">High Fraud Rate</option>
                            <option value="High Chargeback Rate">High Chargeback Rate</option>
                            <option value="Multiple Accounts">Multiple Accounts</option>
                            <option value="VPN/Proxy Usage">VPN/Proxy Usage</option>
                            <option value="Speedy Offers">Speedy Offers</option>
                            <option value="Country Phone Number Different Than Country Offers Completed">Country Phone Number Different Than Country Offers Completed</option>
                            <option value="Country Account Location Different Than Country Offers Completed">Country Account Location Different Than Country Offers Completed</option>
                            <option value="Too many countries used for offers completion">Too many countries used for offers completion</option>
                        </select>}
                        <button style={{ width: 'auto', display: 'inline-block', background: '#8f4646' }} class="button button-disabled" onClick={() => {

                            banUserWithGoodReason()

                        }}>{userProfile.banned == undefined ? '' : userProfile.banned == true ? 'Unban' : 'Ban'}</button>

                        <button style={{ width: 'auto', display: 'inline-block', background: '#322fae' }} class="button button-disabled" onClick={() => {


                            socket.emit('adminAction', {
                                action: 'mute_user',
                                user: userProfile.id,
                            })

                        }}>{userProfile.muted == undefined ? '' : userProfile.muted == true ? 'Unmute' : 'Mute'}</button>
                        <button style={{ width: 'auto', display: 'inline-block', background: '#747b36' }} class="button button-disabled" onClick={() => {


                            socket.emit('adminAction', {
                                action: 'mute_user_support',
                                user: userProfile.id,
                            })

                        }}>{userProfile.locked == undefined ? '' : userProfile.locked == '1' ? 'Unmute Support' : 'Mute Support'}</button>
                        <button style={{ width: 'auto', display: 'inline-block', background: '#34593b' }} class="button button-disabled" onClick={() => {


                            socket.emit('adminAction', {
                                action: 'check_user',
                                user: userProfile.id,
                            })

                        }}>Check</button>
                        <button style={{ width: 'auto', display: 'inline-block', background: '#747b36' }} class="button button-disabled" onClick={() => {


                        }}>{userProfile.holdWithdraw == undefined ? '' : userProfile.holdWithdraw == true ? 'Unhold Withdrawals' : 'Hold Withdrawals'}</button>


                    </div>}
                    <div class=" pt-4 d-flex justify-content-center text-center" style={{ background: 'var(--bg-color-3)', color: '#fff', margin: '0 auto', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="user-details-stats" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            {userData.susRate != undefined ?
                                <div className="card">
                                    <p>
                                        {numberWithCommas(userData.susRate)}%
                                    </p>
                                    <p>Sus Offer Rate</p>
                                </div>
                                : <Skeleton style={{ width: '136px', height: '50px' }} />}
                            {banRate != undefined ?
                                <div className="card">
                                    <p>
                                        {banRate}%
                                    </p>
                                    <p>Ban Senzor</p>
                                </div>
                                : <Skeleton style={{ width: '136px', height: '50px' }} />}
                            {userProfile.usedVPNForOffers != undefined ?
                                <div className="card" >
                                    <p style={{ color: userProfile.usedVPNForOffers == "Yes" ? 'red' : '' }}>
                                        {numberWithCommas(userProfile.usedVPNForOffers)}
                                    </p>
                                    <p>Used VPN/PROXY</p>
                                </div>
                                : <Skeleton style={{ width: '136px', height: '50px' }} />}
                            {userData.cbRate != undefined ?
                                <div className="card">
                                    <p>
                                        {numberWithCommas(userData.cbRate)}%
                                    </p>
                                    <p>Chargeback Rate</p>
                                </div>
                                : <Skeleton style={{ width: '136px', height: '50px' }} />}
                        </div>

                    </div>
                    <div class=" pb-4 d-flex justify-content-center text-center" style={{ background: 'var(--bg-color-3)', color: '#fff', margin: '0 auto', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="user-details-stats" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            {userProfile.invitedUsers != undefined ?
                                <div className="card">
                                    <p>
                                        {numberWithCommas(userProfile.invitedUsers)}
                                    </p>
                                    <p>Users Invited</p>
                                </div>
                                : <Skeleton style={{ width: '136px', height: '50px' }} />}
                            {userProfile.balance != undefined ?
                                <div className="card">
                                    <p>
                                        ${numberWithCommas(userProfile.balance)}
                                    </p>
                                    <p>Balance</p>
                                </div>
                                : <Skeleton style={{ width: '136px', height: '50px' }} />}
                            {userProfile.withdrawAmount != undefined ?
                                <div className="card">
                                    <p>
                                        ${numberWithCommas(userProfile.withdrawAmount)}
                                    </p>
                                    <p>Withdrawn</p>
                                </div>
                                : <Skeleton style={{ width: '136px', height: '50px' }} />}
                            {userProfile.totalEarned != undefined ?
                                <div className="card">
                                    <p>
                                        ${numberWithCommas(userProfile.totalEarned)}
                                    </p>
                                    <p>Total Earned</p>
                                </div>
                                : <Skeleton style={{ width: '136px', height: '50px' }} />}
                        </div>

                    </div>


                    <div style={{ overflow: 'auto', background: 'var(--bg-color-3)', color: 'var(--text-primary)', padding: '10px' }}>

                        <div style={{ margin: '5px 0', display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'row', flexWrap: 'wrap', fontSize: '12px' }}>
                            <div className={`button ${category == 0 ? 'button-brand' : 'button-disabled'} waves-effect`} style={{ width: 'auto', display: 'inline-block' }} onClick={() => {
                                ChangeCategory(0)
                            }}>Offers</div>
                            <div className={`button ${category == 1 ? 'button-brand' : 'button-disabled'} waves-effect`} style={{ width: 'auto', display: 'inline-block' }} onClick={() => {
                                ChangeCategory(1)
                            }}>System Earnings</div>
                            {rank < 8 ? '' : <div className={`button ${category == 2 ? 'button-brand' : 'button-disabled'} waves-effect`} style={{ width: 'auto', display: 'inline-block' }} onClick={() => {
                                ChangeCategory(2)
                            }}>Withdrawals</div>}
                            <div className={`button ${category == 3 ? 'button-brand' : 'button-disabled'} waves-effect`} style={{ width: 'auto', display: 'inline-block' }} onClick={() => {
                                ChangeCategory(3)
                            }}>Chragebacks</div>
                        </div>

                        <p style={{ color: 'var(--text-primary)', fontWeight: 'bold', }}>{category == 0 ? "Offers" : category == 1 ? "System Earnings" : category == 2 ? "Withdrawals" : "Chragebacks"}</p>
                        <div style={{ fontWeight: 'bold' }}>
                            <div className="user-details-table-elements" >

                                <div style={{ minWidth: '200px', maxWidth: '200px' }}>Name</div>
                                {category == 0 || category == 3 ? <div style={{ minWidth: '100px', maxWidth: '100px' }}>Cb Rate</div> : ''}
                                {category == 0 || category == 3 ? <div style={{ minWidth: '100px', maxWidth: '100px' }}>Ban Rate</div> : ''}
                                {category == 0 || category == 3 ? <div style={{ minWidth: '100px', maxWidth: '100px' }}>Req Ban Rate</div> : ''}
                                {category == 0 || category == 3 ? <div style={{ minWidth: '100px', maxWidth: '100px' }}>Should Ban</div> : ''}
                                <div style={{ minWidth: '100px' }}>Amount</div>
                                <div style={{ minWidth: '100px', textAlign: 'right' }}>Date</div>

                                <div style={{ minWidth: '100px' }}>Wall</div>
                                <div style={{ minWidth: '100px', textAlign: 'right' }}>{category == 2 ? "Paid" : "Country"}</div>
                                <div style={{ minWidth: '100px', textAlign: 'right' }}>{category == 2 ? "Tx" : "Status"}</div>

                                {category == 0 ? <div style={{ minWidth: '100px', textAlign: 'right' }}>verified</div> : ''}
                                {category == 2 ?
                                    <>
                                        <div style={{ minWidth: '100px' }}>paid time</div>
                                        <div style={{ minWidth: '100px' }}>in review</div>
                                    </>
                                    : ''}


                            </div>
                        </div>

                        {offersMade.map((earning, i) => {
                            return (
                                <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>
                                    {category == 2 ?
                                        <Link to={`/pluto/withdrawals/${earning.id}`} style={{ minWidth: '200px', fontSize: '12px' }} > {earning.name || <Skeleton style={{ width: '30px' }} />}</Link> :
                                        <div style={{ minWidth: '200px' }} > {earning.name || <Skeleton style={{ width: '30px' }} />}</div>}
                                    {category == 0 || category == 3 ? <div style={{
                                        minWidth: '100px',
                                        background: parseInt(earning.cb) <= 10 && parseInt(earning.cb) >= 1 ? '#434317' :
                                            parseInt(earning.cb) <= 20 && parseInt(earning.cb) > 10 ? '#5c5c1f' :
                                                parseInt(earning.cb) <= 40 && parseInt(earning.cb) > 20 ? '#4c1b1a' :
                                                    parseInt(earning.cb) >= 50 ? '#702322' : ''
                                    }} > {earning.cbRate == undefined ? <Skeleton style={{ width: '30px' }} /> : earning.cbRate + "%"}</div> : ''}
                                  {category == 0 || category == 3 ? <div style={{
                                        minWidth: '100px', }} > {earning.banRate == undefined ? <Skeleton style={{ width: '30px' }} /> : earning.banRate}</div> : ''}
   {category == 0 || category == 3 ? <div style={{
                                        minWidth: '100px', }} > {earning.requiredBanRate == undefined ? <Skeleton style={{ width: '30px' }} /> : earning.requiredBanRate}</div> : ''}   {category == 0 || category == 3 ? <div style={{
                                            minWidth: '100px',background:  earning.shouldBanX==true?'#4c1b1a':'transparent'}} > {earning.shouldBan == undefined ? <Skeleton style={{ width: '30px' }} /> : earning.shouldBan}</div> : ''}
                                    <div style={{ minWidth: '100px' }} > {earning.amount || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px', textAlign: 'right' }} >{earning.date || <Skeleton style={{ width: '30px' }} />}</div>

                                    <div style={{ minWidth: '100px' }} >{earning.wall_name || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px', textAlign: 'right' }} >
                                        {category == 2 ? earning.paid || <Skeleton style={{ width: '30px' }} /> : ""}
                                        {category != 2 ? earning.ip || <Skeleton style={{ width: '30px' }} /> : ""}</div>
                                    <div style={{ minWidth: '100px', textAlign: 'right', color: category == 2 ? '' : earning.status == "Pending" ? '#aeae2d' : earning.status == "Credited" ? '#249724' : '#a92121' }} >
                                        {category == 2 ?
                                            earning.wall_name == "Direct Wallet" ? earning.paid == "No" ? 'None' :
                                                <a href={`https://blockchair.com/${earning.name.toLowerCase().split(" ").join("-")}/transaction/${earning.status}`}
                                                    target="_blank">TX Link</a> : earning.status != undefined ? earning.status.substr(0, 4) + "..." : '' || <Skeleton style={{ width: '30px' }} /> : ''}
                                        {category != 2 ? earning.status == undefined ? <Skeleton style={{ width: '30px' }} /> :
                                            <div>{earning.status == "Pending" ? <>
                                                Pending ( <p style={{ cursor: 'pointer', display: 'inline-block', color: 'rgb(13, 110, 253)' }} onClick={() => {
                                                    setAlert({ show: true, user_id: userProfile.id, offer_id: earning.id, name: earning.name })
                                                }}>Release</p>) ({earning.release_time})
                                            </> : earning.status}</div> : ''}

                                    </div>
                                    {category == 0 ? <div style={{
                                        minWidth: '100px', textAlign: 'right',
                                        color: earning.verified == undefined ? '' : earning.verified == true ? '#249724' : '#aeae2d'
                                    }}  >{earning.verified == undefined ? <Skeleton style={{ width: '30px' }} /> :
                                        earning.verified == true ? `Yes ` : 'No '}{earning.verified == undefined ? <Skeleton style={{ width: '30px' }} /> :
                                            earning.verified == true ?
                                                <a onClick={() => {
                                                    socket.emit('adminAction', {
                                                        action: 'remove_trustedoffer',
                                                        user: userProfile.id,
                                                        name: earning.name,
                                                        wall: earning.wall_name,
                                                    })
                                                    socket.emit('getUserAdminData', {
                                                        user: props.second_page,
                                                        page: pageN,
                                                        cat: category

                                                    })

                                                }} style={{ color: '#0d6efd', cursor: 'pointer' }}>Remove</a> :
                                                <a onClick={() => {
                                                    socket.emit('adminAction', {
                                                        action: 'add_trustedoffer',
                                                        user: userProfile.id,
                                                        name: earning.name,
                                                        wall: earning.wall_name,
                                                    })
                                                    socket.emit('getUserAdminData', {
                                                        user: props.second_page,
                                                        page: pageN,
                                                        cat: category

                                                    })
                                                }} style={{ color: '#0d6efd', cursor: 'pointer' }}>Add</a>}</div> : ''}
                                    ({earning.fcd})
                                    {category == 2 ?
                                        <>
                                            <div style={{ minWidth: '100px' }}>{earning.paid_date}</div>
                                            <div style={{ minWidth: '100px' }}>{earning.review}</div>
                                        </>
                                        : ''}
                                </div>

                            )
                        })}
                        {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                            canUsePagination(false)
                            setOffersMade([0, 0, 0, 0, 0])
                            setPageN(page)
                            socket.emit('getUserAdminData', {
                                user: props.second_page,
                                page: page,
                                cat: category

                            })

                        }} />}
                    </div>
                    {rank < 8 ? '' : <div style={{ overflow: 'auto', background: 'var(--bg-color-3)', color: 'var(--text-primary)', padding: '10px' }}>
                        <p style={{ color: 'var(--text-primary)', fontWeight: 'bold', }}>Fingerprints</p>
                        <div style={{ fontWeight: 'bold' }}>
                            <div className="user-details-table-elements" >

                                <div style={{ minWidth: '100px' }}>ID</div>
                                <div style={{ minWidth: '250px' }}>Fingerprint</div>
                                <div style={{ minWidth: '100px' }}>IP</div>
                                <div style={{ minWidth: '100px' }}>Count</div>
                                <div style={{ minWidth: '100px', textAlign: 'right' }}>Check</div>




                            </div>
                        </div>

                        {fingerprints.map((earning, i) => {
                            return (
                                <div key={earning == undefined ? '' : earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>


                                    <div style={{ minWidth: '100px' }} > {earning.id == undefined ? '' : earning.id + '' || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '250px' }} >{earning.id == undefined ? '' : earning.fingerprint + '' || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px' }} >{earning.id == undefined ? '' : earning.ip || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px' }} >{earning.id == undefined ? '' : earning.count || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px', textAlign: 'right', cursor: 'pointer' }} ><Link to={`/pluto/fingerprint/` + earning.fingerprint}>Check details</Link></div>


                                </div>
                            )
                        })}
                        <p style={{ color: 'var(--text-primary)', fontWeight: 'bold', }}>IP Used For Offers</p>
                        <div style={{ fontWeight: 'bold' }}>
                            <div className="user-details-table-elements" >

                                <div style={{ minWidth: '200px', maxWidth: '200px' }}>IP</div>
                                <div style={{ minWidth: '100px' }}>isVPN</div>
                                <div style={{ minWidth: '100px' }}>Statis Score</div>
                                <div style={{ minWidth: '100px' }}>Use Type</div>
                                <div style={{ minWidth: '100px' }}>User Count</div>
                                <div style={{ minWidth: '100px', textAlign: 'right' }}>Date</div>




                            </div>
                        </div>

                        {userProfile.ipUsedData == undefined ? '' : userProfile.ipUsedData.map((earning, i) => {
                            return (
                                <div key={earning == undefined ? '' : earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>
                                    <div style={{ minWidth: '200px' }} > {earning == undefined ? <Skeleton style={{ width: '30px' }} /> : earning.ip}</div>


                                    <div style={{ minWidth: '100px', color: earning == undefined ? '' : earning.ipData.isVPN == true ? 'red' : '' }} > {earning == undefined ? '' : earning.ipData.isVPN + '' || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px' }} >{earning == undefined ? '' : earning.ipData.score + '' || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px' }} >{earning == undefined ? '' : earning.ipData.userType || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px' }} >{earning == undefined ? '' : earning.user.length}</div>
                                    <div style={{ minWidth: '100px', textAlign: 'right', cursor: 'pointer' }} ><Link to={`/pluto/ip/` + earning.ip}>Check details</Link></div>


                                </div>
                            )
                        })}
                    </div>}
                    <div style={{ overflow: 'auto', background: 'var(--bg-color-3)', color: 'var(--text-primary)', padding: '10px' }}>
                    </div>
                </div></div>

        </div>

    )
}

export default PlutoUser