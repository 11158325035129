import ProgressiveImage from "react-progressive-image-loading";
import { numberWithCommas } from "../../components/utils";


export const BalanceHeader = (props) => {
  return (
    <div className="header-balance">
      <ProgressiveImage
            preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            src="/assets/img/faucetify_coin_small.png"
            render={(src, style) => <img alt="offer image" className="header-balance-icon" style={style}
                loading="lazy" src={src} />}
      />
      <div className="header-balance-text">{numberWithCommas(props.balance)}</div>
    </div>
  );
}