import { useState } from 'react';
import { FaTwitter, FaGoogle, FaCoins, FaTimesCircle } from "react-icons/fa";
import { ChangeStateUrlPath } from '../../components/utils';
import { IoClose } from 'react-icons/io5';
import { ModalContent } from '../ModalContent/ModalContent';

function PTCTestLink(props) {
  const [series, useSeries] = useState([{
    name: 'series-1',

    data: [0, 40, 0, 50, 49, 60, 125]
  }])


  return (
    <ModalContent
      title='PTC Test Link'
      click={props.click}
      width='100%'
      height='100%'
      style={{ marginTop: '25px' }}
      content={
        <div className="profile-details">
          <iframe id="ifrmame-site" seamless="seamless" className="ptc-view-iframe" src={props.link} ></iframe>
      </div>
      }
    />
  
  );
}

export default PTCTestLink;
