import ProgressiveImage from "react-progressive-image-loading"
import { ModalContent } from "./ModalContent"
import { FaAndroid, FaStar } from "react-icons/fa"
import { CreateToast, EUC, getLocalStorage, getOfferWallName, getWallName, hideLoginModal, hideRegisterModal, numberWithCommas, showLoginModal, showRegisterModal, submitFingersWall, validateEmail, validatePassword, validateUsername } from "../../components/utils"
import { GiSandsOfTime } from "react-icons/gi"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { useEffect, useRef, useState } from "react"
import { socket } from "../../components/Containers/MainContainer"
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import { MdOutlineEmail } from "react-icons/md"
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri"
import { IoMdLock } from "react-icons/io"
import { Link } from "react-router-dom"
import { FcGoogle } from "react-icons/fc"
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "@react-oauth/google";

export const SignupModalContent = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRe, setPasswordRe] = useState('');
    const [checked, setChecked] = useState(false);
    const captchaRef = useRef(null)
    const creaRef = useRef(null);
    const LoginGoogle = useGoogleLogin({
        onSuccess: codeResponse => {
            socket.emit('signupUser', {
                type: 'google', action: 'signup', ...codeResponse,
                referee: getLocalStorage("referee") == undefined ? "-1" : JSON.parse(getLocalStorage("referee")).referee,
            })
        },
    });
    const [ipNot, setIpNot] = useState(false);
    const [canPress, setCanPress] = useState(true);
    useEffect(() => {
        socket.on('resetcapthca_signup', () => {
            captchaRef.current.reset();
        })
        socket.on('canpress_signup', () => {
            setCanPress(true)
        })
        socket.on('ipNotSignup', () => {
            setIpNot(true)
        })
    }, [])
    return (
        <ModalContent 
        click={props.click}
        title={``} 
        hideTitle={true} 
        hide={true}

        className={`modal-content-wall modal-content-register`}
        content={
            <>
            <div className="modal-content-login-data">
                        {/* signup with email and password */}
                       
                            <div className="sc-right-box-2">
                                <p className="sc-right-box-2-el">Sign Up</p>
                                <p className="sc-right-box-2-el" style={{fontSize:'10px', color:'var(--text-gray-5)'}}>Create an account and enjoy the earnings.                                </p>
                                <div class="line" ></div>

                            </div>
                            
                            <div className='input-container'>
                                <div className="input-icon"><MdOutlineEmail/></div>
                                <input onChange={(e) => {
                                setEmail(e.currentTarget.value)
                            }} placeholder='Email address' id="email" type="email" name="email"  />
                            </div>
                          
                            <div className='input-container'>
                                <div className="input-icon"><IoMdLock/></div>
                                <input onChange={(e) => {
                                setPassword(e.currentTarget.value)
                            }} placeholder='Password' id="email" type={`${showPassword==true?"text":"password"}`} name="password"  />
                                <div className="input-icon">{showPassword==true?<RiEyeFill onClick={()=>setShowPassword(false)}/>:<RiEyeCloseFill onClick={()=>setShowPassword(true)}/>}</div>
                            </div>
                           
                           
                            <div class="checkbox-wrapper-29">
                            <label class="checkbox">
                                <input onChange={(e) => {
                                        setChecked(e.currentTarget.checked)
                                    }} type="checkbox" class="checkbox__input"  id={'checked-tos-pp'}/>  
                                <span class="checkbox__label"></span>
                                <div className="checkbox-label">I agree to the <Link to={'/policies/terms'} onClick={()=>{hideRegisterModal()}}>Terms of Service</Link> and <Link onClick={()=>{hideRegisterModal()}} to={`/policies/privacy${(EUC.includes((getLocalStorage('country') + '').toUpperCase()) ? '-eu' : '')}`}>Privacy Policy</Link></div>
                            </label>
                            </div>
                            <div style={{display:checked==true?'inherit':'none'}}><ReCAPTCHA
                            sitekey="6LeE9VsjAAAAAJ4T3_cd2KhZlrpm09CqY2x3rVZc"
                            ref={captchaRef} /></div>
                        <div className="signup-main-button" onClick={(e) => { 
                             e.preventDefault();
                             if (canPress == false) {
                             } else if (ipNot == true) {
                                 CreateToast("Your IP is restricted.", creaRef, 1)
                             } else {
                                 if (checked == false) {
                                     CreateToast('You must agree to our TOS and Privacy Policy in order to continue.', creaRef, 1)
                                 } else if (email.split("").length < 3) {
                                     CreateToast("Enter an email address.", creaRef, 1)
                                 } else if (validateEmail(email) == false) {
                                     CreateToast("Email address not valid.", creaRef, 1)
                                 } else if (password.split("").length < 6) {
                                     CreateToast("Password must be at least 6 characters long.", creaRef, 1)
                                 } else if (validatePassword(password)[0] == false) {
                                     CreateToast(validatePassword(password)[1], creaRef, 1)
                                 } else {
                                     if (captchaRef.current.getValue().length > 5) {
                                         setCanPress(false)
                                         socket.emit('signupUser', {
                                             type: 'local',
                                             email,
                                             username: email.split("@")[0],
                                             password,
                                             passwordRe:password,
                                             tokenType: 'v2',
                                             token: captchaRef.current.getValue(),
                                             checked: checked,
                                             referee: getLocalStorage("referee") == undefined ? "-1" : JSON.parse(getLocalStorage("referee")).referee
                                         })
                                     } else {
                                         CreateToast('Captcha is invalid', creaRef, 1)
                                     }
                                 }
 
                             }
                         }}>Create An Account</div>
                        <div class="social-message" >
                            <div class="line" ></div>
                            <p class="message">or</p>
                            <div class="line" ></div>
                            </div>
                        <div className="signup-main-button google-button" onClick={() => {  
                            if (typeof window.PaidCashChannel !== 'undefined' && window.PaidCashChannel.postMessage) {
                                window.PaidCashChannel.postMessage('singup_google');
                            }else{
                                LoginGoogle()
                            }  
                             }}><FcGoogle style={{fontSize:'15px'}}/> Sign Up With Google</div>
                        <div className="sc-right-box-3-el">Already have an account? <div style={{color:'var(--text-white)', display:'inline', cursor:'pointer'}} onClick={()=>{
                        hideRegisterModal();
                        showLoginModal();
                        }}>Log In And Earn</div></div>
                        <p className="sc-right-box-3-el">
General Prohibited Uses Using multiple accounts Completing offers on another user's account Using any type of VPN, VPS, Emulator software or the usage of Temp Mails.</p>
        </div>
            </>
        }/>
    )
}