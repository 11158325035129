import ProgressiveImage from "react-progressive-image-loading"
import { ModalContent } from "./ModalContent"
import { FaAndroid, FaStar } from "react-icons/fa"
import { CreateToast, getOfferWallName, getWallName, hideLoginModal, hideResetPWModal, numberWithCommas, showLoginModal, showRegisterModal, submitFingersWall, validateEmail } from "../../components/utils"
import { GiSandsOfTime } from "react-icons/gi"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { useEffect, useRef, useState } from "react"
import { socket } from "../../components/Containers/MainContainer"
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import { MdOutlineEmail } from "react-icons/md"
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri"
import { IoMdLock } from "react-icons/io"
import { Link } from "react-router-dom"
import { FcGoogle } from "react-icons/fc"

export const ForgotPasswordModalContent = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const creaRef = useRef(null);
    return (
        <ModalContent 
        click={props.click}
        hideTitle={true} 
        hide={true}

        className={`modal-content-wall modal-content-forgot-password`}
        content={
            <>
            <div className="modal-content-login-data">
                        {/* signup with email and password */}
                       
                            <div className="sc-right-box-2">
                                <p className="sc-right-box-2-el">Forgot Password?</p>
                                <p className="sc-right-box-2-el" style={{fontSize:'10px', color:'var(--text-gray-5)'}}>Let's get it back together.</p>
                                <div class="line" ></div>

                            </div>
                            
                            <div className='input-container'>
                                <div className="input-icon"><MdOutlineEmail/></div>
                                <input onChange={(e) => {
                                setEmail(e.currentTarget.value)
                            }} t placeholder='Email address' id="email" type="email" name="email"  />
                            </div>
                          
                           
                           
                        <div className="signup-main-button" onClick={() => {
                            if (email.split("").length < 3) {
                                CreateToast("Enter an email address.", creaRef, 1)
                            } else if (validateEmail(email) == false) {
                                CreateToast("Email address not valid.", creaRef, 1)
                            } else {
                                socket.emit('userAction', {
                                    action: 'reset_password_send',
                                    email: email
                                })
                            }
                        }}>Reset Password</div>
                        
                        <div className="sc-right-box-3-el">Remembering the password? <div style={{color:'var(--text-white)', display:'inline', cursor:'pointer'}} onClick={()=>{
                           
                            showLoginModal()
                           hideResetPWModal()
                        }}>Log In And Earn</div></div>
                        
        </div>
            </>
        }/>
    )
}