import MainContainer from './components/Containers/MainContainer';
import OfferWallsContainer from './components/Containers/OfferWallsContainer';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'vanilla-cookieconsent/dist/cookieconsent.css';
// import './App.css';
import './assets/style/Style.css';
import { HeaderContainer } from './Containers/Header.Container';
import { EarningFeedContainer } from './Containers/EarningFeed.Container';
import { SideMenuContainer } from './Containers/SideMenu.Container';
import { BottomMenuContainer } from './Containers/BottomMenu.Container';
import { PromotedSmallOfferContainer } from './Containers/PromotedSmallOffer.Container';
import { PromotedSurveysContainer } from './Containers/PromotedSurveys.Container';
import { WallsContainer } from './Containers/Walls.Container';
import { AnnouncementsContainer } from './Containers/Announcements.Container';
import { ModalContent } from './Containers/ModalContent/ModalContent';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
function App() {
const { referral_id, page, second_page, third_page } = useParams();

  return (
    <div className="App">
     
      <MainContainer />
      <OfferWallsContainer />
    </div>
  );
}

export default App;
