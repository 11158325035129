import ProgressiveImage from "react-progressive-image-loading"
import { ModalContent } from "./ModalContent"
import { FaAndroid, FaApple, FaArrowLeft, FaArrowRight, FaDeskpro, FaDesktop, FaStar } from "react-icons/fa"
import { ChangeStateUrlPath, getOfferWallName, getWallName, numberWithCommas } from "../../components/utils"
import { GiSandsOfTime } from "react-icons/gi"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { useEffect, useState } from "react"
import { socket } from "../../components/Containers/MainContainer"
import Skeleton from "react-loading-skeleton"
import QRCode from "react-qr-code"


export const PromotedOfferModalContent = (props) => {
    const [offerDetails, setofferDetails] = useState({})
    const [showQR, setShowQR] = useState(false);

    useEffect(() => {
    socket.on('offerDetails', (data) => {
        let steps = data.offer.steps;
        steps = steps.filter((x) => x != '')
        steps = steps.filter((x) => x != undefined)
        let osIn = [];
        if (data.offer.platforms == undefined ? '' : data.offer.platforms.includes("ios") || data.offer.platforms.includes("all") || data.offer.platforms.includes("apple")
            || data.offer.platforms.includes("iphone") || data.offer.platforms.includes("ipad") || data.offer.platforms.includes("*")) {
            osIn.push('ios')
        }
        if (data.offer.platforms.includes("android") || data.offer.platforms.includes("all") || data.offer.platforms.includes("*")) {
            osIn.push('android')
        }
        if (data.offer.platforms.includes("desktop") || data.offer.platforms.includes("all") || data.offer.platforms.includes("*") || data.offer.platforms.includes("windows") || data.offer.platforms.includes("window") || data.offer.platforms.includes("linux")
            || data.offer.platforms.includes("mac") || data.offer.platforms.includes("")) {
            osIn.push('window')
        }
        let uA = getOS();
        if (!osIn.includes(uA)) {
            setShowQR(true)
        }
        // document.title = `PaidCash Offer | ${data.offer.name}`
        // ChangeStateUrlPath(`/offer/${data.offer.name.toLowerCase().split(".").join(" ").split(":").join(" ").split("-").join("").split(" ").join("-").split("---").join("-").split("--").join("-")}-${data.offer.id}`)
        setofferDetails({ ...data.offer, steps })
        socket.off('offerDetails')
    })
    socket.emit('getOfferDetails', {
        offer: props.offer.offerId,
    })
    }, [])
    return (
        <ModalContent 
        click={props.click}
        title={props.offer.name} 
        className="modal-content-promoted-offer"
        content={
            <>
                <div className="modal-content-promoted-offer-banner">
                    <img src={props.offer.picture} alt="Promoted Offer" />
                </div>
                <div className="modal-content-promoted-offer-info">
                    <div className="modal-content-promoted-offer-info-header">
                        <div className="modal-content-promoted-offer-info-header-left">
                        <div className="modal-content-promoted-offer-info-header-image">
                            <img src={props.offer.picture} alt="Promoted Offer" />
                        </div>
                        <div className="modal-content-promoted-offer-info-header-data">
                            <div className="modal-content-promoted-offer-info-header-reward">
                                <div className="modal-content-promoted-offer-info-header-reward-image">
                                    <ProgressiveImage
                                            preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            src="/assets/img/faucetify_coin_small.png"
                                            render={(src, style) => <img alt="coin image" className="header-balance-icon" style={style}
                                                loading="lazy" src={src} />}
                                    />
                                </div>
                                <div className="modal-content-promoted-offer-info-header-reward-amount">{numberWithCommas(props.offer.reward)}</div>
                              
                            </div>
                            <div className="modal-content-promoted-offer-info-header-reward-popularity">
                                    <div className="modal-content-promoted-offer-info-header-reward-popularity-icon">
                                        {offerDetails.popularity==undefined?<Skeleton width={80} height={30} />: offerDetails.popularity.toLowerCase()=='very low'?
                                        <><FaStar className="fill-star-yellow"/>
                                        <FaStar/>
                                        <FaStar/>
                                        <FaStar/>
                                        <FaStar/></>:offerDetails.popularity.toLowerCase()=='low'?
                                        <><FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar/>
                                        <FaStar/>
                                        <FaStar/></>:offerDetails.popularity.toLowerCase()=='medium'?
                                        <><FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar/>
                                        <FaStar/></>:offerDetails.popularity.toLowerCase()=='high'?
                                        <><FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar/></>:offerDetails.popularity.toLowerCase()=='very high'?
                                        <><FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/>
                                        <FaStar className="fill-star-yellow"/></>:''}
                                    </div>
                                    <div className="modal-content-promoted-offer-info-header-reward-popularity-title">Popularity</div>
                                </div>
                        </div>
                        </div>
                        <div className="modal-content-promoted-offer-info-header-right">
                           
                            <div className="modal-content-promoted-offer-info-header-platforms-icon">
                               {props.offer.platforms.includes("android") || props.offer.platforms.includes("all") || props.offer.platforms.includes("*") ? <FaAndroid/> : ''}
                               { props.offer.platforms.includes("desktop") || props.offer.platforms.includes("all") || props.offer.platforms.includes("*") || props.offer.platforms.includes("windows") || props.offer.platforms.includes("window") || props.offer.platforms.includes("linux") || props.offer.platforms.includes("mac") || props.offer.platforms.includes("") ? <FaDesktop/> : ''}
                               {props.offer.platforms.includes("ios") || props.offer.platforms.includes("all") || props.offer.platforms.includes("apple") || props.offer.platforms.includes("iphone") || props.offer.platforms.includes("ipad") || props.offer.platforms.includes("*") ? <FaApple/> : ''}
                            </div>
                            <div className="modal-content-promoted-offer-info-header-platforms-title">
                                Platforms
                            </div>
                          
                        </div>
                        
                    </div>
                    <div className="modal-content-promoted-offer-info-mid">
                        <div className="modal-content-promoted-offer-info-mid-item">
                            <div className="modal-content-promoted-offer-info-mid-item-data">
                            {offerDetails.status==undefined?<Skeleton width={80} height={30} />: <> 
                            {/* <GiSandsOfTime/>  */}
                            {offerDetails.status}</>}
                            </div>
                            <div className="modal-content-promoted-offer-info-mid-item-title">
                                Status
                            </div>
                        </div>
                        <div className="modal-content-promoted-offer-info-mid-item">
                            <div className="modal-content-promoted-offer-info-mid-item-data">
                            {offerDetails.wall==undefined?<Skeleton width={80} height={30} />: <>  <img src={`https://paidcash.co/assets/img/profile_small/${getOfferWallName(offerDetails.wall.split(' ').join('-')).toLowerCase()}.png`} alt="Provider of Offer" />
                            {getOfferWallName(offerDetails.wall.split(' ').join('-'))}</>}
                            </div>
                            <div className="modal-content-promoted-offer-info-mid-item-title">
                                Provider
                            </div>
                        </div>
                    </div>
                    <div className="modal-content-promoted-offer-info-description">
                        Join the battle in this action-packed game starring the legendary heroes of Raid Shadow Legends. Collect and upgrade your champions to fight against the shadow forces and save the world of Teleria. This offer is exclusive to PaidCash members.
                    </div>
                    <div className="modal-content-promoted-offer-info-bottom-container">
                    <div className="modal-content-promoted-offer-info-bottom">
                        {offerDetails.steps==undefined?
                        <Skeleton count={3} height={50} style={{marginBottom:'10px'}}/>
                        :offerDetails.steps.map(stepData=>{

                        let step = stepData.split('. Reward -->')
                        return (<div className="modal-content-promoted-offer-info-bottom-item">
                            <div className="modal-content-promoted-offer-info-bottom-left">
                            {step[1]==undefined?'': <> <div className="modal-content-promoted-offer-info-bottom-item-reward">
                                 <div className="modal-content-promoted-offer-info-bottom-item-reward-image">
                               <ProgressiveImage
                                                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                src="/assets/img/faucetify_coin_small.png"
                                                render={(src, style) => <img alt="coin image" className="header-balance-icon" style={style}
                                                    loading="lazy" src={src} />}
                                        />
                                    </div>
                                    <div className="modal-content-promoted-offer-info-bottom-item-reward-amount">{numberWithCommas(step[1].split(' coins.').join('').split(' coin.').join('').trim())}</div>
                                    </div> </>}
                                <div className="modal-content-promoted-offer-info-bottom-item-title">
                                    {step[0].trim()}
                                </div>
                            </div>
                            <div className="modal-content-promoted-offer-info-bottom-right">
                            {/* <GiSandsOfTime/> */}<FaArrowLeft/>
                            </div>
                        </div>)})}
                      
                       
                    </div>
                    {showQR == true &&
                    <div className="modal-content-promoted-offer-info-description" style={{marginBottom:'20px'}}>
                            <>

                                Scan to play
                                <div style={{
                                    display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: '17px',
                                    flexDirection: 'column', gap: '10px'
                                }}>

                                    <QRCode value={`https://paidcash.co${window.location.pathname}`}
                                        style={{ background: '#fff', padding: '5px', width: '100px', height: '100px', margin: '0 auto' }} />

                                        It looks like this offer is intended for {offerDetails == undefined ? <Skeleton width={30} height={10} /> : <>{offerDetails.platforms == undefined ? '' : offerDetails.platforms.includes("android") || offerDetails.platforms.includes("all") || offerDetails.platforms.includes("all") || offerDetails.platforms.includes("*") ? "Android " : ''}
                                            {offerDetails.platforms == undefined ? '' : offerDetails.platforms.includes("desktop") || offerDetails.platforms.includes("*")
                                                || offerDetails.platforms.includes("windows") || offerDetails.platforms.includes("window") || offerDetails.platforms.includes("linux")
                                                || offerDetails.platforms.includes("mac") || offerDetails.platforms.includes("all") || offerDetails.platforms.includes("") || offerDetails.platforms.includes("all") ?
                                                "Windows " : ''}
                                            {offerDetails.platforms == undefined ? '' : offerDetails.platforms.includes("ios") || offerDetails.platforms.includes("apple")
                                                || offerDetails.platforms.includes("iphone") || offerDetails.platforms.includes("all") || offerDetails.platforms.includes("all") || offerDetails.platforms.includes("ipad") || offerDetails.platforms.includes("*") ? "iOS " : ''}
                                        </>}
                                </div>

                            </></div>}
                    </div>
                   {showQR==true || offerDetails.link==undefined?'': <div className={`modal-content-promoted-offer-button ${offerDetails.link==undefined?'button-disabled':''}`}
                    onClick={()=>{
                        if(offerDetails.link==undefined){
                            return
                        }else{
                        window.open(offerDetails.link, '_blank')
                        }
                    }}>
                            Start Offer
                        </div>}
                </div>
               
            </>
        }/>
    )
}
const getOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return "android"
    }
    else if ((/iPad|iPhone|iPod/.test(ua))
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return "ios"
    }
    return "window"
}