import { Last7Days, Last7Months, numberWithCommas } from "../utils";
import { useEffect } from "react"

function ChartComp(props) {
  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.4.0/Chart.min.js');
    document.head.appendChild(addScript);
    if (typeof window.Chart !== "undefined") {
    }
  }, [])
  useEffect(() => {
    if (typeof window.Chart !== "undefined") {

      const ctx = document.getElementById(props.chartID);
      const myChart = new window.Chart(ctx, {
        type: 'line',

        data: {

          labels: props.title.toLowerCase().includes("month") ? Last7Months().split(",").reverse() : Last7Days().split(",").reverse(),
          datasets: [{
            label: `${props.title}`,
            displayColors: false,
            data: props.data,
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.3

          }]
        },
        options: {
          showAlltTooltips: false,

          tooltips: {
            custom: function (tooltip) {
              if (!tooltip) return;
              // disable displaying the color box;
              tooltip.displayColors = false;
            },
            callbacks: {
              // use label callback to return the desired label
              label: function (tooltipItem, data) {
                return tooltipItem.xLabel + " - " + numberWithCommas(tooltipItem.yLabel) + ` ${props.obj}`;
              },
              // remove title
              title: function (tooltipItem, data) {
                return;
              }
            }
          },
          legend: {
            display: true,
            onClick: () => { return null },
            labels: {
              fontColor: '#93acd3',
              fontSize: 15,
              boxWidth: 0,

            }
          },
          scales: {
            yAxes: [{
              ticks: {
                fontColor: "#55657e",
                fontSize: 12
              },
              gridLines: {
                color: "transparent",
                lineWidth: 2
              }
            }],
            xAxes: [{
              ticks: {
                fontColor: "#55657e",
                fontSize: 12
              },
              gridLines: {
                color: "transparent",
                lineWidth: 2
              }
            }],

            y: {
              beginAtZero: true,

            },

          },
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          }
        },

      });
    }
  }, [window.Chart])
  return (
    <canvas id={props.chartID}></canvas>
  )
}

export default ChartComp;