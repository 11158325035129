import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { PromotedSmallOfferItem } from "../Component/PromotedSmallOffer/Item.PromotedSmallOffer"
import '../assets/style/PromotedSmallOffer.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState } from "react";
import { PromotedOfferModalContent } from "./ModalContent/PromotedOffer.ModalContent";
import { socket } from "../components/Containers/MainContainer";
import { getDevicesSelected, isLoggedIn, showLoginModal } from "../components/utils";
import Skeleton from "react-loading-skeleton";

export const PromotedSmallOfferContainer = (props) => {
    function getBodyValue(open) { return document.querySelector('body').clientWidth  - (document.querySelector('body').clientWidth<=1050?0:(open==undefined?223:open==true?50:223))}
    const [perpage, setPerPage] = useState(Math.floor((getBodyValue()) / 140))
    const [offerData, setOfferData] = useState(0)
    const size = useWindowSize();
    const [offers, setPromotedOffers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    useEffect(() => {
        setPerPage(Math.floor((getBodyValue()) / 140))
    }, [size.width])
    useEffect(() => {
        document.addEventListener('handleUpdateSize', function(e){
            setPerPage(Math.floor((getBodyValue(e.detail.open)) / 140))
        })
    }, [])
    useEffect(() => {
        setTimeout(() => {
            socket.emit((props.titlex!=undefined?'getPromotedBigOffers':'getPromotedSmallOffers'), { devices: getDevicesSelected() });
        }, 1000)
        socket.on((props.titlex!=undefined?'emitPromotedBigOffers':'emitPromotedSmallOffers'), (data) => {
            setPromotedOffers(data.offers)
            // socket.off((props.titlex!=undefined?'emitPromotedBigOffers':'emitPromotedSmallOffers'))
        })
    }, [])
    const options = {
        rewind: true,
        perPage: perpage,
        perMove: 1,
        gap: 20,
        padding: "0",
        pagination: false,
       

    }

    const [showOfferModal, setShowOfferModal] = useState(false)
    function hideOfferModal(){setShowOfferModal(false)}
    return (
        <div className="promoted-small-offer-container" >
            {showOfferModal==true?<PromotedOfferModalContent offer={offerData} click={hideOfferModal}/>:''}
            <h2 className="promoted-small-offer-container-title" style={{width:'100%'}}>{props.titlex!=undefined?props.titlex:'Most Completed'}
            {props.titlex==undefined?
            <div className="input-container" style={{display:'inline-block', width:'auto', padding:'0px', marginTop:'-10px'}}>
                <input style={{ borderRadius: '5px', background: 'transparent', marginTop: '-10px', color: '#fff', fontSize: '12px', padding: '0px 0px 0px 10px' }} onChange={(e) => {
                    setPromotedOffers([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                    if (e.target.value.trim().split('').length > 2) {
                        socket.emit('getPromotedSmallOffers', { devices: getDevicesSelected(), name: e.target.value.trim() });
                    }
                    if (e.target.value.trim().split('').length <= 2) {
                        socket.emit('getPromotedSmallOffers', { devices: getDevicesSelected() });
                    }
                }}  type="text" name="amount" placeholder="Search in all offers.." autoComplete="off" />
            </div>
            :''}
            </h2>
           
            <Splide
                    options={options}
                    hasTrack={false}>


                    <div className="splide__arrows custom_arrows splide__arrows--ltr"><div className="custom_arrow_container custom_arrow_container_left"><button className="splide__arrow splide__arrow--prev custom_arrow" disabled="" aria-label="Previous slide" aria-controls="splide02-track"><svg width="20" height="20" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M10.35 16.63a1.29 1.29 0 0 1 0-1.76L13.1 12l-2.75-2.87a1.29 1.29 0 0 1 0-1.76 1.17 1.17 0 0 1 1.7 0l3.6 3.75c.47.48.47 1.28 0 1.76l-3.6 3.75c-.47.5-1.23.5-1.7 0Z"></path></svg></button></div><div className="custom_arrow_container custom_arrow_container_right"><button className="splide__arrow splide__arrow--next custom_arrow" aria-label="Next slide" aria-controls="splide02-track"><svg width="20" height="20" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M10.35 16.63a1.29 1.29 0 0 1 0-1.76L13.1 12l-2.75-2.87a1.29 1.29 0 0 1 0-1.76 1.17 1.17 0 0 1 1.7 0l3.6 3.75c.47.48.47 1.28 0 1.76l-3.6 3.75c-.47.5-1.23.5-1.7 0Z"></path></svg></button></div></div>

                <SplideTrack>
                    {offers.map((item, i)=> {
                      if (item.name == undefined) {
                        return <Skeleton className='offer-featured-box' style={{ width: '110px', height: '170px', marginRight:'15px', borderRadius: '8px' }} />
                    }else
                    return <SplideSlide key={i} className='offer-featured-box' onClick={() => {
                        let loggedIN = isLoggedIn();
                        if (loggedIN == false) {
                            showLoginModal()
                        } else {
                            setOfferData(item)
                            setShowOfferModal(true)
                        }
                        
                    }}>
                        <PromotedSmallOfferItem 
                            image={item.picture}
                            title={item.name}
                            description={item.description!=undefined?item.description:item.name}
                            amount={item.reward}
                            category={item.category}
                            platforms={item.platforms}
                            locked={false}
                            lockedText={'Locked'}

                        />
                    </SplideSlide>})}
                </SplideTrack>

            </Splide >
        </div>
    )
}
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // only execute all the code below in client side
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}