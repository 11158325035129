
import { useEffect } from 'react'
import '../assets/style/Terms.css'
import TopTOSCOntainer from './TopTOSCOntainer'

export const Cookies = (props) => {
  useEffect(function () {
    document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
    window.scrollTo(0, 0)
}, [])
    const lastEdited = '2023-09-09 [YYYY-MM-DD]'
  let TermsOfServicePage = [

    {
      title: 'Cookie Use Policy',
      id: 'cookie-use-policy',
      content: `<p>Cookies are small files of letters and numbers stored on your browser or device that enable the cookie owner to recognize the device when it visits websites or uses online services. The website you visit may set cookies directly, known as first-party cookies, or may trigger cookies set by other domain names, known as third-party cookies.</p>
            <br/>
            <p>While we may automatically use some cookies that are strictly necessary to provide the services you request or enable communications, we request your consent for all of our other cookie uses.</p> `
    },
    {
      title: 'Types of Cookies We Use',
      id: 'types-of-cookies-we-use',
      content: `
            <p>We may use the following types of cookies:</p>
            <br/>
            <ul style="list-style:circle;margin-left:20px">
                <li><strong>Strictly Necessary Cookies:</strong> Our website requires the use of these cookies to properly operate or provide necessary functions relating to the services you request. They include, for example, cookies enabling you to securely log into our website or sign up for an account.</li>
        
                <li><strong>Analytical/Performance Cookies:</strong> These cookies allow us and our third-party service providers to recognize and count the number of visitors and to see how visitors move around our website when they are using it. This helps us improve how our website works by ensuring that users can easily find what they need. These cookies generate aggregate statistics that are not associated with an individualized profile.</li>
        
                <li><strong>Functionality Cookies:</strong> These cookies enable helpful but non-essential website functions that improve your website experience. By recognizing you when you return to our website, they may, for example, allow us to personalize our content for you, greet you by name, or remember your preferences (such as your choice of language or region).</li>
        
                <li><strong>Advertising, Tracking, or Targeting Cookies:</strong> These cookies enable different advertising-related functions. They may allow us to record information about your visit to our website, such as pages visited, links followed, and videos viewed, so we can make our website and the advertising displayed on it more relevant to your interests.</li>
        
                <li><strong>Third-Party Cookies:</strong> Our website may contain helpful but non-essential features or plug-ins enabling third-party services that use cookies, such as social network connectors, maps, advertising networks, or web traffic analysis services. These cookies may enable visitor identification across websites and over time. We do not control the third party's use of those cookies, their duration, or their ability to share information with other third parties. Please review each party's cookie disclosure before consenting to this use category.</li>
            </ul>
        <br/>
            <strong>Cookie Expiration and Table</strong>
            <br/>
            <p>Our Privacy Policy describes our practices for any personal data that our first-party cookies collect, store, or use, including our legal basis for processing personal data. We rely on the following legal basis to process personal data collected through our first-party cookies.</p>
            <br/>
            <p>Except for strictly necessary cookies, all first-party cookies set by this site will expire no later than two years after your last website visit. Third-party cookie expiration periods are set by their respective owners.</p>
        
            `
    },
    {
      title: 'Cross-Border Data Transfers',
      id: 'cross-border-data-transfers',
      content: `
            <p>The cookies we use may process, store, or transfer personal data in and to a country outside your own, with privacy laws that provide different, possibly lower, protections. You consent to this transfer, storing, or processing when you consent to our cookie use. We are based in Romania. Please review each third-party cookie provider's policy to learn more about its location.</p>    `

    },
    {
      title: 'Your Cookie Choices',
      id: 'your-cookie-choices',
      content: `
            <p>If you wish to customize your cookie preferences or manage their use, please visit our <a onClick="window.CC.showSettings(0);" style="cursor:pointer">consent manager</a> tool. By continuing to use our site or accepting cookies through our cookie banner, you consent to their use.</p>
            <br/>
            <p>If you decide to withdraw your consent for cookies that are not strictly necessary, you can do so at any time by revisiting the <a onClick="window.CC.showSettings(0);" style="cursor:pointer">consent manager</a> tool.</p>
            <br/>
            <p>It's worth noting that most web browsers offer options to block cookies, either all of them or only third-party cookies, via your browser settings. However, keep in mind that blocking all cookies, including those that are strictly necessary, might impact the proper functioning of our website.</p>
            `
    },
    {
      title: 'Changes to Our Cookie Uses',
      id: 'changes-to-our-cookie-uses',
      content: `
        <p>We are committed to transparency in our use of cookies. Any changes to how we utilize cookies will be promptly posted on this page. Additionally, we will indicate on our website that we have updated this cookie notice.</p>
        <br/>
        <p>If these changes significantly affect how we employ cookies, we will provide notice on our website and request your consent for the revised use. To stay informed about updates or modifications to our cookie usage notice, please visit this page regularly.</p>
    
        `
    },
    {
      title: 'Contact Information',
      id: 'contact-information',
      content: `
            <p>If you have any questions, comments, or requests regarding this cookie use notice, please don't hesitate to contact us at:</p>

            <p><a href="mailto:hello@paidcash.co">hello@paidcash.co</a></p>
`
    },
    {
      title: 'Cookie Table',
      id: 'cookie-table',
      content: `
            <p>The table below provides a list of all cookies that may be in use on the <a href="https://paidcash.co">https://paidcash.co</a> website. We will regularly update this table to reflect changes and advancements on our website. Please check back periodically to review any new cookie uses. Please note that without the use of some of these cookies, you may not be able to access certain features of our website.</p>
            <br/>
            <strong>Strictly Necessary Cookies</strong>
            <p>These cookies are necessary for the web app to function and cannot be switched off in our systems.</p>
            <table class="border-policy">
              <tr>
                <th>Cookie Name</th>
                <th>Domain</th>
                <th>Purpose</th>
                <th>Expiration</th>
              </tr>
              <tr>
                <td>CONSENT</td>
                <td>google.com</td>
                <td>Used to detect if the visitor has accepted the marketing category in the cookie banner. This cookie is necessary for GDPR-compliance of the website.</td>
                <td>399 days</td>
              </tr>
              <tr>
                <td>cc_cookie</td>
                <td>paidcash.co</td>
                <td>Stores the user's cookie consent state for the current domain</td>
                <td>1 year</td>
              </tr>
              <tr>
                <td>rc::a</td>
                <td>google.com</td>
                <td>This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.</td>
                <td>Persistent</td>
              </tr>
              <tr>
                <td>rc::c</td>
                <td>google.com</td>
                <td>This cookie is used to distinguish between humans and bots.</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>session_token</td>
                <td>paidcash.co</td>
                <td>This is for PaidCash to track session data.</td>
                <td>365 days</td>
              </tr>
            </table>
            <br/>
            <strong>Preference Cookies</strong>
            <p>Preference cookies allow a website to remember information that affects the way a website behaves or looks.</p>
            <table class="border-policy" style="width:100%">
              <tr>
                <th>Cookie Name</th>
                <th>Domain</th>
                <th>Purpose</th>
                <th>Expiration</th>
              </tr>
              <!-- Add rows for preference cookies if available -->
            </table>
            <br/>
            <strong>Analytics & Performance Cookies</strong>
            <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our web shop.</p>
            <table class="border-policy">
              <tr>
                <th>Cookie Name</th>
                <th>Domain</th>
                <th>Purpose</th>
                <th>Expiration</th>
              </tr>
              <tr>
                <td>_clck</td>
                <td>paidcash.co</td>
                <td>Collects data on the user’s navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.</td>
                <td>1 year</td>
              </tr>
              <tr>
                <td>_clsk</td>
                <td>paidcash.co</td>
                <td>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</td>
                <td>1 day</td>
              </tr>
              <tr>
                <td>_cltk</td>
                <td>paidcash.co</td>
                <td>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>_ga</td>
                <td>paidcash.co</td>
                <td>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
                <td>399 days</td>
              </tr>
              <tr>
                <td>_ga_#</td>
                <td>paidcash.co</td>
                <td>Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.</td>
                <td>399 days</td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>paidcash.co</td>
                <td>Used by Google Analytics to throttle request rate.</td>
                <td>1 day</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>paidcash.co</td>
                <td>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
                <td>1 day</td>
              </tr>
              <tr>
                <td>c.gif</td>
                <td>c.clarity.ms</td>
                <td>Collects data on the user's navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.</td>
                <td>Session</td>
                
                  </tr>
                  <tr>
                    <td>CLID</td>
                    <td>c.clarity.ms</td>
                    <td>Collects data on the user's navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.</td>
                    <td>1 year</td>
                  </tr>
                  <tr>
                    <td>ANONCHK</td>
                    <td>c.clarity.ms</td>
                    <td>Collects data on the user's navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.</td>
                    <td>1 day</td>
                  </tr>
                  <tr>
                    <td>amp_#</td>
                    <td>paidcash.co</td>
                    <td>Registers statistical data on users' behavior on the website. Used for internal analytics by the website operator.</td>
                    <td>1 year</td>
                  </tr>
                  <tr>
                    <td>amp_cookie_test#</td>
                    <td>paidcash.co</td>
                    <td>Registers statistical data on users' behavior on the website. Used for internal analytics by the website operator.</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>amplitude_#</td>
                    <td>paidcash.co</td>
                    <td>Registers statistical data on users' behavior on the website. Used for internal analytics by the website operator.</td>
                    <td>1 year</td>
                  </tr>
                  <!-- Add more rows for analytics & performance cookies if available -->
                </table>
                <br/>
                <strong>Advertising Cookies</strong>
                <p>These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.</p>

                <table class="border-policy">
                  <tr>
                    <th>Cookie Name</th>
                    <th>Domain</th>
                    <th>Purpose</th>
                    <th>Expiration</th>
                  </tr>
                  <tr>
                    <td>ads/ga-audiences</td>
                    <td>google.com</td>
                    <td>Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor's online behavior across websites.</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>MUID</td>
                    <td>c.clarity.ms</td>
                    <td>Used widely by Microsoft as a unique user ID. The cookie enables user tracking by synchronizing the ID across many Microsoft domains.</td>
                    <td>1 year</td>
                  </tr>
                  <tr>
                    <td>pagead/landing</td>
                    <td>google.com</td>
                    <td>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>SM</td>
                    <td>c.clarity.ms</td>
                    <td>Registers a unique ID that identifies the user's device during return visits across websites that use the same ad network. The ID is used to allow targeted ads.</td>
                    <td>Session</td>
                  </tr>
                  <!-- Add more rows for advertising cookies if available -->
                </table>
            `

    }


  ]
    return (
        <div className='terms-page'>
            <TopTOSCOntainer edited={lastEdited} title={'Cookie Policy'} second_page={props.second_page}/>
            {TermsOfServicePage.map((item, index) => {
                return (
                        <div className="terms-item"  id={item.id} key={index}>
                            <div className="terms-title" >{index + 1}{' -'} {item.title}</div>
                            <div className='terms-content' dangerouslySetInnerHTML={{ __html: item.content }} />
                            {index == 0 ?
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', fontSize: '12px' }}>
                                    {TermsOfServicePage.map((item, index) => {
                                        return <div className='tos-al' style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div >{index + 1}</div> <a href={`#${item.id}`} key={index}> {`) ${item.title}`}</a>
                                            </div>
                                    })}
                                </div> : ''}
                        </div>

                )
            })}
        </div>
    )
}