import { FaCheckDouble, FaCircle, FaClosedCaptioning, FaDiscord, FaDollarSign, FaFacebook, FaInstagram, FaPercent, FaPercentage, FaTimes, FaTired, FaUser, FaUsers } from 'react-icons/fa';
import '../assets/style/DailyRewards.css'
import { socket } from '../components/Containers/MainContainer';
import { useEffect, useRef, useState } from 'react';
import ProgressiveImage from 'react-progressive-image-loading';
import UserIDProfileModalContainerDTImg from '../components/earn/UserIDProfileModalContainerDTImg';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MdOutlineVerified } from 'react-icons/md';
import { CreateToast, numberWithCommas } from '../components/utils';
import Skeleton from 'react-loading-skeleton';

export const ReferralData = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const [data, setData] = useState({})
    const creaRef = useRef(null)
    useEffect(() => {

        socket.on('referralData', (data) => {
            setData(data)
        })
        socket.emit('emitReferralData')
    }, [])
    const affiliateSteps = [
        {
          level: "3%",
          commission: 3,
          earnedThisMonth: 0,
          totalInvitedUsers: 1,
          totalEarned: 0,
        },
        {
          level: "5%",
          commission: 5,
          earnedThisMonth: 0,
          totalInvitedUsers: 25,
          totalEarned: 70,
        },
        {
          level: "7%",
          commission: 7,
          earnedThisMonth: 15,
          totalInvitedUsers: 50,
          totalEarned: 350,
        },
        {
          level: "10%",
          commission: 10,
          earnedThisMonth: 50,
          totalInvitedUsers: 100,
          totalEarned: 1000,
        },
        {
          level: "15%",
          commission: 15,
          earnedThisMonth: 500,
          totalInvitedUsers: 200,
          totalEarned: 5000,
        },
        {
          level: "20%",
          commission: 20,
          earnedThisMonth: 1000,
          totalInvitedUsers: 500,
          totalEarned: 15000,
        },
        {
          level: "30%",
          commission: 30,
          earnedThisMonth: 2500,
          totalInvitedUsers: 1000,
          totalEarned: 35000,
        }
      ];
    return (
        <div className='daily-rewards-container'>
            <div className='daily-rewards-top'>
            <div className='daily-rewards-top-info'>
                <div className='daily-rewards-top-info-title'>
                Affiliate Program
                </div>
                <div className='daily-rewards-top-info-description'>
                Earn by sharing! Invite friends to join, and for every successful referral, you’ll receive a generous commission. The more you refer, the more you earn—there's no limit! Start growing your income today by sharing your unique link.
                </div>
            </div>
            <div className='daily-rewards-header' style={{marginBottom:'0px'}}>
                <div className='daily-rewards-header-amount-container'>
                    <div className='daily-rewards-header-amount-container-title'> 
                    <img src="/assets/img/faucetify_coin_small.png" alt="coin image" /> {numberWithCommas(data.referralEarnedTotal)}
                    </div>
                    <div className='daily-rewards-header-amount-container-description'> 
                       Earned Today
                    </div>
                </div>
                <div className='daily-rewards-header-amount-container'>
                    <div className='daily-rewards-header-amount-container-title'> 
                    <img src="/assets/img/faucetify_coin_small.png" alt="coin image" /> {numberWithCommas(data.referralEarnedTotal)}
                    </div>
                    <div className='daily-rewards-header-amount-container-description'> 
                        Total Earned
                    </div>
                </div>
                <div className='daily-rewards-header-amount-container'>
                    <div className='daily-rewards-header-amount-container-title'> 
                    <FaUsers style={{fill:'#cdae25', fontSize:'15px', marginBottom:'2px'}}/> {numberWithCommas(data.totalUsersInvited)}
                    </div>
                    <div className='daily-rewards-header-amount-container-description'> 
                       Friends Joined
                    </div>
                </div>
            </div>
            <div className='daily-rewards-code'>
                <div style={{ texAling: 'left', fontSize: '12px', gap:'10px', display:'flex', flexDirection:'column' }}>Get started on your journey by creating your unique referral ID! This ID will not only help you track your referrals but also allow you to earn rewards for every new user who joins through your link.
                    {/* <div onClick={(e) => {
                        navigator.clipboard.writeText(`https://paidcash.co/r/${data.referralCode}`);
                    }} className="link">{data.referralCode == undefined ? <Skeleton style={{ width: '90px' }} /> : `https://paidcash.co/r/`}
                    </div> */}

                     <div className='input-container' style={{gap:'0px'}}>
                     <div className="input-icon" style={{marginTop:'1px', paddingRight:'0px'}}>https://paidcash.co/r/{data.referralCode}</div>
                        {/* <input onChange={(e) => {
                        }} type='text' className="walletCrypto" name="coin_amount" defaultValue={data.referralCode} autoComplete="off" />
  <button onClick={(e) => {
                            navigator.clipboard.writeText(`https://paidcash.co/r/${data.referralCode}`);
                        }} className="button button-brand">Save</button> */}
                    </div>
                   
                         <button  onClick={(e) => {
                            CreateToast('Referral link copied to clipboard', creaRef)
                            navigator.clipboard.writeText(`https://paidcash.co/r/${data.referralCode}`);
                        }} className="button button-brand" style={{height:'30px', cursor:'pointer'}}>Copy</button>
                    </div>
                </div>
            </div>
            <div className='affiliate-levels'>
            {
                affiliateSteps.map((step, index) => {
                    return (
                        <div className='affiliate-level-container' >
                            <div className={`affiliate-level-position`} style={{ border: (step.totalInvitedUsers<=data.totalUsersInvited) && 
                                (step.totalEarned<=(data.referralEarnedTotal/1000))?'1px solid var(--text-green)':'1px solid var(--bg-color-6)'}}>
                            <FaCircle style={{color:
                                (step.totalInvitedUsers<=data.totalUsersInvited) && 
                                (step.totalEarned<=(data.referralEarnedTotal/1000))?'var(--text-green)':'var(--bg-color-3)'
                                , width:'10px', height:'10px'}}/>
                            </div>
                            <div className='affiliate-level-data' style={{ border: (step.totalInvitedUsers<=data.totalUsersInvited) && 
                                (step.totalEarned<=(data.referralEarnedTotal/1000))?'1px solid var(--text-green)':''}}>
                                <div className='affiliate-level-title'>
                                    Tier {index+1}
                                    <div className='affiliate-level-description-item'>
                                        <div className='affiliate-level-description-item-title'>
                                            <MdOutlineVerified/> {step.commission}% commission
                                        </div>
                                        
                                    </div>
                                </div>
                              
                                <div className='affiliate-level-description'>
                                   
                                  {step.earnedThisMonth==0?'':  <div className='affiliate-level-description-item'>
                                        <div className='affiliate-level-description-item-title'>
                                        <FaCircle style={{color:'var(--bg-color-6)'}}/>  ${numberWithCommas(step.earnedThisMonth)} Earned This Month
                                        </div>
                                    </div>}
                                    {step.totalInvitedUsers==0?'':    <div className='affiliate-level-description-item'>
                                        <div className='affiliate-level-description-item-title'>
                                        <FaCircle style={{color:step.totalInvitedUsers<=data.totalUsersInvited?'var(--text-green)':'var(--bg-color-6)'}} /> {numberWithCommas(step.totalInvitedUsers)}  Total Invited Users
                                        </div>
                                       
                                    </div>}
                                    {step.totalEarned==0?'':  <div className='affiliate-level-description-item'>
                                        <div className='affiliate-level-description-item-title'>
                                        <FaCircle  style={{color:step.totalEarned<=(data.referralEarnedTotal/1000)?'var(--text-green)':'var(--bg-color-6)'}}/> ${numberWithCommas(step.totalEarned)}  Total Earned
                                        </div>
                                      
                                    </div>}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            </  div>
        </div>
    );
}