import { FaCheckDouble, FaDiscord, FaDollarSign, FaFacebook, FaInstagram } from 'react-icons/fa';
import '../assets/style/PromoCode.css'
import { socket } from '../components/Containers/MainContainer';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const PromoCodeContainer = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const FAQ = [
        {
            "q": "What are promo codes?",
            "a": "Promo codes are special codes that you can enter to earn extra rewards or bonuses on your account. Keep an eye out for these codes to increase your earnings.",
            "show": true
        },
        {
            "q": "Where can I find promo codes?",
            "a": "You can find our latest promo codes on our official Facebook, Instagram, and Discord pages. Make sure to follow us to never miss out!",
            "show": true
        },
        {
            "q": "How do I use a promo code?",
            "a": "Simply log into your account, navigate to the 'Promo Code' section, and enter the code. Your bonus will be added instantly to your account.",
            "show": true
        },
        {
            "q": "How often are new promo codes released?",
            "a": "We regularly release new promo codes on our social media channels, so be sure to check them frequently for updates.",
            "show": true
        },
        {
            "q": "Can I use more than one promo code at a time?",
            "a": "Typically, only one promo code can be applied at a time. However, you can use multiple promo codes over time to continue boosting your earnings.",
            "show": true
        },
        {
            "q": "Do promo codes expire?",
            "a": "Yes, promo codes usually come with an expiration date. Be sure to use them before they expire to take full advantage of the offer.",
            "show": true
        },
        {
            "q": "What happens if a promo code doesn’t work?",
            "a": "If a promo code isn’t working, it may have expired or been entered incorrectly. Double-check the code for any typos, and ensure it hasn’t passed its expiration date. If the issue persists, contact our support team for assistance.",
            "show": true
        },
        {
            "q": "Are promo codes available for everyone?",
            "a": "Yes, promo codes are available to all users. Just follow us on social media to gain access to them and increase your rewards.",
            "show": true
        },
        {
            "q": "Can I share promo codes with friends?",
            "a": "Absolutely! Feel free to share promo codes with your friends so they can benefit from extra rewards too.",
            "show": true
        },
        {
            "q": "Can promo codes be combined with other offers?",
            "a": "Promo codes are generally standalone offers and cannot be combined with other promotions or discounts unless stated otherwise.",
            "show": true
        }
    ]
    const [bonusCode, setBonusCode] = useState('')
    const [data, setData] = useState({})
    useEffect(() => {

        socket.on('receive_reward_data', (data) => {
            setData(data)
        })
        socket.emit('emitRewardsData')
    }, [])
    return (
        <div className="promo-code-main">

        <div className="promo-code-container">
          
            <div className="promo-code-container-content">
                <div className='promo-code-container-title'>
                    Promo Code
                </div>
               
                <div className='input-container'>
                    <div className="input-icon"><FaCheckDouble/></div>
                        <input onChange={(e) => {
                                setBonusCode(e.currentTarget.value)
                            }} placeholder='Promo Code' id="amount" type="text" name="amount"  />
                        </div>
                <div className="promo-code-container-content-button" onClick={(e) => {
                      if (props.loggedIN == true) {
                        if (bonusCode.trim().split("").length > 3) {
                            socket.emit('userAction', {
                                action: 'bonus_code',
                                bonusCode: bonusCode
                            })
                        }
                    }
                    }} style={{ background: props.loggedIN == true && bonusCode.trim().split("").length > 3 ? '' : 'var(--bg-color-6)'}}>
                    Claim
                </div>
               
            </div>
            <div className="promo-code-container-content">
            <div className='promo-code-container-title'>
                    Where to get promo codes from?
                </div>
                <div className='promo-code-container-description'>
                Discover exclusive promo codes on our Facebook, Instagram, and Discord pages to boost your earnings even more!
                </div>
                <div className='promo-code-container-links'>
                <Link to="https://www.facebook.com/paidcash.co" target="_blank"><FaFacebook/></Link>
                    <Link to="https://www.instagram.com/paidcash_co" target="_blank"><FaInstagram/></Link>
                    <Link to="/discord" target="_blank"><FaDiscord/></Link>
                    </div>
            </div>
            </div>
            <h2 className="promo-code-content-title">Frequently Asked Questions</h2>

            <div className="faq-container">
            {FAQ.map(function (q, i) {
                        return (
                                <div className='faq-item' onClick={function (e) {
                                    e.currentTarget.children[0].classList.toggle('faq-item-active')
                                    e.currentTarget.children[0].children[0].classList.toggle('fa-arrow-right')
                                    e.currentTarget.children[0].children[0].classList.toggle('fa-arrow-down')
                                    e.currentTarget.children[1].classList.toggle('faq-item-show')
                                }}>
                                    <div className='faq-item-top'>
                                        <i class="fal fa-arrow-right"></i>
                                        {q.q}
                                    </div>

                                    <div className='faq-item-bottom' dangerouslySetInnerHTML={{ __html: q.a }}>
                                    </div>

                                </div>
                        )
                })}
                </div>
        </div>
    );
}