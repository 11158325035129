
import { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { getChatStatus, isLoggedIn, showLoginModal } from '../../components/utils';
import { FaCashRegister, FaCoins, FaFacebook, FaInstagram, FaStopwatch, FaTelegram, FaTwitter } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { socket } from '../../components/Containers/MainContainer';
import '../../assets/style/PTC.css'

function PTCAdsShow() {
  useEffect(function () {
    document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
    window.scrollTo(0, 0)
}, [])
  const [chatStatus, setChatStatus] = useState(getChatStatus())
  const [ads, setAds] = useState([])
  useEffect(() => {
    window.addEventListener('showChat', () => {
      setChatStatus(getChatStatus())
    })

      ;
  }, [])
  const { referral_id, page, second_page, third_page } = useParams();

  const [facebookLink, setFacebookLink] = useState("")
  const [instagramlink, setInstagramLink] = useState("")
  const [twiteerlink, setTwiteerLink] = useState("")
  const [telegramLink, setTelegramLink] = useState("")
  useEffect(() => {
    socket.emit('get_ptc_ads', { frameless: false })
    socket.on('ptc_ads', (data) => {
      setAds(data)
    })
  }, [])
  return (
<div className={`main-container`}>

<div className="top">

  <div className="cat-earn-mob" style={{margin:'0px'}}>
    <Link onClick={() => {
      if (isLoggedIn() == false) {
        showLoginModal()
      }
    }} state={{

    }} to='/advertise' className="cat-earn-mob-item"
      >
      Advertise with us
    </Link>
    <Link onClick={() => {
      if (isLoggedIn() == false) {
        showLoginModal()
      }
    }} state={{

    }} to='/ptc/frameless' className="cat-earn-mob-item"
      >
      Frameless Ads
    </Link>
    <Link onClick={() => {
      if (isLoggedIn() == false) {
        showLoginModal()
      }
    }} state={{

    }} to='/ptc' className="cat-earn-mob-item sel"
      >
      iFrame Ads
    </Link>
  </div>
</div>
        <div className="ptc-ad-principal" >
          {ads[0] == undefined ? [0, 0, 0, 0].map(s => {
            return <div className="advertise-ad-fa ptc-ad"><Skeleton height={200} width={300} /></div>
          }) : ads.map(ad => {
            return <div className={`advertise-ad-fa ptc-ad ${ad.is_promoted==true?'ptc-ad-promoted':''}`} >
              <div className="featured-ad">
              </div><div className="featured-ad2">
              </div>
              {ad.is_promoted == true ? <>
                <div className="featured-ad">
                  Promoted
                </div></> : ""}
              <div>
                <p className="ptc-ad-title" style={{ marginTop: ad.is_social == true == true ? '10px' : '0px' }}>{ad.title} </p>
                <p className="ptc-ad-description">{ad.description.length > 100 ? ad.description.substring(0, 100) + '...' : ad.description}</p>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div className="ptc-ad-details">
                    <div style={{ display: 'flex', gap: '10px' }}>
                    <p className='ptc-ad-reward'><FaCoins /> {ad.CPC} </p>
                    <p className='ptc-ad-timer'><FaStopwatch style={{ marginTop: '-5px' }} /> {ad.timer}  s</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                {ad.viewed == true ? <p className='watch-ptc-tmrw'>Watch again tomorrow</p> : ''}
                <div onClick={() => {
                  // /ptc/view / ${ ad.link }

                  let myWindow = window.open(`/ptc/view/${ad.link}`, '_blank');
                  myWindow.addEventListener('continueClick', function () {
                    // socket.emit('viewed_ad', { ad_id: ad._id })
                    socket.emit('get_ptc_ads', { frameless: false })

                  })
                }} className="ptc-ad-btn" style={{ background: ad.viewed == true || ad.link == undefined ? '#0d131a' : '#178a94', textDecoration: 'none', color: '#f3f3f3', fontSize: '13px', width: '100%', textAlign: 'center' }}>{ad.viewed == true ? 'Visit Again' : 'Watch AD'}</div>
              </div>

              <div className="mobile-social-links-ad-fa" style={{ flexDirection: 'column', alignItems: 'start', minWidth: '0px', display: ad.is_social == true ? "flex" : 'inherit' }}>
                {ad.is_social == true ? <div>
                  {ad.facebook_link == "" ? "" : <a href={ad.facebook_link} target='_blank'><FaFacebook style={{ color: '#3b7ee7', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} /></a>}
                  {ad.instagram_link == "" ? "" : <a href={ad.instagram_link} target='_blank'><FaInstagram style={{ color: '#f36c6c', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} /></a>}
                  {ad.twitter_link == "" ? "" : <a href={ad.twitter_link} target='_blank'><FaTwitter style={{ color: '#55b1e7', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} /></a>}
                  {ad.telegram_link == "" ? "" : <a href={ad.telegram_link} target='_blank'><FaTelegram style={{ color: '#5f99f3', fontSize: '16px', margin: ' 0 2px ', cursor: 'pointer' }} /></a>}
                </div> : ""}

              </div>
            </div>
          })}
        </div>
    </div>);

}
const buildPagination = function (pageNumberWithdraw, pageSelected, setPage) {

  return (<nav aria-label="Page navigation" style={{ marginTop: '15px', display: 'flex', overflow: 'auto', justifyContent: 'center' }}>
    {pageNumberWithdraw == 0 ? "" :
      <ul className="pagination">
        <li onClick={function () {
          setPage(1)
        }} className="page-item">
          <a className="page-link" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {pageNumberWithdraw == 1 ? <li className="page-item active"><a className="page-link" >1</a></li> : pageNumberWithdraw > 6 ?
          <>
            {pageSelected <= 2 ? "" :
              <>
                <li onClick={function () {
                  setPage(1)
                }} className="page-item"><a className="page-link" >1</a></li>
                ...</>}
            <li onClick={function () {
              pageSelected == 1 ? setPage(1) : setPage(pageSelected == [...Array(pageNumberWithdraw)].length ? pageSelected - 2 : pageSelected - 1)
            }} className={pageSelected == 1 ? "page-item active" : "page-item"}><a className="page-link" > {pageSelected == 1 ? 1 : pageSelected == [...Array(pageNumberWithdraw)].length ? pageSelected - 2 : pageSelected - 1}</a></li>
            <li onClick={function () {
              pageSelected <= Math.floor([...Array(pageNumberWithdraw)].length) ? setPage(pageSelected == 1 ? 2 : pageSelected == [...Array(pageNumberWithdraw)].length ? pageSelected - 1 : pageSelected) : (function () { })()
            }} className={pageSelected == 1 ? "page-item" : pageSelected != Math.floor([...Array(pageNumberWithdraw)].length) ? "page-item active" : "page-item"}
            ><a className="page-link" > {pageSelected == 1 ? 2 : pageSelected == [...Array(pageNumberWithdraw)].length ? pageSelected - 1 : pageSelected}</a></li>
            {pageSelected >= Math.floor([...Array(pageNumberWithdraw)].length - 1) ? "" :
              <><li onClick={function () {
                setPage(pageSelected == 1 ? 3 : pageSelected + 1)
              }} className="page-item"><a className="page-link" > {pageSelected == 1 ? 3 : pageSelected + 1}</a></li>
                ...</>}
            <li onClick={function () {
              pageSelected <= Math.floor([...Array(pageNumberWithdraw)].length) ? setPage([...Array(pageNumberWithdraw)].length) : (function () { })()
            }} className={pageSelected == Math.floor([...Array(pageNumberWithdraw)].length) ? "page-item active" : "page-item"}><a className="page-link"> {[...Array(pageNumberWithdraw)].length}</a></li>
          </> : <>
            {[...Array(pageNumberWithdraw)].map(function (x, i) {
              return <li onClick={function () {
                setPage(i + 1)
              }} className={pageSelected == i + 1 ? "page-item active" : "page-item"}><a className="page-link" >{i + 1}</a></li>;
            })}
          </>}
        <li onClick={function () {
          setPage(pageNumberWithdraw < ([...Array(pageNumberWithdraw)].length) ? (pageNumberWithdraw + 1) : pageNumberWithdraw)
        }} className="page-item">
          <a className="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>}
  </nav>)
}
export default PTCAdsShow;