
import React, { useEffect, useState } from 'react'
import { BiSupport } from 'react-icons/bi'
import { FaCircle, FaPlane, FaRegPaperPlane, FaSmile } from 'react-icons/fa'
import { getChatTab, getLocalStorage, isLoggedIn, numberWithCommas } from '../utils'
import CountUp from 'react-countup'
import { addMessage, emojiList } from './ChatMid'
import { Menu } from '@headlessui/react'

import { AiOutlineCloseCircle } from 'react-icons/ai'
import { socket } from '../Containers/MainContainer'
import Skeleton from 'react-loading-skeleton'
import ProgressiveImage from 'react-progressive-image-loading'

function ChatBottom({ }) {
    let [loggedIN, setLogged] = useState(isLoggedIn());
    useEffect(() => { setLogged(isLoggedIn()) }, [isLoggedIn()])
    const [onlineUsers, setOnlineUsers] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true)
    const [selectedTab, setSelectedTab] = useState(getChatTab())
    useEffect(() => {
        window.addEventListener('chatTab', (e) => {
            setSelectedTab(getChatTab())

        })
    }, [])
    let [showTagManager, setTagManager] = useState(false);
    const [tags, setTags] = useState([0, 0, 0, 0])
    function emitMessage(message) {
        // if (selectedTab == 0) {
            socket.emit('emitMessage', {
                message,
            })
        // } else {
        //     socket.emit('emitSupportMessage', {
        //         message,
        //     })
        // }


    }

    useEffect(() => {
        socket.on('onlineUsers', (data) => {
            setOnlineUsers(data.count)
            if (firstLoad == true) { setTimeout(() => { setFirstLoad(false) }, 10000) }

        })
        socket.on('tagManager', (data) => {
            setTags(data.usersTag)
        })
    }, [])
    const [banned, setBanned] = useState(getLocalStorage("banned"))
    useEffect(() => {
        window.addEventListener('setBanned', (e) => {
            setBanned(true)
        })
    }, [])
    return (
        <div className='chat-bottom'>
            {!loggedIN ? <div className="chat-cover">
                You must be logged in to chat!
            </div> : ''}
            {banned == true && selectedTab == 0 ? <div className="chat-cover" style={{
                color: 'rgb(225 92 92)', fontWeight: 'bold'
            }}>
                You are banned!
            </div> : ''}
            <textarea className='chat-textarea' disabled={!loggedIN} onChange={(e) => {
                if (e.target.value.split(" ")[e.target.value.split(" ").length - 1].includes("@") && e.target.value.split(" ")[e.target.value.split(" ").length - 1].split("").length > 1
                    && !e.target.value.split(" ")[e.target.value.split(" ").length - 1].includes("@uid")) {
                    setTagManager(true)
                    socket.emit('emitTagManager', {
                        tag: e.target.value.split(" ")[e.target.value.split(" ").length - 1]
                    })
                } else {
                    setTagManager(false)
                }
                if (e.currentTarget.value.trim().split("").length >= 2) {
                    document.querySelector('.chat-btn-send').classList.remove('btn-off')
                    document.querySelector('.chat-btn-send').classList.add('btn-brand')


                } else {
                    document.querySelector('.chat-btn-send').classList.add('btn-off')
                    document.querySelector('.chat-btn-send').classList.remove('btn-brand')
                }
            }} onKeyDown={(e) => {

                if (e.target.value.trim().length >= 2) {
                    if (e.keyCode == 13) {
                        emitMessage(e.target.value)
                        e.target.value = ''
                        document.querySelector('.chat-btn-send').classList.add('btn-off')
                        document.querySelector('.chat-btn-send').classList.remove('btn-brand')
                    }
                }
            }} placeholder='Message here' ></textarea>
            <Menu >

                <Menu.Button style={{ background: 'none', padding: '0px', border: 'none' }}>
                    <FaSmile className='chat-btn-emoji' />
                </Menu.Button>
                <Menu.Items className="emoji-box">
                    <div className='emoji-box-head'>
                        <p>Emoji Box</p>

                    </div>

                    <div className='emoji-box-container'>

                        {emojiList.map((emoji, index) => {
                            return <Menu.Item>
                                {({ active }) => (
                                    <img
                                        src={`/assets/img/emojilist/${emoji.img}`}
                                        onClick={() => {

                                            document.querySelector('.chat-textarea').value += ` ${emoji.tag} `
                                        }}
                                        class="emoji-chat-item"

                                    />


                                )}
                            </Menu.Item>
                        })}
                    </div>


                </Menu.Items>
            </Menu>
            {showTagManager == false ? '' : <div className="tag-manager">
                {tags.map((x, i) => {
                    let v = Math.random();
                    return (
                        <div key={x.userId} onClick={() => {
                            setTagManager(false)
                            document.querySelector('.chat-textarea').value = document.querySelector('.chat-textarea').value.substring(0, document.querySelector('.chat-textarea').value.lastIndexOf("@")) + `${x.userId}`;
                        }} className="tag-manager-item">
                            {x.profilePicture == undefined ? <Skeleton style={{ width: '40px', height: '40px' }} /> : <img src={x.profilePicture} />}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ marginBottom: '0px' }}>{x.username || <Skeleton style={{ width: '100%' }} />}</p>
                                <p>{x.userId || <Skeleton style={{ width: '130px' }} />} </p>
                            </div>
                        </div>
                    )
                })}
            </div>}
            <div className='chat-btn-send btn btn-off' onClick={(e) => {
                if (e.currentTarget.classList.contains('btn-off') == false) {
                    e.preventDefault();
                    if (document.querySelector('.chat-textarea').value.trim().length >= 2) {
                        // addMessage('Laurentiu',1020,e.target.value);
                        emitMessage(document.querySelector('.chat-textarea').value)
                        document.querySelector('.chat-textarea').value = ''
                        document.querySelector('.chat-btn-send').classList.add('btn-off')
                        document.querySelector('.chat-btn-send').classList.remove('btn-brand')
                    }

                }
                document.querySelector('.chat-textarea').value = ''
            }}>
                <FaRegPaperPlane />
            </div>
        </div>
    )
}

export default ChatBottom