'use client'

import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';

function PlutoMenu({ rank, user_id }) {
    const { referral_id, page, second_page, third_page } = useParams();

    const pathname = `/${page}/${second_page}/${third_page}`
    return (
        <header className='header-plto'>
        <nav className='cat-earn-mob'>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${second_page==undefined?'sel':''}`}><Link to='/pluto' >Home</Link></div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='support-chat'?'sel':''}`}><Link to='/pluto/support-chat' >Support Chat</Link></div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='user'?'sel':''}`}> {rank == 4 || rank == 7 ? "" : <Link to='/pluto/user'  >Users</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='withdrawals'?'sel':''}`}> {rank != 10 ? "" : <Link to='/pluto/withdrawals'  >Withdrawals</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='invoices'?'sel':''}`}> {rank != 10 ? "" : <Link to='/pluto/invoices' >Invoices</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='ptcs'?'sel':''}`}> {rank != 10 ? "" : <Link to='/pluto/ptcs'  >PTCS</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='chargebacks'?'sel':''}`}> {rank < 8 ? "" : <Link to='/pluto/chargebacks' >Chargebacks</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='pending-offers'?'sel':''}`}> {rank < 8 ? "" : <Link to='/pluto/pending-offers'  >Pending Offers</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='suspect-offers'?'sel':''}`}> {rank < 8 ? "" : <Link to='/pluto/suspect-offers' >Suspect Offers</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='offerwalls'?'sel':''}`}> {rank != 10 ? "" : <Link to='/pluto/offerwalls'  >OfferWalls</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='pc-offers'?'sel':''}`}> {rank != 10 ? "" : <Link to='/pluto/pc-offers'  >PaidCash Offers</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='offers'?'sel':''}`}> {rank < 8 ? "" : <Link to='/pluto/offers' >Offers</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='offer-total'?'sel':''}`}>{rank < 8 ? "" : <Link to='/pluto/offer-total'  >Offers Data</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='offer-today'?'sel':''}`}>{rank < 8 ? "" : <Link to='/pluto/offer-today'  >Offers Data Today</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='users-today'?'sel':''}`}>{rank < 8 ? "" : <Link to='/pluto/users-today' >New Users</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='offers-today'?'sel':''}`}> {rank < 8 ? "" : <Link to='/pluto/offers-today'  >New Offers</Link>}</div>
           <div style={{fontSize:'10px'}} className={`cat-earn-mob-item ${page=='pluto' && second_page=='withdrawals-today'?'sel':''}`}> {rank < 8 ? "" : <Link to='/pluto/withdrawals-today'  >Withdrawals Today</Link>}</div>
</nav>
            </header>
    )
}

export default PlutoMenu