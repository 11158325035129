import { FaDiscord, FaFacebook, FaInstagram } from 'react-icons/fa'
import '../assets/style/Footer.css'
import { LogoHeader } from '../Component/Header/Logo.Header'
import { Link } from 'react-router-dom'
import { EUC, getLocalStorage } from '../components/utils'

export const FooterContainer = (props) => {
    return (
        <div className="footer-main">
            <div className="footer-top">
            
            <div className='footer-top-first'>
                    <div className='footer-top-first-title'>
                    Fun That Pays, Anytime Anywhere!
                    </div>
                    <div className='footer-top-first-description'>
                    Download the PaidCash App to keep the fun rolling and the cash flowing, anytime, anywhere!
                    </div>
                    <div className='footer-top-first-link'>
                    <Link to="https://play.google.com/store/apps/details?id=com.ml.paidcash&referrer=soruce_website" target="_blank"><img src="/assets/img/get_it_on_gp.webp" alt="Google Play Badge" /></Link>
                    </div>
                </div>
                <div className='footer-top-second'>
                    <LogoHeader />
                    <div className='footer-top-second-items'>
                        <div className='footer-top-second-item'>
                            <Link to="/policies/terms">Terms of Service</Link>
                        </div>
                        <div className='footer-top-second-item'>
                        <Link to={`/policies/privacy${(EUC.includes((getLocalStorage('country') + '').toUpperCase()) ? '-eu' : '')}`}>Privacy Policy</Link>
                        </div>
                        <div className='footer-top-second-item'>
                        <Link to="/policies/cookies">Cookies Policy</Link>
                        </div>
                        <div className='footer-top-second-item'>
                        <Link to="https://www.trustpilot.com/review/paidcash.co?stars=4&stars=5" target="_blank">Payment Proof</Link>
                        </div>
                    </div>
                    <div className='footer-top-social-links'>
                    <Link to="https://www.facebook.com/paidcash.co" target="_blank"><FaFacebook/></Link>
                    <Link to="https://www.instagram.com/paidcash_co" target="_blank"><FaInstagram/></Link>
                    <Link to="/discord" target="_blank"><FaDiscord/></Link>
                    </div>
                </div>
                
            </div>
            <div className="footer-bottom">
                <div className='footer-bottom-site'>
                    PaidCash.co | All Rights Reserved
                </div>
                <div className='footer-bottom-copyright'>
                    Copyright 2022 - {new Date().getFullYear()}
                </div>
            </div>
        </div>
    )
}