
import { useEffect, useState } from "react";
import { socket } from "../Containers/MainContainer";
import { hideAdJoeModal, hideWithdrawMSGModal, showWithdrawMSGModal } from "../utils";
import { IoCloseSharp } from "react-icons/io5";

function AdJoeModal(props) {
    const [msg, SetMsg] = useState('')
    const [paid, setPaid] = useState(0);
    const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.ml.paidcash';

    return (
        <div className='modal' style={{ display: 'none' }} onClick={(e) => {
            if (e.target.classList.contains('modal') && !e.target.classList.contains('modal-uip')) {

                e.target.style.display = 'none'
            }
        }} >
            <div className='adjoe-modal modal-uip x5'>
            <div className=" p-8 rounded-lg shadow-md text-center" style={{position:'relative'}}>

                <p class="modal-title" id="exampleModalLabel"
                    style={{ display: 'inline-block', width: '100%', textAlign: 'center', fontFamily: 'Roboto', fontSize: '18px', color: 'var(--text-highlight)', marginTop: '15px' }}
                    dangerouslySetInnerHTML={{ __html: msg }}>
                </p>
        <p className=" font-bold mb-4" style={{fontSize:'25px'}}>Download Our Android App</p>
        <div className="mb-4"> 
            <div className="mb-4" >
          <img
          style={{background:'#fff'}}
            src="/assets/img/app_gq.png"
            alt="App QR Code"
            className="mx-auto"
            width={150}
            height={150}
          />
          </div>
        </div>
        <p className="mt-4 text-gray-600">
          Scan the QR code above or click the button below to download our Android app from Google Play Store for extra features!
        </p>
        <a
          href={playStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 inline-block bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition-colors"
        >
          Download App
        </a>
      </div>
            </div>
        </div>

    )
}

export default AdJoeModal;