'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, getNextMonthLastDay, numberWithCommas } from '../utils';

import { useEffect } from 'react';
import { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PlutoMenu from './PlutoMenu';
import PaginationPluto from './Pagination';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { set } from 'nprogress';

function PlutoInvoices() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [rank, setRank] = useState(0)
    const [type, setType] = useState(0)
    const [invoiceClients, setInvoiceClients] = useState([])
    const [alert, setAlert] = useState({ show: false, type: '', flagReason: '', code: '', pin: '', id: -1 })
    const [alert2, setAlert2] = useState({ show: false, name: '', reward: 0 })
    const [selectedInvoice, setSelectedInvoice] = useState(0);

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    useEffect(() => {
        socket.on('response_get_admin_invoices', (data) => {
            setInvoiceClients(data.invoiceClients)
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 5))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)
            setSelectedInvoice(data.withdrawals.length-1)
            setAlert2({...alert2,
                invoice_id:`INV${new Date().getFullYear()}`,
                invoice_nr: earnings[0]==undefined?'034':earnings[0].invoice_nr==undefined?'034':earnings[0].invoice_nr.includes('0')?'0'+(parseInt(earnings[0].invoice_nr.split('0')[1])+1):(parseInt(earnings[0].invoice_nr.split('0')[1])+1),
                issue_date: ((new Date().getUTCDate()<10)?"0"+new Date().getUTCDate():new Date().getUTCDate())+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear(),
                due_date: (getNextMonthLastDay())+'/'+((new Date().getUTCMonth()+2)<10?"0"+(new Date().getUTCMonth()+2):(new Date().getUTCMonth()+2))+'/'+new Date().getFullYear(),
                delivery_date: ((new Date().getUTCDate()<10)?"0"+new Date().getUTCDate():new Date().getUTCDate())+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear(),
                client_name: '',
                client_address: '',
                client_country: '',
                tax_id: '',
                vat_number: '',
                email_address: '',
                item_name:`Marketing Services for ${months[new Date().getUTCMonth()]} ${new Date().getFullYear()}`,
                items_price:0,
                item_quantity:1,
                item_currency:'USD',
                item_exchange:0,
                item_exchange_to:'RON',
                item_exchange_date:(((new Date().getUTCDate()-1)<10)?"0"+(new Date().getUTCDate()-1):(new Date().getUTCDate()-1))+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear(),
                item_id:1

            })
         
        })
        socket.emit('getAdminInvoices', {
            offers_like: username,
            type,
            page:page,
        })

    }, [])
  
    function generatePDF(name) {
        // We'll use jsPDF for simplicity
        const { jsPDF } = window.jspdf;
        const doc = new jsPDF("portrait", "pt", "a4");
        let width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
    
        doc.html(document.querySelector(".invoice-container"), {
          x: 20, // Adjust X and Y offsets for desired positioning
          y: 20,
          html2canvas: {
            scale: 0.85,
          },
          callback: function (pdf) {
            pdf.save(`${name}.pdf`);
          },
        });
      }
      
      
      const mockDataInvoices = [
        {
          id: 1,
          invoice_id: "INV2024",
          invoice_nr: "004",
          issue_date: "27/02/2024",
          due_date: "31/03/2024",
          delivery_date: "27/02/2024",
          client_name: "MYLEAD CY LTD",
          client_address: `Arch. Makariou III & Evagorou 1-7
          MITSI 3, 1st Floor, Office 102
          1065 Nicosia`,
          client_country: "Cyprus",
          tax_id: "450986",
          vat_number: "CY60026100J",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for February 2024",
              price: 666.15,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9731,
              exchange_to: "RON",
              exchange_date: "26/02/2024",
            },
          ],
        },
        {
          id: 2,
          invoice_id: "INV2024",
          invoice_nr: "005",
          issue_date: "29/02/2024",
          due_date: "31/03/2024",
          delivery_date: "29/02/2024",
          client_name: "HANG MY ADS, LDA",
          client_address: "Rua Poeta Bocage, 2, Lisboa",
          client_country: "Portugal",
          tax_id: "PT513065369",
          vat_number: "PT513065369",
          email_address: "billing@hangmyads.com",
          items: [
            {
              id: 1,
              name: "Marketing Services for January 2024",
              price: 1180.67,
              quantity: 1,
              currency: "USD",
              exchange: 4.5823,
              exchange_to: "RON",
              exchange_date: "28/02/2024",
            },
          ],
        },
        {
          id: 3,
          invoice_id: "INV2024",
          invoice_nr: "006",
          issue_date: "04/03/2024",
          due_date: "31/03/2024",
          delivery_date: "04/03/2024",
          client_name: "Game Media Sp. z o.o.",
          client_address: "Ul. Hubska 52/14, 50-502 Wroclaw",
          client_country: "Poland",
          tax_id: "8992868696",
          vat_number: "PL8992868696",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for January 2024",
              price: 421.78,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9699,
              exchange_to: "RON",
              exchange_date: "01/03/2024",
            },
          ],
        },
        {
          id: 4,
          invoice_id: "INV2024",
          invoice_nr: "007",
          issue_date: "21/03/2024",
          due_date: "30/04/2024",
          delivery_date: "21/03/2024",
          client_name: "AppSamurai Inc.",
          client_address:
            "287 Park Avenue South, Suite 700 2nd Floor, New York, NY 10010",
          client_country: "United States",
          tax_id: "320485642",
          vat_number: "",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for February 2024",
              price: 1155,
              quantity: 1,
              currency: "USD",
              exchange: 4.5885,
              exchange_to: "RON",
              exchange_date: "20/03/2024",
            },
          ],
        },
        {
          id: 5,
          invoice_id: "INV2024",
          invoice_nr: "008",
          issue_date: "25/03/2024",
          due_date: "30/04/2024",
          delivery_date: "25/03/2024",
          client_name: "Game Media Sp. z o.o.",
          client_address: "Ul. Hubska 52/14, 50-502 Wroclaw",
          client_country: "Poland",
          tax_id: "8992868696",
          vat_number: "PL8992868696",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for February 2024",
              price: 814.03,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9727,
              exchange_to: "RON",
              exchange_date: "22/03/2024",
            },
          ],
        },
        {
          id: 6,
          invoice_id: "INV2024",
          invoice_nr: "009",
          issue_date: "25/03/2024",
          due_date: "30/04/2024",
          delivery_date: "25/03/2024",
          client_name: "MYLEAD CY LTD",
          client_address: `Arch. Makariou III & Evagorou 1-7
          MITSI 3, 1st Floor, Office 102
          1065 Nicosia`,
          client_country: "Cyprus",
          tax_id: "450986",
          vat_number: "CY60026100J",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for March 2024",
              price: 1353.24,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9727,
              exchange_to: "RON",
              exchange_date: "22/03/2024",
            },
          ],
        },
        {
          id: 7,
          invoice_id: "INV2024",
          invoice_nr: "010",
          issue_date: "26/03/2024",
          due_date: "30/04/2024",
          delivery_date: "26/03/2024",
          client_name: "HANG MY ADS, LDA",
          client_address: "Rua Poeta Bocage, 2, Lisboa",
          client_country: "Portugal",
          tax_id: "PT513065369",
          vat_number: "PT513065369",
          email_address: "billing@hangmyads.com",
          items: [
            {
              id: 1,
              name: "Marketing Services for February 2024",
              price: 4054.24,
              quantity: 1,
              currency: "USD",
              exchange: 4.5956,
              exchange_to: "RON",
              exchange_date: "25/03/2024",
            },
          ],
        },
        {
          id: 8,
          invoice_id: "INV2024",
          invoice_nr: "011",
          issue_date: "23/04/2024",
          due_date: "31/05/2024",
          delivery_date: "23/04/2024",
          client_name: "MobSuccess SAS",
          client_address: "Rue DU FOUR SEPTEMBRE, 25, Paris",
          client_country: "France",
          tax_id: "809891583",
          vat_number: "FR40809891583",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for March 2024",
              price: 1663.12,
              quantity: 1,
              currency: "USD",
              exchange: 4.6712,
              exchange_to: "RON",
              exchange_date: "22/04/2024",
            },
          ],
        },
        {
          id: 9,
          invoice_id: "INV2024",
          invoice_nr: "012",
          issue_date: "23/04/2024",
          due_date: "31/05/2024",
          delivery_date: "23/04/2024",
          client_name: "AppSamurai Inc.",
          client_address:
            "287 Park Avenue South, Suite 700 2nd Floor, New York, NY 10010",
          client_country: "United States",
          tax_id: "320485642",
          vat_number: "",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for March 2024",
              price: 1207,
              quantity: 1,
              currency: "USD",
              exchange: 4.6712,
              exchange_to: "RON",
              exchange_date: "22/04/2024",
            },
          ],
        },
        {
          id: 10,
          invoice_id: "INV2024",
          invoice_nr: "013",
          issue_date: "01/05/2024",
          due_date: "31/05/2024",
          delivery_date: "01/05/2024",
          client_name: "HANG MY ADS, LDA",
          client_address: "Rua Poeta Bocage, 2, Lisboa",
          client_country: "Portugal",
          tax_id: "PT513065369",
          vat_number: "PT513065369",
          email_address: "billing@hangmyads.com",
          items: [
            {
              id: 1,
              name: "Marketing Services for March 2024",
              price: 4961.41,
              quantity: 1,
              currency: "USD",
              exchange: 4.6361,
              exchange_to: "RON",
              exchange_date: "30/04/2024",
            },
          ],
        },
        {
          id: 11,
          invoice_id: "INV2024",
          invoice_nr: "014",
          issue_date: "01/05/2024",
          due_date: "31/05/2024",
          delivery_date: "01/05/2024",
          client_name: "HANG MY ADS, LDA",
          client_address: "Rua Poeta Bocage, 2, Lisboa",
          client_country: "Portugal",
          tax_id: "PT513065369",
          vat_number: "PT513065369",
          email_address: "billing@hangmyads.com",
          items: [
            {
              id: 1,
              name: "Marketing Services for November 2023",
              price: 9.42,
              quantity: 1,
              currency: "USD",
              exchange: 4.6361,
              exchange_to: "RON",
              exchange_date: "30/04/2024",
            },
            {
              id: 2,
              name: "Marketing Services for December 2023",
              price: 7.04,
              quantity: 1,
              currency: "USD",
              exchange: 4.6361,
              exchange_to: "RON",
              exchange_date: "30/04/2024",
            },
            {
              id: 3,
              name: "Marketing Services for January 2024",
              price: 7.45,
              quantity: 1,
              currency: "USD",
              exchange: 4.6361,
              exchange_to: "RON",
              exchange_date: "30/04/2024",
            },
            {
              id: 4,
              name: "Marketing Services for February 2024",
              price: 321.27,
              quantity: 1,
              currency: "USD",
              exchange: 4.6361,
              exchange_to: "RON",
              exchange_date: "30/04/2024",
            },
            {
              id: 5,
              name: "Marketing Services for March 2024",
              price: 217.25,
              quantity: 1,
              currency: "USD",
              exchange: 4.6361,
              exchange_to: "RON",
              exchange_date: "30/04/2024",
            },
          ],
        },
        {
          id: 12,
          invoice_id: "INV2024",
          invoice_nr: "015",
          issue_date: "17/05/2024",
          due_date: "31/05/2024",
          delivery_date: "17/05/2024",
          client_name: "Revlum d.o.o",
          client_address: "Ilica 338, 10000 Zagreb",
          client_country: "Croatia",
          tax_id: "66898481722",
          vat_number: "HR66898481722",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for January-March 2024",
              price: 3194,
              quantity: 1,
              currency: "USD",
              exchange: 4.5772,
              exchange_to: "RON",
              exchange_date: "16/05/2024",
            },
          ],
        },
        {
          id: 13,
          invoice_id: "INV2024",
          invoice_nr: "016",
          issue_date: "22/05/2024",
          due_date: "30/06/2024",
          delivery_date: "22/05/2024",
          client_name: "HANG MY ADS, LDA",
          client_address: "Rua Poeta Bocage, 2, Lisboa",
          client_country: "Portugal",
          tax_id: "PT513065369",
          vat_number: "PT513065369",
          email_address: "billing@hangmyads.com",
          items: [
            {
              id: 1,
              name: "Marketing Services for April 2024",
              price: 5245.37,
              quantity: 1,
              currency: "USD",
              exchange: 4.5756,
              exchange_to: "RON",
              exchange_date: "21/05/2024",
            },
          ],
        },
        {
          id: 14,
          invoice_id: "INV2024",
          invoice_nr: "017",
          issue_date: "22/05/2024",
          due_date: "30/06/2024",
          delivery_date: "22/05/2024",
          client_name: "MobSuccess SAS",
          client_address: "Rue DU FOUR SEPTEMBRE, 25, Paris",
          client_country: "France",
          tax_id: "809891583",
          vat_number: "FR40809891583",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for April 2024",
              price: 5328.58,
              quantity: 1,
              currency: "USD",
              exchange: 4.5756,
              exchange_to: "RON",
              exchange_date: "21/05/2024",
            },
          ],
        },
        {
          id: 15,
          invoice_id: "INV2024",
          invoice_nr: "018",
          issue_date: "22/05/2024",
          due_date: "30/06/2024",
          delivery_date: "22/05/2024",
          client_name: "Game Media Sp. z o.o.",
          client_address: "Ul. Hubska 52/14, 50-502 Wroclaw",
          client_country: "Poland",
          tax_id: "8992868696",
          vat_number: "PL8992868696",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for April 2024",
              price: 180.40,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9746,
              exchange_to: "RON",
              exchange_date: "21/05/2024",
            },
          ],
        },
        {
          id: 16,
          invoice_id: "INV2024",
          invoice_nr: "019",
          issue_date: "17/06/2024",
          due_date: "30/06/2024",
          delivery_date: "17/06/2024",
          client_name: "Revlum d.o.o",
          client_address: "Ilica 338, 10000 Zagreb",
          client_country: "Croatia",
          tax_id: "66898481722",
          vat_number: "HR66898481722",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for April 2024",
              price: 7835.08,
              quantity: 1,
              currency: "USD",
              exchange: 4.6576,
              exchange_to: "RON",
              exchange_date: "14/06/2024",
            },
          ],
        },
        {
          id: 17,
          invoice_id: "INV2024",
          invoice_nr: "020",
          issue_date: "21/06/2024",
          due_date: "30/06/2024",
          delivery_date: "21/06/2024",
          client_name: "MobSuccess SAS",
          client_address: "Rue DU FOUR SEPTEMBRE, 25, Paris",
          client_country: "France",
          tax_id: "809891583",
          vat_number: "FR40809891583",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for April 2024",
              price: 4982.22,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9770,
              exchange_to: "RON",
              exchange_date: "20/06/2024",
            },
          ],
        },
        {
          id: 18,
          invoice_id: "INV2024",
          invoice_nr: "021",
          issue_date: "21/06/2024",
          due_date: "31/07/2024",
          delivery_date: "21/06/2024",
          client_name: "MobSuccess SAS",
          client_address: "Rue DU FOUR SEPTEMBRE, 25, Paris",
          client_country: "France",
          tax_id: "809891583",
          vat_number: "FR40809891583",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for May 2024",
              price: 4276.58,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9770,
              exchange_to: "RON",
              exchange_date: "20/06/2024",
            },
          ],
        },
        {
          id: 19,
          invoice_id: "INV2024",
          invoice_nr: "022",
          issue_date: "24/06/2024",
          due_date: "31/07/2024",
          delivery_date: "24/06/2024",
          client_name: "MYLEAD CY LTD",
          client_address: `Arch. Makariou III & Evagorou 1-7
          MITSI 3, 1st Floor, Office 102
          1065 Nicosia`,
          client_country: "Cyprus",
          tax_id: "450986",
          vat_number: "CY60026100J",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for May 2024",
              price: 2045.77,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9771,
              exchange_to: "RON",
              exchange_date: "21/06/2024",
            },
          ],
        },
        {
          id: 20,
          invoice_id: "INV2024",
          invoice_nr: "023",
          issue_date: "24/06/2024",
          due_date: "31/07/2024",
          delivery_date: "24/06/2024",
          client_name: "HANG MY ADS, LDA",
          client_address: "Rua Poeta Bocage, 2, Lisboa",
          client_country: "Portugal",
          tax_id: "PT513065369",
          vat_number: "PT513065369",
          email_address: "billing@hangmyads.com",
          items: [
            {
              id: 1,
              name: "Marketing Services for May 2024",
              price: 5699.11,
              quantity: 1,
              currency: "USD",
              exchange: 4.6535,
              exchange_to: "RON",
              exchange_date: "21/06/2024",
            },
          ],
        },
        {
          id: 21,
          invoice_id: "INV2024",
          invoice_nr: "024",
          issue_date: "27/06/2024",
          due_date: "31/07/2024",
          delivery_date: "27/06/2024",
          client_name: "Game Media Sp. z o.o.",
          client_address: "Ul. Hubska 52/14, 50-502 Wroclaw",
          client_country: "Poland",
          tax_id: "8992868696",
          vat_number: "PL8992868696",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for May 2024",
              price: 358.85,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9769,
              exchange_to: "RON",
              exchange_date: "26/06/2024",
            },
          ],
        },
        {
          id: 22,
          invoice_id: "INV2024",
          invoice_nr: "025",
          issue_date: "28/06/2024",
          due_date: "31/07/2024",
          delivery_date: "28/06/2024",
          client_name: "Nedeljka Merdovica B.B.",
          client_address: "84000 Bijelo Polje",
          client_country: "Montenegro",
          tax_id: "03274233",
          // vat_number: "PL8992868696",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for May 2024",
              price: 3335.50,
              quantity: 1,
              currency: "USD",
              exchange: 4.6516,
              exchange_to: "RON",
              exchange_date: "27/06/2024",
            },
          ],
        },
        {
          id: 23,
          invoice_id: "INV2024",
          invoice_nr: "026",
          issue_date: "05/07/2024",
          due_date: "31/07/2024",
          delivery_date: "05/07/2024",
          client_name: "MobSuccess SAS",
          client_address: "Rue DU FOUR SEPTEMBRE, 25, Paris",
          client_country: "France",
          tax_id: "809891583",
          vat_number: "FR40809891583",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for May 2024",
              price: 3649.86,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9772,
              exchange_to: "RON",
              exchange_date: "04/07/2024",
            },
          ],
        },
        {
          id: 24,
          invoice_id: "INV2024",
          invoice_nr: "027",
          issue_date: "08/07/2024",
          due_date: "31/07/2024",
          delivery_date: "08/07/2024",
          client_name: "Revlum d.o.o",
          client_address: "Ilica 338, 10000 Zagreb",
          client_country: "Croatia",
          tax_id: "66898481722",
          vat_number: "HR66898481722",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for April 2024",
              price: 5164.08,
              quantity: 1,
              currency: "USD",
              exchange: 4.5975,
              exchange_to: "RON",
              exchange_date: "05/07/2024",
            },
          ],
        },
        {
          id: 25,
          invoice_id: "INV2024",
          invoice_nr: "028",
          issue_date: "08/07/2024",
          due_date: "31/07/2024",
          delivery_date: "08/07/2024",
          client_name: "Revlum d.o.o",
          client_address: "Ilica 338, 10000 Zagreb",
          client_country: "Croatia",
          tax_id: "66898481722",
          vat_number: "HR66898481722",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for May 2024",
              price: 3825.31,
              quantity: 1,
              currency: "USD",
              exchange: 4.5975,
              exchange_to: "RON",
              exchange_date: "05/07/2024",
            },
          ],
        },
        {
          id: 26,
          invoice_id: "INV2024",
          invoice_nr: "029",
          issue_date: "26/07/2024",
          due_date: "30/08/2024",
          delivery_date: "26/07/2024",
          client_name: "MYLEAD CY LTD",
          client_address: `Arch. Makariou III & Evagorou 1-7
          MITSI 3, 1st Floor, Office 102
          1065 Nicosia`,
          client_country: "Cyprus",
          tax_id: "450986",
          vat_number: "CY60026100J",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for May 2024",
              price: 2760.50,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9690,
              exchange_to: "RON",
              exchange_date: "25/07/2024",
            },
          ],
        },
        {
          id: 27,
          invoice_id: "INV2024",
          invoice_nr: "030",
          issue_date: "26/07/2024",
          due_date: "30/08/2024",
          delivery_date: "26/07/2024",
          client_name: "HANG MY ADS, LDA",
          client_address: "Rua Poeta Bocage, 2, Lisboa",
          client_country: "Portugal",
          tax_id: "PT513065369",
          vat_number: "PT513065369",
          email_address: "billing@hangmyads.com",
          items: [
            {
              id: 1,
              name: "Marketing Services for June 2024",
              price: 6243.24,
              quantity: 1,
              currency: "USD",
              exchange: 4.5785,
              exchange_to: "RON",
              exchange_date: "25/07/2024",
            },
          ],
        },
        {
          id: 28,
          invoice_id: "INV2024",
          invoice_nr: "031",
          issue_date: "26/07/2024",
          due_date: "30/08/2024",
          delivery_date: "26/07/2024",
          client_name: "MobSuccess SAS",
          client_address: "Rue DU FOUR SEPTEMBRE, 25, Paris",
          client_country: "France",
          tax_id: "809891583",
          vat_number: "FR40809891583",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for June 2024",
              price: 2902.85,
              quantity: 1,
              currency: "EUR",
              exchange: 4.9690,
              exchange_to: "RON",
              exchange_date: "25/07/2024",
            },
          ],
        },
        {
          id: 29,
          invoice_id: "INV2024",
          invoice_nr: "032",
          issue_date: "03/08/2024",
          due_date: "30/08/2024",
          delivery_date: "03/08/2024",
          client_name: "Nedeljka Merdovica B.B.",
          client_address: "84000 Bijelo Polje",
          client_country: "Montenegro",
          tax_id: "03274233",
          // vat_number: "PL8992868696",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for June 2024",
              price: 434.33,
              quantity: 1,
              currency: "USD",
              exchange: 4.5995,
              exchange_to: "RON",
              exchange_date: "02/08/2024",
            },
          ],
        },
        {
          id: 30,
          invoice_id: "INV2024",
          invoice_nr: "033",
          issue_date: "01/08/2024",
          due_date: "30/08/2024",
          delivery_date: "03/08/2024",
          client_name: "Revlum d.o.o",
          client_address: "Ilica 338, 10000 Zagreb",
          client_country: "Croatia",
          tax_id: "66898481722",
          vat_number: "HR66898481722",
          email_address: "",
          items: [
            {
              id: 1,
              name: "Marketing Services for June 2024",
              price: 3526.00,
              quantity: 1,
              currency: "USD",
              exchange: 4.5995,
              exchange_to: "RON",
              exchange_date: "02/08/2024",
            },
          ],
        },
      ];
      
      const [items, setItems] = useState({});
    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
       <SweetAlert
                show={alert2.show}
                closeOnClickOutside={false}

                onConfirm={function () {
                    socket.emit('adminAction', {
                        action: 'add_invoice',
                        page: page,
                        ...alert2
                    })
                    setAlert2({ show: false, name: '', reward: 0 })
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Edit"
                showCancel={true}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert2({ show: false, name: '', reward: 0 }) }}
            >
                <>
                    <select style={{ width: '100%', height: '30px', backgroundColor: '#3a3a3a', color: '#f3f3f3', border: 'none', borderBottom: '1px solid #f3f3f3' }} onChange={(e) => {
                    setAlert2({ ...alert2, 
                        invoice_id:`INV${new Date().getFullYear()}`,
                invoice_nr: earnings[0]==undefined?'034':earnings[0].invoice_nr==undefined?'034':earnings[0].invoice_nr.includes('0')?'0'+(parseInt(earnings[0].invoice_nr.split('0')[1])+1):(parseInt(earnings[0].invoice_nr.split('0')[1])+1),
                issue_date: ((new Date().getUTCDate()<10)?"0"+new Date().getUTCDate():new Date().getUTCDate())+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear(),
                due_date: (getNextMonthLastDay())+'/'+((new Date().getUTCMonth()+2)<10?"0"+(new Date().getUTCMonth()+2):(new Date().getUTCMonth()+2))+'/'+new Date().getFullYear(),
                delivery_date: ((new Date().getUTCDate()<10)?"0"+new Date().getUTCDate():new Date().getUTCDate())+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear(),
                client_id: e.currentTarget.value,  ...invoiceClients.filter((client) => client.id == e.currentTarget.value)[0],
                        item_name:`Marketing Services for ${months[new Date().getUTCMonth()]} ${new Date().getFullYear()}`,
                        items_price:0,
                        item_quantity:1,
                        item_currency:'USD',
                        item_exchange:0,
                        item_exchange_to:'RON',
                        item_exchange_date:(((new Date().getUTCDate()-1)<10)?"0"+(new Date().getUTCDate()-1):(new Date().getUTCDate()-1))+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear(),
                        item_id:1

            })
           
                    }}>
                      {invoiceClients.map((client) => {
                        return <option value={client.id}>{client.client_name}</option>
                      })}

                    </select>
                    <a href='https://www.cursbnr.ro/' target="_blank">Curs BNR</a>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice ID</p>
                        <input defaultValue={`INV${new Date().getFullYear()}`} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, invoice_id: e.currentTarget.value==''?`INV${new Date().getFullYear()}`:e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                        <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Number</p>
                        <input defaultValue={earnings[0]==undefined?'034':earnings[0].invoice_nr==undefined?'034':earnings[0].invoice_nr.includes('0')?'0'+(parseInt(earnings[0].invoice_nr.split('0')[1])+1):(parseInt(earnings[0].invoice_nr.split('0')[1])+1)} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, invoice_nr: e.currentTarget.value==''?(earnings[0]==undefined?'034':earnings[0].invoice_nr==undefined?'034':earnings[0].invoice_nr.includes('0')?'0'+(parseInt(earnings[0].invoice_nr.split('0')[1])+1):(parseInt(earnings[0].invoice_nr.split('0')[1])+1)):e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Issue Date</p>
                        <input defaultValue={((new Date().getUTCDate()<10)?"0"+new Date().getUTCDate():new Date().getUTCDate())+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear()} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, issue_date: e.currentTarget.value==''?((new Date().getUTCDate()<10)?"0"+new Date().getUTCDate():new Date().getUTCDate())+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear():e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                            <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Due Date</p>
                        <input defaultValue={(getNextMonthLastDay())+'/'+((new Date().getUTCMonth()+2)<10?"0"+(new Date().getUTCMonth()+2):(new Date().getUTCMonth()+2))+'/'+new Date().getFullYear()} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, due_date: e.currentTarget.value==''?(getNextMonthLastDay())+'/'+((new Date().getUTCMonth()+2)<10?"0"+(new Date().getUTCMonth()+2):(new Date().getUTCMonth()+2))+'/'+new Date().getFullYear():e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                            <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Delivery Date</p>
                        <input defaultValue={((new Date().getUTCDate()<10)?"0"+new Date().getUTCDate():new Date().getUTCDate())+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear()}  name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, delivery_date: e.currentTarget.value==''?((new Date().getUTCDate()<10)?"0"+new Date().getUTCDate():new Date().getUTCDate())+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear():e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Client Name</p>
                        <input defaultValue={alert2.client_name==undefined?'':alert2.client_name} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, client_name: e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Client Address</p>
                        <input defaultValue={alert2.client_address==undefined?'':alert2.client_address} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, client_address: e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                      
                           <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Client Country</p>
                        <input defaultValue={alert2.client_country==undefined?'':alert2.client_country} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, client_country: e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                          <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Client Tax ID</p>
                        <input defaultValue={alert2.tax_id==undefined?'':alert2.tax_id} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, tax_id: e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Client Vat ID</p>
                        <input defaultValue={alert2.vat_number==undefined?'':alert2.vat_number} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, vat_number: e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Client Email</p>
                        <input defaultValue={alert2.email_address==undefined?'':alert2.email_address} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, email_address: e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Item ID</p>
                        <input defaultValue={1} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, item_id: e.currentTarget.value==''?1:e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                          <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Item Name</p>
                        <input defaultValue={`Marketing Services for [month] ${new Date().getFullYear()}`} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, item_name: e.currentTarget.value==''?`Marketing Services for [month] ${new Date().getFullYear()}`:e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                           <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Item Price</p>
                        <input defaultValue={0} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, items_price: e.currentTarget.value==''?0:e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                        <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Item Quantity</p>
                        <input defaultValue={1} name="input-name" onChange={(e) => {
                            setItems({ ...items, quantity: e.currentTarget.value==''?1:e.currentTarget.value })
                            setAlert2({ ...alert2, item_quantity: e.currentTarget.value==''?1:e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                        <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Item Currency</p>
                        <input defaultValue={'USD'} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, item_currency: e.currentTarget.value==''?'USD':e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Item Exchange</p>
                        <input defaultValue={4.0000} name="input-name" onChange={(e) => {
                            setItems({ ...items, exchange: e.currentTarget.value==''?4.0000:e.currentTarget.value })
                            setAlert2({ ...alert2, item_exchange:  e.currentTarget.value==''?4.0000:e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Item Exchange To</p>
                        <input defaultValue={'RON'} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, item_exchange_to:  e.currentTarget.value==''?'RON':e.currentTarget.value  })
                        }} type={'text'} className="input_field" />
                         <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Invoice Item Exchange Date</p>
                        <input defaultValue={(((new Date().getUTCDate()-1)<10)?"0"+(new Date().getUTCDate()-1):new Date().getUTCDate()-1)+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear()}  name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, item_exchange_date: e.currentTarget.value==''?(((new Date().getUTCDate()-1)<10)?"0"+(new Date().getUTCDate()-1):new Date().getUTCDate()-1)+'/'+((new Date().getUTCMonth()+1)<10?"0"+(new Date().getUTCMonth()+1):(new Date().getUTCMonth()+1))+'/'+new Date().getFullYear():e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                    </div>
       
                </>

            </SweetAlert>
            <SweetAlert
                show={alert.show}
                closeOnClickOutside={false}
                onConfirm={function () {
                    socket.emit('adminAction', {
                        action: 'editPaidCashOffer',
                        id: alert.id,
                        page: page,
                        dataOffer: { ...alert }
                    })
                    setAlert({ show: false, type: '', flagReason: '', code: '', pin: '', id: -1 })
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Edit"
                showCancel={true}
                onClickOutside={() => { }}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert({ show: false }) }}
            >
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {Object.keys(alert).map((x, i) => {
                            if (x != 'show') {
                                if (x != 'id') {
                                    return <>
                                        <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>{x}</p>
                                        <input placeholder={x} name="input-name" onChange={(e) => {
                                            setAlert({ ...alert, [x]: e.currentTarget.value })
                                        }} defaultValue={Object.values(alert)[i]} type={'text'} className="input_field" />
                                    </>
                                }
                            }
                        })}
                    </div>
                </>

            </SweetAlert>
            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {userProfile.withdrawalsLength != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.withdrawalsLength)}
                            </p>
                            <p>Count invoices</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}


                </div>
                <div onClick={() => {
                    setAlert2({ show: true, name: '', reward: 0 })
                }} className="btn button-disabled" style={{ minWidth: '100px', height: '15px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px', marginBottom: '5px', }} >Add new</div>

                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Suspect Offers</p>
                    <div className="input-container">
                        <input style={{ borderRadius: '5px', background: 'transparent', marginBottom: '5px', color: '#fff', fontSize: '12px', padding: '5px' }} onChange={(e) => {
                            setUsername(e.currentTarget.value)
                            setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

                            socket.emit('getAdminInvoices', {
                                offers_like: e.currentTarget.value.toLowerCase(),
                                 type,
                                page: 1,
                            })

                        }} type="text" name="amount" placeholder="Search by offer name" autoComplete="off" />
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '200px' }}>INV</div>
                            <div style={{ minWidth: '90px' }}>Client</div>
                            <div style={{ minWidth: '50px' }}>Amount</div>
                            <div style={{ minWidth: '50px' }}>Download</div>
                            <div style={{ minWidth: '50px' }}>Select</div>


                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={i} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ minWidth: '200px' }} >{earning.invoice_id}{earning.invoice_nr}</div>
                                <div style={{ minWidth: '90px', }} >{earning.client_name || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', }} >{earnings==undefined?'':earnings.length==0?'':earnings[0]==0?'':earning.items[0].currency} {earnings==undefined?'':earnings.length==0?'':earnings[0]==0?'':earning.items[0].price}</div>
                                <div style={{ minWidth: '50px', }} onClick={()=>{
                                    generatePDF(`${earning.invoice_id}${earning.invoice_nr}`);
                                }} >Download</div>
                                <div style={{ minWidth: '50px', }} onClick={()=>{
                                    setSelectedInvoice(i)
                                }} >Select</div>

                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getAdminInvoices', {
                        offers_like: username,
                        type,
                        page,
                    })
                }} />}

            </div>
            {earnings==undefined?'':earnings.length==0?'':earnings[0]==0?'':<div className="container-inv" style={{ letterSpacing: "10px" }}>
        <div
          className="invoice-container"
          id="frame"
          frameBorder={0}
          sandbox="allow-same-origin"
          style={{ letterSpacing: "0.9px" }}
        >
          <div id="header-inv">
            <div>
              <h1>
                Invoice &nbsp;{earnings[selectedInvoice].invoice_id}
                {earnings[selectedInvoice].invoice_nr}
              </h1>
              <div className="invoice-short-info">
                <dt className="label">Issue date:</dt>
                <dd className="text-black">
                  {earnings[selectedInvoice].issue_date}
                </dd>
                <dt className="label">Due date:</dt>
                <dd className="text-black">
                  {earnings[selectedInvoice].due_date}
                </dd>
                <dt className="label">Delivery date:</dt>
                <dd className="text-black">
                  {earnings[selectedInvoice].delivery_date}
                </dd>
              </div>
            </div>
          </div>
          <table className="bodypart-inv">
            <thead>
              <tr>
                <td>
                  <div id="header-inv-space">&nbsp;</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {/* Issuer and recipient info */}
                  <div className="issuer-recipient-block">
                    <div>
                      {/* Issuer info */}
                      <div className="label">Issuer</div>
                      <div className="address-block">
                        <div className="title">
                          LOMORA LAURENTIU PERSOANA FIZICA AUTORIZATA
                        </div>
                        <div>
                          <span>Sfântul Francisc, 68</span>
                        </div>
                        <div>
                          <span>607437 </span>
                          SAT PUSTIANA, COMUNA PARJOL
                        </div>
                        <div>Bacau</div>
                        <div>Romania</div>
                      </div>
                      <div>
                        <span className="label">Commercee number:</span>{" "}
                        F4/81/2021
                      </div>
                      <div>
                        <span className="label">TAX ID:</span> RO43681925
                      </div>
                      <div>
                        <span className="label">Phone number:</span> 0753461170
                      </div>
                      <div>
                        <span className="label">E-mail:</span>
                        lomoralaurentiu1@gmail.com
                      </div>
                    </div>
                    <div>
                      {/* Recipient info */}
                      <div className="label">Client</div>
                      <div className="address-block">
                        <div className="title">
                          {earnings[selectedInvoice].client_name}
                        </div>
                        <div>
                          <span>
                            {earnings[selectedInvoice].client_address}
                          </span>
                        </div>
                        {/* <div>Lisboa</div> */}
                        <div>
                          {earnings[selectedInvoice].client_country}
                        </div>
                      </div>
                      <div>
                        <span className="label">TAX ID:</span>{" "}
                        {earnings[selectedInvoice].tax_id}
                      </div>
                      {earnings[selectedInvoice].vat_number == "" ? (
                        ""
                      ) : (
                        <div>
                          <span className="label">VAT Number:</span>{" "}
                          {earnings[selectedInvoice].vat_number}
                        </div>
                      )}
                      <div>
                        {earnings[selectedInvoice].email_address ==
                        "" ? (
                          ""
                        ) : (
                          <>
                            <span className="label">E-mail:</span>
                            {earnings[selectedInvoice].email_address}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="payment-info-title">Payment info</div>
                  <div className="payment-info">
                    <div className="payment-info-left">
                      <div className="payment-info-rows">
                        <div>
                          <span className="label">IBAN / Account number:</span>
                          RO90RNCB0029169283620001
                        </div>
                        <div>
                          <span className="label">Bank name:</span> Banca
                          Comerciala Romana
                        </div>
                        <div>
                          <span className="label">SWIFT / BIC code:</span>{" "}
                          RNCBROBUXXX
                        </div>
                        <div>
                          <span className="label">Payment description:</span>
                          {earnings[selectedInvoice].invoice_id}
                          {earnings[selectedInvoice].invoice_nr}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Items */}
                  <table className="header-rounded-corners invoice-items">
                    <thead>
                      <tr className="purple-bg">
                        <th>Item name</th>
                        <th style={{ width: "16%" }}>Quantity</th>
                        <th style={{ width: "16%" }}>Units</th>
                        <th style={{ width: "16%" }}>
                          <span>Unit price</span>,{" "}
                          {earnings[selectedInvoice].items[0].currency}
                        </th>
                        <th style={{ width: "12%" }}>
                          <span>Subtotal</span>,{" "}
                          {earnings[selectedInvoice].items[0].currency}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {earnings[selectedInvoice].items.map((item) => {
                        return (
                          <tr className='invoice-item'>
                            <td>
                              <div>{item.name}</div>
                            </td>
                            <td>{item.quantity}</td>
                            <td />
                            <td>{numberWithCommas(item.price)}</td>
                            <td>
                              {numberWithCommas(item.quantity * item.price)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/*Summary and additional info*/}
                  <div className="summary-additional-info">
                    {/*Additional info*/}
                    <div />
                    {/*Summary*/}
                    <div>
                      <div className="summary-table">
                        <div className="total-amount-item">
                          <div className="total-amount">Total:</div>
                          <div className="total-amount">
                            {
                              earnings[selectedInvoice].items[0]
                                .currency
                            }
                            &nbsp;
                            {numberWithCommas(
                              earnings[selectedInvoice].items
                                .map((item) => item.quantity * item.price)
                                .reduce((a, b) => a + b, 0)
                                .toFixed(2)
                            )}
                          </div>
                        </div>
                        <div className="exchange-rate-item">
                          <div>
                            1{" "}
                            {
                              earnings[selectedInvoice].items[0]
                                .currency
                            }{" "}
                            ={" "}
                            {
                              earnings[selectedInvoice].items[0]
                                .exchange_to
                            }
                            &nbsp;
                            {
                              earnings[selectedInvoice].items[0]
                                .exchange
                            }
                          </div>
                          <div>
                            {
                              earnings[selectedInvoice].items[0]
                                .exchange_to
                            }
                            &nbsp;
                            {numberWithCommas(
                              parseFloat(
                                earnings[selectedInvoice].items
                                  .map((item) => item.quantity * item.price)
                                  .reduce((a, b) => a + b, 0)
                                  .toFixed(2) *
                                  earnings[selectedInvoice].items[0]
                                    .exchange
                              ).toFixed(2)
                            )}
                          </div>
                        </div>
                        <div className="exchange-rate-item">
                          <div>Exchange date:</div>
                          <div>
                            {
                              earnings[selectedInvoice].items[0]
                                .exchange_date
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div id="footer-inv-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div id="footer-inv">
            <div className="footer-left">
              <div>
                The invoice is valid without signature and stamp, according to
                art. 319 paragraph (29) of Law no. 227/2015 regarding the Fiscal
                Code.
              </div>
              <div>
                VAT exempted, as per art. 310 of Law 227/2015 regarding the
                Fiscal Code.
              </div>
            </div>
          </div>
        </div>
      </div>}
            <div >
                <input onKeyDown={
                    (e) => {
                        if (e.key == 'Enter') {
                            canUsePagination(false)
                            setEarnings([0, 0, 0, 0, 0])
                            socket.emit('getAdminInvoices', {
                                offers_like: username,
                                type,
                                page: document.getElementById('jumptotoday3').value,
                            })
                        }
                    }

                } type="number" id="jumptotoday3" style={{ background: 'gray', color: '#000' }} />
                <button onClick={() => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getAdminInvoices', {
                        offers_like: username,
                        type,
                        page: document.getElementById('jumptotoday3').value,
                    })
                }
                } style={{ color: '#fff', background: '#000' }}>Go to page</button>
            </div>

        </SkeletonTheme>
    )
}

export default PlutoInvoices