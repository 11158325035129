import ProgressiveImage from "react-progressive-image-loading"


export const ItemEarningFeed = (props) => {
    return (
        <div onClick={props.onClick} className="earning-feed-item">
            <div className="earning-feed-item-image">
                <img src={`https://api.dicebear.com/9.x/fun-emoji/svg?seed=${props.photo}`} alt="Earning Feed Item" />
            </div>
            <div className="earning-feed-item-content">
                <p className="earning-feed-item-content-title">HangMyAds</p>
                <p className="earning-feed-item-content-description">Coin Flip Switch</p>
            </div>
            <div className="earning-feed-item-reward">
                <div className="earning-feed-item-reward-image"> 
                    <ProgressiveImage
                        preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        src="/assets/img/faucetify_coin_small.png"
                        render={(src, style) => <img alt="coin image" className="earning-feed-item-reward-image-coin" style={style}
                            loading="lazy" src={src} />}
                    />
                </div>
                <p className="earning-feed-item-reward-amount">{props.amount}</p>
            </div>
        </div>
    )
}