import { useEffect, useState } from "react"
import { socket } from "../components/Containers/MainContainer"
import { AnnouncementsContainer } from "./Announcements.Container"
import { PromotedSmallOfferContainer } from "./PromotedSmallOffer.Container"
import { WallsContainer } from "./Walls.Container"
import Skeleton from "react-loading-skeleton"
import { Link } from "react-router-dom"


export const EarnHomeContainer = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const [offerCategories, setOfferCategories] = useState([])
    useEffect(() => {
        socket.emit('fetchCountCategoryOffers', {});
        socket.on('emitCountCategoryOffers', (data) => {
            let count = 0;
            data.offers.forEach(element => {
                count += parseInt(element.count);
            });
            let c = data.offers.reverse();
            c = data.offers.reverse();
            setOfferCategories(c.map(x => { return { name: x.category, count: x.count } }));
        });
    }, [])
    return (
        <div style={{display:'flex', flexDirection:'column', gap:'20px'}}>
            {/* <AnnouncementsContainer /> */}
            <PromotedSmallOfferContainer  titlex='High Earning Offers'/>
            <div className='cat-earn-mob'>
            {offerCategories.length == 0 ? <Skeleton width={2000} height={32} /> : [{name:'all'},...offerCategories].map((item, index) => {
                    return (
                        <Link className={`${props.page==undefined?'':props.page.toLowerCase() == "earn" && item.name.toLowerCase() == "home" ? 'sel' :
                            props.page.toLowerCase() == "offers" && item.name.toLowerCase() == "all" ? 'sel' :
                                props.page.toLowerCase().includes(item.name.toLowerCase()) ? 'sel' : ''}`} to={`${(item.name.toLowerCase() == "home" ? 'earn' : item.name.toLowerCase() == "all" ? 'offers' : `offers/${item.name.toLowerCase().split(" ").join("-")}`)}`} key={index}>
                            <div className={`cat-earn-mob-item `}>
                                <div className='cat-earn-mob-item-name'>{item.name}</div>
                            </div>
                        </Link>
                    )
                })
                }
                </div>
            <PromotedSmallOfferContainer />
            <WallsContainer  for='offerwall' title='Offer Walls' logged={props.loggedIN} />
            <WallsContainer for='surveywall' title='Survey Walls' logged={props.loggedIN} />
            {/* <PromotedSurveysContainer /> */}
        </div>
    )
}