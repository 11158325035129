
import { useState, useEffect, createRef } from 'react'
import { useParams, useLocation, Link, Navigate, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { FaAd, FaArrowLeft, FaAward, FaCashRegister, FaChalkboardTeacher, FaClipboardCheck, FaCoins, FaDollarSign, FaEye, FaFacebook, FaInstagram, FaLink, FaQuestion, FaQuestionCircle, FaSortNumericDown, FaStopwatch, FaTelegram, FaTwitter, FaUsb } from "react-icons/fa";

import { GiPartyPopper } from "react-icons/gi";
import { toast } from "react-toastify";
import { ChangeStateUrl, CreateToast, getChatStatus, getUserID } from '../../components/utils';
import queryString from 'query-string';
import { socket } from '../../components/Containers/MainContainer';
import PTCTestLink from './PTCTestLink';
import '../../assets/style/PTC.css'

const durationPrice = [[7, 1.1], [10, 2.1], [15, 5.1], [20, 8.1], [40, 14.1], [60, 20.1]]
const rerunAdsOptions = [[1, 0], [2, 0.03], [3, 0.15], [7, 0.25], [15, 0.25], [30, 0.35], [-1, 0.5]]
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+\b)/g, ",");
}
function isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
};

function Advertise() {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const STYLE = {
        input: { width: '100%', backgroundColor: '#0e151e', color: '#7a7a7a', border: 'none', padding: '11px 9px 12px 9px', fontSize: '13px', outline: 'none', borderRadius: 'var(--default-border-radius)' }
    }

    const [showTestLink, setTestLink] = useState(false);
    const [adtitle, setadtitle] = useState("")
    const [addescription, setaddescription] = useState('')
    const [adlink, setadlink] = useState('');
    const [showOffer, setShowOffer] = useState({ show: false, data: {} });
    const { state } = useLocation();
    const statusChat = state == null ? undefined : state.chatStatus;
    const [showChat, setShowChat] = useState(statusChat == undefined ? false : statusChat)

    const [showSocialInputs, setShowSocialInputs] = useState(false);
    const [showDailyInput, setShowDailyInput] = useState(false);
    const [isPromoted, setIsPromoted] = useState(false);
    const [CPC, setCPC] = useState(durationPrice[0][1]);
    const [clicksNumber, setClicksNumber] = useState(2000);
    const [allowCreate, setAllowCreate] = useState(true);
    const [waitingTime, setWaitingTime] = useState(durationPrice[0][0]);
    const [durationCPC, setdurationCPC] = useState(durationPrice[0][1]);
    const [dailyLimit, setDailyLimit] = useState(2500)
    const [facebookLink, setfblink] = useState("");
    const [twiteerlink, settwlink] = useState("");
    const [instagramlink, setinstalink] = useState("");
    const [telegramLink, settelegramlink] = useState("");
    const [chatStatus, setChatStatus] = useState(getChatStatus())
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalPricePromoted, setTotalPricePromoted] = useState(0);
    const [totalPriceSocial, setTotalPriceSocial] = useState(0);
    const [usdValueFaucetPay, setUsdValueFaucetPay] = useState(0);
    const [coinsBalance, setCoinsBalance] = useState(0);
    const [canConvert, setCanConvert] = useState(true);
    const [canDeposit, setCanDeposit] = useState(false);
    const [tokens, setTokens] = useState(0);
    const creaRef = createRef();
    const [type, setType] = useState(0)
    const navigate = useNavigate();
    useEffect(() => {
        window.addEventListener('showChat', () => {
            setChatStatus(getChatStatus())
        })
            ;
        socket.on('allowConvertPTC', (data) => {
            setCanConvert(true)
        })
        socket.on('allowCreatePTC', (data) => {
            setAllowCreate(true)
        })
        socket.on('allowCreatePTCSuccess', (data) => {
            document.querySelectorAll('input').forEach(x => x.value = "")
            setadlink('')
            setadtitle('')
            setaddescription('')
            setClicksNumber(2000)
            setCPC(durationPrice[0][1])

            setWaitingTime(durationPrice[0][0])
            setdurationCPC(durationPrice[0][1])
            setDailyLimit(2500)
            setfblink('')
            settwlink('')
            setinstalink('')
            settelegramlink('')
            setTotalPrice(0)
            setTotalPricePromoted(0)
            setTotalPriceSocial(0)
            setUsdValueFaucetPay(0)
            setCoinsBalance(0)
            setCanConvert(true)
            setAllowCreate(true)
            navigate('/ptc/campaigns')
        })
        socket.on('updateTokens', (data) => {
            setTokens(data.amount)
        })
        socket.emit('ptc_ads_create_data_get')
        socket.on('ptc_ads_create_data', (data) => {
            setTokens(data.amount)
        })
        let params = queryString.parse(window.location.search)
        if (params.faucetpay != undefined) {

            setTimeout(() => {
                if (params.faucetpay == "success") {
                    CreateToast("FaucetPay Deposit successful", creaRef, 0)
                    ChangeStateUrl('')

                }
                if (params.faucetpay == "canceled") {
                    CreateToast("FaucetPay Deposit canceled", creaRef, 1)
                    ChangeStateUrl('')
                }
            }, 0)
        }
    }, [])

    useEffect(() => {
        setTotalPrice(clicksNumber * CPC)
        let price = parseInt(clicksNumber) * parseInt(CPC);
        if (isPromoted == true) {
            setTotalPricePromoted(parseInt(0.1 * (price)))
        } else {
            setTotalPricePromoted(0)
        }
        if (showSocialInputs == true) {
            setTotalPriceSocial(parseInt(0.1 * (price)))
        } else {
            setTotalPriceSocial(0)
        }
    }, [clicksNumber, CPC, isPromoted, showSocialInputs])
    const { referral_id, page, second_page, third_page } = useParams();
    function hideModal() {
        setTestLink(false)
    }
    return (
        <div className={`main-container`}>
            <div className='cat-earn-mob' style={{ display: 'flex', margin:'0px' }}>
                <Link className={`${second_page== undefined  || second_page==''? 'sel' : ''}`} to={`/advertise`} key={-1}>
                    <div className={`cat-earn-mob-item `}>
                        <div className='cat-earn-mob-item-name'>Create Ads</div>
                    </div>
                </Link>
                <Link className={`${second_page==undefined?'':second_page.toLowerCase() == "ads" ? 'sel' : ''}`} to={`/advertise/campaigns`} key={-1}>
                    <div className={`cat-earn-mob-item `} style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                        <div className='cat-earn-mob-item-name'>Campaigns</div>
                    </div>
                </Link>
                <Link className={`${second_page==undefined?'':second_page.toLowerCase() == "ads" ? 'sel' : ''}`} to={`/advertise/deposits`} key={-1}>
                    <div className={`cat-earn-mob-item `} style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                        <div className='cat-earn-mob-item-name'>Deposits</div>
                    </div>
                </Link>
            </div>
            {showTestLink == true ? <PTCTestLink link={adlink} click={()=>{setTestLink(false)}} /> : ''}


            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div className='add-balance-ptc-banner'>
                    <div>
                        <h1 style={{ color: 'white', fontSize: '20px', marginBottom: '0px' }}>Deposit From Balance</h1>
                        <p style={{ color: '#8c8a8a', fontSize: '12px', marginTop: '0px', marginBottom: '2px' }}>You will receive <p style={{ color: 'var(--bs-info)', display: 'inline-block' }}>{coinsBalance == '' || coinsBalance == undefined ? 0 : numberWithCommas(coinsBalance)} tokens</p></p>
                    </div>
                    <div style={{ width: '100%' }}>
                    <div className='input-container'>
                    <div className="input-icon"><FaSortNumericDown/></div>
                        <input onChange={(e) => { setCoinsBalance(e.target.value) }} placeholder='Amount in Coins' id="amountCoins" type="number" name="amount"  />
                        </div>
                    </div>
                    <div onClick={(e) => {
                        if (coinsBalance > 0) {
                            if (canConvert == true) {
                                setCanConvert(false)
                                socket.emit('userAction', {
                                    action: 'convertBalancePTC',
                                    amount: coinsBalance,
                                })
                                setCoinsBalance(0)
                                document.querySelector('#amountCoins').value = 0
                            }
                        }
                    }} className="payout-button" style={{ background: canConvert == true ? 'var(--text-brand-2)' : '#25292a', height: '33px', fontSize: '12px', width: '100%' }}>
                        Convert Balance
                    </div>
                </div>
                <div className='add-balance-ptc-banner'>
                    <div>
                        <h1 style={{ color: 'white', fontSize: '20px', marginBottom: '0px' }}>Deposit With FaucetPay</h1>
                        <p style={{ color: '#8c8a8a', fontSize: '12px', marginTop: '0px', marginBottom: '2px' }}>You will receive <p style={{ color: 'var(--bs-info)', display: 'inline-block' }}>{numberWithCommas(parseInt(usdValueFaucetPay * 1000))} tokens</p></p>
                    </div>
                    <div style={{ width: '100%' }}>
                    <div className='input-container'>
                    <div className="input-icon"><FaDollarSign/></div>
                        <input onChange={(e) => {
                            setUsdValueFaucetPay(e.target.value)
                        }} placeholder='Amount in USD' id="amount" type="number" name="amount"  />
                        </div>
                    </div>
                    <form onChange={(e) => {
                    }} action="https://faucetpay.io/merchant/webscr" id='faucetpay' method="post">
                        <input type="hidden" name="merchant_username" value="lawr18" />
                        <input type="hidden" name="item_description" value={`Advertising balance deposit ${usdValueFaucetPay} USD`} />
                        <input type="hidden" name="amount1" value={usdValueFaucetPay} />
                        <input type="hidden" name="currency1" value="USD" />
                        <input type="hidden" name="custom" value={getUserID()} />
                        <input type="hidden" name="callback_url" value="https://util.paidcash.co/ptc/create/callback" />
                        <input type="hidden" name="success_url" value="https://util.paidcash.co/ptc/create/success" />
                        <input type="hidden" name="cancel_url" value="https://util.paidcash.co/ptc/create/canceled" />
                        <input type="submit" name="submit" value="Deposit Via FaucetPay" onClick={(e) => {
                            if (usdValueFaucetPay > 0) {
                                e.currentTarget.submit()
                            } else {
                                e.preventDefault()

                                CreateToast("Minimum amount is 1 USD", creaRef, 1);

                            }
                        }} className="payout-button" style={{ height: '33px', fontSize: '12px', width: '100%', border: 'none' }} />
                    </form>

                </div>
            </div>
            <div style={{ background: 'var(--bg-color-3)', padding: '5px 10px', borderRadius: 'var(--default-border-radius)', paddingBottom: '15px', display:'flex', flexDirection:'column', padding:'20px', gap:'10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                    <div onClick={() => {
                        setType(0)
                    }} className='add-balance-ptc-banner' style={{ border: type == 0 ? '2px solid var(--text-brand-2)' : '2px solid gray', cursor: 'pointer' }}>
                        <div>
                            <h1 style={{ color: 'white', fontSize: '20px', marginBottom: '0px' }}>Frameless Ads</h1>
                            <p style={{ color: '#8c8a8a', fontSize: '12px', marginTop: '0px', marginBottom: '2px' }}>It will be opened in a new tab without iframe.</p>
                        </div>
                    </div>
                    <div onClick={() => {
                        setType(1)
                    }} className='add-balance-ptc-banner' style={{ border: type == 1 ? '2px solid var(--text-brand-2)' : '2px solid gray', cursor: 'pointer' }}>
                        <div>
                            <h1 style={{ color: 'white', fontSize: '20px', marginBottom: '0px' }}>Framed Ads</h1>
                            <p style={{ color: '#8c8a8a', fontSize: '12px', marginTop: '0px', marginBottom: '2px' }}>It will be opened in a new tab with iframe.</p>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-between' }}>
                    {/* <div className="payout-price" style={{marginBottom:'20px', height:'40px'}}>
                        Advertiser Balance: 
                            <div>
                                {0.05}
                                <FaDollarSign style={{marginLeft:'3px', marginTop:'-4px'}}/>
                            </div>

                        </div>  */}


                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <label htmlFor="url" style={{ color: 'white', padding: '8px 0 8px 2px' }}>URL
                        {type == 1 ? <> -
                            <div onClick={(e) => {
                                if (isValidURL(adlink) && (adlink.includes("https") || adlink.includes("http"))) {
                                    setTestLink(true)
                                } else {
                                    CreateToast(
                                        "Link provided is not valid.", creaRef, 1);
                                }
                            }} style={{ textDecoration: 'none', color: 'rgb(1, 200, 214)', marginLeft: '5px', cursor: 'pointer', display: 'inline-block' }}> test link </div></> : ''}</label>
                    <div style={{ fontSize: '12px', color: '#f3f3f3', background: 'var(--bg-color-1)', borderRadius: 'var(--default-border-radius)', padding: '7px 10px' }}>
                        Ad Balance: <p style={{ color: 'var(--bs-info)', display: 'inline-block', marginBottom:'0px' }}>{numberWithCommas(tokens)} tokens</p>
                    </div>
                </div>
                <div className='input-container'>
                        <div className="input-icon"><FaLink/></div>
                        <input onChange={(e) => {
                        setadlink(e.target.value)
                        }} placeholder='https://' id="url" type="text" name="url"  />
                    </div>
                                <label htmlFor="title" style={{ color: 'white', padding: '8px 0 8px 2px' }}>Title </label>
                                <div className='input-container'>
                        <div className="input-icon"><FaChalkboardTeacher/></div>
                        <input onChange={(e) => {
                    if (e.target.value.length <= 25) {
                        setadtitle(e.target.value)
                    } else {
                        e.target.value = e.target.value.substring(0, 25)
                    }
                }} placeholder='Title shown to users - minimum 4 characters, maximum 25' id="title" type="text" name="title"  />
                    </div>
               
                <label htmlFor="description" style={{ color: 'white', padding: '8px 0 8px 2px' }}>Description </label>
                <div className='input-container'>
                        <div className="input-icon"><FaChalkboardTeacher/></div>
                       
                <input onChange={(e) => {
                    if (e.target.value.length <= 100) {
                        setaddescription(e.target.value)
                    } else {
                        e.target.value = e.target.value.substring(0, 100)
                    }
                }} placeholder='Description shown to users - minimum 10 characters, maximum 100' id="description" type="text" name="description" />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-between' }}>
                    <div style={{ width: '49%', minWidth: '250px' }}>
                        <label htmlFor="clicks" style={{ color: 'white', padding: '8px 0 8px 2px' }}>Clicks </label>
                        <div className='input-container'>
                        <div className="input-icon"><FaClipboardCheck/></div>
                        <input onChange={(e) => {
                            setClicksNumber(parseInt(e.target.value))
                        }} min="2000" defaultValue="2000" id="clicks" type="number" name="clicks"  />
                        </div>
                    </div>
                    <div style={{ width: '49%', minWidth: '250px' }}>
                        <label htmlFor="duration" style={{ color: 'white', padding: '8px 0 8px 2px' }}>Duration </label>
                        <div className='input-container'>
                        <div className="input-icon"><FaClipboardCheck/></div>
                        <select onChange={(e) => {
                            setCPC(durationPrice[e.target.value][1])
                            setWaitingTime(durationPrice[e.target.value][0])
                            setdurationCPC(durationPrice[e.target.value][1])
                        }} id="duration" >
                            {durationPrice.map((x, i) => {
                                return <option value={i}>{x[0]} seconds. CPC - {x[1]} Tokens</option>
                            })}
                        </select>
                        </div>
                    </div>
                </div>
                {/* <div>
                            <input onChange={(e)=>{
                                setShowRerunInput(!showRerunInput)
                                if(showRerunInput==false){
                                    setTotalCPC(totalCPC+parseFloat(parseFloat(rerunPercent*CPC).toFixed(0)))
                                }else{
                                    setTotalCPC(totalCPC-parseFloat(parseFloat(rerunPercent*CPC).toFixed(0)))
                                    setRerunPercent(rerunAdsOptions[0][1])
                                }
                            }} autoComplete='off' type="checkbox"  name="remember" style={{marginBottom:'0px', height:'25px'}} id="box-1"/>
                            <label for="box-1" style={{color:'#959595', margin:'20px 3px', fontSize:'14px', display:'flex',  width:'auto', display:'inline-block',
                            justifyContent:'start', alignItems:'start'}}>
                            <p style={{marginBottom:'0px', marginTop:'1px', marginLeft:'3px', color:'white', fontSize:'15px'}}> Rerun Ads</p>
                            </label>
                            <Tooltip style={{backgroundColor:"transparent", width:'20px', cursor:'pointer', marginLeft:'4px'}} trigger="mouseenter"  hideDelay="2000"  position="right"
                            html={(<div style={{fontSize:'12px', maxWidth:'200px'}}>This feature indicates that when the ads will be displayed to the visitors again after ads click. </div>
                            )}><FaQuestionCircle style={{fill:'#d65d31', fontSize:'15px', marginTop:'-3.5px'}}/></Tooltip>
                            {showRerunInput==true?<select onChange={(e)=>{
                                    console.log(rerunAdsOptions[e.target.value][1])
                                    let total = totalCPC;
                                    total -=parseFloat(parseFloat(rerunPercent*CPC).toFixed(0))
                                    setRerunPercent(rerunAdsOptions[e.target.value][1])

                                    console.log("here", rerunAdsOptions[e.target.value][1]*CPC)
                                    total+=parseFloat(parseFloat(rerunAdsOptions[e.target.value][1]*CPC).toFixed(0))
                                    
                                    console.log(total)
                                    setTotalCPC(parseFloat(parseFloat(total).toFixed(0)));
                            }} id="rerunads" style={STYLE.input}>
                                {rerunAdsOptions.map((x,i)=>{
                                    return <option value={i}>{x[0]==-1?"Run Always":`${x[0]} ${x[0]>1?"Days":"Day"}`} | {x[1]==0?"Default":`+${x[1]*100}% CPC`}</option>
                                })}
                              
                            </select>:""}
                        </div> */}
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        
                        <input onChange={(e) => {
                            setShowSocialInputs(e.target.checked)

                        }} autoComplete='off' type="checkbox" name="remember" style={{ marginBottom: '0px', height: '25px' }} id="box-2" />
                        <label for="box-2" style={{
                            color: '#959595', margin: '20px 3px', fontSize: '14px', display: 'flex', width: 'auto', display: 'inline-block',
                            justifyContent: 'start', alignItems: 'start'
                        }}>
                            <p style={{ marginBottom: '0px', marginTop: '1px', marginLeft: '3px', color: 'white', fontSize: '15px' }}> Social Network Links</p>
                        </label>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap:'10px' }}>
                        {showSocialInputs ? <>
                            <div className='input-container'>
                            <div className="input-icon"><FaFacebook/></div>
                            <input onChange={(e) => {
                                if (isValidURL(e.target.value) && (e.target.value.includes("https") || e.target.value.includes("http")) && e.target.value.toLowerCase().includes("facebook")) {
                                    if (/https?:\/\/(?:www\.)?(?:facebook|fb)\.com\//.test(e.target.value)) {
                                        setfblink(e.target.value);
                                    }
                                } else {
                                    setfblink("");
                                }
                            }} placeholder='Facebook' id="url" type="text" name="url"  />
                            </div>
                            <div className='input-container'>
                            <div className="input-icon"><FaTwitter/></div>
                            <input onChange={(e) => {
                                if (isValidURL(e.target.value) && (e.target.value.includes("https") || e.target.value.includes("http")) && e.target.value.toLowerCase().includes("twitter")) {
                                    if (/https?:\/\/(?:www\.)?(?:twitter|x)\.com\//.test(e.target.value)) {

                                        settwlink(e.target.value);
                                    }
                                } else {
                                    settwlink("");
                                }
                            }} placeholder='Twitter' id="url" type="text" name="url" />
                            </div>
                            <div className='input-container'>
                            <div className="input-icon"><FaInstagram/></div>
                            <input onChange={(e) => {
                                if (isValidURL(e.target.value) && (e.target.value.includes("https") || e.target.value.includes("http")) && e.target.value.toLowerCase().includes("instagram")) {
                                    if (/https?:\/\/(?:www\.)?(?:instagram|x)\.com\//.test(e.target.value)) {
                                        setinstalink(e.target.value);
                                    }
                                } else {
                                    setinstalink("");
                                }
                            }} placeholder='Instagram' id="url" type="text" name="url"  />
                            </div>
                            <div className='input-container'>
                            <div className="input-icon"><FaTelegram/></div>
                            <input onChange={(e) => {
                                if (isValidURL(e.target.value) && (e.target.value.includes("https") || e.target.value.includes("http")) && (e.target.value.toLowerCase().includes("t.me") || e.target.value.toLowerCase().includes("telegram"))) {
                                    if (/https?:\/\/(?:www|web\.)?(?:telegram|x)\.org\//.test(e.target.value)) {
                                        settelegramlink(e.target.value);
                                    }
                                } else {
                                    settelegramlink("");
                                }
                            }} placeholder='Telegram' id="url" type="text" name="url"  />
                            </div>
                        </> : ""}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    
                    <input onChange={(e) => {
                        setShowDailyInput(e.target.checked)
                    }} autoComplete='off' type="checkbox" name="remember" style={{ marginBottom: '0px', height: '25px' }} id="box-3" />
                    <label for="box-3" style={{
                        color: '#959595', margin: '20px 3px', fontSize: '14px', display: 'flex', width: 'auto', display: 'inline-block',
                        justifyContent: 'start', alignItems: 'start'
                    }}>
                        <p style={{ marginBottom: '0px', marginTop: '1px', marginLeft: '3px', color: 'white', fontSize: '15px' }}> Daily Limit</p>
                    </label>

                    {showDailyInput == true ?
                       <div className='input-container'>
                            <div className="input-icon"><FaClipboardCheck/></div>
                             <input onChange={(e) => {
                        if (e.target.value < 2500) {
                            setDailyLimit(2500)
                        } else {
                            setDailyLimit(e.target.value)
                        }
                    }} min="2500" defaultValue="25000" id="daily" type="number" name="daily"  /> </div>: ""}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <input onChange={(e) => {
                        setIsPromoted(!isPromoted)

                    }} autoComplete='off' type="checkbox" name="remember" style={{ marginBottom: '0px', height: '25px' }} id="box-4" />
                    <label for="box-4" style={{
                        color: '#959595', margin: '20px 3px', fontSize: '14px', display: 'flex', width: 'auto', display: 'inline-block',
                        justifyContent: 'start', alignItems: 'start'
                    }}>
                        <p style={{ marginBottom: '0px', marginTop: '1px', marginLeft: '3px', color: 'white', fontSize: '15px' }}> Promote Ad</p>
                    </label>

                </div>
                <div className="preview-text" style={{background:'var(--bg-color-2)', width: '100%',  padding: '10px 20px', display: 'flex', justifyContent: 'center', borderRadius:'var(--default-border-radius)' }}>
                    <div  className={`advertise-ad-fa ptc-ad ${isPromoted==true?'ptc-ad-promoted':''}`} >
                        {isPromoted == true ? <>
                            <div className="featured-ad">
                                Promoted
                            </div></> : ""}
                        <div>
                            <p className="ptc-ad-title" style={{ marginTop: facebookLink != '' || instagramlink != '' || twiteerlink != '' || telegramLink != '' ? '10px' : '0px' }}>{adtitle == "" ? "PTC Ad Title" : adtitle} </p>
                            <p className="ptc-ad-description">{addescription == "" ? "Your fab. description goes here" : addescription}</p>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div className="ptc-ad-details">
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <p className='ptc-ad-reward'><FaCoins /> {waitingTime == '7' ? 1 : Math.floor((((totalPrice + totalPricePromoted + totalPriceSocial) / clicksNumber) * 0.7))} </p>
                                        <p className='ptc-ad-timer'><FaStopwatch style={{ marginTop: '-5px' }} /> {waitingTime} s</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr />
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className="ptc-ad-btn" style={{ background: '#178a94', color: '#f3f3f3', fontSize: '13px', width: '100%', textAlign: 'center' }}>Watch AD</div>
                        </div>
                        <div className="mobile-social-links-ad-fa" style={{ flexDirection: 'column', alignItems: 'start', minWidth: '0px', display: showSocialInputs == true ? "flex" : 'inherit' }}>
                            {showSocialInputs == true ? <div>
                                {facebookLink == "" ? "" : <FaFacebook style={{ color: '#3b7ee7', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} />}
                                {instagramlink == "" ? "" : <FaInstagram style={{ color: '#f36c6c', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} />}
                                {twiteerlink == "" ? "" : <FaTwitter style={{ color: '#55b1e7', fontSize: '16px', margin: '0 2px', cursor: 'pointer' }} />}
                                {telegramLink == "" ? "" : <FaTelegram style={{ color: '#5f99f3', fontSize: '16px', margin: ' 0 2px ', cursor: 'pointer' }} />}
                            </div> : ""}

                        </div>
                    </div>
                </div>


                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'end' }}>
                    <div className="payout-bottom-left">
                        <div className="payout-fee">
                            CPC:
                            <div>
                                {parseFloat(parseFloat(totalPrice + totalPricePromoted + totalPriceSocial).toFixed(0)) / clicksNumber}

                                {parseFloat(parseFloat(totalPrice + totalPricePromoted + totalPriceSocial).toFixed(0)) / clicksNumber > 1 ? " Tokens" : ' Token'}
                            </div>
                        </div>
                        <div className="payout-price" >
                            Total:
                            <div>
                                {numberWithCommas(parseInt(parseFloat(totalPrice) + parseFloat(totalPricePromoted) + parseFloat(totalPriceSocial)))}
                                {(parseFloat(totalPrice) + parseFloat(totalPricePromoted) + parseFloat(totalPriceSocial)) > 1 ? " Tokens" : ' Token'}
                            </div>

                        </div>
                    </div>
                    <div onClick={() => {
                        if (adtitle.length < 4) {
                            CreateToast("Title must be at least 4 characters long", creaRef, 1)
                        } else if (addescription.length < 10) {
                            CreateToast("Description must be at least 10 characters long", creaRef, 1)
                        } else if (adlink.length < 2) {
                            CreateToast("Link must be at least 2 characters long", creaRef, 1)
                        } else if (adtitle.length > 25) {
                            CreateToast("Title must be at most 25 characters long", creaRef, 1)
                        } else if (addescription.length > 100) {
                            CreateToast("Description must be at most 100 characters long", creaRef, 1
                            )
                        } else if (clicksNumber < 200) {
                            CreateToast("Minimum clicks is 200", creaRef, 1)
                        } else if (dailyLimit < 200) {
                            CreateToast("Minimum daily limit is 200", creaRef, 1)
                        } else {
                            setAllowCreate(false)
                            socket.emit('userAction', {
                                action: 'createAdPTC',
                                title: adtitle,
                                description: addescription,
                                link: adlink,
                                clicks: clicksNumber,
                                duration: waitingTime,
                                dailyLimit: dailyLimit,
                                facebookLink: facebookLink,
                                isDailyLimit: showDailyInput,
                                isSocial: showSocialInputs,
                                twitterLink: twiteerlink,
                                instagramLink: instagramlink,
                                telegramLink: telegramLink,
                                promoted: isPromoted,
                                durationCPC: durationCPC,
                                type,
                                CPC: CPC,
                            })

                        }
                    }} className="payout-button" style={{ height: '43px', background: allowCreate == true ? 'var(--text-brand-2)' : 'var(--bg-color-1)', }}>
                        Create Ad
                    </div>
                </div>
            </div >
        </div >);
}

export default Advertise;