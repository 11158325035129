'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';
import React from 'react'
import PaginationPluto from './Pagination';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SweetAlert from 'react-bootstrap-sweetalert';

import { useEffect } from 'react';
import { useState } from 'react';
import PlutoMenu from './PlutoMenu';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';

function PlutoPaidCashOffers() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [length, setLength] = useState(0)
    const [rank, setRank] = useState(0)
    const [uid, setUID] = useState(-1)
    const [alert, setAlert] = useState({ show: false, type: '', flagReason: '', code: '', pin: '', id: -1 })
    const [alert2, setAlert2] = useState({ show: false, name: '', reward: 0 })
    useEffect(() => {
        socket.on('response_get_admin_paidcashoffers', (data) => {
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 10))
            setLength(data.withdrawalsLength)
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)

        })
        socket.emit('getPaidCashOffersAdminToday', {
            username_like: username,
            page,
        })
    }, [])

    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
            <SweetAlert
                show={alert2.show}
                closeOnClickOutside={false}

                onConfirm={function () {
                    socket.emit('adminAction', {
                        action: 'addPaidCashOffer',
                        page: page,
                        dataOffer: { ...alert2 }
                    })
                    setAlert2({ show: false, name: '', reward: 0 })
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Edit"
                showCancel={true}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert2({ show: false, name: '', reward: 0 }) }}
            >
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Name</p>
                        <input placeholder={'name'} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, name: e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                        <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>Reward</p>
                        <input placeholder={'reward'} name="input-name" onChange={(e) => {
                            setAlert2({ ...alert2, reward: e.currentTarget.value })
                        }} type={'text'} className="input_field" />
                    </div>
                </>

            </SweetAlert>
            <SweetAlert
                closeOnClickOutside={false}

                show={alert.show}
                onConfirm={function () {
                    socket.emit('adminAction', {
                        action: 'editPaidCashOffer',
                        id: alert.id,
                        page: page,
                        dataOffer: { ...alert }
                    })
                    setAlert({ show: false, type: '', flagReason: '', code: '', pin: '', id: -1 })
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Edit"
                showCancel={true}
                onClickOutside={() => { }}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert({ show: false }) }}
            >
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {Object.keys(alert).map((x, i) => {
                            if (x != 'show') {
                                if (x != 'id') {
                                    return <>
                                        <p style={{ width: '100%', textAlign: 'left', fontSize: '10px' }}>{x}</p>
                                        <input placeholder={x} name="input-name" onChange={(e) => {
                                            setAlert({ ...alert, [x]: e.currentTarget.value })
                                        }} defaultValue={Object.values(alert)[i]} type={'text'} className="input_field" />
                                    </>
                                }
                            }
                        })}
                    </div>
                </>

            </SweetAlert>
            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {length != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(length)}
                            </p>
                            <p>Total Direct Offers</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}
                </div>
                <div onClick={() => {
                    setAlert2({ show: true, name: '', reward: 0 })
                }} className="btn button-disabled" style={{ minWidth: '100px', height: '15px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px', marginBottom: '5px', }} >Add new</div>

                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Users</p>

                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '50px', textAlign: 'left', color: 'var(--text-primary)' }} >ID</div>
                            <div style={{ minWidth: '200px' }}>Name</div>
                            <div style={{ minWidth: '100px' }}>Reward</div>
                            <div style={{ minWidth: '100px' }}>Offer ID</div>
                            <div style={{ minWidth: '100px' }}>Enabled</div>
                            <div style={{ minWidth: '100px' }}>Sticky</div>
                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)', textDecoration: 'none' }}>

                                <div style={{ minWidth: '50px' }} >{earning.id == undefined ? '' : earning.id || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '200px', textOverflow: 'ellipsis' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.reward || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.offer_id || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.enabled == true ? 'yes' : 'no'}</div>
                                <div style={{ minWidth: '100px' }} >{earning.sticky == true ? 'yes' : 'no'}</div>
                                <div onClick={() => {
                                    socket.emit('adminAction', {
                                        action: 'doEnableOfferPaidCash',
                                        id: earning.id,
                                        page: page,
                                    })
                                }} className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{earning.enabled == true ? "Disable" : "Enable"}</div>
                                <div onClick={() => {
                                    socket.emit('adminAction', {
                                        action: 'doStickyOfferPaidCash',
                                        id: earning.id,
                                        page: page,
                                    })
                                }} className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{earning.sticky == true ? "DeSticky" : "Sticky"}</div>
                                <div onClick={() => {
                                    setAlert({ show: true, ...earning })
                                }} className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >Edit</div>
                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                    setPage(page)
                    socket.emit('getPaidCashOffersAdminToday', {
                        username_like: username,
                        page,
                    })
                }} />}
                <div >
                    <input onKeyDown={
                        (e) => {
                            if (e.key == 'Enter') {
                                canUsePagination(false)
                                setEarnings([0, 0, 0, 0, 0])
                                socket.emit('getPaidCashOffersAdminToday', {
                                    username_like: username,
                                    page: document.getElementById('jumpto3dpaidcashoffers').value,
                                })
                            }
                        }

                    } type="number" id="jumpto3dpaidcashoffers" style={{ background: 'gray', color: '#000' }} />
                    <button onClick={() => {
                        canUsePagination(false)
                        setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                        socket.emit('getPaidCashOffersAdminToday', {
                            username_like: username,
                            page: document.getElementById('jumpto3dpaidcashoffers').value,
                        })
                    }
                    } style={{ color: '#fff', background: '#000' }}>Go to page</button>
                </div>
            </div>

        </SkeletonTheme>
    )
}

export default PlutoPaidCashOffers