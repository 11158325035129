import HomePageShowCase from "./ShowCase"
import '../assets/style/HomeScreen.css'
import { PromotedSmallOfferContainer } from "./PromotedSmallOffer.Container"
import { useEffect, useState } from "react"
import { socket } from "../components/Containers/MainContainer"
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide"
import Skeleton from "react-loading-skeleton"
import { showRegisterModal, withdrawCryptoCards, withdrawGiftcardCards } from "../components/utils"
import TrustBox from "./TrustBox"
import { FaUserPlus } from "react-icons/fa"

export const HomeScreenContainer = (props) => {
    const [promotedOffers, setPromotedOffers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        setTimeout(() => {
            socket.emit('emitOfferWalls');
        }, 1000)
        socket.on('activityOfferWalls', (data) => {
            let offers = data.offerwallsPack.map((item, i) => {
                return (item.name=='revlum' || item.name=='tyrads' || item.name=='paidcash-exclusive'?undefined:
                 {
                    ...item,
                    img: item.img,
                    increase: item.increase,
                    levelRequired: item.levelRequired,
                    name: item.name,
                    type: item.type
                })
            })
            offers = offers.filter((item) => item != undefined)
            setPromotedOffers([
                { background:"linear-gradient(336.89deg, rgb(19, 23, 57) -26.71%, rgb(103, 88, 209) 107.56%)",
                canUse:true,
                id:15,
                img:"adjoe.png",
                increase:"+50%",
                levelRequired:0,
                name:"PlayTime",
                type: "OfferWall"
                },
                ...offers])
                socket.off('activityOfferWalls')
        })
    }, [])
   useEffect(() => {
    const trustbox = document.querySelector('trustbox-widget');
window.Trustpilot.loadFromElement(trustbox);
    }, [])
    const options = {
        rewind: true,
        gap: 20,
        autoplay: true,
        pagination: false,
        arrows     : false

    }
    return (
        <div className="home-screen-container">
               
             <HomePageShowCase />

                <Splide 
                style={{marginBottom:'-10px'}}
                    options={options}
                    hasTrack={false}>
             <h2 className="promoted-small-offer-container-title">Our Partners</h2>

                <SplideTrack>
                    {promotedOffers.map((item, i)=> {
                      if (item.name == undefined) {
                        return <Skeleton className='offer-featured-box' style={{ width: '110px', height: '100px', marginRight:'15px', borderRadius: '8px' }} />
                    }else
                    return <SplideSlide key={i} className='partner-showcase' style={{width:item.name=='revlum' || item.name=='tyrads' || item.name=='paidcash'?'0px ':'inherit'}} onClick={() => {
                    }}>
                        {item.name=='revlum' || item.name=='tyrads' || item.name=='paidcash'?<div style={{width:'0px', height:'0px'}}></div>:
                        <img src={`/assets/img/partners/${item.img}`}/>}
                          <p> {item.name}</p>
                    </SplideSlide>})}
                </SplideTrack>

            </Splide >
            <PromotedSmallOfferContainer titlex={'High Earning Offers'} />
            <Splide 

                    options={options}
                    hasTrack={false}>
             <h2 className="promoted-small-offer-container-title">Available Cash Out Options</h2>

                <SplideTrack>
                    {[...withdrawGiftcardCards, ...withdrawCryptoCards].map((item, i)=> {
                      if (item.name == undefined) {
                        return <Skeleton className='offer-featured-box' style={{ width: '110px', height: '100px', marginRight:'15px', borderRadius: '8px' }} />
                    }else
                    return <SplideSlide key={i} className='cashout-showcase' style={{width:item.name=='revlum' || item.name=='tyrads' || item.name=='paidcash'?'0px ':'inherit'}} onClick={() => {
                    }}>
                        <img src={`${item.img}`}/>
                        <p> {item.name}</p>
                    </SplideSlide>})}
                </SplideTrack>

            </Splide >
                    <TrustBox/>
                    <div style={{margin:'0 auto', marginTop:'-20px', marginBottom:'20px'}}><button className="header-signup-button" onClick={()=>{showRegisterModal()}}><FaUserPlus/> Sign Up Now For Free</button></div>
        </div>
    )
}