import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { PromotedSmallOfferItem } from "../Component/PromotedSmallOffer/Item.PromotedSmallOffer"
import '../assets/style/PromotedSmallOffer.css'
import '../assets/style/Walls.css'
import { useEffect, useState } from "react";
import { WallsItem } from "../Component/Walls/Item.Walls";
import { WallModalContent } from "./ModalContent/Wall.ModalContent";
import { socket } from "../components/Containers/MainContainer";
import Skeleton from "react-loading-skeleton";
import { showAdjoeModal } from "../components/utils";

export const WallsContainer = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    function getBodyValue(open) { return document.querySelector('body').clientWidth  - (document.querySelector('body').clientWidth<=1050?0:(open==undefined?223:open==true?50:223))}
    const [perpage, setPerPage] = useState(Math.floor((getBodyValue()) / (document.querySelector('body').clientWidth<=600?110:140)))
    const size = useWindowSize();
    const [promotedOffers, setPromotedOffers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    function sendOpenPlayTime(){
        try{
            if(typeof window.PaidCashAPP != 'undefined'){

                window.PaidCashAPP.postMessage('openPlayTime');
            }else{
                
            }
        }catch(e){
        }
    }
    useEffect(() => {
        setPerPage(Math.floor((getBodyValue()) / 140))
    }, [size.width])
    useEffect(() => {
        document.addEventListener('handleUpdateSize', function(e){
            setPerPage(Math.floor((getBodyValue(e.detail.open)) / 140))
        })
    }, [])
    useEffect(() => {
        setTimeout(() => {
            socket.emit('emitOfferWalls');
        }, 1000)
        socket.on('activityOfferWalls', (data) => {
          
            setPromotedOffers([
                { background:"linear-gradient(336.89deg, rgb(19, 23, 57) -26.71%, rgb(103, 88, 209) 107.56%)",
                canUse:true,
                id:15,
                img:"adjoe.png",
                increase:"+50%",
                levelRequired:0,
                name:"adjoe",
                type: "OfferWall"
                },
                ...data.offerwallsPack])
                socket.off('activityOfferWalls')
        })
    }, [])
    const options = {
        rewind: true,
        perPage: perpage,
        perMove: 1,
        gap: 20,
        padding: "0",
        pagination: false,
       

    }
    
    const [showWallModal, setShowWallModal] = useState(false)
    const [wallModalData, setWallModalData] = useState({})
   
    return (
        <div className="promoted-small-offer-container">
            
            <h2 className="promoted-small-offer-container-title">{props.title}</h2>
            <Splide
                    options={options}
                    hasTrack={false}>


                    <div className="splide__arrows custom_arrows splide__arrows--ltr"><div className="custom_arrow_container custom_arrow_container_left"><button className="splide__arrow splide__arrow--prev custom_arrow" disabled="" aria-label="Previous slide" aria-controls="splide02-track"><svg width="20" height="20" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M10.35 16.63a1.29 1.29 0 0 1 0-1.76L13.1 12l-2.75-2.87a1.29 1.29 0 0 1 0-1.76 1.17 1.17 0 0 1 1.7 0l3.6 3.75c.47.48.47 1.28 0 1.76l-3.6 3.75c-.47.5-1.23.5-1.7 0Z"></path></svg></button></div><div className="custom_arrow_container custom_arrow_container_right"><button className="splide__arrow splide__arrow--next custom_arrow" aria-label="Next slide" aria-controls="splide02-track"><svg width="20" height="20" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M10.35 16.63a1.29 1.29 0 0 1 0-1.76L13.1 12l-2.75-2.87a1.29 1.29 0 0 1 0-1.76 1.17 1.17 0 0 1 1.7 0l3.6 3.75c.47.48.47 1.28 0 1.76l-3.6 3.75c-.47.5-1.23.5-1.7 0Z"></path></svg></button></div></div>

                <SplideTrack>
                    {promotedOffers.map((item, i)=>{
                     if (item.type == undefined) {
                        return <Skeleton className='offer-featured-box' style={{ width: '130px', height: '140px', marginRight:'15px', borderRadius: '8px' }} />
                    }
                    else  if(item.type.toLowerCase()==props.for){
                     return  <SplideSlide key={i} className='offer-featured-box' onClick={() => {
                        if(item.canUse==false){
                            window.dispatchEvent(new CustomEvent('showInfoModal', {detail: {title: 'is not yet available for you', description: `${item.unavDescription}`, image: `https://paidcash.co/assets/img/partners/${item.img}`, type:'small', click:()=>{}}}))
                        }else{
                            // if(item.unavDescription!=undefined){
                            //     window.dispatchEvent(new CustomEvent('showInfoModal', {detail: {title: 'News', description: `${item.unavDescription}`, image: `https://paidcash.co/assets/img/partners/${item.img}`, type:'small', click:()=>{}}}))
                            // }
                            if(item.name.toLowerCase()=='adjoe'){
                                
                                if (typeof window.PaidCashChannel !== 'undefined' && window.PaidCashChannel.postMessage) {
                                    window.PaidCashChannel.postMessage('adjoe');
                                }else{
                                    showAdjoeModal();
                                }
                            }else{
                                window.dispatchEvent(new CustomEvent('handleWallsUp', {detail: {name:item.name}}))
                                setWallModalData(item)
                            }
                        }
                        
                    }}>
                        
                        <WallsItem 
                        image={`/assets/img/partners/${item.img}`}
                        title={item.name}
                        increase={item.increase}
                        locked={props.logged==false?false:item.canUse==false?true:false}
                        lockedText={"Not Yet Available For You"}
                        background={item.background}
                        />
                    </SplideSlide>}})}
                </SplideTrack>

            </Splide >
        </div>
    )
}
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // only execute all the code below in client side
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}