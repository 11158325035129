'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';

import { useEffect } from 'react';
import { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PlutoMenu from './PlutoMenu';
import PaginationPluto from './Pagination';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';

function PlutoOfferDataTotalToday() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [rank, setRank] = useState(0)
    const [type, setType] = useState(0)
    const [order_by, setOrder_by] = useState(0)
    const [wallList, setWallList] = useState([]);
    const [offerWall, setOfferWall] = useState('all')
    useEffect(() => {
        socket.on('response_get_admin_offersdatatoday', (data) => {
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 10))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)
            setWallList(data.offerwalls)

        })
        socket.emit('getOffersDataTodayAdmin', {
            offers_like: username,
offerwall_selected:offerWall,            type,
            order_by: order_by,
            page,
        })
    }, [])
    useEffect(() => {
        socket.on('reTakePendingData', () => {
            socket.emit('getOffersDataTodayAdmin', {
                offers_like: username,
offerwall_selected:offerWall,                order_by: order_by,
                type,
                page,
            })
        })
    }, [])
    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">

            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {userProfile.withdrawalsLength != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.withdrawalsLength)}
                            </p>
                            <p>Count offers</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}


                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: '20px', marginBottom:'20px' }}>
                    <div className={`button button-${order_by == 0 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                        setOrder_by(0)
                        socket.emit('getOffersDataTodayAdmin', {
                            offers_like: username,
offerwall_selected:offerWall,                            type:type,
                            order_by: 0,
                            page,
                        })
                    }}>By Count</div>
                    <div className={`button button-${order_by == 1 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setOrder_by(1)
                        setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                        socket.emit('getOffersDataTodayAdmin', {
                            offers_like: username,
offerwall_selected:offerWall,                            type:type,
                            order_by: 1,
                            page,
                        })
                    }}>By Revenue</div>
                    </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: '20px', marginBottom:'20px'  }}>
                    <div className={`button button-${type == 0 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setType(0)
                        setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                        socket.emit('getOffersDataTodayAdmin', {
                            offers_like: username,
offerwall_selected:offerWall,                            type:0,
                            order_by: order_by,
                            page,
                        })
                    }}>Today</div>
                    <div className={`button button-${type == 1 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setType(1)
                        setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                        socket.emit('getOffersDataTodayAdmin', {
                            offers_like: username,
offerwall_selected:offerWall,                            order_by: order_by,
                            type:1,
                            page,
                        })
                    }}>Yesterday</div>
                    
                </div>
                <select style={{ maxWidth: '300px',width:'100%', height: '30px', backgroundColor: '#3a3a3a', color: '#f3f3f3', border: 'none', borderBottom: '1px solid #f3f3f3' }} onChange={(e) => {
               setOfferWall(e.currentTarget.value)
               socket.emit('getOffersDataTodayAdmin', {
                offers_like: username,
offerwall_selected:e.currentTarget.value,                order_by: order_by,
                type,
                page,
            })
              }}>
                    <option value="all">All</option>
                    {wallList==undefined|| wallList.length==0?'':wallList.map((wall) => 
                        <option value={wall+''.toLowerCase()}>{wall+''}</option>
                    )}
                    </select>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Suspect Offers</p>
                    <div className="input-container">
                        <input style={{ borderRadius: '5px', background: 'transparent', marginBottom: '5px', color: '#fff', fontSize: '12px', padding: '5px' }} onChange={(e) => {
                            setUsername(e.currentTarget.value)
                            setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

                            socket.emit('getOffersDataTodayAdmin', {
                                offers_like: e.currentTarget.value.toLowerCase(),
                                offerwall_selected:offerWall,  
                            order_by: order_by,
                            type,
                                page: 1,
                            })

                        }} type="text" name="amount" placeholder="Search by offer name" autoComplete="off" />
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                            <div style={{ minWidth: '200px' }}>Amount</div>
                            <div style={{ minWidth: '150px' }}>Wall Name</div>
                            <div style={{ minWidth: '150px' }}>Completed Count</div>



                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '200px' }} >{earning.amount || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '150px', }} >{earning.wall || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '150px', }} >{earning.count || <Skeleton style={{ width: '30px' }} />}</div>



                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getOffersDataTodayAdmin', {
                        offers_like: username,
offerwall_selected:offerWall,                            order_by: order_by,
                            type,
                        page,
                    })
                }} />}

            </div>
            <div >
                <input onKeyDown={
                    (e) => {
                        if (e.key == 'Enter') {
                            canUsePagination(false)
                            setEarnings([0, 0, 0, 0, 0])
                            socket.emit('getOffersDataTodayAdmin', {
                                offers_like: username,
offerwall_selected:offerWall,                            order_by: order_by,
                            type,
                                page: document.getElementById('jumptotoday').value,
                            })
                        }
                    }

                } type="number" id="jumptotoday" style={{ background: 'gray', color: '#000' }} />
                <button onClick={() => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getOffersDataTodayAdmin', {
                        offers_like: username,
offerwall_selected:offerWall,                            order_by: order_by,
                            type,
                        page: document.getElementById('jumptotoday').value,
                    })
                }
                } style={{ color: '#fff', background: '#000' }}>Go to page</button>
            </div>
        </SkeletonTheme>
    )
}

export default PlutoOfferDataTotalToday