import { FaCheckDouble, FaDiscord, FaDollarSign, FaFacebook, FaInstagram } from 'react-icons/fa';
import '../assets/style/PromoCode.css'
import { socket } from '../components/Containers/MainContainer';
import { useEffect, useState } from 'react';
import {  showAdjoeModal } from '../components/utils';

export const RewardedVideoAds = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const FAQ = [
        {
            "q": "What are Video Ads?",
            "a": "Video Ads are short, engaging video advertisements that users can watch to earn rewards, such as in-app currency, points, or other perks. These ads typically last between 15 and 30 seconds, and users receive their reward after watching the full video.",
            "show": true
        },
        {
            "q": "How do I watch Video Ads?",
            "a": "To watch a Video Ad, go to the designated 'Rewards' section in the app or tap on any button that prompts you to 'Watch an Ad.' After watching the ad completely, you’ll receive your reward automatically.",
            "show": false
        },
        {
            "q": "What rewards can I earn from watching these ads?",
            "a": "Rewards vary by platform and may include points, tokens, in-game currency, or real-world rewards, like cashback or gift cards. Check the rewards section for specific offers available to you.",
            "show": false
        },
        {
            "q": "How many ads can I watch per day?",
            "a": "The number of Video Ads you can watch daily may be limited to ensure fair use and balance. Typically, users can watch between 5–20 ads daily, but this may vary depending on availability and app policies.",
            "show": false
        },
        {
            "q": "Why didn’t I receive my reward after watching an ad?",
            "a": "If you didn’t receive your reward, please ensure: 1) The video was watched to completion without skipping. 2) There was a stable internet connection throughout the ad. If you still didn’t receive your reward, please contact support with details, including the date, time, and type of ad.",
            "show": false
        },
        {
            "q": "Can I skip a Video Ad?",
            "a": "No, skipping a Video Ad will prevent you from earning a reward. You must watch the ad from start to finish to be eligible for the reward.",
            "show": false
        },
        {
            "q": "Do Video Ads cost money to watch?",
            "a": "No, watching Video Ads is completely free. The ads allow users to earn rewards without any purchase, adding value to your experience on the app.",
            "show": false
        },
        {
            "q": "Why are some ads unavailable?",
            "a": "Ad availability may vary based on factors like region, demand, and app settings. If no ads are available, try again later, as they may become available throughout the day.",
            "show": false
        },
        {
            "q": "Is there a limit to how much I can earn from Video Ads?",
            "a": "Earnings limits depend on the app’s reward policies, but there may be daily, weekly, or monthly caps to balance earnings and maintain app performance.",
            "show": false
        },
        {
            "q": "Can I earn rewards by watching ads on multiple devices?",
            "a": "Using multiple devices is not allowed. Please check the app’s terms to avoid losing access to rewards.",
            "show": false
        },
        {
            "q": "Are Video Ads safe to watch?",
            "a": "Yes, all Video Ads undergo checks to ensure they meet safety standards, but if you encounter an inappropriate ad, report it through the app’s feedback feature.",
            "show": false
        },
        {
            "q": "Who can I contact if I have issues with Video Ads?",
            "a": "For any issues, contact the app’s support team through the 'Help' or 'Contact Us' section. Be ready to provide details like screenshots or descriptions of the issue for faster assistance.",
            "show": false
        }
    ]
    const [bonusCode, setBonusCode] = useState('')
    const [data, setData] = useState({})
    useEffect(() => {

        socket.on('receive_reward_data', (data) => {
            setData(data)
        })
        socket.emit('emitRewardsData')
    }, [])
    return (
        <div className="promo-code-main">

        <div className="promo-code-container">
          
            <div className="promo-code-container-content">
            <div className='promo-code-container-title'>
            Watch, Earn, Repeat – Get rewarded with every ad!
                </div>
                <div className='promo-code-container-description'>
                Unlock rewards simply by watching! Dive into quick, engaging ads and earn points, perks, or in-game bonuses – the more you watch, the more you gain!
                </div>
               
               
                <div className="promo-code-container-content-button" onClick={(e) => {
                      if (typeof window.PaidCashChannel !== 'undefined' && window.PaidCashChannel.postMessage) {
                        window.PaidCashChannel.postMessage('ad');
                    }else{
                        showAdjoeModal();
                    }
                    }} style={{ background: props.loggedIN == true && bonusCode.trim().split("").length > 3 ? '' : 'var(--bg-color-6)', maxWidth:'200px' , borderRadius:'8px'}}>
                    Watch Video Ad
                </div>
               
            </div>
            
            </div>
            <h2 className="promo-code-content-title">Frequently Asked Questions</h2>

            <div className="faq-container">
            {FAQ.map(function (q, i) {
                        return (
                                <div className='faq-item' onClick={function (e) {
                                    e.currentTarget.children[0].classList.toggle('faq-item-active')
                                    e.currentTarget.children[0].children[0].classList.toggle('fa-arrow-right')
                                    e.currentTarget.children[0].children[0].classList.toggle('fa-arrow-down')
                                    e.currentTarget.children[1].classList.toggle('faq-item-show')
                                }}>
                                    <div className='faq-item-top'>
                                        <i class="fal fa-arrow-right"></i>
                                        {q.q}
                                    </div>

                                    <div className='faq-item-bottom' dangerouslySetInnerHTML={{ __html: q.a }}>
                                    </div>

                                </div>
                        )
                })}
                </div>
        </div>
    );
}