
import React, { useEffect, useState } from 'react'
import EarnOfferWalls from '../earn/EarnOfferWalls';
import { getWallName, setLocalStorage } from '../utils';
import { WallModalContent } from '../../Containers/ModalContent/Wall.ModalContent';
function OfferWallsContainer() {
    const [offerwallsPages, setOfferwallsPages] = useState({});
    const [wallChange, setWallChange] = useState(false);
    function addOffer(name2) {
        let name = name2.split(" ").join("-")
        if (document.querySelector(`.modal-content-wall-${name.split(' ').join('-')}`) == undefined) {
            setOfferwallsPages({
                ...offerwallsPages,
                [name]: <WallModalContent click={(e)=>{e.currentTarget.parentNode.parentNode.parentNode.style.display ='none'}} title={name}/>
            })

        } else {
            document.querySelector(`.modal-content-wall-${name.split(' ').join('-')}`).parentNode.style.display = 'block'
        }
    }
    useEffect(() => {
        window.addEventListener('handleWallsUp', (data) => {
            let name = data.detail.name
            setLocalStorage('wallname', name)
            setWallChange(name + Math.random())
        })
    }, [])
    useEffect(() => {
        if (getWallName() != undefined) {
            addOffer(getWallName())


        }
    }, [wallChange]);

    return (
        <>
            {Object.values(offerwallsPages).map(x => x)}
        </>
    )
}

export default OfferWallsContainer