import CountUp from 'react-countup';
import '../assets/style/Header.css';
import { BalanceHeader } from '../Component/Header/Balance.Header';
import { LogoHeader } from '../Component/Header/Logo.Header';
import { NotificationHeader } from '../Component/Header/Notification.Header';
import { ProfileHeader } from '../Component/Header/Profile.Header';
import { SideMenuButtonHeader } from '../Component/Header/SideMenuButton.Header';
import { socket } from '../components/Containers/MainContainer';
import { useEffect, useState } from 'react';
import { FaKey, FaUserPlus } from 'react-icons/fa';
import { showLoginModal, showRegisterModal } from '../components/utils';


export const HeaderContainer = (props) => {
   
    return (
        <header className='header-container'>
            
            <div className='header-left'>
                <LogoHeader />
                {props.loggedIN==true?<SideMenuButtonHeader/>:''}
            </div>
            <div className='header-right'>
            {props.loggedIN==true?<> <BalanceHeader balance={props.balance} />
                {/* <NotificationHeader notifications={props.notifications}/> */}
                <ProfileHeader username={props.username} level={props.level}  rank={props.rank}/>
                </>:
                <div className="header-login-buttons">
                    <button className="header-login-button" onClick={()=>{showLoginModal()}}><FaKey/> Sign In</button>
                    <button className="header-signup-button" onClick={()=>{showRegisterModal()}}><FaUserPlus/> Sign Up</button>
                </div>}
            </div>
        </header>
    )
}