'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PaginationPluto from './Pagination';
import { useState } from 'react';
import { useEffect } from 'react';

import PlutoMenu from './PlutoMenu';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';
import ChartComp2 from './ChartComp2';

function PlutoChargebacks() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [rank, setRank] = useState(0)
    const [type, setType] = useState(0)
    const [grouppedCb, setGrouppedCb] = useState(true)
    const [chargebackData, setChargebackData] = useState(undefined)
    const [chargebackDataWalls, setChargebackDataWalls] = useState(undefined)
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    useEffect(() => {
        socket.on('response_get_admin_chargeback', (data) => {
            setChargebackData(data.dataMonthCB)
            setChargebackDataWalls(data.wallChargebackData)
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 10))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)
        })

        socket.emit('getChargebacksAdmin', {
            offers_like: username,
            page,
            groupped: grouppedCb,
            type: type
        })
    }, [])
    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
            <div class="modal-body" style={{ color: 'var(--text-primary)', marginTop: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: '20px' }}>
                    <div className={`button button-${type == 2 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setType(2)
                        setChargebackDataWalls(undefined)
                        socket.emit('getChargebacksAdmin', {
                            offers_like: username,
                            page,
                            groupped: grouppedCb,
                            type: 2
                        })
                    }}>Today</div>
                    <div className={`button button-${type == 4 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setType(4)
                        setChargebackDataWalls(undefined)
                        socket.emit('getChargebacksAdmin', {
                            offers_like: username,
                            page,
                            groupped: grouppedCb,
                            type: 4
                        })
                    }}>Yesterday</div>
                    <div className={`button button-${type == 1 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setType(1)
                        setChargebackDataWalls(undefined)

                        socket.emit('getChargebacksAdmin', {
                            offers_like: username,
                            groupped: grouppedCb,
                            page,
                            type: 1
                        })
                    }}>This Month</div>
                    <div className={`button button-${type == 3 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setType(3)
                        setChargebackDataWalls(undefined)

                        socket.emit('getChargebacksAdmin', {
                            offers_like: username,
                            groupped: grouppedCb,
                            page,
                            type: 3,
                            month: new Date().getMonth()-1
                        })
                    }}>Last Month</div><div className={`button button-${type == 0 ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {
                        setType(0)
                        setChargebackDataWalls(undefined)

                        socket.emit('getChargebacksAdmin', {
                            offers_like: username,
                            groupped: grouppedCb,
                            page,
                            type: 3,
                            month: new Date().getMonth()-1
                        })
                    }}>All</div>
                </div>
              
                <div className={`button button-${grouppedCb == true ? 'brand' : 'disabled'} waves-effect`} style={{ width: '100px' }} onClick={() => {


                    socket.emit('getChargebacksAdmin', {
                        offers_like: username,
                        groupped: !grouppedCb,
                        page,
                        type: type
                    })
                    setGrouppedCb(!grouppedCb)
                }}>{grouppedCb == true ? "UnGroup" : 'Group'}</div>

<select style={{ maxWidth: '300px',width:'100%', height: '30px', backgroundColor: '#3a3a3a', color: '#f3f3f3', border: 'none', borderBottom: '1px solid #f3f3f3' }} onChange={(e) => {
               setType(5)
               setChargebackDataWalls(undefined)
                  
               socket.emit('getChargebacksAdmin', {
                   offers_like: username,
                   groupped: grouppedCb,
                   page,
                   type: 3,
                   month: parseInt((e.currentTarget.value)<0?11-(e.currentTarget.value*-1):e.currentTarget.value)

            })
              }}>
                    <option value="-1">nothing</option>
                    <option value={new Date().getMonth()}>{months[new Date().getMonth()]}</option>
                    <option value={new Date().getMonth()-1}>{months[new Date().getMonth()-1]}</option>
                    <option value={new Date().getMonth()-2}>{months[new Date().getMonth()-2]}</option>
                    <option value={new Date().getMonth()-3}>{months[new Date().getMonth()-3]}</option>
                    <option value={new Date().getMonth()-4}>{months[new Date().getMonth()-4]}</option>
                    <option value={new Date().getMonth()-5}>{months[new Date().getMonth()-5]}</option>
                    <option value={new Date().getMonth()-6}>{months[new Date().getMonth()-6]}</option>
                    <option value={new Date().getMonth()-7}>{months[new Date().getMonth()-7]}</option>
                    <option value={new Date().getMonth()-8}>{months[new Date().getMonth()-8]}</option>
                    </select>
                <div className="user-details-stats">
                    {userProfile.cbAmount != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.cbAmount)}
                            </p>
                            <p>Total chargebakcs</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}
                    {userProfile.withdrawalsLength != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.withdrawalsLength)}
                            </p>
                            <p>Count chargebakcs</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}


                </div>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Chargebacks</p>
                    <div className="input-container">
                        <input style={{ borderRadius: '5px', background: 'transparent', marginBottom: '5px', color: '#fff', fontSize: '12px', padding: '5px' }} onChange={(e) => {
                            setUsername(e.currentTarget.value)
                            setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

                            socket.emit('getChargebacksAdmin', {
                                offers_like: e.currentTarget.value.toLowerCase(),
                                page: 1,
                                groupped: grouppedCb,
                                type: type
                            })

                        }} type="text" name="amount" placeholder="Search by offer name" autoComplete="off" />
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >
                            {grouppedCb == true ? <>
                                <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                                <div style={{ minWidth: '100px' }}>Wall</div>
                                <div style={{ minWidth: '150px' }}>Amount</div>
                                <div style={{ minWidth: '150px' }}>Count</div>
                                <div style={{ minWidth: '100px' }}>Total $</div>
                            </> : <>
                                <div style={{ minWidth: '50px' }} >id</div>
                                <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                                <div style={{ minWidth: '100px' }}>Wall</div>
                                <div style={{ minWidth: '150px' }}>Amount</div>
                                <div style={{ minWidth: '100px' }}>Date</div>
                                <div style={{ minWidth: '100px', maxWidth: '100px' }}>Check</div>
                            </>}
                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={i} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>
                                {grouppedCb == true ? <>
                                    <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px' }} >{earning.wall || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '150px', }} >{earning.amount || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '150px', }} >{earning.c || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '150px', }} >{(earning.amount == undefined ? '' : '$' + numberWithCommas(((parseFloat(earning.amount.split("$").join('')) * earning.c) / 0.6).toFixed(2))) || <Skeleton style={{ width: '30px' }} />}</div>
                                </> : <>
                                    <div style={{ minWidth: '50px' }} > {earning.id || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '100px' }} >{earning.wall || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '150px', }} >{earning.amount || <Skeleton style={{ width: '30px' }} />}</div>
                                    <div style={{ minWidth: '150px', }} >{earning.date || <Skeleton style={{ width: '30px' }} />}</div>
                                    <Link to={`/pluto/user/${earning.user_id}`} className="button button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px', fontSize: '10px' }} >'Check User'</Link>
                                </>}
                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getChargebacksAdmin', {
                        offers_like: username,
                        page,
                        groupped: grouppedCb,
                        type: type
                    })
                }} />}

            </div>
            <div >
                <input onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                        canUsePagination(false)
                        setEarnings([0, 0, 0, 0, 0])
                        socket.emit('getChargebacksAdmin', {
                            offers_like: username,
                            page: document.getElementById('jumpto').value,
                            groupped: grouppedCb,
                            type: type
                        })
                    }
                }} type="number" id="jumpto" style={{ background: 'gray', color: '#000' }} />
                <button onClick={() => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getChargebacksAdmin', {
                        offers_like: username,
                        page: document.getElementById('jumpto').value,
                        groupped: grouppedCb,
                        type: type
                    })
                }
                } style={{ color: '#fff', background: '#000' }}>Go to page</button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', flexWrap: 'wrap', marginBottom: '100px' }}>
                <div style={{ height: '200px', width: '90vw', maxWidth: '500px', marginTop: '20px' }}> {chargebackData == undefined ? '' : <ChartComp2 title="Chargebacks ($ )" title2="" title3='' title4='' dataLength={24} dataType='month' data={chargebackData.map(x => x.amount)} chartID={"eariusersacm2"} obj={'$'} />}</div>
                <div style={{ height: '200px', width: '90vw', maxWidth: '500px', marginTop: '20px' }}> {chargebackDataWalls == undefined ? '' : <ChartComp2 title="Chargebacks ($) Walls" title2="" title3='' title4='' walls={chargebackDataWalls.map(x => x.wall)} dataType='month' data={chargebackDataWalls.map(x => x.amount)} chartID={"eariusersacmwalls"} obj={'$'} />}</div>
            </div>
        </SkeletonTheme>
    )
}

export default PlutoChargebacks