
import { useEffect } from 'react'
import '../assets/style/Terms.css'
import TopTOSCOntainer from './TopTOSCOntainer'

export const TermsOfServices = (props) => {
    const lastEdited = '2024-01-17 [YYYY-MM-DD]'
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    let TermsOfServicePage = [
        {
            title: `Acceptance of the Terms of Services`,
            id: 'acceptance-terms-services',
            content: `These Website Terms of Services are entered into by and between you and LOMORA LAURENTIU P.F.A. ("PaidCash," "we," or "us"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Services"), govern your access to participate in certain market activities and use of paidcash.co to earn Rewards (as defined below), including any content, features, internet browser, emails, text (SMS) messages, online services, functionality, services, and mobile applications offered on or through paidcash.co (collectively, "Website"), whether as a guest or a registered user.<br/>
            <br/>
            PLEASE READ THE TERMS OF SERVICES CAREFULLY BEFORE YOU START TO USE THE WEBSITE. BY USING THE WEBSITE OR BY CLICKING TO ACCEPT OR AGREE TO THE TERMS OF SERVICES WHEN THIS OPTION IS MADE AVAILABLE TO YOU, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS OF SERVICES AND OUR PRIVACY POLICY, FOUND AT www.paidcash.co/policies/privacy, INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT WANT TO AGREE TO THESE TERMS OF SERVICES OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE WEBSITE.`,
        },
        {
            id: 'changes-tos',
            title: 'Changes to Terms of Service',
            content: `We retain the right to occasionally amend and update these Terms of Service at our discretion. Such adjustments shall take immediate effect upon their publication and shall pertain to all subsequent access to and usage of the Website. HOWEVER, ANY ALTERATIONS TO THE DISPUTE RESOLUTION PROVISIONS AS STATED UNDER GOVERNING LAW AND JURISDICTION SHALL NOT BE APPLICABLE TO ANY DISPUTES FOR WHICH THE PARTIES HAVE RECEIVED ACTUAL NOTICE ON OR BEFORE THE DATE OF THE AMENDMENT'S PUBLICATION ON THE WEBSITE. By continuing your use of the Website following the posting of revised Terms of Service, you express your acceptance of and agreement to these amendments. We recommend that you periodically visit this page to stay informed about any changes, as they are legally binding on you.`
        },
        {
            id: 'eligibility',
            title: 'Eligibility',
            content: `This Website is provided and accessible to individuals who are at least 16 years old or older. By utilizing this Website, you affirm and guarantee that you are at least 16 years of age and meet all the aforementioned eligibility criteria required to enter into a legally binding agreement with PaidCash. IF YOU FAIL TO SATISFY THESE CRITERIA, YOU ARE NOT PERMITTED TO ACCESS OR UTILIZE THE WEBSITE.<br/>
            <br/>
            Engagement on the Website is restricted to a single (1) account per individual and only one (1) account per household.`
        },
        {
            id: 'taxes',
            title: 'Taxes',
            content: `You acknowledge and consent to the fact that, in certain instances, we may not have the capability to definitively ascertain whether the Rewards you have received or redeemed within any Rewards Program constitute reportable income or taxable earnings in your jurisdiction. Consequently, you bear the responsibility for any and all tax obligations arising from or related to your utilization of the Website and/or your participation in the Rewards Program. This includes the liability stemming from the accumulation or redemption of Rewards. As a prerequisite for your ongoing utilization of the Website and our services, we retain the right to request essential tax reporting information if our records indicate that you may be required to report the value of your redeemed Rewards to the relevant tax authority. We strongly recommend and emphasize that it is your responsibility to seek counsel from a tax professional to ascertain the tax implications of your use of the Website, participation in the Rewards Program, and any associated receipt or redemption of Rewards.`
        },
        {
            id: 'user-website',
            title: 'Access and Utilization of Our Website',
            content: `Subject to all the stipulations within these Terms of Service, PaidCash hereby grants you a limited, revocable, non-transferable, individual, and non-exclusive privilege to access and utilize the Website in accordance with the provisions herein. You commit to employing our Website solely for purposes that align with these Terms of Service and any pertinent laws, regulations, or commonly accepted practices within the relevant jurisdictions. Furthermore, you affirm that all data you provide during registration on this Website or through the utilization of any interactive features is subject to our Privacy Policy, and you consent to all actions we take regarding your information in accordance with our Privacy Policy. For more comprehensive information regarding the use of the Website, please refer to Section 16 (Utilization of the Website) and Section 17 (Limitations and Forbidden Activities).`
        },
        {
            id: 'account-inactive',
            title: 'Inactive Accounts',
            content: `Any Account that has not been accessed, and Rewards either received or redeemed, for a period of one (1) year or more may be classified as inactive, and the Account may be terminated. We may also modify our policies and regulations concerning inactive Accounts in our Rewards Programs periodically. If your Account becomes inactive in accordance with the prevailing rules or policies at that time, we may proceed to close your Account without any compensation or further obligations to you regarding the closed Account. If you have any inquiries or concerns about these measures or would like to request the reactivation of your Account (subject to any terms, restrictions, and conditions that we may apply from time to time), please reach out to us at hello@paidcash.co.`
        },
        {
            id: 'rewards-programs',
            title: 'Programs Involving Rewards',
            content: `PaidCash may introduce one or more rewards programs ("Rewards Programs") in which you may have the chance to acquire points, also known as coins, dollar equivalents, points, and other credits/rewards (collectively referred to as "Rewards"), associated with your involvement in or interaction with various game offers, advertisements, content, shopping opportunities, special offers, surveys, coupons, location-based deals, and other opportunities for Rewards (collectively known as "Offers").

            Irrespective of whether they are denominated as points or dollar amounts, it should be noted that Rewards have no intrinsic cash, monetary, or equivalent value. They are solely redeemable for gift cards or other prizes, or through redemption methods as offered by the Rewards Program at the time, collectively referred to as "Redemption Prizes." These redemptions are subject to compliance with these Terms of Service and the Redemption Prizes requirements outlined by the Rewards Program at the time of redemption.`,
        },
        {
            id: 'rewards-value',
            title: 'Rewards Hold No Cash Value and Are Non-Transferable',
            content: `You consent and recognize that unredeemed Rewards in your Account possess no cash, monetary, or equivalent worth, and they continue to be the exclusive property of PaidCash. They can only be exchanged for Redemption Prizes as determined by PaidCash within the Rewards Program, subject to these Terms of Service and the redemption conditions stipulated by the Rewards Program at the moment of redemption.`
        }, {
            id: 'rewards',
            title: 'Rewards',
            content: `You may acquire Rewards within a Rewards Program by engaging in various Offers, as detailed in the respective Rewards Program. Subject to the other clauses outlined in these Terms of Service, PaidCash will credit any Rewards earned from Offers you choose to participate in and successfully complete to the account you established upon joining the Rewards Program (referred to as the "Account").<br/>
            <br/>
            Rewards are considered successfully completed when you have fully and appropriately fulfilled all the requirements of the Offer, as specified in the Offer terms (including any time constraints or waiting periods for Offer acceptance or completion), in accordance with these Terms of Service. Additionally, PaidCash and/or its third-party Rewards Program partners must be able to properly verify, track, and record your progress.<br/>
            <br/>
            To clarify, PaidCash will not be held responsible for, nor obliged to award Rewards for, any Offer that is not accurately recorded, tracked, validated, and deemed a successfully completed Reward as per the policies, procedures, and systems of PaidCash and/or its third-party Rewards Program associates. In certain instances, the final determination of whether an Offer has been successfully completed and should result in a Reward may be made by PaidCash's third-party Rewards Program associates instead of PaidCash itself.<br/>
            <br/>
            We urge you to thoroughly review all applicable terms governing Offers and Rewards before deciding whether or not to participate. Furthermore, we reserve the right to: (i) modify or restrict the availability of Offers or Rewards, or your ability to participate in specific Offers or the Rewards Program itself, (ii) modify or restrict eligibility for or the allowable frequency of Offers, and (iii) modify or restrict the number or type of Rewards that can be obtained for a particular Offer or within a specific timeframe. These changes or limitations shall apply to all past, present, or future Rewards, unless explicitly stated otherwise.`,

        },
        {
            title: 'Redemption of Rewards',
            id: 'redemption-rewards',
            content: `Subject to the other provisions of these Terms of Services, you may redeem accumulated Rewards in your Account for Redemption Prizes pursuant to the applicable Rewards Program redemption requirements at the time of redemption.
            Supplies of Redemption Prizes may be limited. Redemption Prizes may be awarded or redeemed on a first-come, first-serve, and while-supplies-last basis. If you attempt to redeem Rewards for a Redemption Prize and PaidCash determines that the Redemption Prize is unavailable, out of stock, or for whatever reason cannot be provided to you, PaidCash may, in its sole and absolute discretion, provide you with a Redemption Prize of equal or greater value.
            All redemptions are final, and rewards may not be returned for credit except as otherwise provided in these Terms of Services or as otherwise agreed to in writing by an authorized representative of PaidCash.
            No re-crediting, reversal, or refund of Rewards will be issued for any reason after Rewards have been successfully redeemed. Once you order and receive a Redemption Prize, you may not reverse or cancel the Redemption Prize transaction or return the Redemption Prize for a refund or re-crediting of Rewards to your Account.
            PaidCash reserves the right to temporarily withhold any offer credits for up to 90 days after an Offer has been completed.`

        },
        {
            title: 'Prizes Redemption',
            id: 'prizes-redemption',
            content: `Redemption Prizes are in digital form and will be delivered electronically to the email address linked to your Account at the time or as specified in the relevant Rewards Program.<br/>
            <br/>
            In cases where we are unable to deliver a Redemption Prize or if it remains unclaimed for any reason, you hereby authorize and instruct us, where feasible, to cancel or reverse the redemption for that specific Redemption Prize and reinstate the associated Rewards to your Account within the applicable Rewards Program. This allows you the opportunity to attempt to redeem those Rewards at a later date. It's important to note that we shall not be held responsible or liable to you for any inability or failure to cancel or reverse the redemption and reinstate the associated Rewards to your Account.`
        },
        {
            title: `Identity and Eligibility Verification`,
            id: 'identity-eligibility-verification',
            content: `PaidCash also retains the right to conduct identity verification to our full satisfaction prior to crediting or allowing redemption of any Rewards, issuing or delivering any Redemption Prizes in any Rewards Program, or granting you access to any benefits associated with the Website, its features, or your Account. This verification may be carried out by requesting, either directly or indirectly through a third-party verification service, that you furnish a photocopy of your passport, driver's license, or state ID card. We may also require you to confirm the mobile phone number linked to your Account or provide any other proof of identity or eligibility that we may deem necessary. In certain cases, some of our services or Redemption Prizes may have additional eligibility prerequisites, and in such instances, PaidCash also retains the right to determine or verify your eligibility using methods it considers appropriate. It's important to note that the failure or refusal to provide requested documentation or proof of identity or eligibility may result in the denial of access to the Website or any benefits associated with your Account.
            `
        }, {
            title: 'Expiration of Rewards',
            id: 'expiration-rewards',
            content: `We may also implement or revise our policies and regulations regarding the expiration of Rewards for any previously acquired, current, or forthcoming Rewards at our discretion. If your Rewards expire in accordance with the prevailing rules or policies at that time, we reserve the right to deduct such expired Rewards from your Account, without any obligation or compensation to you for the expired Rewards.<br/>
            <br/>
            Should you have inquiries or concerns regarding these actions or if you wish to request the reinstatement of expired points to your Account (subject to any terms, restrictions, and conditions we may establish from time to time), please contact us at hello@paidcash.co.`
        },
        {
            title: 'Sweepstakes, Contests and Promotions',
            id: 'sweepstakes-contests-promotions',
            content: `Any sweepstakes, contests, or promotions (referred to collectively as "Promotions") that may be offered through any of the Website's features or our services may be subject to additional terms of service specifically related to promotions ("Additional Promotional Terms"). These Additional Promotional Terms, which may include official rules, can outline eligibility criteria, such as age or geographic restrictions, terms and conditions, and details governing the use of your personal information.<br/>
            <br/>
            In the event of any conflict between these Terms of Service and the Additional Promotional Terms, the Additional Promotional Terms shall take precedence.<br/>
            <br/>
            It is your responsibility to review any applicable Additional Promotional Terms to determine whether you wish to participate, enter, or register for the Promotions and whether you meet the eligibility requirements. By taking part in a Promotion, you agree to comply with and abide by such Additional Promotional Terms and the decisions made by the sponsor(s) of the selected Promotions.<br/>
            <br/>
            Please be aware that the sponsors or partners of the Promotions may have their own terms and conditions, which you should review carefully.`
        },
        {
            title: 'Your User Account',
            id: 'user-account',
            content: `To access the Website or certain resources it offers, you may be required to provide specific registration details and other information. It is a fundamental condition of your use of the Website that all the information you furnish on the Website is accurate, up-to-date, and complete.<br/>
            <br/>
            If you opt to use or are provided with a username, password, or any other piece of information as part of our security protocols, it is imperative that you maintain the confidentiality of such information. You must not disclose it to any other individual or entity. Additionally, you acknowledge that your account is for your personal use only, and you commit not to grant any other person access to this Website or any of its sections using your username, password, or other security information. You also agree to promptly notify us of any unauthorized access or use of your username or password, or any other security breach. Furthermore, you agree to ensure that you log out of your account at the conclusion of each session. When accessing your account from a public or shared computer, exercise particular caution to prevent others from viewing or recording your password or other personal information.<br/>
            <br/>
            We retain the right to deactivate any username, password, or other identifier, whether chosen by you or provided by us, at our sole discretion, for any reason or without reason, including if we believe that you have breached any provision of these Terms of Service. We also reserve the right to alter or discontinue this Website, including any features, services, or materials we offer on the Website, at our sole discretion, without prior notice.<br/>
            <br/>
            On occasion, we may restrict access to specific parts of the Website or the entire Website to users, including registered users. We will not be held responsible if, under any circumstances: (i) any portion or the entirety of the Website becomes unavailable at any time or for any duration, or (ii) we prevent you from using the site.`
        },
        {
            title: 'Utilization of the Website',
            id: 'utilization-website',
            content: `These Terms of Service grant you permission to use the Website solely for your personal, non-commercial purposes. You must not reproduce, distribute, modify, create derivative works from, publicly display, publicly perform, republish, download, store, or transmit any material from our Website, except under the following conditions:<br/>
            <br/>
            Your computer may temporarily store copies of these materials in RAM as a result of your access and viewing of them.
            Your web browser may automatically cache files for display improvement purposes.
            You may print or download a single copy of a reasonable number of Website pages for your personal, non-commercial use, and not for further reproduction, publication, or distribution.
            If we offer desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your personal, non-commercial use, provided that you agree to be bound by our end-user license agreement for such applications.<br/>
            You must not:<br/>
            <br/>
            Modify copies of any materials from this site.
            Use any illustrations, photographs, videos, audio sequences, or graphics separately from the accompanying text.
            Remove or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.
            You must not access or use any part of the Website, its features, services, or materials available through the Website for commercial purposes.<br/>
            <br/>
            If you print, copy, modify, download, or otherwise use or provide access to any part of the Website in violation of these Terms of Service, your right to use the Website will be terminated immediately, and, at our discretion, you must either return or destroy any copies of the materials you have made. No ownership rights, titles, or interests in the Website or its content are transferred to you, and all rights not expressly granted are retained by PaidCash. Any use of the Website not explicitly permitted by these Terms of Service is a violation of these Terms and may infringe on copyright, trademark, and other laws.`
        },
        {
            title: 'Limitations and Forbidden Activities',
            id: 'limitations-forbidden-activities',
            content: `You may use the Website solely for lawful purposes and in accordance with these Terms of Services. You agree not to use the Website:<br/>
            <br/>
        In any manner that violates any applicable federal, state, local, or international law or regulation, including, but not limited to, any laws concerning the export of data or software to and from the US or other countries.<br/>
        <br/>
        With the intention of exploiting, harming, or attempting to exploit or harm minors in any way, including exposing them to inappropriate content, soliciting personally identifiable information, or engaging in any harmful activities.<br/>
        <br/>
        To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content Standards set out in these Terms of Services.<br/>
        <br/>
        To transmit or facilitate the transmission of any advertising or promotional material, including any "junk mail," "chain letter," "spam," or similar unsolicited solicitations.<br/>
        <br/>
        To impersonate or attempt to impersonate PaidCash, a PaidCash employee, another user, or any other individual or entity, including using email addresses or screen names associated with any of the aforementioned.<br/>
        <br/>
        To engage in any other behavior that restricts or inhibits the use or enjoyment of the Website by anyone else, or that, as determined by us, may harm PaidCash or other users of the Website, or expose them to liability.<br/>
        <br/>
        To engage in fraudulent activities, which may include, but are not limited to, concealing or falsifying your identity, providing false information during the registration process, submitting false or untrue data, attempting to redeem Rewards through deceptive or fraudulent means, or manipulating surveys or Offers.<br/>
        <br/>
        In addition, you agree not to:<br/>
        <br/>
        Use the Website in any way that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real-time activities through the Website.<br/>
        <br/>
        Use any automated devices, such as robots or spiders, or any other automatic methods to access the Website for any purpose, including monitoring or copying any of the Website's material.<br/>
        <br/>
        Use any manual processes to monitor or copy any of the Website's material or for any other purpose not expressly authorized in these Terms of Services, without our prior written consent.<br/>
        <br/>
        Use any device, software, or routine that interferes with the proper functioning of the Website.<br/>
        <br/>
        Use fraudulent means to access the Website. Therefore, you agree not to use VPNs, proxies, emulator software on our Website, and you will not use fake SMS numbers, Google Voice, or VOIP when verifying your phone number.<br/>
        <br/>
        Introduce any viruses, trojan horses, worms, logic bombs, or other malicious or technologically harmful materials.<br/>
        <br/>
        Attempt to gain unauthorized access to, interfere with, damage, or disrupt any part of the Website, the server on which the Website is hosted, or any server, computer, or database connected to the Website.<br/>
        <br/>
        Launch an attack on the Website, such as a denial-of-service attack or a distributed denial-of-service attack.<br/>
        <br/>
        Otherwise attempt to interfere with the proper operation of the Website.`
        },
        {
            title: 'Intellectual Property Rights',
            id: 'intellectual-property-rights',
            content: `The Website, along with its entire contents, features, and functionalities (including, but not limited to, all information, software, text, displays, images, video and audio, and the arrangement and design thereof), are the property of PaidCash, its licensors, or other providers of such materials and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.<br/>
            <br/>
            The PaidCash name, PaidCash trademarks, PaidCash logo, and all related names, logos, product and service names, designs, and slogans are trademarks of PaidCash or its affiliates or licensors. You must not use these marks without the prior written permission of PaidCash. All other names, logos, product and service names, designs, and slogans found on this Website are the trademarks of their respective owners.`,

        },
        {
            title: 'Changes to the Website',
            id: 'changes-website',
            content: `We may periodically update the content on this Website; however, the content may not always be complete or current. Any material on the Website may become outdated, and we are not obligated to update such material.`,
        },
        {
            title: 'Links from the Website',
            id: 'links-website',
            content: `If the Website includes links to other websites and resources provided by third parties, these links are provided solely for your convenience. This includes links found in advertisements, such as banner advertisements and sponsored links. We have no control over the content of these sites or resources and assume no responsibility for them or any loss or damage that may result from your use of them. If you choose to access any of the third-party websites linked to this Website, you do so entirely at your own risk and are subject to the terms and conditions of use of those websites.
            `
        },
        {
            title: 'Copyright Policy',
            id: 'copy-right-policy',
            content: `If you believe that any User Contributions infringe upon your copyright, please refer to our Copyright Policy for guidance on how to submit a notice of copyright infringement to us. PaidCash has a policy to terminate the user accounts of individuals who repeatedly infringe upon copyright.`
        },
        {
            title: 'Reliance on Information Posted',
            id: 'reliance-information-posted',
            content: `The information presented on or via the Website is made accessible solely for general information purposes. We make no assurances regarding the accuracy, comprehensiveness, or usefulness of this information. Your reliance on such information is entirely at your own discretion. We disclaim any liability and responsibility arising from your reliance on these materials or that of any other visitor to the Website, or from anyone who may come across its contents.<br/>
            <br/>
            This Website contains content furnished by third parties, including materials contributed by other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All opinions and viewpoints expressed in these materials, along with all articles and responses to inquiries and other content, except for the content provided by PaidCash, are exclusively the opinions and responsibility of the individuals or entities providing those materials. These materials do not necessarily represent the viewpoint of PaidCash. We are not answerable or accountable to you or any third party for the content or accuracy of any materials presented by third parties.`
        },
        {
            title: 'Utilizing Website Links and Social Media Functionalities',
            id: 'utilizing-website-links-social-media-functionalities',
            content: `Certainly, here's a rearranged and slightly revised version of your provided text for your Terms of Service:<br/>
            <br/>
            Linking to the Website and Using Social Media Features<br/>
            <br/>
            You are permitted to link to our website's homepage, provided that you do so in a manner that is both fair and lawful, and that does not harm our reputation or exploit it. However, you must refrain from establishing a link that might imply any form of association, endorsement, or approval on our part.<br/>
            <br/>
            Should you wish to share a link to this website using our Affiliate feature to invite a family member or friend, you must first obtain their consent to use their personal information for sending the invitation from our website.<br/>
            <br/>
            This website may offer certain social media features that enable you to:<br/>
            <br/>
            Link to specific content on this website from your own or certain third-party websites.
            Send emails or other communications containing specific content or links to specific content on this website.
            Display limited portions of content from this website, or create the appearance of displaying such content, on your own or certain third-party websites.
            You may use these features exclusively as they are provided by us and solely in connection with the content they are associated with. Except as expressly permitted herein, you must not:<br/>
            <br/>
            Establish a link from any website that you do not own.
            Display or appear to display the website or its portions using methods such as framing, deep linking, or in-line linking on any other site.<br/>
            Engage in any action concerning the materials on this website that contradicts any other provision of these Terms of Service.<br/>
            The website to which you link, or on which you make specific content accessible, must fully comply with the Content Standards outlined in these Terms of Service.<br/>
            <br/>
            You hereby agree to cooperate with us to promptly terminate any unauthorized framing or linking. We reserve the right to withdraw linking permission without prior notice.<br/>
            <br/>
            We retain the discretion to disable any or all social media features and links at any time, without prior notice.`,
        },
        {
            title: 'Contributions by Users',
            id: 'contributions-users',
            content: `Certainly, here's a rephrased version of the provided text for your Terms of Service:<br/>
            <br/>
            "The Website may offer various interactive features, including message boards, chat rooms, personal web pages, profiles, forums, bulletin boards, and similar features (collectively referred to as 'Interactive Services'), which enable users to post, submit, publish, display, or transmit content or materials ('User Contributions') on or through the Website.<br/>
            <br/>
            All User Contributions must adhere to the Content Standards outlined in these Terms of Service. Please note that any User Contribution you post on the Website is considered non-confidential and non-proprietary. By providing any User Contribution on the Website, you grant us, our affiliates, service providers, and their respective licensees, successors, and assigns the right to utilize, modify, perform, display, distribute, and otherwise share such material with third parties in accordance with your account settings.<br/>
            <br/>
            You affirm and warrant that:<br/>
            <br/>
            You possess or control all rights to the User Contributions and have the authority to grant the license described above to us, our affiliates, service providers, and their respective licensees, successors, and assigns.<br/>
            <br/>
            All of your User Contributions comply with these Terms of Service.<br/>
            <br/>
            You understand and acknowledge that you bear the responsibility for any User Contributions you submit or contribute. You, not PaidCash, assume full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.<br/>
            <br/>
            We disclaim any responsibility or liability to any third party for the content or accuracy of any User Contributions posted by you or any other user of the Website."`
        },
        {
            title: 'Monitoring and Enforcement. Termination',
            id: 'monitoring-enforcement-termination',
            content: `We retain the right to:<br/>
            <br/>
            Remove or decline to publish any User Contributions at our discretion, without specifying any reason.
            Take any actions concerning any User Contribution that we consider necessary or appropriate, solely at our discretion. This includes instances where we believe the User Contribution violates the Terms of Service, including the Content Standards, infringes on intellectual property or other rights of any individual or entity, poses a threat to the safety of Website users or the public, or could potentially create liability for PaidCash.
            Share your identity or other information about you with any third party claiming that content posted by you infringes on their rights, such as intellectual property rights or privacy.
            Pursue suitable legal action, including but not limited to, reporting to law enforcement, in response to any illegal or unauthorized use of the Website.
            Terminate or suspend your access to the entire Website or parts of it, for any reason or without specifying a reason, including violations of these Terms of Service.
            Restrict, suspend, or terminate your participation in a Rewards Program at our sole discretion. We may also suspend or void any Rewards or potential Rewards you have accumulated in a Rewards Program but have not yet redeemed if we determine, at our sole discretion, that you have not adhered to these Terms of Service.
            Modify, suspend, or cancel all or part of a Rewards Program, including any unredeemed Rewards, at any time, without prior notice or compensation to you.
            Please note that we may fully cooperate with law enforcement authorities or court orders requesting or directing us to disclose the identity or other information of anyone who posts materials on or through the Website. YOU WAIVE AND RELEASE PAIDCASH AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS ARISING FROM ACTIONS TAKEN BY ANY OF THESE PARTIES DURING OR AS A RESULT OF INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY THESE PARTIES OR LAW ENFORCEMENT AUTHORITIES.<br/>
            <br/>
            We are unable to review all material before it is posted on the Website and cannot guarantee the immediate removal of objectionable material once posted. Therefore, we assume no liability for any actions or inactions related to transmissions, communications, or content provided by any user or third party. We bear no liability or responsibility to any party for the execution or non-execution of the activities described in this section.`,
        },
        {
            title: 'Content Standards',
            id: 'content-guidelines',
            content: `These content standards apply to all User Contributions and the use of Interactive Services. Your User Contributions must fully comply with all applicable federal, state, local, and international laws and regulations. Your User Contributions must not, among other things:<br/>
            <br/>
            Contain material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.<br/>
            <br/>
            Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.<br/>
            <br/>
            Infringe upon any patent, trademark, trade secret, copyright, or other intellectual property or proprietary rights of any other person.<br/>
            <br/>
            Violate the legal rights, including rights of publicity and privacy, of others, or contain material that could lead to civil or criminal liability under applicable laws or regulations, or that may be in conflict with these Terms of Services and our Privacy Policy.<br/>
            <br/>
            Be likely to deceive any person.<br/>
            <br/>
            Manipulate the results of any survey, prize draw, or contest.<br/>
            <br/>
            Promote any illegal activity, or advocate, promote, or assist any unlawful act.<br/>
            <br/>
            Cause annoyance, inconvenience, or needless anxiety, or be likely to upset, embarrass, alarm, or annoy any other person.<br/>
            <br/>
            Impersonate any person or misrepresent your identity or affiliation with any person or organization.<br/>
            <br/>
            Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.<br/>
            <br/>
            Give the impression that they originate from or are endorsed by us or any other person or entity if this is not the case.`,

        },
        {
            title: ' Disclaimer of Warranties',
            id: 'disclaimer-warranties',
            content: `You acknowledge that we cannot guarantee or warrant that files available for download from the internet or the Website will be free of viruses or other destructive code. It is your responsibility to implement adequate procedures and safeguards to meet your specific requirements for anti-virus protection, data accuracy, and data backup. WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, OR YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.<br/>
            <br/>
            YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER PAIDCASH NOR ANY PERSON ASSOCIATED WITH PAIDCASH MAKES ANY WARRANTY OR REPRESENTATION REGARDING THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER PAIDCASH NOR ANYONE ASSOCIATED WITH PAIDCASH REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.<br/>
            <br/>
            PAIDCASH HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.<br/>
            <br/>
            THE FOREGOING DISCLAIMERS DO NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.`,
        },
        {
            title: `Limitation on Liability`,
            id: 'limitation-liability',
            content: `To the fullest extent permitted by applicable law, PaidCash, its affiliates, licensors, service providers, employees, agents, officers, or directors shall not be liable for any damages of any nature, under any legal theory, arising from or related to your use or inability to use the website, any websites linked to it, any content on the website or such other websites, or any services or items obtained through the website or such other websites. This includes, without limitation, direct, indirect, special, incidental, consequential, or punitive damages. Such damages may encompass personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, or loss of data, whether caused by tort (including negligence), breach of contract, or otherwise, even if such damages were foreseeable.<br/>
            <br/>
            Please note that the above provision does not affect any liability that cannot be excluded or limited under applicable law.`,
        },
        {
            title: 'Indemnification',
            id: 'indemnification',
            content: `You agree to act as a defender and protector, indemnifying and absolving PaidCash, along with its affiliates, licensors, service providers, and all their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns, from any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees). These claims may arise due to your violation of these Terms of Services or your utilization of the Website. This includes, but is not limited to, your User Contributions, any usage of the Website's content, services, and products beyond what is explicitly authorized in these Terms of Services, or your utilization of any information acquired from the Website.`,
        },
        {
            title: 'Governing Law and Jurisdiction',
            id: 'governing-law-jurisdiction',
            content: `
            If you are a consumer, it's important to understand that these Terms of Services, their content, and how they are established are regulated by the laws of (country) Romania. Both you and we concur that the courts located in the (country) Romania will possess exclusive jurisdiction over any matters related to these Terms of Services.`
        },
        {
            title: 'Limitation on Time to File Claims',
            id: 'arbitration',
            content: `Any claim or cause of action you may have arising out of or relating to these Terms of Services or the Website must be commenced within one (1) year after the claim or cause of action accrues, otherwise, it will be permanently barred. This provision applies to both legal and equitable claims.`
        },
        {
            title: 'Waiver and Severability',
            id: 'waiver-severability',
            content: `The Terms of Services, our Privacy Policy, any Additional Promotional Terms, and Copyright Policy constitute the sole and entire agreement between you and PaidCash with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website.`
        },
        {
            title: 'Entire Agreement',
            id: 'entire-agreement',
            content: `
            The Terms of Services, our Privacy Policy, any Additional Promotional Terms, and Copyright Policy constitute the sole and entire agreement between you and PaidCash with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website.`
        },
        {
            title: 'Comments and Concerns',
            id: 'comments-concerns',
            content: `All notices of copyright infringement claims should be sent to the copyright agent designated in our Copyright Policy in the manner and by the means set forth therein.<br/>
            <br/>
            All other feedback, comments, requests for technical support and other communications relating to the Website should be directed to: hello@paidcash.co`
        }

    ]
    return (
        <div className='terms-page'>
            <TopTOSCOntainer edited={lastEdited} title={'Terms of Service'} second_page={props.second_page}/>
            {TermsOfServicePage.map((item, index) => {
                return (
                        <div className="terms-item"  id={item.id} key={index}>
                            <div className="terms-title" >{index + 1}{' -'} {item.title}</div>
                            <div className='terms-content' dangerouslySetInnerHTML={{ __html: item.content }} />
                            {index == 0 ?
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', fontSize: '12px' }}>
                                    {TermsOfServicePage.map((item, index) => {
                                        return <div className='tos-al' style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div >{index + 1}</div> <a href={`#${item.id}`} key={index}> {`) ${item.title}`}</a>
                                            </div>
                                    })}
                                </div> : ''}
                        </div>

                )
            })}
        </div>
    )
}