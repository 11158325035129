import { useEffect } from "react"
import { Last24Months, Last7Days, Last7Months, numberWithCommas } from "../utils";


function ChartComp2(props) {
  useEffect(() => {
    const ctx = document.getElementById(props.chartID);
    const myChart = new window.Chart(ctx, {
      type: 'line',

      data: {

        labels: props.walls != undefined ? props.walls : props.dataLength == 24 ? Last24Months().split(",").reverse() : props.title.toLowerCase().includes("month") ? Last7Months().split(",").reverse() : Last7Days().split(",").reverse(),
        datasets: [{
          label: `${props.title}`,
          displayColors: false,
          data: props.data,
          fill: true,
          borderColor: 'red',
          tension: 0.3

        }, {
          label: `${props.title2}`,
          displayColors: false,
          data: props.data2,
          fill: true,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.3

        }, {
          label: `${props.title3}`,
          displayColors: false,
          data: props.data3,
          fill: true,
          borderColor: 'green',
          tension: 0.3

        },
        {
          label: `${props.title4}`,
          displayColors: false,
          data: props.data4,
          fill: true,
          borderColor: 'darkred',
          tension: 0.3

        }]
      },
      options: {
        showAlltTooltips: false,

        tooltips: {
          custom: function (tooltip) {
            if (!tooltip) return;
            // disable displaying the color box;
            tooltip.displayColors = false;
          },
          callbacks: {
            // use label callback to return the desired label
            label: function (tooltipItem, data) {
              return tooltipItem.xLabel + " - " + numberWithCommas(tooltipItem.yLabel) + ` ${props.obj}`;
            },
            // remove title
            title: function (tooltipItem, data) {
              return;
            }
          }
        },
        legend: {
          display: true,
          onClick: () => { return null },
          labels: {
            fontColor: '#93acd3',
            fontSize: 15,
            boxWidth: 0,

          }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: "#55657e",
              fontSize: 12
            },
            gridLines: {
              color: "transparent",
              lineWidth: 2
            }
          }],
          xAxes: [{
            ticks: {
              fontColor: "#55657e",
              fontSize: 12
            },
            gridLines: {
              color: "transparent",
              lineWidth: 2
            }
          }],

          y: {
            beginAtZero: true,

          },

        },
        chartArea: {
          backgroundColor: 'rgba(251, 85, 85, 0.4)'
        }
      },

    });
  }, [])
  return (
    <canvas id={props.chartID}></canvas>
  )
}

export default ChartComp2;