import { initializeApp } from "@firebase/app";
import { getAuth } from "@firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA45v1Z2WarXtl7xfxz3fe2pLFcz7Pclow",
  authDomain: "faucetify.firebaseapp.com",
  projectId: "faucetify",
  storageBucket: "faucetify.appspot.com",
  messagingSenderId: "870119466429",
  appId: "1:870119466429:web:40ed85feab15849b4b5ed4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
