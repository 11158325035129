import { Link } from "react-router-dom"
import { ModalContent } from "./ModalContent"
import { createRef, useEffect, useState } from "react";
import { CreateToast, getOfferWindowStatus } from "../../components/utils";
import { socket } from "../../components/Containers/MainContainer";
import ReCAPTCHA from "react-google-recaptcha";


export const FaucetModalContent = (props) => {
    const [captchaInstance, setCaptchaInstance] = useState(null);
    const [earnState, setEarnState] = useState({ token: '' })
    const creaRef = createRef();
    const [offerWindowFull, setOfferWindowFull] = useState(getOfferWindowStatus());
    useEffect(() => {
        setOfferWindowFull(getOfferWindowStatus())
    }, [])
    const [isAdBlocker, setAdBlocker] = useState(false);
    useEffect(() => {
        socket.on('resetcapthca_login', () => {
            captchaRef.current.reset();
        })
     
        
    }, [])
    const [timer, setTimer] = useState(21);
    const [timerMessage, setTimerMessage] = useState("Wait 8s");
    const [update, setUpdate] = useState(false);
    const [CanRun, setCanRun] = useState(false)
    const [page, setPage] = useState(0)
    const captchaRef = createRef();
    const [faucetData, setFaucetData] = useState({})
    useEffect(function () {

        socket.on('good_capthca_faucet', () => {
            setPage(2)
        })
        if (CanRun == true) {
            setTimeout(function () {
                setTimer(timer - 1);
                if (timer > 8) {

                    setTimerMessage(getTime(faucetData.time))
                } else {
                    setTimerMessage(`Wait ${timer}s`)

                }
                if (timer > 0) {
                    setUpdate(update == true ? false : true);
                } else {
                }

            }, 1000 * 1)
        }
    }, [update])
    const [canWait, setCanWait] = useState();
    useEffect(() => {
        socket.on('faucetData', (data) => {
            setCanWait(data.canWait)
            setTimeout(() => {

                if (data.canWait == true) {
                    setTimer(50)
                    setTimerMessage(data.time)
                    setFaucetData(faucetData);
                    setCanRun(false)

                } else {

                    setTimer(8)
                    setTimerMessage("Wait 8s")
                    setPage(0)
                    setCanRun(true)

                    setUpdate(update == true ? false : true)

                }
            }, 1000)
        })
        socket.emit('emitFaucetData')

        // setCanRun(false)
        // setTimer(8)
        // setTimerMessage("Wait 8s")
        // setPage(0)

    }, [])
    return (
        <ModalContent 
        click={props.click}
        hideTitle={true}
        hide={!props.show}
        title={props.title} 
        className={`modal-content-${props.modalSize}`}
        content={
            <>

                <div  style={{ width: '100%', height: '100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
  
                    {page == 0 ? <AAdsAd /> : <AAdsAd />}
                    {page == 0 ? <AAdsAd2 /> : <AAdsAd2 />}
                    <div  style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>


                        <div className='card' style={{ background: '#202937', textAlign: 'center', maxWidth: '300px', margin: '0 auto', marginTop: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', border: 'none', padding: '20px' }}>
                            <h1 style={{ color: '#fff' }}>Faucet</h1>
                            {isAdBlocker == true ? <p style={{ color: '#93acd3' }}>
                                Ad Blocker Detected. Disabled it and refresh the page in order to be able to claim.
                            </p> : page == 1 ? <>
                            <ReCAPTCHA
                            sitekey="6LeE9VsjAAAAAJ4T3_cd2KhZlrpm09CqY2x3rVZc"
                            ref={captchaRef} />
                                <button onClick={function (e) {
                                    if (timer <= -1 && timer != -699) {
                                        if (captchaRef.current.getValue().split("").length > 5) {
                                            e.target.innerText = "Claiming.."
                                            setPage(2)
                                            socket.emit('userAction', {
                                                action: 'claim_faucet',
                                                token: earnState.token,
                                                token: captchaRef.current.getValue(),
                                            })
                                        }else{
                                            CreateToast('Captcha is invalid', creaRef, 1)
                                        }



                                    } else {
                                        e.preventDefault();
                                    }
                                }} className='claimBtn' style={{ textDecoration: 'none', backgroundColor: '#21838a', border: 'none', borderRadius: '2px', cursor: timer <= -1 ? 'pointer' : 'not-allowed', color: '#f3f3f3', padding: '7px 20px', fontSize: '12px', marginTop: '20px' }} target="_blank">{timer <= -1 ? "Claim Free Coins Now" : timerMessage}</button>
                            </> : page == 0 ? <>
                                <p style={{ marginBottom: '0px', color: 'var(--text-primary)' }}>Claim up to 100 coins & 1 XP each 30 minutes</p>
                                <button onClick={function (e) {
                                    if (timer <= -1 && timer != -699) {
                                        setPage(1)
                                        setTimer(8)
                                        setTimerMessage("Wait 8s")
                                        setCanRun(true)
                                        setUpdate(update == true ? false : true)
                                    } else {
                                        e.preventDefault();
                                    }
                                }} className='claimBtn' style={{ textDecoration: 'none', backgroundColor: '#21838a', border: 'none', borderRadius: '2px', cursor: timer <= -1 ? 'pointer' : 'not-allowed', color: '#f3f3f3', padding: '7px 20px', fontSize: '12px', marginTop: '20px' }} target="_blank">{timer <= -1 ? "Go to Faucet" : timerMessage}</button>
                            </> : <p style={{ marginBottom: '0px', color: 'var(--text-primary)' }}>Come back later!</p>}</div>
                    </div>
        </div>
        </>
        }/>
    )
}


function AAdsAd() {
    const [changeAd, setChangeAd] = useState(false)
    useEffect(function () {
        //  setTimeout(function(){
        //     setChangeAd(changeAd==true?false:true);
        //  },1000*60)
    }, [changeAd])

    return (
        changeAd ? <div style={{
            width: '728px',
            height: '90px', minHeight: '90px', maxHeight: '90px', margin: '0 auto'
        }}>  <iframe data-aa='2188245' src='//ad.a-ads.com/2188245?size=728x90' style={{
            width: '728px',
            height: '90px', minHeight: '90px', maxHeight: '90px', border: '0px', margin: '0 auto', padding: '0', overflow: 'hidden', backgroundColor: 'transparent'
        }}></iframe></div>
            : <div style={{
                width: '728px',
                height: '90px', minHeight: '90px', maxHeight: '90px', margin: '0 auto'
            }}>  <iframe data-aa='2188243' src='//ad.a-ads.com/2188243?size=728x90' style={{
                width: '728px',
                height: '90px', minHeight: '90px', maxHeight: '90px', border: '0px', margin: '0 auto', padding: '0', overflow: 'hidden', backgroundColor: 'transparent'
            }}></iframe></div>

    )

}
function AAdsAd2() {
    const [changeAd, setChangeAd] = useState(false)
    useEffect(function () {
        setTimeout(function () {
            setChangeAd(changeAd == true ? false : true);
        }, 1000 * 60)
    }, [changeAd])

    return (
        changeAd ? <div style={{
            width: '728px',
            height: '90px', minHeight: '90px', maxHeight: '90px', margin: '0 auto'
        }}>  <iframe data-aa='2188243' src='//ad.a-ads.com/2188243?size=728x90' style={{
            width: '728px',
            height: '90px', minHeight: '90px', maxHeight: '90px', border: '0px', margin: '0 auto', padding: '0', overflow: 'hidden', backgroundColor: 'transparent'
        }}></iframe></div>
            : <div style={{
                width: '728px',
                height: '90px', minHeight: '90px', maxHeight: '90px', margin: '0 auto'
            }}> <iframe data-aa='2188245' src='//ad.a-ads.com/2188245?size=728x90' style={{
                width: '728px',
                height: '90px', minHeight: '90px', maxHeight: '90px', border: '0px', margin: '0 auto', padding: '0', overflow: 'hidden', backgroundColor: 'transparent'
            }}></iframe></div>

    )



}
const getTime = (time) => {
    let date = new Date(time);
    let minutes = date.getMinutes() > 30 ? date.getMinutes() - 30 : date.getMinutes()
    let newTime = minutes + ":" + date.getSeconds();
    return newTime
}