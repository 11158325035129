'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';
import React from 'react'
import PaginationPluto from './Pagination';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useEffect } from 'react';
import { useState } from 'react';
import PlutoMenu from './PlutoMenu';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';

function PlutoWithdrawalsToday() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [length, setLength] = useState(0)
    const [rank, setRank] = useState(0)
    const [uid, setUID] = useState(-1)
    useEffect(() => {
        socket.on('response_get_admin_withdrawalstoday', (data) => {
            setEarnings(data.users)
            setPageNumber(Math.ceil(data.usersLength / 10))
            setLength(data.usersLength)
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)
            setUID(data.uid);

        })
        socket.emit('getWithdrawalsAdminToday', {
            username_like: username,
            page,
        })
    }, [])

    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {length != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(length)}
                            </p>
                            <p>Flagged/Review Today</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}
                </div>

                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Users</p>

                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '50px', textAlign: 'left', color: 'var(--text-primary)' }} >UID</div>
                            <div style={{ minWidth: '100px' }}>Amount</div>
                            <div style={{ minWidth: '100px' }}>Flagged</div>
                            <div style={{ minWidth: '100px' }}>Review</div>
                            <div style={{ minWidth: '100px' }}>Date</div>
                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <Link to={`/pluto/user/${earning.user_id}`} key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)', textDecoration: 'none' }}>

                                <div style={{ minWidth: '200px' }} >{earning.username == undefined ? '' : earning.username || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.balance || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.status || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.review || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.date || <Skeleton style={{ width: '30px' }} />}</div>

                            </Link>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                    socket.emit('getWithdrawalsAdminToday', {
                        username_like: username,
                        page,
                    })
                }} />}
                <div >
                    <input onKeyDown={
                        (e) => {
                            if (e.key == 'Enter') {
                                canUsePagination(false)
                                setEarnings([0, 0, 0, 0, 0])
                                socket.emit('getWithdrawalsAdminToday', {
                                    username_like: username,
                                    page: document.getElementById('jumpto3dwithdrawalstoday').value,
                                })
                            }
                        }

                    } type="number" id="jumpto3dwithdrawalstoday" style={{ background: 'gray', color: '#000' }} />
                    <button onClick={() => {
                        canUsePagination(false)
                        setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                        socket.emit('getWithdrawalsAdminToday', {
                            username_like: username,
                            page: document.getElementById('jumpto3dwithdrawalstoday').value,
                        })
                    }
                    } style={{ color: '#fff', background: '#000' }}>Go to page</button>
                </div>
            </div>

        </SkeletonTheme>
    )
}

export default PlutoWithdrawalsToday