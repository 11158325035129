import { FaCheckDouble, FaClosedCaptioning, FaDiscord, FaDollarSign, FaFacebook, FaInstagram } from 'react-icons/fa';
import '../assets/style/DailyRewards.css'
import { socket } from '../components/Containers/MainContainer';
import { useEffect, useState } from 'react';
import ProgressiveImage from 'react-progressive-image-loading';
import UserIDProfileModalContainerDTImg from '../components/earn/UserIDProfileModalContainerDTImg';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MdOutlineVerified } from 'react-icons/md';
import { numberWithCommas } from '../components/utils';

export const DailyRewardsContainer = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const [userEarnings, setUserEarnings] = useState([])
    const [isOpen, setIsOpen] = useState(true)
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [claimedAmount, setClaimedAmount] = useState(0);
    const [toClaimAmount, setToClaimAmount] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    let networkTimeout = null;

    useEffect(() => {
        loadLazyData();
    }, [lazyState]);

    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
      

    };
    useEffect(() => {

        socket.emit('dailyTasks');
        socket.on('emitDailyTasks', (data) => {
            let claimed = 0;
            let toClaim = 0;
            data.tasks.map((task) => {
                if (task.claimed == true) {
                    claimed += parseFloat(task.reward)
                } else {
                    toClaim += parseFloat(task.reward)
                }
            })
            setClaimedAmount(claimed)
            setToClaimAmount(toClaim)
            setUserEarnings(data.tasks)
            setTotalRecords(data.tasks.length)
            setLoading(false);
        }   )
    }, [])
  
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setlazyState(event);
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setSelectedCustomers(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            userEarnings.getCustomers().then((data) => {
                setSelectAll(true);
                setSelectedCustomers(data.customers);
            });
        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onPage = (event) => {
        setlazyState(event);
    };
    useEffect(() => {
        setIsOpen(true)

            ;
    }, [])
    return (
        <div className='daily-rewards-container'>
            <div className='daily-rewards-top'>
            <div className='daily-rewards-top-info'>
                <div className='daily-rewards-top-info-title'>
                    Daily Rewards
                </div>
                <div className='daily-rewards-top-info-description'>
                Earn extra rewards every day just by staying active! Log in daily, complete tasks, and boost your balance with bonus points. The more consistent you are, the higher the rewards!
                </div>
            </div>
            <div className='daily-rewards-header'>
                <div className='daily-rewards-header-amount-container'>
                    <div className='daily-rewards-header-amount-container-title'> 
                    <img src="/assets/img/faucetify_coin_small.png" alt="coin image" /> {numberWithCommas(claimedAmount)}
                    </div>
                    <div className='daily-rewards-header-amount-container-description'> 
                        Claimed Today
                    </div>
                </div>
                <div className='daily-rewards-header-amount-container'>
                    <div className='daily-rewards-header-amount-container-title'> 
                    <img src="/assets/img/faucetify_coin_small.png" alt="coin image" /> {numberWithCommas(toClaimAmount)}
                    </div>
                    <div className='daily-rewards-header-amount-container-description'> 
                        To Be Claimed
                    </div>
                </div>
            </div>
            </div>
            <DataTable
                value={userEarnings} lazy dataKey="id" paginator
                first={lazyState.first} rows={totalRecords} totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder} alwaysShowPaginator={false}
                onFilter={onFilter} filters={lazyState.filters} loading={loading}
                selection={userEarnings} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
                <Column field="title" header="Task" body={(product) => {
                    return <UserIDProfileModalContainerDTImg image={product.image}  name={product.title}  />
                }}></Column>
                <Column field="reward" header="Reward" body={(product) => {
                    return <p className="reward-up-modal">

                            <ProgressiveImage
                            preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                            src="/assets/img/faucetify_coin_small.png"
                            render={(src, style) => <img className="earning-feed-item-reward-image-coin" style={style}
                                alt="C" src={src} />}
                        />
                        {product.reward}</p>
                }}></Column>
                <Column field="claimed" header="Claimed" body={(product) => {
                    return <div style={{marginLeft:'10px'}}>
                        {product.claimed == 'true' || product.claimed == true ? <MdOutlineVerified style={{ color: 'green', fontSize:'15px' }} /> : <MdOutlineVerified style={{ color: 'red', fontSize:'15px'  }} />}
                        </div>
                }}></Column>
                  <Column field="claim" header="Claim" body={(product) => {
                    return   <button onClick={(e) => {      if (product.completed == true && product.claimed == false ) {
                        e.currentTarget.style.backgroundColor = 'var(--accent-0)'
                        socket.emit('userAction', {
                            action: 'claim_daily_task',
                            taskId: product.id
                        })
                    }
                }} style={{background:product.claimed==true?'none':'', background:product.claimed==true?'default':''}} className={`button ${props.logged == false || props.banned == true || product.completed == false || product.claimed == true ?'':'button-brand'}`}>{product.claimed == true ? 'Claimed' : 'Claim'}</button>
                }}></Column>
            </DataTable>
        </div>
    );
}