import { FaAndroid, FaApple, FaBoxOpen, FaDesktop, FaLock } from "react-icons/fa"
import { numberWithCommas } from "../../components/utils"

export const PromotedSmallOfferItem = (props) => {
    return (
        <div className="promoted-small-offer-item">
            {props.locked!=undefined?props.locked==true?<div className="promoted-small-offer-item-locked">
                <div className="promoted-small-offer-item-locked-text">
                    Not Yet Available For You
                </div>
                <div className="promoted-small-offer-item-locked-question">
                    Why?
                </div>
            </div>:'':''}
            <div className="promoted-small-offer-item-image">
                <img src={`${props.image}`} alt="Promoted Small Offer Item" />
            </div>
            <div className="promoted-small-offer-item-devices">
                {props.platforms.includes("android") || props.platforms.includes("all") || props.platforms.includes("*") ? <FaAndroid/> : ''}
                {props.platforms.includes("desktop") || props.platforms.includes("all") || props.platforms.includes("*")
                    || props.platforms.includes("windows") || props.platforms.includes("window") || props.platforms.includes("linux")
                    || props.platforms.includes("mac")  ?
                    <FaDesktop/> : ''}
                {props.platforms.includes("ios") || props.platforms.includes("all") || props.platforms.includes("apple") || props.platforms.includes("iphone") || props.platforms.includes("ipad") || props.platforms.includes("*") ? <FaApple/> : ''}
            </div>
            <div className="promoted-small-offer-item-open">
                <FaBoxOpen/>
            </div>
            <div className="promoted-small-offer-item-info">
                <div className="promoted-small-offer-item-info-title">
                {`${props.title}`}
                </div>
                <div className="promoted-small-offer-item-info-description">
                {`${props.description}`}
                </div>
            </div>
            <div className="promoted-small-offer-item-bottom">
                <div className="promoted-small-offer-item-reward">
                    <div className="promoted-small-offer-item-reward-image">
                        <img src="/assets/img/faucetify_coin_small.png" alt="coin image" />
                    </div>
                    <div className="promoted-small-offer-item-reward-amount">
                    {`${numberWithCommas(props.amount)}`}
                    </div>
                </div>
                <div className={`promoted-small-offer-item-category category-${props.category.toLowerCase()}`}>
                {`${props.category.toUpperCase()}`}
                </div>
            </div>

        </div>
    )
}