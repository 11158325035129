'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';
import React from 'react'
import PaginationPluto from './Pagination';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useEffect } from 'react';
import { useState } from 'react';
import PlutoMenu from './PlutoMenu';
import VerifyAdmin from './PlutoVerify';
import { Link, useParams } from 'react-router-dom';

function PlutoWuthdrawal() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [rank, setRank] = useState(0)
    const { referral_id, page2, second_page, third_page } = useParams();

    useEffect(() => {
        socket.on('response_get_admin_withdrawals', (data) => {
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 5))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)
        })
        socket.emit('getWithdrawalsAdmin', {
            page,
        })

        // setInterval(() => {
        //     if (page == 1) {
        //         if (second_page == 'withdrawals' && third_page == undefined) {
        //             socket.emit('getWithdrawalsAdmin', {
        //                 page,
        //             })
        //         }
        //     }
        // }, 1000 * 10)
    }, [])

    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">

            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">
                    {userProfile.total_paid != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.total_paid)}
                            </p>
                            <p>Total paid</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}
                    {userProfile.total_requests != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.total_requests)}
                            </p>
                            <p>Count Requests</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}
                    {userProfile.total_requests_cash != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.total_requests_cash)}
                            </p>
                            <p>Total Requests</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}

                </div>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Withdrawals</p>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '50px' }} >id</div>
                            <div style={{ minWidth: '50px', textAlign: 'left', color: 'var(--text-primary)' }} >Type</div>
                            <div style={{ minWidth: '100px' }}>Amount</div>
                            <div style={{ minWidth: '150px' }}>Requested</div>
                            <div style={{ minWidth: '50px', maxWidth: '50px' }}>Check</div>
                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ minWidth: '50px' }} > {earning.id || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.type || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '100px' }} >{earning.amount || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '150px', }} >{earning.option || <Skeleton style={{ width: '30px' }} />}</div>
                                <Link to={'/pluto/withdrawals/' + earning.id} className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '50px', maxWidth: '50px' }} >{earning.id == undefined ? <Skeleton style={{ width: '30px' }} /> : 'Check'}</Link>

                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getWithdrawalsAdmin', {
                        username_like: username,
                        page,
                    })
                }} />}

            </div>

        </SkeletonTheme>
    )
}

export default PlutoWuthdrawal