import ProgressiveImage from "react-progressive-image-loading"
import { ModalContent } from "./ModalContent"
import { FaAndroid, FaStar } from "react-icons/fa"
import { CreateToast, EUC, getLocalStorage, getOfferWallName, getWallName, hideLoginModal, numberWithCommas, showRegisterModal, showResetPWModal, submitFingersWall, validateEmail } from "../../components/utils"
import { GiSandsOfTime } from "react-icons/gi"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { useEffect, useRef, useState } from "react"
import { socket } from "../../components/Containers/MainContainer"
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import { MdOutlineEmail } from "react-icons/md"
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri"
import { IoMdLock } from "react-icons/io"
import { Link } from "react-router-dom"
import { FcGoogle } from "react-icons/fc"
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "@react-oauth/google"

export const LoginModalContent = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const captchaRef = useRef(null)
    const [tokenLogin, setTokenLogin] = useState(undefined);
    const creaRef = useRef(null);
    const [showPW, setShowPW] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const LoginGoogle = useGoogleLogin({
        onSuccess: codeResponse => {
            socket.emit('loginUser', { type: 'google', action: 'login', ...codeResponse })
        },
    });
    const [ipNot, setIpNot] = useState(false);
    const [canPress, setCanPress] = useState(true);
    useEffect(() => {
        socket.on('resetcapthca_login', () => {
            captchaRef.current.reset();
        })
        socket.on('canpress', () => {
            setCanPress(true)
        })
        socket.on('ipNot', () => {
            captchaRef.current.reset();
            setIpNot(true)
        })
    }, [])
    return (
        <ModalContent 
        click={props.click}
        hideTitle={true} 
        hide={true}
        className={`modal-content-wall modal-content-login`}
        content={
            <>
            <div className="modal-content-login-data">
                        {/* signup with email and password */}
                       
                            <div className="sc-right-box-2">
                                <p className="sc-right-box-2-el">Sign In</p>
                                <p className="sc-right-box-2-el" style={{fontSize:'10px', color:'var(--text-gray-5)'}}>Sign in and earn with us.</p>
                                <div class="line" ></div>

                            </div>
                            
                            <div className='input-container'>
                                <div className="input-icon"><MdOutlineEmail/></div>
                                <input onChange={(e) => {
                                    setEmail(e.currentTarget.value)
                                }} placeholder='Email address' id="email" type="email" name="email"  />
                            </div>
                            <div className='input-container'>
                                <div className="input-icon"><IoMdLock/></div>
                                <input onChange={(e) => {
                                    setPassword(e.currentTarget.value)
                                }} placeholder='Password' id="email" type={`${showPassword==true?"text":"password"}`} name="password"  />
                                <div className="input-icon">{showPassword==true?<RiEyeFill onClick={()=>setShowPassword(false)}/>:<RiEyeCloseFill onClick={()=>setShowPassword(true)}/>}</div>
                            </div>
                            <div className="sc-right-box-3-el" style={{width:'100%', marginLeft:'5px'}}><div style={{color:'var(--text-white)', textAlign:'left', alignItems:'start', cursor:'pointer', width:'auto', display:'inline'}} onClick={()=>{
                            showResetPWModal()
                            hideLoginModal()
                        }}>Forgot password?</div></div>
                            
                        <ReCAPTCHA
                            sitekey="6LeE9VsjAAAAAJ4T3_cd2KhZlrpm09CqY2x3rVZc"
                            ref={captchaRef} />
                        <div className="signup-main-button" onClick={(e) => { 
                            e.preventDefault();
                            if (canPress == false) {
                            } else if (ipNot == true) {
                                CreateToast("Your IP is restricted.", creaRef, 1)
                            } else {
                                if (email.split("").length < 3) {
                                    CreateToast("Enter an email address.", creaRef, 1)
                                } else if (validateEmail(email) == false) {
                                    CreateToast("Email address not valid.", creaRef, 1)
                                } else if (password.split("").length < 6) {
                                    CreateToast("Enter a valid password.", creaRef, 1)
                                } else {
                                    if (captchaRef.current.getValue().split("").length > 5) {
                                        setCanPress(false)
                                        socket.emit('loginUser', {
                                            type: 'local',
                                            email,
                                            password,
                                            tokenType: 'v2',
                                            token: captchaRef.current.getValue(),
                                        })
                                    } else {
                                        CreateToast('Captcha is invalid', creaRef, 1)
                                    }
                                }
                            }
                         }}>Log In And Earn</div>
                        <div class="social-message" >
                            <div class="line" ></div>
                            <p class="message">or</p>
                            <div class="line" ></div>
                            </div>
                        <div className="signup-main-button google-button" onClick={() => { if (typeof window.PaidCashChannel !== 'undefined' && window.PaidCashChannel.postMessage) {
                                window.PaidCashChannel.postMessage('login_google');
                            }else{
                                LoginGoogle()
                            }  }}><FcGoogle style={{fontSize:'15px'}}/> Sign Up With Google</div>
                        <div className="sc-right-box-3-el">Don't have an account? <div style={{color:'var(--text-white)', display:'inline', cursor:'pointer'}} onClick={()=>{
                           
                            showRegisterModal()
                           hideLoginModal()
                        }}>Sign Up For FREE</div></div>
                        <p className="sc-right-box-3-el">By logging in, you are agreeing to our <Link to={'/policies/terms'} onClick={()=>{hideLoginModal()}}>Terms of Service</Link> and <Link to={`/policies/privacy${(EUC.includes((getLocalStorage('country') + '').toUpperCase()) ? '-eu' : '')}`} onClick={()=>{hideLoginModal()}}>Privacy Policy</Link><br/><br/>
General Prohibited Uses Using multiple accounts Completing offers on another user's account Using any type of VPN, VPS, Emulator software or the usage of Temp Mails.</p>
        </div>
            </>
        }/>
    )
}