import { IoIosWallet } from 'react-icons/io';
import '../assets/style/SideMenu.css'
import { CategorySideMenu } from '../Component/SideMenu/Category.SideMenu'
import { MenuButtonSideMenu } from '../Component/SideMenu/MenuButton.SideMenu'
import { BiSolidHome } from 'react-icons/bi';
import { GiChampions, GiMoneyStack, GiPayMoney, GiTakeMyMoney } from 'react-icons/gi';
import { PiCursorClickFill } from 'react-icons/pi';
import { MdVideoLibrary, MdWaterDrop } from 'react-icons/md';
import { FaAd, FaUsers } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { socket } from '../components/Containers/MainContainer';

export const SideMenuContainer = (props) => {
    const size = useWindowSize();
    const [onlineUsers, setOnlineUsers] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true)
    useEffect(() => {
        socket.on('onlineUsers', (data) => {
            setOnlineUsers(data.count)
            if (firstLoad == true) {
                setTimeout(() => { setFirstLoad(false) }, 10000)
            }
           
        })
    }, [])
    useEffect(() => {
        if(size.width==1051){
            const SideMenuDOM = document.querySelector('.side-menu-container');
            const AppContainerDOM = document.querySelector('.earning-feed-container');

            if(SideMenuDOM!=undefined){
                SideMenuDOM.style.display='block'
            }
            if(AppContainerDOM.classList.contains('earning-feed-container-full')){
                if(SideMenuDOM.classList.contains('side-menu-container-open')){
                    SideMenuDOM.classList.remove('side-menu-container-open')
                }
            }else{
                if(SideMenuDOM.classList.contains('side-menu-container-open')){
                    SideMenuDOM.classList.add('side-menu-container-open')
                }
            }
        }
        if (size.width <= 1050) {
            const SideMenuDOM = document.querySelector('.side-menu-container');
            if(SideMenuDOM!=undefined){
                SideMenuDOM.style.display='none'
            }
        }else{
            const SideMenuDOM = document.querySelector('.side-menu-container');
            if(SideMenuDOM!=undefined){
                SideMenuDOM.style.display='block'
            }
        } 
    }, [size.width])
    useEffect(() => {
 
        document.addEventListener('handleSideMenu', function(e){
            const SideMenuDOM = document.querySelector('.side-menu-container');
            const AppContainerDOM = document.querySelector('.app-container');

            if(SideMenuDOM!=undefined){
                if(SideMenuDOM.classList.contains('side-menu-container-open')){
                    SideMenuDOM.classList.remove('side-menu-container-open')
                }else{
                    SideMenuDOM.classList.add('side-menu-container-open')
                }
            }
        })

        document.addEventListener('handleSideMenuMobile', function(e){
            const SideMenuDOM = document.querySelector('.side-menu-container');
            const AppContainerDOM = document.querySelector('.app-container');
            if(SideMenuDOM!=undefined){
                if(SideMenuDOM.style.display=='none'){
                   SideMenuDOM.style.display='block'
                   SideMenuDOM.classList.add('side-menu-container-open')
                }else{
                   SideMenuDOM.style.display='none'
                   SideMenuDOM.classList.remove('side-menu-container-open')

                }
            }
        })
    }, [])

    return (
        <div className="side-menu-container side-menu-container-open">
            <CategorySideMenu title={"Main"}/>
            <MenuButtonSideMenu title={"Home"} icon={<BiSolidHome/>} selected={props.page==undefined || props.page==''} link={true} to={'/'}/>
            <MenuButtonSideMenu title={"Cash Out"} icon={<IoIosWallet />} selected={props.page=='cash-out'} link={true} to={'/cash-out'}/>
            <MenuButtonSideMenu title={"Leaderboard"} icon={<GiChampions />} selected={props.page=='leaderboard'} link={true} to={'/leaderboard'}/>
            <CategorySideMenu title={"Earn"}/>
            <MenuButtonSideMenu title={"Offers"} icon={<GiTakeMyMoney/>} selected={props.page=='offers'} link={true} to={'/offers'}/>
            <MenuButtonSideMenu title={"Ptc Ads"} icon={<PiCursorClickFill />} selected={props.page=='ptc'} link={true} to={'/ptc'}/>
            <MenuButtonSideMenu title={"Video Ads"} icon={<MdVideoLibrary />} selected={props.page=='video'} link={true} to={'/video'}/>
            <MenuButtonSideMenu title={"Faucet"} icon={<MdWaterDrop />} selected={props.page=='faucet'} link={false} click={()=>{window.dispatchEvent(new CustomEvent('showFaucetModal'))}}/>
            <CategorySideMenu title={"Other"}/>
            <MenuButtonSideMenu title={"Affiliate"} icon={<FaUsers/>} selected={props.page=='affiliate'} link={true} to={'/affiliate'}/>
            <MenuButtonSideMenu title={"Rewards"} icon={<GiPayMoney />} selected={props.page=='rewards'} link={true} to={'/rewards'}/>
            <MenuButtonSideMenu title={"Promo Code"} icon={<GiMoneyStack/>} selected={props.page=='promo-code'} link={true} to={'/promo-code'} />
            <CategorySideMenu title={"Ads"}/>
            <MenuButtonSideMenu title={"Advertise"} icon={<FaAd/>} selected={props.page=='advertise'} link={true} to={'/advertise'}/>
            <div className='chat-top-online'>
                    <div className='chat-top-online-dot'></div>
                    <p>
                        {firstLoad == true ? <CountUp
                            end={onlineUsers}
                            duration="2"
                            separator=","
                            decimals={0}
                        /> : onlineUsers} online</p>
                </div>
        </div>
    )
}
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // only execute all the code below in client side
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}