import { socket } from '../Containers/MainContainer'
import React from 'react'
import PaginationPluto from './Pagination'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { canUsePagination, numberWithCommas } from '../utils'
import { useState } from 'react'
import { useEffect } from 'react'
import PlutoMenu from './PlutoMenu'
import VerifyAdmin from './PlutoVerify'

function PlutoOfferWalls() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [rank, setRank] = useState(0)

    useEffect(() => {
        socket.on('response_get_admin_offerwalls', (data) => {

            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 5))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)

        })
        socket.emit('getOfferWallsAdmin', {
            page,
        })
    }, [])
    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">

            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {userProfile.withdrawalsLength != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.withdrawalsLength)}
                            </p>
                            <p>Count OfferWalls</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}


                </div>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Offer Walls</p>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                            <div style={{ minWidth: '200px' }}>Increase</div>
                            <div style={{ minWidth: '90px' }}>Level Req</div>
                            <div style={{ minWidth: '50px' }}>Has API</div>

                            <div style={{ minWidth: '100px', maxWidth: '100px' }}>Edit</div>

                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '200px' }} >{earning.increase || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '90px', }} >{earning.level_req || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', }} >{earning.has_api || <Skeleton style={{ width: '30px' }} />}</div>
                                <div className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{earning.id == undefined ? <Skeleton style={{ width: '30px' }} /> : 'Edit'}</div>

                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getOfferWallsAdmin', {
                        username_like: username,
                        page,
                    })
                }} />}

            </div>

        </SkeletonTheme>
    )
}

export default PlutoOfferWalls