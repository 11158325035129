import { FaAndroid, FaApple, FaBoxOpen, FaDesktop, FaLock } from "react-icons/fa"
import { getOfferWallName, numberWithCommas } from "../../components/utils"

export const WallsItem = (props) => {
    return (
        <div className="wall-container-item"  style={{background:props.background}}>
             <div className="wall-container-promo">
               {props.increase}
            </div>
            {props.locked!=undefined?props.locked==true?<div className="promoted-small-offer-item-locked">
                <div className="promoted-small-offer-item-locked-text">
                    Not Yet Available For You
                </div>
                <div className="promoted-small-offer-item-locked-question">
                    Why?
                </div>
            </div>:'':''}
            <div className="wall-container-image">
                <img src={`${props.image}`} alt="Promoted Small Offer Item" />
            </div>
          
            <div className="wall-container-item-open">
                <FaBoxOpen/>
            </div>
            <div className="wall-container-item-info">
                <div className="wall-container-item-info-title">
                {`${getOfferWallName(props.title)}`}
                </div>
             
            </div>

        </div>
    )
}