
import React from 'react'
import ProgressiveImage from 'react-progressive-image-loading'

function UserIDProfileModalContainerDTImg({ image, name, wallName }) {
    const [error, setError] = React.useState(false)
    const [loaded, setLoaded] = React.useState(false)
    return (
        <>
            <div className="profile-details-activity-item">


                <ProgressiveImage
                    preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                    src={image}

                    render={(src, style) => <img src={src} width={20}
                        height={20}
                        onError={(e) => { e.currentTarget.src = `/assets/img/profile_small/paidcash.co.png` }}
                        onLoad={(e) => {
                            if (e.currentTarget.src == 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7') {
                                e.currentTarget.src = `/assets/img/profile_small/${wallName}.png`
                                e.currentTarget.style.filter = 'blur(0)'
                            }
                        }}
                        alt={'image'} className="w-6rem shadow-2 border-round" style={style} />}
                />


                <p>{name.split('_').join(' ')}</p>
            </div>
        </>
    )
}

export default UserIDProfileModalContainerDTImg