

import { useEffect, useRef, useState } from "react";
import { ChangeStateUrl, CreateToast, EUC, getLocalStorage, isLoggedIn, numberWithCommas, setSignUserTab, showCurrencyText, showRegisterModal, validateEmail, validatePassword } from "../components/utils.js";
import CountUp from "react-countup";
import { Link } from 'react-router-dom'
import { FaCircle, FaDollarSign, FaInfinity, FaLock } from "react-icons/fa";
import { IoFileTrayStacked, IoLockClosed } from "react-icons/io5";
import { GiStoneStack } from "react-icons/gi";
import { socket } from "../components/Containers/MainContainer.js";
import ProgressiveImage from "react-progressive-image-loading";
import { MdOutlineAlternateEmail, MdOutlineEmail } from "react-icons/md";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import { IoMdLock } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "@react-oauth/google";

function HomePageShowCase(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRe, setPasswordRe] = useState('');
    const [checked, setChecked] = useState(false);
    const captchaRef = useRef(null)
    const creaRef = useRef(null);
    const LoginGoogle = useGoogleLogin({
        onSuccess: codeResponse => {
            socket.emit('signupUser', {
                type: 'google', action: 'signup', ...codeResponse,
                referee: getLocalStorage("referee") == undefined ? "-1" : JSON.parse(getLocalStorage("referee")).referee,
            })
        },
    });
    const [ipNot, setIpNot] = useState(false);
    const [canPress, setCanPress] = useState(true);
    useEffect(() => {
        socket.on('resetcapthca_signup', () => {
            captchaRef.current.reset();
        })
        socket.on('canpress_signup', () => {
            setCanPress(true)
        })
        socket.on('ipNotSignup', () => {
            setIpNot(true)
        })
    }, [])
    let [loggedIN, setLogged] = useState(isLoggedIn());
    useEffect(() => { setLogged(isLoggedIn()) }, [isLoggedIn()])
    const [countryData, setCData] = useState({});
    useEffect(() => {
        socket.emit('mainPageCountryData');
        socket.on('emitMainPageCountryData', (data) => {
            setCData(data)

        })
    }, [])
    return (
        // logged==true?
        <>
            <img className="cover-sc" src="/assets/img/bg-shape.png"/>
            <div className="show-case">

                {/* <img className="logo-sc" src="/assets/img/faucetify.webp" alt="logo"/> */}
               
                {/* <p>Earn money for completing tasks, doing surveys and much more</p> */}
                {/* d-flex flex-row justify-content-between faq-container */}
                <div className="sc-left">
                <ProgressiveImage
                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                src="/assets/img/faucetify.png"
                render={(src, style) => <img className="home-sc-logo-desktop"  style={style} src={src} />} />
                <p className="gpf-sc">Get Paid For <p className="gpf-sc-el">Completing Tasks, Surveys, and Much More</p></p>
                <p className="promo-sc">Earn up to <p className="promo-sc-el">${countryData.offersHAmount == undefined ? 0 :
                        <CountUp
                            end={parseFloat(countryData.offersHAmount).toFixed(2)}
                            duration="1"
                            separator=","
                            decimals={2}
                        />}</p> from  <FaCircle style={{width:'6px', height:'6px', marginBottom:'2px', color:'var(--text-brand)'}}/> <p className="promo-sc-el">{countryData.offersCount == undefined ? 0 :

                    <CountUp
                        end={countryData.offersCount}
                        duration="1"
                        separator=","
                    />}</p> live offers</p>
              
                {/* <HomePageAvailablePayouts /> */}
              </div>
              <div className="sc-right">
                    <div className="sc-right-box">
                        {/* signup with email and password */}
                       
                        <div className="sc-right-box-2">
                                <p className="sc-right-box-2-el">Sign Up Now For Free</p>
                            </div>
                            <div className='input-container'>
                                <div className="input-icon"><MdOutlineEmail/></div>
                                <input onChange={(e) => {
                                setEmail(e.currentTarget.value)
                            }} placeholder='Email address' id="email" type="email" name="email"  />
                            </div>
                            <div className='input-container'>
                                <div className="input-icon"><IoMdLock/></div>
                                <input onChange={(e) => {
                                setPassword(e.currentTarget.value)
                            }} placeholder='Password' id="email" type={`${showPassword==true?"text":"password"}`} name="password"  />
                                <div className="input-icon">{showPassword==true?<RiEyeFill onClick={()=>setShowPassword(false)}/>:<RiEyeCloseFill onClick={()=>setShowPassword(true)}/>}</div>
                            </div>
                            <div class="checkbox-wrapper-29">
                            <label class="checkbox">
                                <input onChange={(e) => {
                                        setChecked(e.currentTarget.checked)
                                    }} type="checkbox" class="checkbox__input" />  
                                <span class="checkbox__label"></span>
                                <div className="checkbox-label">I agree to the <Link to={'/policies/terms'} onClick={()=>{}}>Terms of Service</Link> and <Link onClick={()=>{}} to={`/policies/privacy${(EUC.includes((getLocalStorage('country') + '').toUpperCase()) ? '-eu' : '')}`}>Privacy Policy</Link></div>
                                
                                 </label>
                            </div>
                            <div style={{display:checked==true?'inherit':'none'}}><ReCAPTCHA
                            sitekey="6LeE9VsjAAAAAJ4T3_cd2KhZlrpm09CqY2x3rVZc"
                            ref={captchaRef} /></div>
                        <div className="signup-main-button" onClick={(e) => { 
                             e.preventDefault();
                             if (canPress == false) {
                             } else if (ipNot == true) {
                                 CreateToast("Your IP is restricted.", creaRef, 1)
                             } else {
                                if (email.split("").length < 3) {
                                    CreateToast("Enter an email address.", creaRef, 1)
                                } else  if (password.split("").length < 6) {
                                    CreateToast("Password must be at least 6 characters long.", creaRef, 1)
                                } else if (validateEmail(email) == false) {
                                    CreateToast("Email address not valid.", creaRef, 1)
                                } else if (validatePassword(password)[0] == false) {
                                    CreateToast(validatePassword(password)[1], creaRef, 1)
                                } else if (checked == false) {
                                     CreateToast('You must agree to our TOS and Privacy Policy in order to continue.', creaRef, 1)
                                 } else  {
                                     if (captchaRef.current.getValue().length > 5) {
                                         setCanPress(false)
                                         socket.emit('signupUser', {
                                             type: 'local',
                                             email,
                                             username: email.split("@")[0],
                                             password,
                                             passwordRe:password,
                                             tokenType: 'v2',
                                             token: captchaRef.current.getValue(),
                                             checked: checked,
                                             referee: getLocalStorage("referee") == undefined ? "-1" : JSON.parse(getLocalStorage("referee")).referee
                                         })
                                     } else {
                                         CreateToast('Captcha is invalid', creaRef, 1)
                                     }
                                 }
 
                             }
                         }}>Start Earning Now</div>
                        <div class="social-message" bis_skin_checked="1">
                            <div class="line" bis_skin_checked="1"></div>
                            <p class="message">or</p>
                            <div class="line" bis_skin_checked="1"></div>
                            </div>
                        <div className="signup-main-button google-button" onClick={() => {  if (typeof window.PaidCashChannel !== 'undefined' && window.PaidCashChannel.postMessage) {
                                window.PaidCashChannel.postMessage('singup_google');
                            }else{
                                LoginGoogle()
                            }  }}><FcGoogle style={{fontSize:'15px'}}/> Sign Up With Google</div>
                        <p className="sc-right-box-3-el">+{countryData.usersJoinedToday==undefined?<FaInfinity/>:numberWithCommas(countryData.usersJoinedToday)} new accounts in the past 24h</p>

                    </div>

                </div>
            </div>
           
        </>

    )
}
export default HomePageShowCase;