
import { useEffect } from 'react'
import '../assets/style/Terms.css'
import TopTOSCOntainer from './TopTOSCOntainer'

export const Privacy = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
  const lastEdited = '2023-09-09 [YYYY-MM-DD]'
  let TermsOfServicePage = [

      {
          title: 'Introduction',
          id: 'introduction',
          content: `PaidCash, referred to as "PaidCash" or "We," holds your privacy in high regard and is dedicated to safeguarding it by complying with this policy.<br/>
<br/>
          This policy outlines the categories of information we might gather from you or that you might provide when you browse the paidcash.co website, known as our "Website." Additionally, it delineates our procedures for the collection, utilization, maintenance, protection, and disclosure of this information.<br/>
          <br/>
          If you are a European Union resident, it's important to note that we may transfer your personal data outside the EU. For further details, please refer to our <a href="/policies/privacy-eu">Privacy Policy for EU residents</a>.<br/>
          <br/>
          The scope of this policy encompasses data we collect in the following ways:<br/>
          <br/>
          <ul style="list-style:circle; margin-left:20px">
          <li>Through interactions on this Website.</li>
          <li>Via email, text, and other forms of electronic communication between you and this Website.</li>
          <li>Through the mobile and desktop applications available for download from this Website, which facilitate dedicated, non-browser-based interactions between you and our Website.</li>
          <li>When engaging with our advertising and applications on third-party websites and services, provided that such applications or advertising include links to this policy.</li>
          </ul>
          <br/>
          This policy, however, does not extend to information collected through the following means:<br/>
          <br/>
          <ul style="list-style:circle; margin-left:20px">
         <li> Offline interactions or any other methods, including on other websites operated by PaidCash or third parties, including our affiliates and subsidiaries.</li>
         <li> Interactions with any third parties, including our affiliates and subsidiaries, through applications or content (including advertising) that may link to or be accessible from or on our Website.</li>
         </ul>
          <br/>
          We urge you to carefully read and comprehend the details of this policy to gain insights into our procedures and principles concerning your information and how it will be handled. If you disagree with our practices and policies, you retain the choice to abstain from using our Website. Accessing or using this Website implies your consent to adhere to this privacy policy. It's worth noting that this policy may undergo revisions over time (refer to the "Changes to Our Privacy Policy" section). Your continued use of this Website after these modifications implies your acceptance of them, so we encourage you to review the policy periodically for updates.`
      },
      {
          title: 'Information We Collect and How We Collect It - About You',
          id: 'information-we-collect-and-how-we-collect-it-about-you',
          content: `
  
  <p>At PaidCash understanding the types of information we collect about you and how we gather it is essential. This section elucidates this process.</p><br/>

  <strong>Types of Information We Collect</strong><br/></br>
  <p>We collect various categories of data, including:</p>

  <ul style="list-style:circle; margin-left:20px">
      <li><strong>Identity Data:</strong> This encompasses details such as your name, username, gender, contact numbers, and identifiers like driver's licenses, passports, and more.</li>

      <li><strong>Contact Data:</strong> This includes your address, email address, and phone numbers.</li>

      <li><strong>Special Categories Data:</strong> This category pertains to sensitive information such as race, religious beliefs, sexual orientation, and health data, among others.</li>

      <li><strong>Transaction Data:</strong> Information related to payment methods, payouts, cash-outs, redemptions, and gift cards associated with your participation on our Website.</li>

      <li><strong>Technical Data:</strong> Technical data covers aspects like your internet protocol (IP) address, login data, browser details, device identifiers, location information, and more.</li>

      <li><strong>Profile Data:</strong> Your profile information, including usernames, passwords, selfies, interests, preferences, feedback, and research and survey responses.</li>

      <li><strong>Usage Data:</strong> This involves data concerning how you interact with our Website, products, and services. This information includes activities like page views, clicks, and diagnostic and troubleshooting details.</li>

      <li><strong>Marketing and Communications Data:</strong> Your preferences for receiving marketing communications from us and third parties, as well as your communication preferences.</li>
  </ul><br/>

  <strong>How We Collect Information</strong><br/><br/>
  <p>We use various methods to collect data from and about you, such as:</p>

  <ul style="list-style:circle; margin-left:20px">
      <li><strong>Direct Interactions:</strong> When you interact directly with us, you may provide Identity Data and Contact Data. This can occur when you apply for our products or services, create an account, use our services, subscribe to newsletters, request support, complete surveys or offers, and provide feedback.</li>

      <li><strong>Automated Technologies:</strong> As you interact with our Website, we automatically collect Technical Data. We employ technologies like cookies, server logs, and similar tools for this purpose. Please review our Cookie Policy for more information on managing cookie preferences.</li>

      <li><strong>Third Parties and Publicly Available Sources:</strong> We may receive personal data about you from third parties, including services like Google Analytics and Facebook.</li>
  </ul>
<br/>
  <p>This transparent description of our data collection methods ensures that you understand how we gather and use your information effectively.</p>
`
      },
      {
          title: 'Information You Provide to Us',
          id: 'information-you-provide-to-us',
          content: `
    <p>The information collected through our Website includes:</p>
    <br/>
    <ul style="list-style:circle; margin-left:20px">
        <li>
            <strong>Personal Information from Forms:</strong>
            <p>We gather personal information when you complete forms on our Website. This encompasses details provided during registration, posting content, requesting additional services, and participation in contests or promotions.</p>
        </li>

        <li>
            <strong>Sensitive Personal Information:</strong>
            <p>This category may include your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, health data, and biometric information.</p>
        </li>

        <li>
            <strong>Records of Correspondence:</strong>
            <p>Copies of your correspondence, including email addresses, are retained when you contact us.</p>
        </li>

        <li>
            <strong>Survey Responses:</strong>
            <p>We collect your responses to surveys conducted for research and feedback purposes.</p>
        </li>

        <li>
            <strong>Transaction Details:</strong>
            <p>Information about transactions conducted on our Website, such as payment methods, pay-outs, cash-outs, redemption methods, or gift cards provided to you as a user on our Website.</p>
        </li>

        <li>
            <strong>Search Queries:</strong>
            <p>Details of your search queries performed on the Website.</p>
        </li>
    </ul>
    <br/>

    <p><strong>User Contributions</strong></p>
    <br/>
    <p>Additionally, you may provide information intended for publication or display in public areas of the Website or for transmission to other users or third parties (collectively referred to as "User Contributions"). Please be aware that User Contributions are posted and transmitted at your own risk. While you can configure certain privacy settings within your account profile, no security measures are entirely foolproof. We also cannot control the actions of other users who may view your User Contributions. Consequently, we cannot guarantee that unauthorized individuals won't access this information.</p>`
      },
      {
          title: 'Data Collection Through Automatic Technologies',
          id: 'data-collection-through-automatic-technologies',
          content: `
    <p>We use automatic data collection technologies to gather certain information about your equipment, browsing actions, and patterns as you navigate and interact with our Website. This includes:</p>

    <ul style="list-style:circle; margin-left:20px">
        <li>Details of your visits to our Website, such as traffic data, location data, logs, communication data, and the resources you access.</li>
        <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
    </ul>
    <br/>
    <p>These technologies also allow us to collect data on your online activities over time and across third-party websites or online services (behavioral tracking). For more details about the automatic data collection when using our Website, please refer to our <a href="/policies/cookies">Cookie Policy</a>.</p>
    <br/>
    <p>The information collected automatically may contain personal data, and we may associate it with other personal information collected from different sources or received from third parties. This data helps us improve our Website and provide a more personalized service, including:</p>

    <ul style="list-style:circle; margin-left:20px">
        <li>Estimating our audience size and usage patterns.</li>
        <li>Storing information about your preferences to customize our Website based on your interests.</li>
        <li>Enhancing search speed.</li>
        <li>Recognizing you when you return to our Website.</li>
    </ul>
<br/>
    <p>We use the following technologies for automatic data collection:</p>

    <ul style="list-style:circle; margin-left:20px">
        <li><strong>Cookies (browser cookies):</strong> These are small files stored on your computer's hard drive. You can adjust your browser settings to refuse cookies, but this may limit access to certain parts of our Website.</li>
        <li><strong>Flash Cookies:</strong> Some features on our Website may use local stored objects (Flash cookies) to collect and store information about your preferences and navigation. Flash cookies are managed separately from browser cookies.</li>
        <li><strong>Web Beacons:</strong> Our Website pages and emails may include small electronic files known as web beacons (or clear gifs, pixel tags, single-pixel gifs). These allow us to count users who have visited specific pages or opened an email, and gather related website statistics, like content popularity and system integrity verification.</li>
    </ul>`
      },
      {
          title: 'Third-Party Use of Cookies and Similar Tracking Technologies',
          id: 'third-party-use-of-cookies-and-similar-tracking-technologies',
          content: `
    <p>Some content or applications, including advertisements, on the Website are served by third parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. For more information about third-party cookies, please see our <a href="/policies/cookies">Cookie Policy</a>.</p>
    <br/>
  <p>We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see <a href="#choices-about-how-we-use-and-disclose-your-information">Choices About How We Use and Disclose Your Information</a>.</p>
</body>`
      },
      {
          title: 'How We Utilize Your Data',
          id: 'how-we-utilize-your-data',
          content: `
    <h1>Use of Your Information</h1>

    <ul style="list-style:circle; margin-left:20px">
        <li>To present our Website and its contents to you.</li>
        <li>To provide you with information, products, or services that you request from us.</li>
        <li>To fulfill any other purpose for which you provide it.</li>
        <li>To provide you with notices about your account, including expiration and renewal notices.</li>
        <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
        <li>To notify you about changes to our Website or any products or services we offer or provide through it.</li>
        <li>To allow you to participate in interactive features on our Website.</li>
        <li>In any other way we may describe when you provide the information.</li>
        <li>For any other purpose with your consent.</li>
    </ul>
    <br/>
    <p>We may also use your information to contact you about our own and third parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile. For more information, see <a href="#choices-about-how-we-use-and-disclose-your-information">Choices About How We Use and Disclose Your Information</a>.</p>
    <br/>
    <p>Additionally, we may use the information we have collected from you to enable us to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>
`
      },
      {
          title: 'Disclosure of Your Information',
          id: 'disclosure-of-your-information',
          content: `
    <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>
    <br/>

    <p>We may disclose personal information that we collect or you provide as described in this privacy policy for the following purposes:</p>

    <ul style="list-style:circle; margin-left:20px">
        <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
        <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of PaidCash's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by PaidCash about our Website users is among the assets transferred.</li>
        <li>To third parties to market their products or services to you if you have consented to these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them. For more information, see <a href="#choices-about-how-we-use-and-disclose-your-information">Choices About How We Use and Disclose Your Information</a>.</li>
        <li>To fulfill the purpose for which you provide it. For example, if you give us an email address to use the "email a friend" feature of our Website, we will transmit the contents of that email and your email address to the recipients.</li>
        <li>For any other purpose disclosed by us when you provide the information.</li>
        <li>With your consent.</li>
    </ul>
    <br/>

    <p>We may also disclose your personal information:</p>

    <ul style="list-style:circle; margin-left:20px">
        <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
        <li>To enforce or apply our terms of services and other agreements.</li>
        <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of PaidCash, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
    </ul>`
      },
      {
          title: 'Choices About How We Use and Disclose Your Information',
          id: 'choices-about-how-we-use-and-disclose-your-information',
          content: `
    <h2>Tracking Technologies and Advertising</h2>
    <p>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</p>

    <h2>Disclosure of Your Information for Third-Party Advertising</h2>
    <p>If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt out by logging into the Website and adjusting your user preferences in your account profile or by sending us an email stating your request to <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>.</p>

    <h2>Promotional Offers from PaidCash</h2>
    <p>If you do not wish to have your contact information used by PaidCash to promote our own products or services, you can opt out by logging into the Website and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>. If we have sent you a promotional email, you may use the unsubscribe feature located at the bottom of our emails.</p>

    <h2>Targeted Advertising</h2>
    <p>If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers' target-audience preferences, you can opt out by logging into the Website and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>.</p>

    <p>We do not control third parties' collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the <a href="https://thenai.org">NAI's website</a>.</p>

    <p><strong>California residents</strong> may have additional personal information rights and choices. Please see <a href="/policies/ccpa">Your California Privacy Rights</a> for more information.</p>

    <p><strong>Nevada residents</strong> who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may submit a request to this designated address: <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>.</p>
`
      },
      {
          title: 'Accessing and Correcting Your Information',
          id: 'accessing-and-correcting-your-information',
          content: `
    <p>You can review and change your personal information by logging into the Website and visiting your account profile page.</p>
    <br/>
    <p>You may also send us an email at <a href="mailto:hello@paidcash.co">hello@paidcash.co</a> to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
    <br/>
    <p>If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages or might have been copied or stored by other Website users. Proper access and use of information provided on the Website, including User Contributions, is governed by our terms of services.</p>
    <br/>
    <p><strong>California residents</strong> may have additional personal information rights and choices. Please see <a href="/policies/ccpa">Your California Privacy Rights</a> for more information.</p>
`
      },
      {
          title: 'Your California Privacy Rights',
          id: 'your-california-privacy-rights',
          content: `
    <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, see our privacy policy for <a href="/policies/ccpa">California Residents</a>.</p><br/>

    <p>California's "shine the Light" law (Civil Code Section § 1798.83) permits users of our App that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>.</p>
 `
      },
      {
          title: 'Privacy Rights for Other States',
          id: 'privacy-rights-for-other-states',
          content: ` <p>If you reside in Colorado, Connecticut, Virginia, or Utah, you may have certain privacy rights, including the ability to:</p>
    <ol>
        <li><strong>Confirmation:</strong> Verify whether we process your personal information.</li>
        <li><strong>Access and Deletion:</strong> Obtain access to and request the deletion of specific personal information.</li>
        <li><strong>Data Portability:</strong> Exercise data portability rights.</li>
        <li><strong>Opt-Out:</strong> Opt-out of personal data processing for targeted advertising and sales.</li>
    </ol>
    <p>Additionally, residents of Colorado, Connecticut, and Virginia have the right to rectify inaccuracies in their personal information.</p>
    <p>To exercise any of these rights, please don't hesitate to reach out to us via email at: <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>.</p>
    <p>For residents of Nevada, there exists a limited right to opt-out of certain personal information sales. If you are a Nevada resident and wish to exercise this opt-out right, you can submit a request by sending an email to: <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>.</p>
`
      },
      {
          title: 'International Transfers',
          id: 'international-transfers',
          content: `
    <p>We operate in numerous countries, and it's important to note that we, along with our service providers, may move your data and process it outside the country where you reside. To ensure the security of your information, we utilize third-party service providers for processing and storage across various locations, including the United States, the EU, and other countries.</p>
  <br/>
    <p>For individuals from the European Union (EU), it's worth mentioning that we may transfer your personal data outside the EU. For detailed information specific to EU residents, please refer to our <a href="/policies/privacy-eu">"Privacy Policy for EU Residents"</a>.</p>
    <br/>
    <p>If you seek further insights into the contractual arrangements we establish to safeguard your personal data during its transfer outside the EU, you may contact us via email at <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>. Additionally, you can access the standard contractual clauses approved by the European Commission by visiting the following link: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">Standard Contractual Clauses (SCC)</a>.</p>
    <br/>
    <p>If you require additional information regarding the specific mechanisms we employ when transferring your personal data out of the UK, you can submit a request by sending an email to: <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>.</p>
    `
      },
      {
          title: 'Children under age of 16',
          id: 'children-under-age-of-16',
          content: `
    <p>Our Website is exclusively intended for individuals aged 16 years and older. No person under the age of 16 is permitted to provide any information on the Website. To make it crystal clear, we do not intentionally gather personal data from individuals under the age of 16. If you are under the age of 16, please refrain from using the Website or providing any information through its features.</p>
  </br>
  <p>In the event that we discover we have unintentionally collected or received personal information from a child under the age of 16 without proper verification of parental consent, we will promptly delete that information. If you suspect that we may possess information from or about a child under the age of 16, kindly reach out to us at <a href="mailto:hello@paidcash.co">hello@paidcash.co</a></p>
  `
      },
      {
          title: 'Data Security',
          id: 'data-security',
          content: `
    <p>We've taken extensive measures to ensure the security of your personal data, preventing accidental loss, unauthorized access, misuse, alterations, or disclosures. Furthermore, access to your personal data is restricted to employees, agents, contractors, and other third parties who have a legitimate business need to access it.</p>
  <br/>
    <p>To secure your personal information, we've implemented robust measures. All the information you provide to us is stored on our secure servers, protected by firewalls.</p>
    <br/>
    <p>However, please note that the safety and security of your information also rely on your actions. If we've provided you with a password or if you've chosen one for accessing specific parts of our Website, it's your responsibility to keep it confidential. We strongly advise against sharing your password with anyone. Additionally, exercise caution when sharing information in public areas of the Website, such as message boards, as this information may be visible to all Website users.</p>
    <br/>
    <p>Regrettably, the transmission of information over the internet isn't entirely foolproof. Despite our best efforts to safeguard your personal information, we cannot guarantee the complete security of data transmitted to our Website. Any transmission of personal information is done at your own risk. We cannot be held responsible for any attempts to circumvent privacy settings or security measures on the Website.</p>

    `
      },
      {
          title: 'Changes to Our Privacy Policy',
          id: 'changes-to-our-privacy-policy',
          content: `
    <p>Our policy is to publish any revisions we make to our privacy policy on this page. In the event of substantial changes in how we handle your personal information, we will notify you through either the primary email address provided in your account or by posting a notice on the Website's home page. The date of the last revision to the privacy policy can be found at the top of this page.</p>
  <br/>
    <p>It is your responsibility to ensure that we have an accurate, current, and functional email address on file for you. Additionally, we recommend that you periodically visit our Website and review this privacy policy to stay informed about any updates.</p>
`
      },
      {
          title: 'Contact Information',
          id: 'contact-information',
          content: `
    To ask questions or comment about this privacy policy and our privacy practices kindly reach out to us at <a href="mailto:hello@paidcash.co">hello@paidcash.co</a>`
      }

  ]
    return (
        <div className='terms-page'>
            <TopTOSCOntainer edited={lastEdited} title={'Privacy Policy'} second_page={props.second_page}/>
            {TermsOfServicePage.map((item, index) => {
                return (
                        <div className="terms-item"  id={item.id} key={index}>
                            <div className="terms-title" >{index + 1}{' -'} {item.title}</div>
                            <div className='terms-content' dangerouslySetInnerHTML={{ __html: item.content }} />
                            {index == 0 ?
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', fontSize: '12px' }}>
                                    {TermsOfServicePage.map((item, index) => {
                                        return <div className='tos-al' style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div >{index + 1}</div> <a href={`#${item.id}`} key={index}> {`) ${item.title}`}</a>
                                            </div>
                                    })}
                                </div> : ''}
                        </div>

                )
            })}
        </div>
    )
}