'use client'
import { useEffect } from "react";
import { useState } from "react";

function PaginationPluto(props) {
    let { pageNumber, requestNewList, firstEarnings } = { ...props };
    const [pageSelected, setPage] = useState(1);
    const [canUse, setCanUse] = useState(true);
    useEffect(() => {
        window.addEventListener('canUsePagination', (e) => {
            setCanUse(e.detail)
        })
    }, [])
    return (
        <div aria-label="Page navigation pagination" >
            {pageNumber == 0 ? "" :
                <ul className="pagination" style={{ marginBottom: '0px', width: 'auto', margin: '0 auto' }} >
                    <li style={{ cursor: canUse == false ? 'not-allowed' : 'pointer' }} onClick={function () {
                        if (firstEarnings != "none") {

                            if (canUse == true && pageSelected != 1) {

                                setCanUse(false)

                                requestNewList(1)
                                setPage(1)

                            }
                        }
                    }} className="page-item">
                        <a className="page-link" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    {pageNumber == 1 ?
                        <li style={{ cursor: canUse == false ? 'not-allowed' : 'pointer' }} className="page-item active">
                            <a className="page-link" >1</a>
                        </li> : pageNumber > 6 ?
                            <>
                                {pageSelected <= 2 ? "" :
                                    <>
                                        <li style={{ cursor: canUse == false ? 'not-allowed' : 'pointer' }} onClick={function () {
                                            if (firstEarnings != "none") {
                                                if (canUse == true && pageSelected != 1) {
                                                    setCanUse(false)

                                                    requestNewList(1)
                                                    setPage(1)

                                                }
                                            }
                                        }} className="page-item"><a className="page-link" >1</a></li>
                                        ...
                                    </>
                                }
                                <li style={{ cursor: canUse == false ? 'not-allowed' : 'pointer' }} onClick={function () {
                                    if (firstEarnings != "none") {
                                        if (canUse == true && pageSelected != (pageSelected == [...Array(pageNumber)].length ? pageSelected - 2 : pageSelected - 1)) {
                                            if (pageSelected != 1) {
                                                setCanUse(false)
                                                requestNewList(pageSelected == [...Array(pageNumber)].length ? pageSelected - 2 : pageSelected - 1)
                                                pageSelected == 1 ? setPage(1) : setPage(pageSelected == [...Array(pageNumber)].length ? pageSelected - 2 : pageSelected - 1)
                                            }

                                        }
                                    }
                                }} className={pageSelected == 1 ? "page-item active" : "page-item"}>
                                    <a className="page-link" >
                                        {pageSelected == 1 ?
                                            1 :
                                            pageSelected == [...Array(pageNumber)].length ?
                                                pageSelected - 2 :
                                                pageSelected - 1}
                                    </a>
                                </li>
                                <li style={{ cursor: canUse == false ? 'not-allowed' : 'pointer' }} onClick={function () {
                                    if (firstEarnings != "none") {
                                        if (canUse == true && pageSelected != (pageSelected == 1 ?
                                            2 :
                                            pageSelected == [...Array(pageNumber)].length ?
                                                pageSelected - 1 : pageSelected)) {
                                            setCanUse(false)
                                            requestNewList(pageSelected == 1 ?
                                                2 :
                                                pageSelected == [...Array(pageNumber)].length ?
                                                    pageSelected - 1 :
                                                    pageSelected)
                                            pageSelected <= Math.floor([...Array(pageNumber)].length) ?
                                                setPage(pageSelected == 1 ?
                                                    2 :
                                                    pageSelected == [...Array(pageNumber)].length ?
                                                        pageSelected - 1 : pageSelected) :
                                                (function () { })()
                                        }
                                    }
                                }} className={pageSelected == 1 ?
                                    "page-item" :
                                    pageSelected != Math.floor([...Array(pageNumber)].length) ?
                                        "page-item active" :
                                        "page-item"}>
                                    <a className="page-link" >
                                        {pageSelected == 1 ?
                                            2 :
                                            pageSelected == [...Array(pageNumber)].length ?
                                                pageSelected - 1 :
                                                pageSelected}
                                    </a>
                                </li>
                                {pageSelected >= Math.floor([...Array(pageNumber)].length - 1) ? "" :
                                    <>
                                        <li style={{ cursor: canUse == false ? 'not-allowed' : 'pointer' }} onClick={function () {
                                            if (firstEarnings != "none") {
                                                if (canUse == true && pageSelected != (pageSelected == 1 ? 3 : pageSelected + 1)) {
                                                    setCanUse(false)
                                                    requestNewList(pageSelected == 1 ? 3 : pageSelected + 1)
                                                    setPage(pageSelected == 1 ? 3 : pageSelected + 1)
                                                }
                                            }
                                        }} className="page-item">
                                            <a className="page-link" > {pageSelected == 1 ? 3 : pageSelected + 1}</a>
                                        </li>
                                        ...
                                    </>
                                }
                                <li style={{ cursor: canUse == false ? 'not-allowed' : 'pointer' }} onClick={function () {
                                    if (firstEarnings != "none") {
                                        if (canUse == true && pageSelected != ([...Array(pageNumber)].length)) {
                                            setCanUse(false)
                                            requestNewList([...Array(pageNumber)].length)
                                            pageSelected <= Math.floor([...Array(pageNumber)].length) ?
                                                setPage([...Array(pageNumber)].length) :
                                                (function () { })()
                                        }
                                    }
                                }} className={pageSelected == Math.floor([...Array(pageNumber)].length) ?
                                    "page-item active" :
                                    "page-item"}>
                                    <a className="page-link"> {[...Array(pageNumber)].length}</a>
                                </li>
                            </> :
                            <>
                                {[...Array(pageNumber)].map(function (x, i) {
                                    return <li key={i} onClick={function () {
                                        if (firstEarnings != "none") {
                                            if (canUse == true && pageSelected != (i + 1)) {
                                                setCanUse(false)
                                                requestNewList(i + 1)

                                                setPage(i + 1)
                                            }
                                        }
                                    }} className={pageSelected == i + 1 ? "page-item active" : "page-item"}><a className="page-link" >{i + 1}</a></li>;
                                })}
                            </>}
                    <li style={{ cursor: canUse == false ? 'not-allowed' : 'pointer' }} onClick={function () {
                        if (firstEarnings != "none") {
                            if (canUse == true && pageSelected != (pageNumber < ([...Array(pageNumber)].length) ? (pageNumber + 1) : pageNumber)) {
                                setCanUse(false)
                                requestNewList(pageNumber < ([...Array(pageNumber)].length) ? (pageNumber + 1) : pageNumber)

                                setPage(pageNumber < ([...Array(pageNumber)].length) ? (pageNumber + 1) : pageNumber)
                            }
                        }
                    }} className="page-item">
                        <a className="page-link" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>}
        </div>)
}

export default PaginationPluto;