import ProgressiveImage from "react-progressive-image-loading"
import { Link } from "react-router-dom"


export const LogoHeader = () => {
    return (
        <Link to={'/'} className="header-logo">
             <ProgressiveImage
                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                src="/assets/img/icon.png"
                render={(src, style) => <img className="header-logo-mobile"  style={style} src={src} />} />
            <ProgressiveImage
                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                src="/assets/img/faucetify.png"
                render={(src, style) => <img className="header-logo-desktop"  style={style} src={src} />} />

           
        </Link>
    )
}