'use client'
import { socket } from '../Containers/MainContainer'
import { CreateToast, getLevelColor } from '../utils'
import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import PlutoMenu from './PlutoMenu'
import PlutoPendingOffers from './PlutoPendingOffers'
import PlutoUser from './PlutoUser'
import PlutoUserModal from './PlutoUserModal'
import VerifyAdmin from './PlutoVerify'

function PlutoSupportUser(props) {
    let emojiList = [
        {
            img: 'pepe_sus.webp',
            tag: ':pepe_sus:'
        },
        {
            img: 'pepe_business.webp',
            tag: ':pepe_business:',
        },
        {
            img: 'pepe_cool.webp',
            tag: ':pepe_cool:'
        },
        {
            img: 'pepe_lol.webp',
            tag: ':pepe_lol:'
        },
        {
            img: 'pepe_pray.webp',
            tag: ':pepe_pray:'
        },
        {
            img: 'simp_pepe.webp',
            tag: ':simp_pepe:'
        },
        {
            img: 'dogdance.gif',
            tag: ':dogdance:'
        },
        {
            img: 'cat_yes.gif',
            tag: ':catyes:'
        },
        {
            img: 'doge_cool.gif',
            tag: ':doge_cool:'
        },
        {
            img: 'doge_pet.gif',
            tag: ':doge_pet:'
        },
        {
            img: 'hand_fidget.gif',
            tag: ':hand_fidget:'
        },
        {
            img: 'no_horny.gif',
            tag: ':no_horny:'
        },
        {
            img: 'doge_face.webp',
            tag: ':doge_face:'
        },
        {
            img: 'lul.webp',
            tag: ':lul:'
        },
        {
            img: 'andrew.webp',
            tag: ':andrew:'
        },
        {
            img: 'rock_sus.gif',
            tag: ':rock_sus:'
        },
        {
            img: 'discord_mod.webp',
            tag: ':discord_mod:'
        },
        {
            img: 'dean_angry.webp',
            tag: ':dean_angry:'
        },
        {
            img: 'dean_happy.webp',
            tag: ':dean_happy:'
        },
        {
            img: 'smh.webp',
            tag: ':smh:'
        },
        {
            img: 'emotional_smoker.webp',
            tag: ':emotional_smoker:'
        },
        {
            img: 'confused_bean.webp',
            tag: ':confused_bean:'
        },
        {
            img: 'kekl.webp',
            tag: ':kekl:'
        },
        {
            img: 'jerry_why.webp',
            tag: ':jerry_why:'
        },
        {
            img: 'jerry_shock.webp',
            tag: ':jerry_shock:'
        },
        {
            img: 'jerry_cursed.webp',
            tag: ':jerry_cursed:'
        },
        {
            img: 'sad.webp',
            tag: ':('
        },
        {
            img: 'angry.webp',
            tag: ':angry:'
        },
        {
            img: 'greed.webp',
            tag: ':$D'
        },
        {
            img: 'hands.webp',
            tag: ':pray:'
        },
        {
            img: 'happy.webp',
            tag: ':)'
        },
        {
            img: 'happy2.webp',
            tag: ':ext:'
        },
        {
            img: 'happy3.webp',
            tag: ':D'
        },
        {
            img: 'cool.webp',
            tag: ':cool:'
        },
        {
            img: 'peach.webp',
            tag: ':peach:'
        },
        {
            img: 'egplant.webp',
            tag: ':egplant:'
        },
    ]
    const [tags, setTags] = useState([0, 0, 0, 0])
    const [msgLoaded, setLoadedMsg] = useState(false)
    const [userID, setUserId] = useState(-1);
    const [username, setUsername] = useState('');
    const [responded, setResponded] = useState(1)
    useEffect(() => {
        socket.on('tagManager', (data) => {
            setTags(data.usersTag)
        })
    }, [])
    const creaRef = useRef(null);
    let [showTagManager, setTagManager] = useState(false);
    function emitMessage(message) {
        socket.emit('emitSupportMessageAdmin', {
            message,
            user_id: userID
        })
    }
    const [rank, setRank] = useState(0)
    function handleData(data) {
        if (document.querySelector('.chat-mid-msgs') == null) {
            setTimeout(() => { handleData(data); }, 1000);
        } else {
            handleChatPopulating()
            setLoadedMsg(true)

            data.chatMessages.forEach(x => addMessageAdmin(x))
            setUsername(data.username);
            setUserId(data.user_id)
            setResponded(data.responded)
        }
    }
    function chatSupportMessagePacketAdmin() {
        socket.on('chatSupportMessagePacketAdmin', (data) => {
            setRank(data.rank)

            if (document.querySelector('.chat-mid-msgs') == null) {
                setTimeout(() => { handleData(data); }, 1000);
            } else {
                handleData(data)
            }

        })
    }
    function chatMessageAdmin() {
        socket.on('chatMessageAdmin', (data) => {
            addMessageAdmin(data)
            chatMessageAdmin()
        })
    }
    useEffect(() => {
        chatSupportMessagePacketAdmin()
        chatMessageAdmin()

        socket.emit('emitSupportMessagesAdmin', {
            chat: props.second_page
        });

    }, [])


    function handleChatPopulating() {
        let messageList = document.querySelector('.chat-mid-msgs');
        messageList.innerHTML = ''

    }
    function addMessageAdmin(data) {
        let messageText = data.message
        let messageList = document.querySelector('.chat-mid-msgs');
        if (messageList.children.length >= 40) {
            messageList.children[0].remove()
        }
        let message = document.createElement('div');
        message.classList.add('chat-mid-msg')
        message.classList.add(`chat-mid-msg-${data.messageId}`)
        message.classList.add('animate__animated')
        message.classList.add('animate__fadeIn')
        message.setAttribute('data-user-id', data.userId)
        message.setAttribute('data-user-name', data.username)
        message.style.width = '100%'
        emojiList.forEach(x => {
            if (messageText.includes(x.tag) == true) {
                messageText = messageText.replaceAll(x.tag, `<img class="emoji-chat-item" src='/assets/img/emojilist/${x.img}'/>`)
            }
        })

        let rank = data.rank;
        let userId = data.userId;
        message.innerHTML += `  <div class='chat-mid-msg-top'>
        <div class='chat-mid-msg-top-left'>
            <div class='chat-mid-msg-top-left-img'>
                <Image
                    onerror="this.src='https://eu.ui-avatars.com/api/?background=1e2f43&color=4aa1af&length=1&name=${data.username}'"
                    src="${data.profilePicture}" />
            </div>
            <div class='chat-mid-msg-top-left-name'>
                <p class="username-chat-${data.messageId}">${data.username}</p>

            </div>
            ${data.level == undefined ? '' : `<div class="chat-message-user-level" style='background:${getLevelColor(data.level)}'>${data.level}</div>`}
            ${rank == 0 ? "" : `<p class="rank-chat" style="background:
                    ${rank == 7 || rank == 4 ? "#b93030" :
                    rank == 8 || rank == 5 ? '#1f4eda' :
                        rank == 9 || rank == 6 ? '#392f96' :
                            rank == 3 || rank == 2 ? '#7f3487' :
                                rank == 10 ? '#195f84' : ''}">
                    ${rank == 9 ? "Admin" :
                    rank == 8 ? "Mod" :
                        rank == 7 ? "Support" :
                            rank == 10 ? "Owner" :
                                rank == 6 ? "Trial Admin" :
                                    rank == 5 ? "Trial Mod" :
                                        rank == 4 ? "Trial Support" :
                                            rank == 3 ? "Helper" :
                                                rank == 2 ? "Trial Helper" : ''}</p>'`}
        </div>
        <div class='chat-mid-msg-top-right'>
            <p class='chat-mid-msg-top-right-time'>${new Date(data.time).getHours()}:${new Date(data.time).getMinutes()} ${new Date(data.time).getDate()}/${new Date(data.time).getMonth() + 1}</p>
            <p class='chat-mid-msg-top-right-reply'> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"></path></svg>
            </p>
        </div>
    </div>
    <div class='chat-mid-msg-bottom'>
        ${messageText}
    </div>`
        if (document.querySelector(`.chat-mid-msg-${data.messageId}`) == null) {
            messageList.append(message)
        }
        if ((messageList.scrollHeight - messageList.scrollTop) < 1200) {
            messageList.scrollTo(0, messageList.scrollHeight + 100);
        }

    }
    const [showPendingOffers, setShowPendingOffers] = useState(false)
    const [showPendingUser, setShowPendingUser] = useState(false)
    const [showEmojiBox, setShowEmojiBox] = useState(false)

    return (
        <div>
            {showPendingOffers == true ? <PlutoPendingOffers second_page={userID} x={1} /> : ''}
            {showPendingUser ? <PlutoUserModal userID={userID} x={1} /> : ''}
            <div className={`chat-tab`} style={{ marginTop: '-20px', width: '100%', paddingTop: '0px' }}>
                {/* <div onClick={()=>{
                showChat==true?setChatStatus(false):setChatStatus(true);
            }} className="arrow-chat">
                {showChat==true?<i className="fa-light fa-caret-right"></i>:<i className="fa-light fa-caret-left"></i>}
            </div> */}
                <div className="earnfeed-beff"></div>
                <div className="chat-top" style={{ width: '100%', marginTop: '20px' }}>
                    <div className="chat-bottom-lower" style={{ display: 'flex', flexDirection: 'row', gap: '10px', color: '#fff', fontSize: '11px', overflow: 'auto', width: '100vw', height: '48px' }}>
                        <div className="online-circle" style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ display: 'flex', flexDirection: 'row', width: '160px', gap: '5px' }}>Talking to <p style={{ color: '#6db1c4', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{username}</p> </p>
                            <p style={{ display: 'inline-block', color: '#43e143' }}>( uid_{userID} )</p>
                        </div>
                        <div
                            className='btn' onClick={() => {
                                setShowPendingOffers(!showPendingOffers)

                            }} style={{ cursor: 'pointer', background: 'green', color: '#fff', fontSize: '10px', padding: '5px', height: '30px', maxWidth: '150px' }} target="_blank">{showPendingOffers == true ? 'Hide Pending Offers' : 'Show Pending Offers'}</div>
                        <div
                            className='btn' onClick={() => {
                                setShowPendingUser(!showPendingUser)

                            }} style={{ cursor: 'pointer', background: 'green', color: '#fff', fontSize: '10px', padding: '5px', height: '30px', maxWidth: '150px' }} target="_blank">{showPendingOffers == true ? 'Hide User' : 'Show User'}</div>

                        {responded == 0 ? <div className='btn' onClick={() => {
                            socket.emit('adminAction', {
                                action: 'marksolved_user_support_chat',
                                user_id: userID
                            })
                            setResponded(1)
                        }} style={{ margin: '0px 5px', cursor: 'pointer', background: '#a85050', color: '#f3f3f3', padding: ' 0 10px', borderRadius: '5px', fontSize: '10px', padding: '5px', height: '30px', maxWidth: '150px' }}>Mark as solved</div> : ''}


                    </div>
                </div>
                {msgLoaded == false ? <span className="loader"></span> : ''}
                <div className='chat-top'>
                    <div className='chat-top-left'>
                    </div>
                    <div className='chat-top-right'>
                    </div>
                </div>
                <div className="chat-mid-msgs" style={{ marginTop: '86px', width: '100%', paddingBottom: '163px' }}>
                </div>

                <div className="chat-bottom" style={{ bottom: '0px', width: '100%', paddingBottom: '14px', marginBottom: '0px' }}>


                    <div className="chat-bottom-upper" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>



                        <textarea className='chat-textarea' style={{ width: '100%' }} onChange={(e) => {
                            e.preventDefault();
                            if (showEmojiBox == true) {
                                setShowEmojiBox(false)
                            }
                            if (e.target.value.split(" ")[e.target.value.split(" ").length - 1].includes("@") && e.target.value.split(" ")[e.target.value.split(" ").length - 1].split("").length > 1
                                && !e.target.value.split(" ")[e.target.value.split(" ").length - 1].includes("@uid")) {
                                setTagManager(true)
                                socket.emit('emitTagManager', {
                                    tag: e.target.value.split(" ")[e.target.value.split(" ").length - 1]
                                })
                            } else {
                                setTagManager(false)
                            }
                        }} onKeyUp={(e) => {
                            if (e.key == 'Enter') {
                                if (msgLoaded == false) {
                                    CreateToast("Please wait for messages to load firs.", creaRef, 0)
                                } else {
                                    if (e.target.value.trim().length >= 2) {
                                        // addMessage('Laurentiu',1020,e.target.value);
                                        emitMessage(e.target.value)
                                        e.target.value = ''
                                    }
                                }
                            }
                        }} />
                        <button className="btn button-disabled waves-effect send-msg-mobile-btn" style={{ width: '30px' }}>
                            send
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PlutoSupportUser