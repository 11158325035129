import React, { useEffect } from 'react'
// import { GoogleTranslate } from '../GoogleTranslate'
import { EUC, getLocalStorage } from '../components/utils'
import { Link } from 'react-router-dom'


function TopTOSCOntainer({ edited, title, second_page }) {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='terms-top-info'>
            <div className='terms-top-info-title'>{title}</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {/* <GoogleTranslate /> */}
                <div className='terms-top-info-last-edit'>Last Edited on {edited}</div></div>
            {window != undefined ?
                <div className='cat-earn-mob' style={{marginTop:'10px'}}>
                    <Link className={`cat-earn-mob-item ${second_page=='terms'?'button-brand':''}`}  to="/policies/terms">Terms</Link>
                    <Link className={`cat-earn-mob-item ${second_page=='privacy' || second_page=='privacy-eu'?'button-brand':''}`}  to={`/policies/privacy${EUC.includes(getLocalStorage('country')) ? '-eu' : ''}`}>Privacy</Link>
                    <Link className={`cat-earn-mob-item ${second_page=='cookies'?'button-brand':''}`}  to="/policies/cookies">Cookies</Link>
                    {getLocalStorage('country') == "US" ? <Link className={`cat-earn-mob-item ${second_page=='ccpa'?'button-brand':''}`}  to="/policies/ccpa">CCPA</Link> : ''}
                </div>
                : ''
            }
        </div>
    )
}

export default TopTOSCOntainer