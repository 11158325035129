import { useEffect, useRef, useState } from "react"
import { canUsePagination, CreateToast, getLevelColor, isLoggedIn, numberWithCommas, validateEmail, validateUsername } from "../components/utils";
import { socket } from "../components/Containers/MainContainer";
import { Link, Navigate } from "react-router-dom";
import { byIso } from "country-code-lookup";
import ProgressiveImage from "react-progressive-image-loading";
import { FaCheckCircle, FaDollarSign, FaPencilAlt, FaTicketAlt } from "react-icons/fa";
import UserIDProfileModalContainerDTImg from "../components/earn/UserIDProfileModalContainerDTImg";
import Skeleton from "react-loading-skeleton";
import { IoMdSave } from "react-icons/io";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import '../assets/style/Profile.css'

export const UserProfileContainer = () => {
  
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    let [loggedIN, setLogged] = useState(isLoggedIn());
    useEffect(() => { setLogged(isLoggedIn()) }, [isLoggedIn()])
    const [isOpen, setIsOpen] = useState(true)
    const [userDetails, setUserDetails] = useState({})
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [userEarnings, setUserEarnings] = useState([])
    const [selectAll, setSelectAll] = useState(false);
    const [country, setCountry] = useState('EU')
    const [countryFlag, setCountryFlag] = useState('')
    const [usedTab, setUsedTab] = useState(0);
    const [editUsername, setEditUsername] = useState(false)
    const [username, setUsername] = useState('');
    const [editEmail, setEditEmail] = useState(false)
    const [email, setEmail] = useState('');
    const [usedSubTab, setUsedSubTab] = useState(0);
    const [category, setCategory] = useState(0)
    const creaRef = useRef(null);
    function getEarnings(thisCategory) {
        if (category != thisCategory || usedTab == 1) {
            setUsedSubTab(thisCategory)
            setUserEarnings(undefined)
            setCategory(thisCategory)
            canUsePagination(false)
            if (loggedIN == true) {
                setlazyState({ ...lazyState, page: 0, first: 0 })

            }
        }
    }
    function getWithdrawals(thisCategory) {
        if (category != thisCategory || usedTab == 0) {
            setUsedSubTab(thisCategory)
            setUserEarnings(undefined)
            setCategory(thisCategory)
            canUsePagination(false)
            userEarningsSetter()
            if (loggedIN == true) {
                setlazyState({ ...lazyState, page: 0, first: 0 })

                //     socket.emit('getUserWithdrawals', {
                //         page: 1,
                //         category: thisCategory,
                //     })
            }
        }
    }
    useEffect(() => {
        if (loggedIN == false) {
            <Navigate to='/earn' />
        }
       

            ;
    }, [])
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    let networkTimeout = null;

    useEffect(() => {
        loadLazyData();
    }, [lazyState]);

    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
        if (loggedIN == true) {
            userEarningsSetter()
            if (usedTab == 1) {
                socket.emit('getUserWithdrawals', {
                    user: 'me',
                    page: lazyState.page + 1,
                    category: category,
                })
            } else {
                socket.emit('getUserEarnings', {
                    user: 'me',
                    page: lazyState.page + 1,
                    category: category,
                })
            }

        }

    };



    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setlazyState(event);
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setUserEarnings(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            userEarnings.getCustomers().then((data) => {
                setSelectAll(true);
                setUserEarnings(data.customers);
            });
        } else {
            setSelectAll(false);
            setUserEarnings([]);
        }
    };
    const onPage = (event) => {
        setlazyState(event);
    };
    useEffect(() => {
        setIsOpen(true)
    }, [])
    function userEarningsSetter() {
        socket.on('userEarningsProfile', (data) => {
            setUserEarnings(data.earnings)
            setTotalRecords(data.pages * 5)
            setLoading(false);
        })
    }
    useEffect(() => {

        socket.on('userProfile', (data) => {
            try {
                if (data.userDetails.country != undefined) {
                    setCountry(byIso(data.userDetails.country).country)
                }
            } catch (e) {
                setCountry('EU')
            }
            setCountryFlag(data.userDetails.country)
            setUserDetails(data.userDetails)
            setEmail(data.userDetails.email)

            // setUserProfile({ ...data.userDetails })
        })
        userEarningsSetter()
        if (loggedIN == true) {
            socket.emit('getUserDetails', {
                user: 'me',
            })
            socket.emit('getUserEarnings', {
                user: 'me',
                page: 1,
                category: category,
            })
        }
        window.claimGiftCard = async (id) => {


            socket.emit('userAction', {
                action: 'gift_card_data',
                id: id
            })

        }
    }, [])

    return (loggedIN == false ? window.location.href = '/earn' :
            <div className='profile-container'>
                 <div className="cat-earn-mob" style={{margin:'0px'}}>
                        <Link className={`cat-earn-mob-item sel`} >
                            Profile
                        </Link>
                        <Link className={`cat-earn-mob-item`} to={'/profile/settings'}>
                            Settings
                        </Link>
                    </div>
                <div className='profile-top-user'>
                    {userDetails.username == undefined ? <Skeleton height={90} width={90} style={{ borderRadius: '100%' }} /> :
                        <img width={50} height={50} alt='C' className='img-profile'  src={`https://api.dicebear.com/9.x/fun-emoji/svg?seed=${userDetails.username}`} />}
                        <p className='profile-top-user-name'>{userDetails == undefined ? <Skeleton height={40} width={100} /> :
                            editUsername == true ?
                                <div className="input-container" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '5px' }}>
                                    <input style={{ fontSize: '14px', height: '25px', color: '#fff', paddingLeft: '2px', borderBottom: '1px solid gray', background: 'transparent' }} defaultValue={userDetails.username} onChange={(e) => {
                                        setUsername(e.target.value)
                                        if (validateUsername(e.currentTarget.value) == false) {
                                            CreateToast('Username not valid.', creaRef, 1);
                                        } else {

                                        }

                                    }} type="text" className="password-input" name="amount" placeholder="Enter username" autoComplete="off" />
                                </div> :
                                <p style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '5px' }}>{userDetails.username} 
                                  {userDetails.level == undefined ?<Skeleton height={20} width={80} />: <div className="user-modal-content-header-left-info-level" style={{marginTop:'3px'}}> Level {numberWithCommas(userDetails['level'])}</div>}
                                  </p>
                                  } 
                                  </p>
                        <div className='daily-rewards-header' >
                        <div className="profile-badge-info">
                            <div className="profile-badge-info-item">
                                <div className="profile-badge-info-item-title">
                                    Joined
                                </div>
                                <div className="profile-badge-info-item-data">
                                {userDetails == undefined ? '' : (userDetails.joined+'').split('Joined').join('')}
                                </div>
                            </div>
                        </div>
                        <div className="profile-badge-info">
                            <div className="profile-badge-info-item">
                                <div className="profile-badge-info-item-title">
                                    Country
                                </div>
                                <div className="profile-badge-info-item-data">
                                <img src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryFlag.toUpperCase()}.svg`}
                                />
                               {country}
                                </div>
                            </div>
                        </div>
                        <div className="profile-badge-info">
                            <div className="profile-badge-info-item">
                                <div className="profile-badge-info-item-title">
                                    User ID
                                </div>
                                <div className="profile-badge-info-item-data">
                                uid_{userDetails == undefined ? <Skeleton height={20} width={40} /> : userDetails.userId}
                                </div>
                            </div>
                        </div>
                        <div className="profile-badge-info">
                            <div className="profile-badge-info-item">
                                <div className="profile-badge-info-item-title">
                                    Created
                                </div>
                                <div className="profile-badge-info-item-data">
                                 with {userDetails == undefined ? <Skeleton height={20} width={40} /> : userDetails.created_with}
                                </div>
                            </div>
                        </div>
                        </div>
                       
                       
                </div>
                <div className='profile-top'>

                    <div className='profile-top-left'>
                       
                    <div className='daily-rewards-header' >
                                    <div className="user-modal-content-header-mid-section-item">
                                        <div className="user-modal-content-header-mid-section-item-data">
                                            Coins earned
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.coinsEarned == undefined ?<Skeleton height={20} width={80} />: 
                                        <><ProgressiveImage  preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            src="/assets/img/faucetify_coin_small.png"
                                            render={(src, style) => <img alt="coin image" className="header-balance-icon" style={{marginBottom:'3.5px', marginRight:'1px'}}
                                                loading="lazy" src={src} />}
                                             />  {numberWithCommas(userDetails['coinsEarned'])}</>}
                                        </div>
                                    </div>
                                    <div className="user-modal-content-header-mid-section-item">
                                         <div className="user-modal-content-header-mid-section-item-data">
                                            Tickets Earned
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.ticketsEarned == undefined ?<Skeleton height={20} width={80} />:<>
                                        <FaTicketAlt  className="ticket-design"/> 
                                         {numberWithCommas(userDetails['ticketsEarned'])}
                                        </>}
                                        </div>
                                    </div>
                                    <div className="user-modal-content-header-mid-section-item">
                                         <div className="user-modal-content-header-mid-section-item-data">
                                            Offers Completed
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.offersCompleted == undefined ?<Skeleton height={20} width={80} />:<>
                                       
                                         {numberWithCommas(userDetails['offersCompleted'])}
                                        </>}
                                        </div>
                                    </div>
                                    <div className="user-modal-content-header-mid-section-item">
                                         <div className="user-modal-content-header-mid-section-item-data">
                                            Surveys Completed
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.surveysCompleted == undefined ?<Skeleton height={20} width={80} />:<>
                                         {numberWithCommas(userDetails['surveysCompleted'])}
                                        </>}
                                        </div>
                                    </div>
                                </div>
                                <div className='daily-rewards-header' >
                                    <div className="user-modal-content-header-mid-section-item">
                                        <div className="user-modal-content-header-mid-section-item-data">
                                        Balance
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.balance == undefined ?<Skeleton height={20} width={80} />: 
                                        <><ProgressiveImage  preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            src="/assets/img/faucetify_coin_small.png"
                                            render={(src, style) => <img alt="coin image" className="header-balance-icon" style={{marginBottom:'3.5px', marginRight:'1px'}}
                                                loading="lazy" src={src} />}
                                             />  {numberWithCommas(userDetails['balance'])}</>}
                                        </div>
                                    </div>
                                    <div className="user-modal-content-header-mid-section-item">
                                         <div className="user-modal-content-header-mid-section-item-data">
                                         Referee
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.referee == undefined ?<Skeleton height={20} width={80} />:<>
                                         {numberWithCommas(userDetails['referee'])}
                                        </>}
                                        </div>
                                    </div>
                                    <div className="user-modal-content-header-mid-section-item">
                                         <div className="user-modal-content-header-mid-section-item-data">
                                         Referral Earnings
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.referralEarnings == undefined ?<Skeleton height={20} width={80} />:<>
                                        <ProgressiveImage  preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            src="/assets/img/faucetify_coin_small.png"
                                            render={(src, style) => <img alt="coin image" className="header-balance-icon" style={{marginBottom:'3.5px', marginRight:'1px'}}
                                                loading="lazy" src={src} />}
                                             /> 
                                         {' '+numberWithCommas(userDetails['referralEarnings'])}
                                        </>}
                                        </div>
                                    </div>
                                    <div className="user-modal-content-header-mid-section-item">
                                         <div className="user-modal-content-header-mid-section-item-data">
                                         Users Invited
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.usersReferred == undefined ?<Skeleton height={20} width={80} />:<>
                                         {numberWithCommas(userDetails['usersReferred'])}
                                        </>}
                                        </div>
                                    </div>
                                </div>
                                
                    </div>
                </div>
                <div className='profile-bottom'>
                    <div className="cat-earn-mob" >
                        <a className={`cat-earn-mob-item ${usedTab == 0 && usedSubTab != 3 ? 'sel' : ''}`} onClick={() => {
                            if (usedTab == 0 && usedSubTab != 3) { } else {
                                getEarnings(0)
                            }

                            setlazyState({ ...lazyState, page: 0, first: 0 })
                            setUsedTab(0)
                        }}>
                            Earnings
                        </a>
                        <a className={`cat-earn-mob-item ${usedTab == 1 ? 'sel' : ''}`} onClick={() => {
                            if (usedTab != 1) { getWithdrawals(0) }
                            setlazyState({ ...lazyState, page: 0, first: 0 })
                            setUsedTab(1)
                        }}>
                            Withdrawals
                        </a>
                        <a className={`cat-earn-mob-item ${usedTab == 0 && usedSubTab == 3 ? 'sel' : ''}`} onClick={() => {
                            if (usedTab == 0 && usedSubTab == 3) { } else { getEarnings(3) }
                            setUsedTab(0)
                            setlazyState({ ...lazyState, page: 0, first: 0 })
                            setUsedSubTab(3)
                        }}>
                            Chargebacks
                        </a>
                        {/* <a className={`cat-earn-mob-item ${usedTab == 2 ? 'sel' : ''}`} onClick={() => {
                            if (usedTab != 2) { getWithdrawals(0) }
                            setlazyState({ ...lazyState, page: 0, first: 0 })
                            setUsedTab(2)
                        }}>
                            Clicked
                        </a> */}
                    </div>
                    <div className="cat-earn-mob">
                        {usedTab == 0 && usedSubTab != 3 ?
                            <><a className={`cat-earn-mob-item ${usedSubTab == 0 ? 'sel' : ''}  waves-effect`} onClick={() => getEarnings(0)}>
                                All
                            </a>
                                <a className={`cat-earn-mob-item ${usedSubTab == 1 ? 'sel' : ''}  waves-effect`} onClick={() => getEarnings(1)}>
                                    Credited
                                </a>
                                <a className={`cat-earn-mob-item ${usedSubTab == 2 ? 'sel' : ''}  waves-effect`} onClick={() => getEarnings(2)}>
                                    Pending
                                </a></> : ''}
                        {usedTab == 1 ?
                            <>  <a className={`cat-earn-mob-item ${usedSubTab == 0 ? 'sel' : ''}  waves-effect`} onClick={() => getWithdrawals(0)}>
                                All
                            </a>
                                <a className={`cat-earn-mob-item ${usedSubTab == 1 ? 'sel' : ''}  waves-effect`} onClick={() => getWithdrawals(1)}>
                                    Paid
                                </a>
                                <a className={`cat-earn-mob-item ${usedSubTab == 2 ? 'sel' : ''}  waves-effect`} onClick={() => getWithdrawals(2)}>
                                    Pending
                                </a>
                                <a className={`cat-earn-mob-item ${usedSubTab == 3 ? 'sel' : ''}  waves-effect`} onClick={() => getWithdrawals(3)}>
                                    Review
                                </a></> : ''}
                    </div>

                    {userEarnings == undefined ? <Skeleton height={300} width={'100%'} style={{ overflow: 'hidden' }} /> : usedTab == 0 ?
                        <DataTable
                            style={{ width: '100%', borderRadius: '10px' }}
                            value={userEarnings} lazy dataKey="id" paginator
                            first={lazyState.first} rows={5} totalRecords={totalRecords} onPage={onPage}
                            onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                            onFilter={onFilter} filters={lazyState.filters} loading={loading} alwaysShowPaginator={false}
                            selection={userEarnings} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
                            <Column field="name" header="Name" body={(product) => {
                                return <UserIDProfileModalContainerDTImg image={product.image} name={product.name} wallName={product.wallName} />
                            }}></Column>
                            <Column field="reward" header="Reward" body={(product) => {
                                return <p style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                    { <ProgressiveImage
                                        preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        src={'/img/faucetify_coin_small.png'}
                                        render={(src, style) => <img width={10}
                                            height={10}
                                            alt="C" src={src} />}
                                    />}
                                    {(product.reward)}</p>
                            }}></Column>
                            <Column field="status" header="Status" body={(product) => {
                                return <p style={{
                                    display: 'flex', alignItems: 'center', gap: '2px', color:
                                        product.status == "credited" ? 'green' : product.status == "pending" || product.status == "review" ? '#b7b71f' : '#c04949'
                                }}>

                                    {product.status}</p>
                            }}></Column>

                            <Column field="timeClaimed" header="Time"></Column>
                        </DataTable> :
                        <DataTable
                            style={{ width: '100%', borderRadius: '10px' }}
                            value={userEarnings} lazy dataKey="id" paginator page={lazyState.page + 1}
                            first={lazyState.first} rows={5} totalRecords={totalRecords} onPage={onPage}
                            onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                            onFilter={onFilter} filters={lazyState.filters} loading={loading} alwaysShowPaginator={false}
                            selection={userEarnings} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
                            <Column field="name" header="Name" body={(product) => {
                                return <UserIDProfileModalContainerDTImg image={product.image} name={product.name} wallName={product.wallName} />
                            }}></Column>
                            <Column field="reward" header="Reward" body={(product) => {
                                return <p style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                    { <ProgressiveImage
                                        preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        src={'/img/faucetify_coin_small.png'}
                                        render={(src, style) => <img width={10}
                                            height={10}
                                            alt="C" src={src} />}
                                    />}
                                    {(product.reward)}</p>
                            }}></Column>
                            <Column field="status" header="Status" body={(product) => {
                                return <p style={{
                                    display: 'flex', alignItems: 'center', gap: '2px', color:
                                        product.status == "paid" ? 'green' : product.status == "pending" || product.status == "review" ? '#b7b71f' : '#c04949'
                                }} >  {product.status}</p>
                            }}></Column>
                            <Column field="timeClaimed" header="Data" alignHeader='right' body={(product) => {
                                return <div className='infWdthf2'><div className='infWdthf' dangerouslySetInnerHTML={{ __html: product.timeClaimed }} /></div>
                            }}></Column>
                        </DataTable>}
                </div>

            </div >
    )
}